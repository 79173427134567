export const ApiRootConstants = {
  dashboardApiRoot: process.env.REACT_APP_apiRootUrls_dashboardApiRoot,
  dataCollectorApiRoot: process.env.REACT_APP_apiRootUrls_dataCollectorApiRoot,
  venueManagementApiRoot: process.env.REACT_APP_apiRootUrls_venueManagementApiRoot,
  OccupancyIndicatorApiRoot: process.env.REACT_APP_apiRootUrls_OccupancyIndicatorApiRoot,
  vendorApiRoot: process.env.REACT_APP_apiRootUrls_vendorApiRoot,
  ARMVendorApiRoot: process.env.REACT_APP_apiRootUrls_ARMVendorApiRoot,
  userManagementApiRoot: process.env.REACT_APP_apiRootUrls_userManagementApiRoot,
  reportApiRoot: process.env.REACT_APP_apiRootUrls_reportApiRoot,
  notificationApiRoot: process.env.REACT_APP_apiRootUrls_notificationApiRoot,
  numberValuesApiRoot: process.env.REACT_APP_apiRootUrls_NumberValuesApiRoot
};
  