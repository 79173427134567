import { createSelector } from 'reselect';

export const selectDashboardById = createSelector(
    [
        state => state.dashboards.dashboards,
        (state, dashboardId) => dashboardId
    ],
    (dashboards, dashboardId) => dashboards.find(e => e.id === dashboardId)
)

export const selectDashboardLastUpdatedById = createSelector(
    [
        state => state.dashboards.dashboardsLastUpdated,
        state => state.dashboards.dashboards,
        (state, dashboardId) => dashboardId
    ],
    (dashboardsLastUpdated, dashboards, dashboardId) =>
        dashboardsLastUpdated[dashboardId] ??
        dashboards.find(e => e.id === dashboardId)?.lastUpdated
)

export const selectDashboardLastRequestedById = createSelector(
    [
        state => state.dashboards.dashboardsLastRequested,
        (state, dashboardId) => dashboardId
    ],
    (dashboardsLastRequested, dashboardId) => dashboardsLastRequested[dashboardId] ?? {}
)
