import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ModeItem from './ModeItem';
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage"
import {
    getModeList,
    getModeIsLoading,
    getSelectedVenue
} from '../../../_reducers/mode.selector';
import {
    loadModeList,
} from '../../../_actions/mode.actions';

const mapStateToProps = state => ({
    isLoading: getModeIsLoading(state),
    list: getModeList(state),
    selectedVenue: getSelectedVenue(state)
});

const mapDispatchToProps = dispatch => ({
    startLoadingModes: (venueId) => dispatch(loadModeList(venueId))
});

const VenueMode = (props) => {
    const { list, selectedVenue, isLoading, startLoadingModes } = props;

    useEffect(() => {
        startLoadingModes(selectedVenue?.id);
    }, [startLoadingModes, selectedVenue?.id]);

    return (
        <LoadPleaseWait show={isLoading} >
            <ModeItem
                data={list}
            />
        </LoadPleaseWait>
    );
};


export default connect(mapStateToProps, mapDispatchToProps)(VenueMode);