
const gridLayoutDashboardTheme = (theme) => ({
  dashbaordGridLayout: {
    dashboard: {
      backgroundColor: props => props?.mainAreaBackgroundColor ?? "white",
      display: "flex",
      flexDirection: "row",
      height: "100%"
    },
    dashboardPanel: {
      display: "flex",
      flexDirection: "column",
      flex: "auto",
      verticalAlign: "top",
      overflow: "hidden",
    },
    dashboardPanel_DashboardGrid: {
      overflow: "auto",
      flex: "auto",
      maxHeight: "calc(100%-650px)",
      maxWidth: "100%",
    },
    widgetPanelListMaximize: {
      display: "inline-block",
      width: "338px",
      zIndex: "1",
      background: "white",
      borderLeft: "1px solid lightgrey",
    },
    widgetPanelListMinimize: {
      display: "inline-block",
      width: "90px",
      zIndex: "1",
      background: "white",
      borderLeft: "1px solid lightgrey",
    }
  },
  draggableWidgetType: {
    draggablePanel: {
      padding: "10px 14px",
      display: "flex",
      flexDirection: "row",
      zIndex: "100",
      "&:hover $iconPanel $icon": {
          borderColor: theme.palette.color.primary.main
      }
    },
    iconPanel: {
      display: "inline-block",
      verticalAlign: "top",
    },
    icon: {
      width: "80px",
      height: "80px",
      verticalAlign: "middle",
      textAlign: "center",
      border: "1px solid #DCE3E8",
      padding: "5px",
    },
    iconMinimize: {
      width: "60px",
      height: "64px",
      verticalAlign: "middle",
      textAlign: "center",
      border: "1px solid #DCE3E8",
      padding: "5px",
      "&:hover": {
        borderColor: theme.palette.color.primary.main
      }
    },
    widgetDetailPanel: {
      flex: "auto",
      verticalAlign: "top",
      paddingLeft: " 20px",
    },
    widgetCaption: {
      textTransform:"capitalize",
      fontWeight:"bold",
      fontSize: "20px",
      fontWeight: "500", 
      fontSize: "18px", 
      color: "#031820",
    },
    widgetDescription: {
      maxHeight: "100px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: "#627E84", 
      fontSize: "14px",
    },
  },
  widgetTypesPanel: {
    panel: {
      margin: "0px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    panelTitle: {
      textTransform: "uppercase",
      fontWeight: "bold",
      padding: "10px 20px",
      fontSize: "1.5em",
      minHeight: "60px",
      borderBottom: "1px solid lightgrey",
      display: "flex",
      alignItems: "center"
    },
    panelTitleCollapseIcon: {
      flex: "auto",
      cursor: "pointer"
    },
    panelTitleText: {
      fontSize: "20px", 
      color: "#031820", 
      fontWeight: "500",
    },
    widgetListPanel: {
      flex: "auto",
      overflow: "auto",
      minHeight: "calc(100vh - 259px)",
      maxHeight: "calc(100vh - 260px)",
      height: "100%"
    },
    panelFooterMaximize: {
      borderTop: "1px solid lightgrey",
      padding: "10px 20px",
      textAlign: "right",
    },
    panelFooterMinimize: {
      borderTop: "1px solid #DCE3E8",
      padding: "10px 0px",
      textAlign: "center",
    }, 
  
  }
})
export default gridLayoutDashboardTheme;