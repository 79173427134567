import React from "react";
import { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const buildPointsAttr = (vertices) => {
  return vertices.map((v) => v.x + "," + v.y).join(" ");
};

const buildPolygonSvg = (vertices) => {
  return '<polygon points="' + buildPointsAttr(vertices) + '" />';
};

export const parsePolygonSvg = (svg) => {
  let points = "";
  const matches = svg.match('points="([0-9 ,]+)"');
  if (matches && matches.length > 1) {
    points = matches[1];
  }
  return points;
};

const useStyles = makeStyles((theme) => theme.map.polygonEditor);

export const PolygonEditor = ({layoutSize, scaleFactor, overlayId, markup, onPolygonEdited}) => {
  const classes = useStyles({scaleFactor: scaleFactor});

  const [vertices, setVertices] = useState([]);
  const [currentPhase, setCurrentPhase] = useState({
    name: "view"
  });

  const [mouseOverVertex, setMouseOverVertex] = useState({index: -1, middle: false});
  //const [mouseOverMiddleVertexIndex, setMouseOverProvisionalVertexIndex] = useState(-1);

  const layoutRef = useRef();

  useEffect(() => {
    switch (currentPhase.name) {
      case "view":
        setMouseOverVertex({index: -1, middle: false});
        break;
      case "vertexEditing":
        setMouseOverVertex({index: -1, middle: false});
        break;
      case "middleVertexEditing":
        setMouseOverVertex({index: -1, middle: false});
        break;
      case "vertexSelected":
        break;
      default:
    }
  }, [currentPhase]);
  
  useEffect(() => {
    const points = parsePolygonSvg(markup);
    setVertices( points.split(' ').map((pointStr) => {
      const coordinates = pointStr.split(',').map( coordinateStr => parseFloat(coordinateStr));
      return {x: coordinates[0], y: coordinates[1]};
    }));
  }, [markup]);

  // Listen for the Esc and Del keys.
  useEffect(() => {
    const onKeyDown = (e) => {
      if (e.key === "Escape" ) {
        // Escape key stops/cancels editing the vertex.
        if (currentPhase.name === "vertexEditing") {
          setVertices((prev) => prev.map( (v,i) => {
            return i === currentPhase.vertexIndex ? currentPhase.initialVertex : v;
          }));
          setCurrentPhase({
            name: "view"
          });
        } else if (currentPhase.name === "middleVertexEditing") {
          setVertices((prev) => prev.filter( (v,i) => {
              return i !== currentPhase.vertexIndex;
            })
          );
          setCurrentPhase({
            name: "view"
          });
        }
      } else if (e.key === "Delete") {
        // The Delete key deletes the selected vertex.
        if (currentPhase.name === "vertexSelected") {
          const editedVertices = vertices.filter((v,i) => {
            return i !== currentPhase.vertexIndex;
          });
          setVertices(editedVertices);
          setCurrentPhase({
            name: "view"
          });
          onPolygonEdited({
            overlayId: overlayId,
            markup: buildPolygonSvg(editedVertices)
          });
        }
      }
    };
    document.addEventListener("keydown", onKeyDown);
    return () => {
      document.removeEventListener("keydown", onKeyDown);
    }
  }, [currentPhase, vertices, onPolygonEdited, overlayId]);

  const getPoint = ({clientX, clientY}) => {
    const {top, left, width, height} = layoutRef.current.getBoundingClientRect();
    return {
      x: Math.round((clientX - left) * layoutSize.width / width), 
      y: Math.round((clientY - top) * layoutSize.height / height)
    };
  };

  // Index of the first vertex is 0.
  const onVertexMouseDown = (e, vertexIndex) => {
    if (e.button === 0 ) {
      const {x, y} = getPoint(e);
      const vertex = vertices[vertexIndex];
      setCurrentPhase({
        name: "vertexEditing",
        vertexIndex: vertexIndex,
        offset: {
          x: x - vertex.x,
          y: y - vertex.y
        },
        initialVertex: {
          x: vertex.x,
          y: vertex.y
        }
      });
    }
  };

  const onMouseMove = (e) =>{
    const {x, y} = getPoint(e);
    switch(currentPhase.name) {
      case "middleVertexEditing":
        currentPhase.vertexMoved = true;
        /* falls through */
      case "vertexEditing" :
        const newVertex = {
          x: x - currentPhase.offset.x,
          y: y - currentPhase.offset.y
        };
        setVertices((prev) => prev.map( (v,i) => {
          return i === currentPhase.vertexIndex ? newVertex : v;
        }));
        break;
      default:
    }
  };

  const onMouseUp = (e) => {
    if (e.button === 0) {
      const {x, y} = getPoint(e);
      switch(currentPhase.name) {
        case "middleVertexEditing":
          if (!currentPhase.vertexMoved) {
            setVertices( prev => prev.filter((v,i) => i !== currentPhase.vertexIndex));
            setCurrentPhase({
              name: "view",
              // To avoid ugly flickering effect when selecting a vertex, 
              // we need to ignore the mouseOver venue when it is 
              // triggered next.
              ignoreMiddleVertexMouseOver: true,
            });
            break;
          }
          /* falls through */
        case "vertexEditing" :
          const newVertex = {
            x: x - currentPhase.offset.x,
            y: y - currentPhase.offset.y
          };
          const editedVertices = vertices.map( (v,i) => {
            return i === currentPhase.vertexIndex ? newVertex : v;
          });
          setVertices(editedVertices);
          onPolygonEdited({
            overlayId: overlayId,
            markup: buildPolygonSvg(editedVertices)
          });
          setCurrentPhase({
            name: "vertexSelected",
            vertexIndex: currentPhase.vertexIndex,
            // To avoid ugly flickering effect when selecting a vertex, 
            // we need to ignore the mouseOver venue when it is 
            // triggered next.
            ignoreVertexMouseOver: true,
          });
          break;
        default:
      }
    }
  };

  const onVertexMouseOver = (e, i) => {
    if (currentPhase.name === "vertexSelected") {
      if (currentPhase.ignoreVertexMouseOver) {
        setCurrentPhase((prev) => ({
          ...prev,
          ignoreVertexMouseOver: false
        }));
      } else {
        setMouseOverVertex({index: i, middle: false});  
      }
    } else if (currentPhase.name === "view") {
      setMouseOverVertex({index: i, middle: false});
    }
  };

  const onVertexMouseLeave = (e, i) => {
    if (currentPhase.name === "view" || currentPhase.name === "vertexSelected") {
      setMouseOverVertex({index: -1, middle: false});
    }
  };

  const renderedMouseOverVertexHighlight = 
    (mouseOverVertex.index === -1 
    || mouseOverVertex.middle === true 
    || currentPhase.name === "vertexEditing"
    || currentPhase.name === "middleVertexEditing")
    ? null :
    <ellipse
      cx={vertices[mouseOverVertex.index].x}
      cy={vertices[mouseOverVertex.index].y}
      className={classes.vertexHighlight}
    />;

  const renderedVertices = vertices.map((v,i) => {
    let isSelected = false;
    if ( currentPhase.name === "vertexSelected" || currentPhase.name === "vertexEditing" || currentPhase.name === "middleVertexEditing") {
      if (currentPhase.vertexIndex === i) {
        isSelected = true;
      }
    }
    return (
      <ellipse 
        key={i}
        cx={v.x}
        cy={v.y}
        className={`${classes.vertex} ${isSelected?classes.selectedVertexAddendum : ""}`}
        onMouseDown={(e) => onVertexMouseDown(e, i)} 
        onMouseOver={(e) => onVertexMouseOver(e, i)}
        onMouseLeave={(e) => onVertexMouseLeave(e, i)}
      />
    );
  });

  // Venue handlers for middle vertices.
  const onMiddleVertexMouseDown = (e, middleVertexIndex) => {
    if (e.button === 0 ) {
      const {x, y} = getPoint(e);
      const middleVertex = getMiddleVertex(middleVertexIndex);
      
      setVertices(
        vertices.slice(0, middleVertexIndex + 1).concat(middleVertex, vertices.slice(middleVertexIndex + 1))
      );
      setCurrentPhase({
        name: "middleVertexEditing",
        vertexIndex: middleVertexIndex + 1,
        //isAddingVertex: true,
        offset: {
          x: x - middleVertex.x,
          y: y - middleVertex.y
        },
        initialVertex: {
          x: middleVertex.x,
          y: middleVertex.y
        }
      });
      setMouseOverVertex({index: middleVertexIndex + 1, middle: false});
    }
  };

  const onMiddleVertexMouseOver = (e, i) => {
    if (currentPhase.name === "view") {
      if (currentPhase.ignoreMiddleVertexMouseOver) {
        setCurrentPhase((prev) => ({
          ...prev,
          ignoreMiddleVertexMouseOver: false
        }));
      } else {
        setMouseOverVertex({index: i, middle: true});  
      }
    } else if (currentPhase.name === "vertexSelected") {
      setMouseOverVertex({index: i, middle: true});
    }
  };

  const onMiddleVertexMouseLeave = (e, i) => {
    if (currentPhase.name === "view" || currentPhase.name === "vertexSelected") {
      setMouseOverVertex({index: -1, middle: false});
    }
  };
  // END Venue handler for middle vertices.

  const getMiddleVertex = ( index ) => {
    const v1 = vertices[index];
    const v2 = vertices[( index + 1 ) % vertices.length];
    const middleVertex = {x: Math.round(0.5 * (v1.x + v2.x )), y: Math.round(0.5 * (v1.y + v2.y))};

    const distance = ((v1.x - v2.x)*(v1.x - v2.x) + (v1.y - v2.y)*(v1.y - v2.y)) / scaleFactor;
    if (distance < 3600) {
      middleVertex.tooTight = true;
    }
    return middleVertex;
  };

  let renderedMouseOverMiddleVertexHighlight = null;
  if ( mouseOverVertex.index !== -1 
    && mouseOverVertex.middle === true
    && currentPhase.name !== "vertexEditing" 
    && currentPhase.name !== "middleVertexEditing" ) {
    const middleVertex = getMiddleVertex(mouseOverVertex.index);
    renderedMouseOverMiddleVertexHighlight = <ellipse
      cx={middleVertex.x}
      cy={middleVertex.y}
      className={classes.middleVertexHighlight}
    />;
  }

  const renderedMiddleVertices = vertices.map((v,i) => {
    const middleVertex = getMiddleVertex(i);

    if (middleVertex.tooTight) {
      return null;
    }

    return (
      <g key={i}>
        <ellipse
          cx={middleVertex.x}
          cy={middleVertex.y}
          className={classes.middleVertexMousePad}
          onMouseDown={(e) => onMiddleVertexMouseDown(e, i)} 
          onMouseOver={(e) => onMiddleVertexMouseOver(e, i)}
          onMouseLeave={(e) => onMiddleVertexMouseLeave(e, i)}
        />
        <ellipse
          cx={middleVertex.x}
          cy={middleVertex.y}
          className={classes.middleVertex}
        />
      </g>
    );
  });

  const pointsAttrForPolygon = buildPointsAttr(vertices);

  return (
    <g>
      <g>
        <polygon 
          className={classes.polygon}
          points={pointsAttrForPolygon}
        />
        {renderedMouseOverMiddleVertexHighlight}
        {renderedMouseOverVertexHighlight}
        {renderedMiddleVertices}
        {renderedVertices}
      </g>
      <rect 
        ref={layoutRef}
        x="0" y="0" 
        width={layoutSize.width} 
        height={layoutSize.height} 
        pointerEvents={currentPhase.name==="view" || currentPhase.name==="vertexSelected" ? "none" : "auto"}
        fill="transparent"
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove}  
      />
    </g>
  );
};

export default PolygonEditor;