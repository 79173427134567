import { venueLocationConstants } from "../_constants/venue.location.constants";
import * as venueLocationApi from "../_services/venue.locations.service";

const getVenueLocationsStarted = () => {
  return {
    type: venueLocationConstants.GET_EVENT_LOCATIONS_STARTED,
  };
};
const getVenueLocationsListStarted = () => {
  return {
    type: venueLocationConstants.GET_EVENT_LOCATIONS_LIST_STARTED,
  };
};

const getVenueLocationsSuccess = (locations) => {
  return {
    type: venueLocationConstants.GET_EVENT_LOCATIONS,
    venueLocations: locations,
  };
};

const getVenueLocationsListSuccess = (locationsList) => {
  return {
    type: venueLocationConstants.GET_EVENT_LOCATIONS_LIST,
    venueLocationList: locationsList,
  };
};

export const venueLocationAdded = (location) => {
  return {
    type: venueLocationConstants.ADD_EVENT_LOCATION,
    location: location,
  };
};

const venueLocationUpdated = (location) => {
  return {
    type: venueLocationConstants.UPDATE_EVENT_LOCATION,
    location: location,
  };
};

const venueLocationDeleted = (locationId) => {
  return {
    type: venueLocationConstants.DELETE_EVENT_LOCATION,
    locationId: locationId,
  };
};

const venueLocationRestored = (locationId) => {
  return {
    type: venueLocationConstants.RESTORE_EVENT_LOCATION,
    locationId: locationId,
  };
};

// const errorHandle = (error) => {
//   return {
//     type: venueLocationConstants.ERROR_HANDLE,
//     error: error,
//   };
// };

// const errorReset = () => {
//   return {
//     type: venueLocationConstants.ERROR_RESET,
//     error: null,
//   };
// };

const getLocationTypesSuccess = (locationTypes) => {
  return {
    type: venueLocationConstants.GET_LOCATION_TYPES,
    locationTypes: locationTypes,
  };
};

// const locationSelectedStarted = (location) => {
//   return {
//     type: venueLocationConstants.GET_LOCATION_STARTED,
//     location: location,
//   };
// };

// const locationSelected = (location) => {
//   return {
//     type: venueLocationConstants.LOCATION_SELECTED,
//     location: location,
//   };
// };

const getLocationByIdSuccess = (location) => {
  return {
    type: venueLocationConstants.GET_EVENT_LOCATION,
    location: location,
  };
};

// export const resetIsAdded = () => {
//   return (dispatch) => {
//     dispatch(venueLocationAdded(false));
//   };
// };

export const getLocationById = (locationId) => {
  return async (dispatch) => {
    await venueLocationApi
    .getLocationById(locationId)
    .then((response) => {
      dispatch(getLocationByIdSuccess(response.data));
    })
    .catch((error) => {
      throw error;
    });
  };
};

export const addLocation = (location) => {
  // The Maximum/Amber/Red Capacity properties don't make sense for a sensor.
  // If the type of the added location belongs to the Sensor group, set the Capacity properties to 0.
  if (location.locationType.isSensor) {
    location.maximumCapacity = 0;
    location.amberCapacity = 0;
    location.redCapacity = 0;
  }
  
  return async (dispatch) => {
    await venueLocationApi
      .addLocation(location)
      .then((response) => {
        dispatch(venueLocationAdded(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateLocation = (location) => {
  // The Maximum/Amber/Red Capacity properties don't make sense for a sensor.
  // If the type of the added location belongs to the Sensor group, set the Capacity properties to 0.
  if (location.locationType.isSensor) {
    location.maximumCapacity = 0;
    location.amberCapacity = 0;
    location.redCapacity = 0;
  }
 
  return async (dispatch) => {
    await venueLocationApi
      .updateLocation(location)
      .then((response) => {
        dispatch(venueLocationUpdated(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteLocation = (locationId) => {
  return async (dispatch) => {
    await venueLocationApi
      .deleteLocation(locationId)
      .then(() => {
        dispatch(venueLocationDeleted(locationId));
      })
      .catch((error) => {
        //dispatch(errorHandle(error));
        throw error;
      });
  };
};

export const restoreLocation = (locationId) => {
  return async (dispatch) => {
    await venueLocationApi
      .restoreLocation(locationId)
      .then(() => {
        dispatch(venueLocationRestored(locationId));
      })
      .catch((error) => {
        //dispatch(errorHandle(error));
        throw error;
      });
  };
};

export const exportLocations = (venueId) => venueLocationApi.venueLocationsExport(venueId);

export const getVenueLocations = (venueId) => {
  return async (dispatch) => {
    dispatch(getVenueLocationsStarted());
    await venueLocationApi
      .getVenueLocations(venueId)
      .then((response) => {
        dispatch(getVenueLocationsSuccess(response.data));
      })
      .catch((error) => {
        //dispatch(errorHandle(error));
        throw error;
      });
  };
};

export const getVenueLocationsList = (venueId) => {
  return async (dispatch) => {
    dispatch(getVenueLocationsListStarted());
    await venueLocationApi
      .getVenueLocationsList(venueId)
      .then((response) => {
        dispatch(getVenueLocationsListSuccess(response.data));
      })
      .catch((error) => {
        //dispatch(errorHandle(error));
        throw error;
      });
  };
};

export const getLocationTypes = () => {
  return async (dispatch) => {
    await venueLocationApi
      .getLocationTypes()
      .then((response) => {
        dispatch(getLocationTypesSuccess(response.data));
      })
      .catch((error) => {
        //dispatch(errorHandle(error));
        throw error;
      });
  };
};

// export const resetError = () => {
//   return (dispatch) => {
//     dispatch(errorReset());
//   };
// };

// export const selectLocation = (location) => {
//   return (dispatch) => {
//     dispatch(locationSelected(location));
//   };
// };
