import { AuthService } from "./auth.service";
import { downloadFile } from "./restUtils";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";


var Constants = {
  dataCollectorApiRoot: ApiRootConstants.dataCollectorApiRoot,
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
};

const authService = new AuthService();

export async function getFactorTypes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/Factors/FactorTypes", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
};

export async function getFactorTypeVendorMappings() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.dataCollectorApiRoot + "api/Factors/FactorTypeVendorMappings", {
        headers,
      })
      .then((response) => response.data)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
};

export async function getVenueFactors(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    
    return await axios
      .get(Constants.dataCollectorApiRoot + `api/Factors/VenueFactors/${venueId}`, {
        headers: headers,
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
};

function GetFormattedCurrentUtcTimestamp() {
  return new Date().toISOString(); 
}

export async function getCurrentFactorsByLocationId(venueId, locationId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    const now = GetFormattedCurrentUtcTimestamp();
    
    return await axios
      .get(Constants.dataCollectorApiRoot + `api/Factors/VenueLocationFactors/${venueId}/${locationId}/${now}`, {
        headers: headers,
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

export async function addCurrentFactor(factorTypeId, factorValue, venueId, vendorId, locationId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      FactorTypeId: factorTypeId,
      FactorValue: factorValue,
      StartDateUtc: GetFormattedCurrentUtcTimestamp(),
      VenueId: venueId,
      VendorId: vendorId,
      LocationId: locationId
    };

    return await axios
      .post(
        Constants.dataCollectorApiRoot + "api/Factors",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

export async function addHistoryFactor(venueId, factorTypeId, locationId, vendorId, factorValue, startDate)   {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      FactorTypeId: factorTypeId,
      FactorValue: factorValue,
      StartDateUtc: startDate.toISOString(),
      VenueId: venueId,
      VendorId: vendorId,
      LocationId: locationId
    };

    return await axios
      .post(
        Constants.dataCollectorApiRoot + "api/Factors",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

export function downloadImportSample(venueId) {
  return downloadFile(`${ApiRootConstants.dataCollectorApiRoot}api/Factors/DownloadImportSample/${venueId}`, "FactorsTemplate.xlsx");
};

export async function tryImport(venueId, factorTypeId, file) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("FileName", file.name);
    bodyFormData.append("File", file);

    return await axios
      .post(
        Constants.dataCollectorApiRoot +
          `api/Factors/TryImport/${venueId}/${factorTypeId}`,
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
};

export async function confirmImport(venueId, factorTypeId, applyNow, applyFrom, venueTimeZoneId, factorRows ) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        Constants.dataCollectorApiRoot +
          `api/Factors/ConfirmImport/${venueId}/${factorTypeId}/${venueTimeZoneId.replace('/', '--')}/${applyNow? "now" : applyFrom}`,
        factorRows.map(row => {
          return {
            locationId: row.locationId,
            locationName: row.locationName,
            currentData: row.currentData,
            realData: row.realData,
            factorValue: row.factorValue
          };
        }),
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
};

export async function updateFactor(factor) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      id: factor.id,
      factorTypeId: factor.factorTypeId,
      factorValue: factor.factorValue,
      startDateUtc: factor.startDateUtc,
      venueId: factor.venueId,
      vendorId: factor.vendorId,
      locationId: factor.locationId
    };

    return await axios
      .put(
        Constants.dataCollectorApiRoot + "api/Factors",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

export async function occupancyWidgetFactorUpdate(venueId, locationId, newValue ) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "application/json",
      },
    };

    const bodyFormData = {
      venueId: venueId,
      locationId: locationId,
      widgetNumber: newValue
    };

    return await axios
      .put(
        Constants.dataCollectorApiRoot +
          `api/Factors/OccupancyWidgetFactorUpdate/${venueId}/${locationId}`,
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
};

