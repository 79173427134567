import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.color.warning.main,
    height: "22px",
    width: "22px",
  },
  text: {
    color: theme.palette.color.warning.dark,
    fontSize: 16,
    marginLeft: theme.spacing(1)
  }
}));

export const WarningText = (props) => {
  const { text } = props;
  const classes = useStyles();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center"
      }}
    >
      <InfoOutlinedIcon
        className={classes.icon}
      />
      <Typography
        className={classes.text}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default WarningText;