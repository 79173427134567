import React, { useState, useEffect } from "react";
import {useDispatch, useSelector} from "react-redux";
import ReactTimeout from 'react-timeout';
import { useQuery } from 'react-query';
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Tooltip, MenuItem, Menu } from "@material-ui/core";
import PeopleIcon from '@material-ui/icons/People';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from "@material-ui/icons/Settings";
import { useHistory } from "react-router";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { refreshDashboardWidgetsData, checkDashboardCacheAndRefresh } from "../../../_actions";
import { getWidgetDataAccessToken } from "../../../_services/dashboard.service";
import * as dashboardUserApi from "../../../_services/dashboard.user.service";
import StarRateIcon from '@material-ui/icons/StarRate';
import { EditDashboardConfigurationPopup } from "./EditDashboardConfigurationPopup";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from '@material-ui/core/Popover';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import LinkIcon from '@material-ui/icons/Link';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { toast } from "react-toastify";
import AudioPlayer from "../../_common/AudioPlayer";
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import { v4 as uuidv4 } from 'uuid';
import { selectDashboardLastRequestedById } from "../../../_reducers/dashboard.selectors";

const REFRESH_DATA_INTERVAL = 1000 * 3;
const REFRESH_DATA_TOKEN_INTERVAL = 1000 * 60 * 60;

const log = function () { console.debug('EditDashboardFormV2', ...arguments); }

const useStyles = makeStyles((theme) => ({
   margin: {
    background: "#E9ECF2",
    margin: "0px -15px 0px -15px",
    padding: "0px 10px 0px 25px",
    height: "58px",
    alignItems: "center",
    display: "flex",
    width: "calc(100vw - 255px)",
    [theme.breakpoints.down("xs")]: {
      width: "100vw"
    },
  },
  dashboardNameBox: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  marginLastModified: {
    margin: theme.spacing(1),
    fontSize: "12px"
  },
  dashboardNameInViewMode: {
    fontSize: "26px",
    fontWeight: "500",
    color: theme.palette.color.primary.dark,
    whiteSpace: "nowrap"
  },
  dashboardNameInEditMode: {
    fontSize: "18px",
    fontWeight: "400",
    color: theme.palette.color.primary.dark,
    paddingLeft: "15px", 
    paddingRight: "15px",
    whiteSpace: "nowrap"
  },
  dashboardSettingsLabel: {
    fontSize: "26px",
    fontWeight: "500",
    color: theme.palette.color.primary.dark,
    whiteSpace: "nowrap"
  },
  chevronIcon: {
    color: theme.palette.color.primary.dark
  },
  dashboardTitleSeparator: {
    color: theme.palette.common.panelBorderColor,
    fontSize: "18px",
    padding: "0px 10px"
  },
  dashboardSetting: {
    flex: "auto",
    justifyContent: "right",
    alignItems: "center",
    display: "flex"
  },
  selectControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  buttons: {
    marginLeft: theme.spacing(1),
  },
  gridRoot: {
    flexGrow: 1,
  },
  userDefaultDashboardStar: {
    color: theme.palette.dashboard.trafficLight.amber,
    marginRight: '0.3em',
    marginTop: '-0.25em'
  },
  publicDashboard: {
    color: "#627E84",
    marginRight: '0.3em',
    marginTop: '-0.25em'
  },
  changeDashboardPopup: {
    width: "400px", 
    maxHeight: "300px", 
    textOverflow: "hidden"
   },
  countdown: {
    fontWeight: 500,
    fontSize: '13px',
    color: theme.palette.color.primary.main,
  },
  countdownDiv: {
    marginRight: 8
  },
  visibilityIcon: {
    marginRight: "4px",
  },
  settingsIcon: {
    color: "#031820"
  },
}));

const EditDashboardFormV2 = (props) => {
    const { venue, dashboard, isInEditMode } = props;

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const dashboards = useSelector(state => state.dashboards.dashboards);
  const lastRequested = useSelector(state => selectDashboardLastRequestedById(state, dashboard?.id));

  const [hideDetail, setHideDetail] = useState(false);
  const [anchorSetting, setAnchorSetting] = useState(null);
  const [widgetDataInterval, setWidgetDataInterval] = useState(null);
  const [userDefaultDashboard, setUserDefaultDashboard] = useState(null);
  const [showConfigurationModal, setShowConfigurationModal] = useState(false);
  const [onceWidgetDataLoaded, setOnceWidgetDataLoaded] = useState(false);
  const [lastRequestTime, setLastRequestTime] = useState(null);
  const [sessionId, setSessionId] = useState(uuidv4());
  const [nextRequestSeconds, setNextRequestSeconds] = useState(0);
  const [timerKey, setTimerKey] = useState(0);
  const [userFavoriteDashboards, setUserFavoriteDashboards] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => {
    setHideDetail(false);
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { data: widgetDataAccessToken } = useQuery('widgetDataAccessToken', getWidgetDataAccessToken,
    {
      refetchInterval: REFRESH_DATA_TOKEN_INTERVAL,
      staleTime: REFRESH_DATA_TOKEN_INTERVAL
    });

  const requestWidgetsData = (clearCache = false) =>
    //refreshDashboardWidgetsData(dashboard?.id, widgetDataAccessToken, clearCache);
    checkDashboardCacheAndRefresh(dashboard?.id, sessionId, widgetDataAccessToken, clearCache);

  const refreshWidgetsData = (clearCache = false) => {
    setLastRequestTime(Date.now());
    return dispatch(requestWidgetsData(clearCache)).then(() => setOnceWidgetDataLoaded(true));
  }

  const startWidgetDataRefreshing = (clearCache = false) => {
    stopWidgetDataRefreshing();
    props.setTimeout(() => refreshWidgetsData(clearCache), 100);
    setWidgetDataInterval(props.setInterval(refreshWidgetsData, REFRESH_DATA_INTERVAL));
  }

  const stopWidgetDataRefreshing = () => {
    if (widgetDataInterval) {
      props.clearInterval(widgetDataInterval);
      setWidgetDataInterval(null);
    }
  }

  const sortByFavorite = (a, b) => {
    if (userFavoriteDashboards?.includes(a.id) && !userFavoriteDashboards?.includes(b.id)) {
      return -1;
    }
  }
  const sortByAlphabeticalOrder = (a, b) => {
    var left = a.name.toLowerCase(), right = b.name.toLowerCase();
    
    return left < right ? -1 : 1;
  }

  useEffect(() => {
    if (widgetDataAccessToken && !isInEditMode)
      startWidgetDataRefreshing();
  }, [widgetDataAccessToken, isInEditMode]);

  useEffect(() => {
    // const nextReqSec = (lastRequested.nextRequestSeconds ?? 0);
    // setNextRequestSeconds(nextReqSec <= 0 ? 0 : nextReqSec + 1);
    setNextRequestSeconds(lastRequested.nextRequestSeconds ?? 0);
    setTimerKey(k => k + 1);
  }, [lastRequested.nextRequestSeconds, setNextRequestSeconds, setTimerKey]);

  useEffect(() => {
    let isMounted = true;

    if (userDefaultDashboard === null && dashboard?.venueId !== null) {
      dashboardUserApi.getUserDefaultDashboard(dashboard?.venueId ).then ((result) => {
        if (!isMounted) return;
        
        setUserDefaultDashboard(result.data);
      }).catch((error) => {
        log("Error getUserDefaultDashboard", error);
      });
    }

    if (userFavoriteDashboards === null && dashboard?.venueId !== null) {
      dashboardUserApi.getUserFavoriteDashboards(dashboard?.venueId).then((result) => {
        if (!isMounted) return;

        setUserFavoriteDashboards(result.data);
      }).catch((error) => {
        log("Error getUserFavoriteDashboards", error);
      });
    }

    return () => {
      isMounted = false;
    }
  }, [userDefaultDashboard, dashboard?.venueId])

  if (dashboard && !dashboard?.isActive) {
    history.push("/dashboards");
  }

  const menuItems = [];
  if (isInEditMode) {
    menuItems.push(
      <MenuItem 
        key={1}
        onClick={() => {
          startWidgetDataRefreshing(true);
          history.push(`/dashboard/${dashboard?.id}`);
          setAnchorSetting(null);
        }}
      >
        <VisibilityIcon 
          className={classes.visibilityIcon} 
          fontSize="small" />
        &nbsp;View dashboard
      </MenuItem>
    );
  } else {
    menuItems.push( 
      <div key="2">
        <PermissionsGate
          scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_UPDATE_DASHBOARD]}
          //component={MenuItem}
        >
          <MenuItem
          onClick={() => {
            stopWidgetDataRefreshing();
            history.push(`/dashboard/${dashboard?.id}?editMode=true`);
            setAnchorSetting(null);
          }}>
            <EditIcon fontSize="small" />&nbsp;Edit dashboard
          </MenuItem>
        </PermissionsGate>
      </div>
    );
    if (!dashboard?.isProtected) {
      menuItems.push(
        <MenuItem 
          key={3}
          onClick={() => {
            setAnchorSetting(null);
            window.open(`/public-dashboard/${encodeURIComponent(venue.name)}/${encodeURIComponent(dashboard?.name)}`);
          }}
        >
          <PeopleIcon fontSize="small" />&nbsp;View public dashboard
        </MenuItem>
      );
      menuItems.push(
        <MenuItem
          key={4}
          onClick={() => {
            setAnchorSetting(null);
            navigator.clipboard.writeText(
            `${window.location.protocol}//${window.location.host}/public-dashboard/${encodeURIComponent(venue.name)}/${encodeURIComponent(dashboard?.name)}`);
            toast.success("Link was copied successfully");
          }}
        >
        <LinkIcon fontSize="small" />&nbsp;Copy public link
      </MenuItem>
      );
    }
  }

  return (
    <div className={classes.margin} >
      <div className={classes.dashboardNameBox}>
        {isInEditMode ? (
        <>
          <span className={classes.dashboardSettingsLabel}>Dashboard settings</span>
          <span className={classes.dashboardTitleSeparator}>|</span>
        </>          
        ) : ("")}
        <span id={"dashboardSelectionPopup"}></span>
        { dashboard?.id === userDefaultDashboard ? (
          <Tooltip title="User default dashboard">
          <StarRateIcon className={classes.userDefaultDashboardStar} />
          </Tooltip>
          ) : ("") }
        { !dashboard?.isProtected ? (
          <Tooltip title="Public dashboard">
            <PeopleIcon className={classes.publicDashboard} />
          </Tooltip>
        ) : ("") }
          <span 
            className={isInEditMode ? classes.dashboardNameInEditMode : classes.dashboardNameInViewModel }
            >
              {isInEditMode ? dashboard?.name?.toUpperCase() : dashboard?.name}
          </span>
      </div>
      {!isInEditMode ? (
        <IconButton size={"small"} className={classes.chevronIcon}>
          {hideDetail ? (
              <ArrowDropUpIcon
                fontSize="large"
                onClick={(e) => {
                  setHideDetail(false);
                  setAnchorEl(null);
                }}
              />
          ) : (
            <Tooltip title="Change dashboard">
              <ArrowDropDownIcon
                fontSize="large"
                onClick={(e) => {
                  setHideDetail(true);
                  setAnchorEl(document.getElementById("dashboardSelectionPopup"));
                }}
              />
            </Tooltip>            
          )}
        </IconButton>
        ): ("")}
      {isInEditMode ? (
        <PermissionsGate
          scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_UPDATE_DASHBOARD]}
        >
          <IconButton size={"small"} className={classes.chevronIcon}>
            <Tooltip title="Edit dashboard properties">
            <EditIcon
            fontSize="large"
            onClick={() => {
              setShowConfigurationModal(true);
            }}
            />
            </Tooltip>
          </IconButton>
        </PermissionsGate>
        ): ("")}

      <div className={classes.dashboardSetting} >        
        { // !isWidgetDataLoading 
          // nextRequestSeconds > 0 &&
          onceWidgetDataLoaded && lastRequestTime && !isInEditMode &&
          <div className={classes.countdownDiv}>
              <CountdownCircleTimer
                  key={timerKey}
                  isPlaying
                  size={30}
                  strokeWidth={3}
                  duration={60}
                  initialRemainingTime={nextRequestSeconds}
                  colors="#00C1CE"
                  trailColor="white"
              >
                  {({ remainingTime }) =>
                      <span className={classes.countdown}>{remainingTime}</span>
                  }
              </CountdownCircleTimer>
          </div>
        }
        <AudioPlayer id={"audio"} showPlayIcon={false} showPauseIcon={false} showStopIcon={false} muted={false} style={{marginLeft: "8px", marginRight: "8px"}}></AudioPlayer>
        <span>
          <IconButton size={"small"}  onClick={(e) => {
            setAnchorSetting(e.currentTarget);
          }} >
            <Tooltip title="Dashboard Settings">
              <SettingsIcon fontSize={"small"} className={classes.settingsIcon}/>
            </Tooltip>
          </IconButton>
          <Menu
            id="dashboard-setting-menu"
            anchorEl={anchorSetting}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorSetting)}
            onClose={() => {
              setAnchorSetting(null);
            }}
          >
          {menuItems}
          </Menu>
        </span>
      </div>
      {showConfigurationModal &&
      <EditDashboardConfigurationPopup
        showConfigurationModal={showConfigurationModal}
        setShowConfigurationModal={setShowConfigurationModal}
        dashboard={dashboard}
        dashboards={dashboards} />
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
          <List className={classes.changeDashboardPopup}>
            {dashboards
            .filter(d => d.isActive)
            .sort(sortByAlphabeticalOrder)
            .sort(sortByFavorite)
            .map((a, index) => { return (
              <ListItem key={index} button
                selected={a.id === dashboard?.id}
                onClick={() => {
                setHideDetail(false);
                setAnchorEl(null);
                history.push(`/dashboard/${a.id}`);
              }} >
                <ListItemText primary={a.name} />
              </ListItem>
            )})}
          </List>
      </Popover>
      
    </div>
    
  );
};

export default ReactTimeout(EditDashboardFormV2);
