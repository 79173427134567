import React from 'react';
import './LoadSpinner.css';

const LoadSpinner = (props) => (
  <div>
    <div></div>
      <div className='absolute text-center'>
        <div className="lds-roller">          
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
        <div><h5 style={{padding:"20px"}}>{props.text}</h5></div>
      </div>    
   
  </div>
  
);

export default LoadSpinner;