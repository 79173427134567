import React, { useCallback, useEffect, useState, useRef } from 'react';
import ReactPlayer from 'react-player/file';
import ErrorIcon from '@material-ui/icons/Error';
import axios from "axios";
import md5 from 'md5';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { EmptyContainer } from "../EmptyContainer";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { widgetTypeConstants } from "../../../_constants";
import LoadPleaseWait from '../../notification/LoadingPleaseWait/LoadingMessage';
import { SocketImage } from './SocketImage';
import TimeRefreshingImage from './TimeRefreshingImage';
import devconsole from '../../_common/devconsole';

const useStyles = makeStyles((theme) => ({  
    ...theme.widget.emptyContainer,
    ...theme.widget.camera,
    errorIcon: {
        color: theme.palette.color.primary.red,
        position: "absolute",
        top: 5,
        right: 5,
        width: 30,
        height: 30
    },
    reactPlayerBox: {
        position: "relative"
    }
  }));

const authUrlPattern = /^https:\/\/(\S+?):(.+?)@(\S+?)\/(.*?)$/i;
const wsUrlPattern = /^wss?:\/\/.+$/i;
const refreshingImagePattern = /^https?:\/\/([^?]+?)\/stream(\/)?\?id=[\d\w.]+?_.+$/i;

const canPlay = url => url?.match(wsUrlPattern)?.length || url?.match(refreshingImagePattern)?.length || ReactPlayer.canPlay(url);

const log = function () { devconsole.log('WidgetCameraContent', ...arguments); }
const logerr = function () { console.error('WidgetCameraContent', ...arguments); }

export const WidgetCameraContent = (props) => {
    const { width, height, viewMode, settings, data, brandingSettings } = props;
    const classes = useStyles({ props, ...brandingSettings });
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    const url = data?.url ?? settings?.RefWidgetSettingCameraUrl?.url;
    const title = settings?.WidgetSettingCameraTitle;
    const bgcolorClass = classes.defaultBackground;
    const isTitle = title?.trim() ? true : false;

    props = { ...props, icon: 'SettingsSuggest.svg', color: "#000000" };

    const [streamUrl, setStreamUrl] = useState(null);
    const [wsUrl, setWsUrl] = useState(null);
    const [reactPlayerError, setReactPlayerError] = useState(null);
    const [isRefreshingImage, setIsRefreshingImage] = useState(false);
    
    const [titleHeight, setTitleHeight] = useState(30);
    const [playerBoxHeight, setPlayerBoxHeight] = useState('auto');
    const [playerWidth, setPlayerWidth] = useState(0);
    
    const titleRef = useRef(null);
    const playerRef = useRef(null);

    log(props);

    const authenticate = useCallback(() => {

        if (!isViewMode || !(url?.trim())) return;

        log({isViewMode, url});

        setReactPlayerError(null);
        setWsUrl(null);
        setStreamUrl(null);
        setIsRefreshingImage(false);

        const wsMatches = url.match(wsUrlPattern) || [];
        if (wsMatches.length) {
            setWsUrl(url);
            return;
        }

        if (url.match(refreshingImagePattern)?.length) {
            setIsRefreshingImage(true);
            return;
        }

        const matches = url.match(authUrlPattern) || [];
        if (matches.length < 5) {
            setTimeout(() => setStreamUrl(url), 100);
            return;
        }

        const username = matches[1];
        const password = matches[2];
        const host = matches[3];
        const urlTrail = matches[4];
        
        setTimeout(() => {

            axios
                .get(`https://${host}/api/getNonce`)
                .then(response => {
                    const realm = response.data.reply.realm;
                    const nonce = response.data.reply.nonce;
                    const digest = md5(`${username}:${realm}:${password}`);
                    const partial_ha2 = md5("GET:");
                    const simplified_ha2 = md5(`${digest}:${nonce}:${partial_ha2}`);
                    const authKey = btoa(`${username}:${nonce}:${simplified_ha2}`);

                    const stripedUrl = `https://${host}/${urlTrail}`;
                    const authUrl = urlTrail.includes("?") ? `${stripedUrl}&auth=${authKey}` : `${stripedUrl}?auth=${authKey}`;

                    setStreamUrl(authUrl);
                })
                .catch(err => {
                    logerr(err);
                    setStreamUrl(url);
                });
        }, 100);
        
    }, [isViewMode, url, setStreamUrl, setWsUrl, setReactPlayerError, setIsRefreshingImage]);

    useEffect(() => {
        authenticate();
    }, [authenticate]);


    useEffect(() => {
        setTitleHeight(titleRef?.current?.clientHeight ?? 0);
        setPlayerBoxHeight(playerRef?.current?.clientHeight ?? 'auto');
        setPlayerWidth(playerRef?.current?.clientWidth ?? 0);
    });

    const onReactPlayerError = (error, data) => {
        const err = {error, data: {...(data ?? {fatal: true})}};
        logerr(err);
        setReactPlayerError(err);
        if (err.data.fatal)
        {
            setTimeout(() => {
                log('Reconnecting stream...');
                setReactPlayerError(null);
            }, 5000);
        }
    }

    const clearReactPlayerError = () => setReactPlayerError(null);

    const playerHeight = isNaN(playerBoxHeight) ? playerBoxHeight :
        (playerWidth * 1.0 / (playerBoxHeight - titleHeight) > 16.0 / 9.0 ? playerBoxHeight - titleHeight : 'auto');

    const imgHeight = playerHeight;
    const imgWidth = imgHeight === 'auto' ? '100%' : 'auto';

    return (
        <>
        {!canPlay(url) ?
            <Box display="flex" className={`${classes.container} ${classes.bordered} ${bgcolorClass}`} height={height} >
                <EmptyContainer {...{...props, text: url?.trim() ? 'Unknown URL format' : null}} />
            </Box>
        
        : (streamUrl || wsUrl || isRefreshingImage) && isViewMode ?
            <Box ref={playerRef} className={classes.container}>
                {isTitle &&
                    <Box ref={titleRef} className={classes.title}>
                        <Typography className={classes.caption}>{title}</Typography>
                    </Box>
                }
                {reactPlayerError?.data?.fatal &&
                    <LoadPleaseWait show={true} text={'Error occured, reconnecting...'} />
                }
                {(streamUrl && (!reactPlayerError || !reactPlayerError.data.fatal)) &&
                    <Box className={classes.reactPlayerBox}>
                        {reactPlayerError?.data?.fatal === false &&
                            <ErrorIcon className={classes.errorIcon} />
                        }

                        <ReactPlayer
                            url = {streamUrl}
                            width = "100%"
                            height = {playerHeight}
                            playing = {true}
                            muted = {true}
                            loop = {true}
                            controls = {false}
                            onError = {onReactPlayerError}
                            onProgress = {clearReactPlayerError}
                            onPlay = {clearReactPlayerError}
                        />
                    </Box>
                }
                {wsUrl &&
                    <SocketImage width={imgWidth} height={imgHeight} url={wsUrl} />
                }
                {isRefreshingImage &&
                    <TimeRefreshingImage width={imgWidth} height={imgHeight} url={url} timeout={30} />
                }
            </Box>
    
        : !isViewMode ?
            <Box display="flex" className={`${classes.container} ${classes.bordered} ${bgcolorClass}`}>
                <img src={widgetTypeConstants.widgetCamera.iconSrc}
                    alt={widgetTypeConstants.widgetCamera.caption}
                    title={widgetTypeConstants.widgetCamera.caption}
                    className={classes.icon}
                />
                <Box>
                    <Typography className={classes.caption}>{title}</Typography>
                </Box>
            </Box>
        :
            <LoadPleaseWait show={true} />
        }
        </>
      );
}
