import React from "react";
import {
    Typography
  } from "@material-ui/core";

  
export const NoVenueSelectedError = () => {
    return (
      <div>
        <Typography variant="h4">Please select venue first</Typography>
        {/* <Button variant="outlined" onClick={handleGetVenue}>
        Get Venue
        </Button> */}
      </div>
    );
}