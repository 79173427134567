import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  smallBox: {
    margin: theme.spacing(1)
  },
  largeBox: {
    margin: theme.spacing(1)
  },
  largeTitle: {
    fontSize: 16,
    //lineHeight: 1,
    color: theme.palette.color.secondary.main,
    textAlign: 'center'
  },
  smallTitle: {
    fontSize: 13,
    //lineHeight: 1,
    color: theme.palette.color.secondary.main,
    textAlign: 'center'
  },
  smallValue: {
    fontSize: 24,
    //lineHeight: 1,
    color: theme.palette.color.primary.dark
  },
  largeValue: {
    fontSize: 36,
    lineHeight: 1,
    color: theme.palette.color.primary.dark
  }
}));

export const QueueMetric = (props) => {
  // This widget looks after font sizes and layout.
  // size is "large" or "small"
  // value is a string, already formatted correctly.
  const {title, value, size, formatAsTime} = props;

  const classes = useStyles();

  let displayValue;
  if (value === null || value === undefined || isNaN(value)) {
    displayValue = "N/A";
  } else {
    if (formatAsTime) {
      let hours = Math.floor(value/3600);
      let minutes = Math.floor((value - hours * 3600) / 60);
      let seconds = Math.floor(value - hours * 3600 - minutes * 60);

      const paddedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
      const paddedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

      if (hours) {
        displayValue = `${hours}:${paddedMinutes}:${paddedSeconds}`;
      } else {
        displayValue = `${paddedMinutes}:${paddedSeconds}`;
      }
    } else {
      displayValue = value.toLocaleString("en-US");
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // background: "pink"
      }}
      className={size === "large" ? classes.largeBox : classes.smallBox}
    >
      <Typography
        variant="h4" 
        component="div"
        className={size === "large" ? classes.largeValue: classes.smallValue}>
        {displayValue} 
      </Typography>
      <Typography
        variant="body1" 
        component="div"
        className={size === "large" ? classes.largeTitle: classes.smallTitle}>
        {title}
      </Typography>
    </Box>
  );
};

export default QueueMetric;