import { getLocationMappingsByLocationId, getVenueMappingsByLocationId } from "../../_services/location.mapping.service";

export default function VenueLocationValidator(data)
{
    this.currentLocation = data;
    this.error = [];

    this.validate = async function () {
        
        //validate capacity 
        if (this.validateCapacity()) {
            
            //only validate if current location is sensor and it's changed to Sensor
            if (this.currentLocation.prevLocationTypeId !== undefined &&
                this.currentLocation.prevLocationTypeId !== this.currentLocation.locationTypeId) {
                if (this.currentLocation.locationType.isSensor) {
                    //validate no children
                    if (!this.validateLocationHasNoChildren()) {
                        this.error.push('This location has children.\n');
                    }

                    if (this.error.length === 0) {
                        await this.validateLocationNotHasVendorCode()
                            .then((response) => {
                                if (response.data.length > 0) {
                                    if (response.data[0].vendorCode !== "") {
                                        this.error.push('This location has vendor code.\n');
                                    }
                                }

                                //validate No Mapping to polygon 
                                this.validateLocationNotMappedToPolygon()
                                    .then((response) => {
                                        if (response.data.length > 0) {
                                            this.error.push('This location is mapped to polygon.');
                                        }                                      
                                    });
                            });
                    }
                }
            }
        }

        return this.error;
    }  
    

    this.validateCapacity = function () {
        if (!this.currentLocation.locationType.isSensor) {
            if (parseInt(this.currentLocation.amberCapacity) > parseInt(this.currentLocation.redCapacity))
                this.error.push("Amber Capacity cannot be greater than Red Capacity.");
        
            if (parseInt(this.currentLocation.redCapacity) > parseInt(this.currentLocation.maximumCapacity))
                this.error.push("Red Capacity cannot be greater than Max Capacity.");
        
            if (this.error.length > 0)
                return false;
        }
        return true;
    };

    this.validateLocationNotMappedToPolygon = function() {
        return getVenueMappingsByLocationId(this.currentLocation.id);
    }; 

    this.validateLocationNotHasVendorCode= function(){
        return getLocationMappingsByLocationId(this.currentLocation.venue.id, this.currentLocation.id);        
    };

    this.validateLocationHasNoChildren = function(){
        return this.currentLocation.children.filter(x=>!x.isDeleted).length === 0;
    }; 
}