import * as userConstants from "../_constants/user.management.constants";
import initialState from "../_reducers/initialState";

export function userManagementReducer(
  state = initialState.userManagement,
  action
) {
  switch (action.type) {
    case userConstants.LOAD_ROLES_SUCCESS:
      return { ...state, roles: [...action.roles] };
    case userConstants.ENSURE_USER_SUCCESS:
      return { ...state, users: [...state.users, action.user] };
    case userConstants.UPDATE_USER_SUCCESS:
      const newUsers = state.users.map((t) =>
        t.id === action.user.id ? action.user : t
      );
      return { ...state, users: newUsers };
    case userConstants.LOAD_USERS_STARTED:
      return {
        ...state,
        loading: {...state.loading, usersLoading: true}
      }
    case userConstants.LOAD_USERS_SUCCESS:
      return { 
        ...state, 
        users: [...action.users],
        loading: {...state.loading, usersLoading: false}
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      // when changing user password then no change to the state, so just return state
      return state;
    case userConstants.DISABLE_USER_SUCCESS:
      const disabledUser = state.users.find((e) => e.email === action.userName);
      if (disabledUser) {
        disabledUser.enabled = false;
      }
      return {
        ...state,
        users: state.users.map((t) =>
          t.email === action.userName ? disabledUser : t
        ),
      };
    case userConstants.ENABLE_USER_SUCCESS:
      const enabledUser = state.users.find((e) => e.email === action.userName);
      if (enabledUser) {
        enabledUser.enabled = true;
      }
      return {
        ...state,
        users: state.users.map((t) =>
          t.email === action.userName ? enabledUser : t
        ),
      };
    case userConstants.DELETE_USER_SUCCESS:
      return {
        ...state,
        users: state.users.filter((t) =>
          t.id !== action.userId
        ) 
      };
    default:
      return state;
  }
}

export default userManagementReducer;
