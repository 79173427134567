import tableauReportConstants from "../_constants/tableauReport.constants";
import initialState from "./initialState";

export function tableauReportingReducer(state = initialState.tableauReporting, action) {
    switch (action.type) {
        case tableauReportConstants.GET_TABLEAU_PUBLISHING_CRON_MODE_STARTED:
            return {
                ...state,
                loading: { ...state.loading, tableauPublishingCronModeLoading: true }
            };
        case tableauReportConstants.GET_TABLEAU_PUBLISHING_CRON_MODE_SUCCESS:
            return {
                ...state,
                tableauPublishingCronMode: action.tableauPublishingCronMode,
                loading: { ...state.loading, tableauPublishingCronModeLoading: false }
            };
        default:
            return state;
    }
}

export default tableauReportingReducer;
