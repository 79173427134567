import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    Box,
    List,
} from "@material-ui/core";
import PasswordValidationPoint from "./PasswordValidationPoint";

const useStyles = makeStyles((theme) => ({
    passwordValidationTitle: {
        fontWeight: 500,
        marginBottom: "5px",
    },
    passwordValidationContainer: {
        blockSize: "fit-content",
        width: 300,
        padding: "10px 0px 10px 15px",
    },

    passwordValid: {
        backgroundColor: "rgba(63, 190, 93, 0.05)"
    },
    passwordInvalid: {
        backgroundColor: "rgba(238, 64, 64, 0.05)"
    }
}));

const validationsList = [
    {
        text: "At least 6 characters",
        test: (str) => str && new RegExp(".{6,}").test(str)
    },
    {
        text: "At least 1 uppercase letter (A - Z)",
        test: (str) => str && new RegExp(".*[A-Z]").test(str)
    },
    {
        text: "At least 1 lowercase letter (a - z)",
        test: (str) => str && new RegExp(".*[a-z]").test(str)
    },
    {
        text: "At least 1 number (0 - 9)",
        test: (str) => str && new RegExp(".*[0-9]").test(str)
    },
    {
        text: "At least 1 special character (!@#$%^&*)",
        test: (str) => str && new RegExp(".*[!@#$%^&*]").test(str)
    },
    {
        text: "No leading or trailing spaces",
        test: (str) => {
            if ( str && (str.trim() !== str)) {
                return false;
            } else {
                return true;
            }
        }
    },
]

const isPasswordValid = (newPassword) => {
    for (var i = 0; i < validationsList.length; i++) {
        if (!validationsList[i].test(newPassword))
            return false;
    }

    return true;
}

const PasswordValidation = (props) => {
    const { newPassword, setIsNewPasswordValid } = props;

    const classes = useStyles();

    useEffect(() => {
        setIsNewPasswordValid(isPasswordValid(newPassword));
    }, [newPassword])

    return (
        <>
            <Box className={classes.passwordValidationTitle}>
                <Typography variant="body1">Password must contain:</Typography>
            </Box>

            <Box
                className={
                    classes.passwordValidationContainer
                    + " " +
                    (isPasswordValid(newPassword) ? classes.passwordValid : classes.passwordInvalid)
                }
            >
                <List>
                    {validationsList.map((validationPoint, index) => (
                        <React.Fragment key={index}>
                            <PasswordValidationPoint
                                newPassword={newPassword}
                                text={validationPoint.text}
                                test={validationPoint.test}
                            />
                        </React.Fragment>
                    ))}
                </List>
            </Box>
        </>
    )
}

export default PasswordValidation;