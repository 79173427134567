import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Responsive, WidthProvider} from "react-grid-layout";
import "../../../../../node_modules/react-grid-layout/css/styles.css";
import "../../../../../node_modules/react-resizable/css/styles.css";
import {WidgetTypesPanelExpanded} from "./WidgetTypesPanelExpanded";
import {WidgetTypesPanelCollapsed} from "./WidgetTypesPanelCollapsed";
import {WidgetTypes} from "./WidgetTypes";
import * as dashboardApi from "../../../../_services/dashboard.service";
import {WidgetFactory} from "../../../widgets/WidgetFactory";
import {toast} from "react-toastify";
import appViewModeConstants from "../../../../_constants/app.view.mode.constants";

const ResponsiveGridLayout = WidthProvider(Responsive);

const useStyles = makeStyles((theme) => ({
  dashboard: { ...theme.dashboard.gridLayoutDashboard.dashbaordGridLayout.dashboard },
  dashboardPanel: { ...theme.dashboard.gridLayoutDashboard.dashbaordGridLayout.dashboardPanel },
  dashboardPanel_DashboardGrid: {...theme.dashboard.gridLayoutDashboard.dashbaordGridLayout.dashboardPanel_DashboardGrid },
  widgetPanelListMaximize: {...theme.dashboard.gridLayoutDashboard.dashbaordGridLayout.widgetPanelListMaximize },
  widgetPanelListMinimize: {...theme.dashboard.gridLayoutDashboard.dashbaordGridLayout.widgetPanelListMinimize },
}));

export const DashboardGridLayout = ({
  dashboardId = "00000000-0000-0000-0000-000000000000",
  dashboardWidgets = [], 
  isInEditMode,
  isPublicViewMode = false,
  brandingSettings = {},
  clientType = null
}) => {
  let classes = useStyles(brandingSettings);
  const [widgets, setWidgets] = useState(dashboardWidgets);
  const defaultDroppingElementId = "__dropping-elem__";
  //// NEW DASHBOARD GRID LAYOUT
  const [droppedWidgetType, setDroppedWidgetType] = useState(1);
  const [isWidgetListExpanded, setIsWidgetListExpanded] = useState(false);
  const [isPreventDraggable, setIsPreventDraggable] = useState(false);
  const widgetTypes = JSON.parse(JSON.stringify(WidgetTypes));
  
  useEffect(() => {
    setIsPreventDraggable(isInEditMode);
  }, [isInEditMode]);

  useEffect(() => {
      setWidgets(dashboardWidgets);
  }, [dashboardWidgets]);

  function onDrop(layout, item) { //onDrop(layout, item, e)
    let widgetTypeList = JSON.parse(JSON.stringify(widgetTypes)); // copy the widgetTypes
    const widgetSetting = widgetTypeList.filter(a => a.id === droppedWidgetType)[0];
    if (item !== undefined) {
      widgetSetting.dashboardId = dashboardId;
      widgetSetting.dashboardWidgetId = "00000000-0000-0000-0000-000000000000"; // temp guid
      widgetSetting.i = widgetSetting.dashboardWidgetId;
      widgetSetting.widgetType = widgetSetting.id; // create widgetType value the same as the id because it's needed to create the widget type
      // update position (X,Y)
      widgetSetting.x = item.x;
      widgetSetting.y = item.y;
      dashboardApi.addWidgets(dashboardId, [widgetSetting]).then (result => {
        widgetSetting.dashboardWidgetId = result.data[0];
        widgetSetting.i = result.data[0];
        //currLayout.filter(a => a.i === defaultDroppingElementId)[0].i = result.data[0];
        setWidgets(prev => {
          return [...prev, widgetSetting];
        }); /// replace it with new layout
      }).catch((error) => {
        toast.error(error, { autoClose: false });
      });
    } 
  }
  
  function onAddingNewWidget(widgetType) {
    let widgetTypeList = JSON.parse(JSON.stringify(widgetTypes)); // copy the widgetTypes
    const widgetSetting = widgetTypeList.filter(a => a.id === widgetType)[0];
    widgetSetting.dashboardId = dashboardId;
    widgetSetting.dashboardWidgetId = "00000000-0000-0000-0000-000000000000"; // temp guid
    widgetSetting.i = widgetSetting.dashboardWidgetId;
    widgetSetting.widgetType = widgetSetting.id; // create widgetType value the same as the id because it's needed to create the widget type
    // update position (X,Y)
    widgetSetting.x = 0;
    widgetSetting.y = 0;
    dashboardApi.addWidgets(dashboardId, [widgetSetting]).then (result => {
      widgetSetting.dashboardWidgetId = result.data[0];
      widgetSetting.i = result.data[0];
      setWidgets(prev => {
        return [...prev, widgetSetting];
      }); /// replace it with new layout
    }).catch((error) => {
      toast.error(error, { autoClose: false });
    });
  }
 
  function onSetDroppedWidgetType(widgetType) {
    setDroppedWidgetType(widgetType);
  }
  
  function onDelete(deletedDashboardWidgetId) {
    // delete the serverside
    dashboardApi.deleteWidget(deletedDashboardWidgetId).then(() => {
      toast.success("Widget was deleted successfully.");
      // update the dashboardWidgetList object
      setWidgets((prev) => {
        return prev.filter(a => a.dashboardWidgetId !== deletedDashboardWidgetId);
      });
    }).catch((error) => {
      toast.error(error, { autoClose: false });
    });
  }  
  
  /// END NEW DASHBOARD GRID LAYOUT
  const createLayoutForRender = () => {
    return widgets.map(w => {
      const widgetType = widgetTypes.find((wt) => wt.id === w.widgetType);
      return {
        i: w.dashboardWidgetId,
        x: w.x,
        y: w.y,
        w: w.w,
        h: w.h,
        minW: widgetType ? widgetType.minW ?? 1 : 1,
        maxW: widgetType ? widgetType.maxW ?? Infinity : Infinity,
        minH: widgetType ? widgetType.minH ?? 1 : 1,
        maxH: widgetType ? widgetType.maxH ?? Infinity : Infinity,
      };
    });
  };
  const getAudio = () => {
    return document.getElementById("audio");
  }
  const layoutsForRender = {
    lg: createLayoutForRender(),
    md: createLayoutForRender(),
    sm: createLayoutForRender(),
    xs: createLayoutForRender(),
    xxs: createLayoutForRender()
  };
  
  return (
    <div className={classes.dashboard} id={"test"}>
      <div className={classes.dashboardPanel} >
        <div className={classes.dashboardPanel_DashboardGrid} >
          <ResponsiveGridLayout
            style={{ minHeight: "100%" }}
            layouts={layoutsForRender}
            breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
            rowHeight={32}
            key="responsiveGridLayout"
            isDroppable={isInEditMode && isPreventDraggable} // set isDroppable depending on whether it's draggable or not
            isDraggable={isInEditMode && isPreventDraggable}
            isResizable={isInEditMode}
            resizeHandles={["se"]} //{['s', 'w', 'e', 'n', 'sw', 'nw', 'se', 'ne']}
            onLayoutChange={(layout) => { //(layout, layouts) 
              if (!isInEditMode) {
                return;
              }
              setWidgets((prev) => {
                const newWidgets = prev.map((w) => {
                  const newLayout = layout.filter(
                    (l) => l.i === w.dashboardWidgetId
                  )[0];
                  if (newLayout) {
                    return {
                      ...w,
                      x: newLayout.x,
                      y: newLayout.y,
                      w: newLayout.w,
                      h: newLayout.h
                    };
                  } else {
                    return w;
                  }
                });
                dashboardApi.updateWidgetsPosition(newWidgets).catch((error) => {
                  toast.error(error, { autoClose: false });
                });
                return newWidgets;
              });
            }}
            onResizeStop={(layout) => { // (layout, oldItem, newItem, placeholder, e, element)
              setWidgets((prev) => {
                const newWidgets = prev.map((w) => {
                  let newLayout = layout.filter(
                    (l) => l.i === w.dashboardWidgetId
                  )[0];
                  if (newLayout) {
                    return {
                      ...w,
                      x: newLayout.x,
                      y: newLayout.y,
                      h: newLayout.h,
                      w: newLayout.w
                    };
                  } else {
                    return w;
                  }
                });
                dashboardApi.updateWidgetsPosition(newWidgets).catch((error) => {
                  toast.error(error, { autoClose: false });
                });
                return newWidgets;
              });
            }}
            onDrag={(layout, oldItem, newItem, placeholder) => { //(layout, oldItem, newItem, placeholder, e, element)
              if (placeholder.i === defaultDroppingElementId) {
                let droppedWidget = WidgetTypes.filter(a => a.id === droppedWidgetType)[0];
                if (droppedWidget) {
                  placeholder.w = droppedWidget.w;
                  placeholder.h = droppedWidget.h;
                }
              }
            }}
            onDragStop={(layout) => { //(layout, oldItem, newItem, placeholder, e, element)
              setWidgets((prev) => {
                const newWidgets = prev.map((w) => {
                  let newLayout = layout.filter(
                    (l) => l.i === w.dashboardWidgetId
                  )[0];
                  if (newLayout) {
                    return {
                      ...w,
                      x: newLayout.x,
                      y: newLayout.y,
                      w: newLayout.w,
                      h: newLayout.h
                    };
                  } else {
                    return w;
                  }
                });
                dashboardApi.updateWidgetsPosition(newWidgets).catch((error) => {
                  toast.error(error, { autoClose: false });
                });
                return newWidgets;
              });
            }}
            onDrop={onDrop}
            measureBeforeMount={false}
            compactType={"vertical"}
            useCSSTransforms={true}
            cols={ isInEditMode ? { lg: 40, md: 40, sm: 40, xs: 40, xxs: 40 } : {lg: 40, md: 20, sm: 12, xs: 6, xxs: 3}}
          >
            {widgets.map((data) => { //(data, index)
              return (
                <div
                  style={{
                    overflow: "hidden",
                  }}
                  key={data.dashboardWidgetId}
                >
                  <WidgetFactory
                    widgetType={data.widgetType}
                    widgetId={data.dashboardWidgetId}
                    viewMode={isInEditMode ? appViewModeConstants.ADMIN_MODE : appViewModeConstants.VIEW_MODE}
                    isPublicViewMode={isPublicViewMode}
                    clientType={clientType}
                    width='100%'
                    setIsDraggable={(value) => { setIsPreventDraggable(value); }}
                    height='100%'
                    onDelete={() => {onDelete(data.dashboardWidgetId); }}
                    brandingSettings={brandingSettings}
                    onAudioPlay={() => getAudio()?.play()}
                  />
                </div>
              );
            })}
          </ResponsiveGridLayout>
        </div>
      </div>
      {isInEditMode ?
        isWidgetListExpanded ? (
              <div className={ classes.widgetPanelListMaximize}>
                <WidgetTypesPanelExpanded   key={"widgetTypesPanel"}
                                  isDraggable={isInEditMode && isPreventDraggable}
                                  onSetDroppedWidgetType={onSetDroppedWidgetType}
                                  onAddingNewWidget={onAddingNewWidget}
                                  onCollapseClick={() => setIsWidgetListExpanded(false)}
                />
              </div>
            ) : (
              <div className={ classes.widgetPanelListMinimize}>
                <WidgetTypesPanelCollapsed key={"widgetTypesPanel"}
                                  isDraggable={isInEditMode && isPreventDraggable}
                                  onSetDroppedWidgetType={onSetDroppedWidgetType}
                                  onAddingNewWidget={onAddingNewWidget}
                                  onExpandClick={() => setIsWidgetListExpanded(true)}
                />
              </div>
        )
       : ("")
      }
    </div>
  );
};