import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import {getLivDataApiSettings, updateLivDataApiSettings} from "../../../../../_services/venue.livdata.service";

const useStyles = makeStyles((theme) => ({
  ok: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  cancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  textField: {
    marginTop: theme.spacing(3),
    width: '500px'
  },
}));

const getLivDataApiSettingsCall = async (venueId) => {
  if (venueId) {
    const result = await getLivDataApiSettings(venueId);
    return result.data;
  } else {
    return null;
  }
};

export const LivDataApiSettings = ({venue}) => {
  const classes = useStyles();

  // The "data" here is the Liv Data API settings as they were loaded from the database.
  const [{data: initialSettings, isLoading : isLoading1}] = useApiGet(getLivDataApiSettingsCall, venue.id, null);

  // The settings is the same as the initialSettings until the user edits and saves
  // one for the fields. The settings variable will be set to the newly saved value.
  const [settings, setSettings] = useState("");

  // If cached[field] is null, then the field have not been edited since
  // the page loaded from the database. If the cached[field] is not null (the empty string is not null), 
  // then the field has been edited.
  const [cachedEventId, setCachedEventId] = useState(null);
  const [cachedApiUrl, setCachedApiUrl] = useState(null);
  const [cachedOccupancyApiUrl, setCachedOccupancyApiUrl] = useState(null);
  const [cachedOAuthUrl, setCachedOAuthUrl] = useState(null);
  const [cachedClientId, setCachedClientId] = useState(null);
  const [cachedClientSecret, setCachedClientSecret] = useState(null);
  
  const [isSaving, setIsSaving] = useState(false);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    setSettings(initialSettings);
    setCachedEventId(null);
    setCachedApiUrl(null);
    setCachedOccupancyApiUrl(null);
    setCachedOAuthUrl(null);
    setCachedClientId(null);
    setCachedClientSecret(null);
  }, [initialSettings]);

  const saveEventId = async () => {
    if (isSaving) {
      return;
    }

    let eventIdInt = settings.livEventId;
    if (cachedEventId != null) {
      if (cachedEventId == "") {
        eventIdInt = null;
      } else {
        eventIdInt = parseInt(cachedEventId);
        if (isNaN(eventIdInt)) {
          toast.error("LIV Data API settings. Event ID must be empty or an integer.");
          return;
        }
      }
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      LivEventId: eventIdInt,
      ApiUrl: settings.apiUrl,
      OccupancyApiUrl: settings.occupancyApiUrl,
      OAuthUrl: settings.oAuthUrl,
      ClientId: settings.clientId,
      ClientSecret: null
    }

    setIsSaving(true);
    updateLivDataApiSettings(settingsToSave)
    .then(() => {
      toast.success("LIV Data API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        livEventId: cachedEventId === null ? settings.livEventId : eventIdInt
      }));
      setCachedEventId(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save LIV Data API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };


  const saveApiUrl = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      LivEventId: settings.livEventId,
      ApiUrl: cachedApiUrl ?? settings.apiUrl,
      OccupancyApiUrl: settings.occupancyApiUrl,
      OAuthUrl: settings.oAuthUrl,
      ClientId: settings.clientId,
      ClientSecret: null
    }

    setIsSaving(true);
    updateLivDataApiSettings(settingsToSave)
    .then(() => {
      toast.success("LIV Data API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        apiUrl: cachedApiUrl ?? settings.apiUrl
      }));
      setCachedApiUrl(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save LIV Data API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveOccupancyApiUrl = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      LivEventId: settings.livEventId,
      ApiUrl: settings.apiUrl,
      OccupancyApiUrl: cachedOccupancyApiUrl ?? settings.occupancyApiUrl,
      OAuthUrl: settings.oAuthUrl,
      ClientId: settings.clientId,
      ClientSecret: null
    }

    setIsSaving(true);
    updateLivDataApiSettings(settingsToSave)
    .then(() => {
      toast.success("LIV Data API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        occupancyApiUrl: cachedOccupancyApiUrl ?? settings.occupancyApiUrl
      }));
      setCachedOccupancyApiUrl(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save LIV Data API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveOAuthUrl = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      LivEventId: settings.livEventId,
      ApiUrl: settings.apiUrl,
      OccupancyApiUrl: settings.occupancyApiUrl,
      OAuthUrl: cachedOAuthUrl ?? settings.oAuthUrl,
      ClientId: settings.clientId,
      ClientSecret: null
    }

    setIsSaving(true);
    updateLivDataApiSettings(settingsToSave)
    .then(() => {
      toast.success("See LIV Data API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        oAuthUrl: cachedOAuthUrl ?? settings.oAuthUrl
      }));
      setCachedOAuthUrl(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save LIV Data API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveClientId = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      LivEventId: settings.livEventId,
      ApiUrl: settings.apiUrl,
      OccupancyApiUrl: settings.occupancyApiUrl,
      OAuthUrl: settings.oAuthUrl,
      ClientId: cachedClientId ?? settings.clientId,
      ClientSecret: null
    }

    setIsSaving(true);
    updateLivDataApiSettings(settingsToSave)
    .then(() => {
      toast.success("See LIV Data API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        clientId: cachedClientId ?? settings.clientId
      }));
      setCachedClientId(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save LIV Data API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  const saveClientSecret = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      VenueId: venue.id,
      LivEventId: settings.livEventId,
      ApiUrl: settings.apiUrl,
      OccupancyApiUrl: settings.occupancyApiUrl,
      OAuthUrl: settings.oAuthUrl,
      ClientId: settings.clientId,
      ClientSecret: cachedClientSecret ?? settings.clientSecret
    }

    setIsSaving(true);
    updateLivDataApiSettings(settingsToSave)
    .then(() => {
      toast.success("LIV Data API settings have been saved.");
      setSettings(prev => ({
        ...prev,
        clientSecret: cachedClientSecret ?? settings.clientSecret
      }));
      setCachedClientSecret(null);
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save LIV Data API settings." + error.message, {autoClose: false});
      setIsSaving(false);
    });
  };

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "16px"
            }}>
            <Typography variant="h5">LIV Data API settings</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "6px"
              }}>
              <TextField
                id="eventId"
                label = "Event ID"
                placeholder="Event URL"
                variant="outlined"
                name="eventId"
                value={cachedEventId === null ? settings?.livEventId : cachedEventId}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedEventId(value);
                }}
                className={classes.textField}
                InputProps={cachedEventId !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveEventId()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedEventId(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <TextField
                id="apiUrl"
                label = "API URL"
                placeholder="API URL"
                variant="outlined"
                name="apiUrl"
                value={cachedApiUrl === null ? settings?.apiUrl ?? '' : cachedApiUrl}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedApiUrl(value);
                }}
                className={classes.textField}
                InputProps={cachedApiUrl !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveApiUrl()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedApiUrl(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <TextField
                id="occupancyApiUrl"
                label = "Occupancy API URL"
                placeholder="Occupancy API URL"
                variant="outlined"
                name="occupancyApiUrl"
                value={cachedOccupancyApiUrl === null ? settings?.occupancyApiUrl ?? '' : cachedOccupancyApiUrl}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedOccupancyApiUrl(value);
                }}
                className={classes.textField}
                InputProps={cachedOccupancyApiUrl !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveOccupancyApiUrl()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedOccupancyApiUrl(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <TextField
                id="oAuthUrl"
                label = "OAuth URL"
                placeholder="OAuth URL"
                variant="outlined"
                name="oAuthUrl"
                value={cachedOAuthUrl === null ? settings?.oAuthUrl ?? '' : cachedOAuthUrl}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedOAuthUrl(value);
                }}
                className={classes.textField}
                InputProps={cachedOAuthUrl !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveOAuthUrl()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedOAuthUrl(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <TextField
                id="clientId"
                label = "Client ID"
                placeholder="Client ID"
                variant="outlined"
                name="clientId"
                value={cachedClientId === null ? settings?.clientId ?? '' : cachedClientId}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedClientId(value);
                }}
                className={classes.textField}
                InputProps={cachedClientId !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveClientId()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedClientId(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
              <TextField
                id="clientSecret"
                label = "Client Secret"
                placeholder="Client Secret"
                variant="outlined"
                name="clientSecret"
                //type="password"
                value={cachedClientSecret === null ? (settings?.clientSecret ?? '') : cachedClientSecret}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedClientSecret(value);
                }}
                onFocus={(e) => {
                  if (settings?.clientSecret && settings.clientSecret === initialSettings?.clientSecret) {
                    setCachedClientSecret('');
                  }
                }}
                className={classes.textField}
                InputProps={cachedClientSecret !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={(e) => saveClientSecret()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedClientSecret(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default LivDataApiSettings;