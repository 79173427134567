import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    getModeList,
} from "../../../_reducers/mode.selector";
import { useParams, useHistory } from "react-router";
import { v4 as uuidv4 } from 'uuid';
import  ModeForm from "./ModeForm";
import { toast } from "react-toastify";
import {  
    updateMode,     
} from '../../../_actions/mode.actions';

const EditMode = () => {
    
    const dispatch = useDispatch();
    const data = useSelector(getModeList);
    
    const { id } = useParams();
    const history = useHistory();

    const [mode, setMode] = useState({
        id: uuidv4(),
        name: "",
        description: "",
        isDefault: false,
        isDeleted: false,
    });

    useEffect(() => {
        setMode(data?.find(e => e.id === id))
    }, [data, id]) 
    
    function handleChange(event) {
        const { name, value } = event.target;       
        setMode((prevMode) => ({
            ...prevMode,
            [name]: value,
          }));
    }

    function handleSave(event) {

        event.preventDefault();
        if (data?.find((e) => e.name === mode.name && e.id !== mode.id)) {
            toast.error("Venue mode already exists", { autoClose: false });
        } else {
            
            dispatch(updateMode(mode))
                .then(() => {
                    toast.success("Venue mode updated successfully.");
                    history.push("/venue");
                })
                .catch((error) => {
                    toast.error("Updating venue mode failed" + error, { autoClose: false });
                });
        }
    }

    return (
        <>
            
             <ModeForm
                mode={mode}
                onChange={handleChange}
                onSave={handleSave} />              
        </>
    );
};

export default EditMode;
