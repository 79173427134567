import * as React from 'react';

export const useSortModel = (storageKey, fallbackSortModel) => {
  const [value, setValue] = React.useState(
    JSON.parse(localStorage.getItem(storageKey)) ?? fallbackSortModel
  );

  React.useEffect(() => {
    localStorage.setItem(storageKey, JSON.stringify(value));
  }, [value, storageKey]);

  return [
    value, 
    (model) => {
      if (JSON.stringify(value) !== JSON.stringify(model)) {
      setValue(model);
    }}];
};

export default useSortModel;