import React, {useEffect, useState} from "react";
import * as userGroupApi from "../../../_services/venue.userGroup.service";
import {
  Box,
  IconButton, 
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import { DataGridLoadingOverlay } from '../../_common/DataGridLoadingOverlay';
import {Button, ButtonType} from "../../_common/htmlTags";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NIL as NIL_UUID, validate} from "uuid";
import {toast} from "react-toastify";
import {getVenueUsersIncludingGlobalAdministrators} from "../../../_services/venue.user.service";
import { useApiGet } from "../../../_helpers/useApiGet";

const useStyles = makeStyles((theme) => {
  return {
    deleteIcon: { 
      color: theme.palette.color.primary.red
    },

    popup: {
      width: "800px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
    },
    title: {
      fontSize: "24px",
      fontWeight: "500",
      flexGrow: 1
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    },
    nameField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2)
    },
  };
});

const getUsersCall = async (venueId) => {
  if (venueId ) {
    const result = await getVenueUsersIncludingGlobalAdministrators(venueId);
    const users = result.data;
    users.forEach(function (item, index) {
      item.userId = item.id;
    });
    return users;
  } else {
    return [];
  }
};

export const UserGroupPopup = ({show, onSave, onCancel, userGroup, isDuplicateName}) =>
{
  const classes = useStyles();
  // const ruleSetId = ruleSet?.id ?? NIL_UUID;
  // const venueId = ruleSet?.venueId ?? NIL_UUID;
  const [currentUserGroup, setCurrentUserGroup] = useState({
    id: userGroup.id,
    venueId: userGroup.venueId,
    name: userGroup.name,
    userIds: [
      ...userGroup.userIds
    ]
  });
  const [users, setUsers] = useState([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  // const [{data: users, isLoading : isLoadingUsers, isError: isLoadingUsergsError, errorMessage: loadingUsersErrorMessage}] 
  //   = useApiGet(getUsersCall, userGroup.venueId, []);

  useEffect(() => {
    setIsLoadingUsers(true);
    getUsersCall(userGroup.venueId)
    .then((data) => {
      setUsers(data);
      setIsLoadingUsers(false);
      setCurrentUserGroup(prev => {
        return {
          ...prev,
          userIds: [
            ...userGroup.userIds
          ]
        };
      });
    })
    .catch((error) => {
      setIsLoadingUsers(false);
      console.log("Loading users failed. " + error );
      toast.error("Loading users failed.");
    });
  },[]);


  const handleSave = (e) => {
    e.preventDefault();

    if ((currentUserGroup.name?.trim() ?? "") === "") {
      toast.error("User group name cannot be blank.");
      return;
    }
    if (isDuplicateName( currentUserGroup.name)) {
      toast.error("User group name already exists.")
      return;
    }
    setIsSaving(true);
    if(currentUserGroup.id === NIL_UUID) {
      userGroupApi.addUserGroup(currentUserGroup)
        .then(result => {
          setIsSaving(false);
          onSave();
          toast.success("User group added successfully.");
        })
        .catch((error) => {
          setIsSaving(false);
          console.error(error);
        });
    } else {
      userGroupApi.updateUserGroup(currentUserGroup)
        .then(result => {
          setIsSaving(false);
          toast.success("User group updated successfully.");
          onSave();
        })
        .catch((error) => {
          setIsSaving(false);
          console.error(error);
        });
    }
  };

  return (
    <Modal open={show}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "920px",
          alignItems: "stretch"
        }}
        className={classes.popup}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Typography className={classes.title}>
            User group details
          </Typography>
          <Tooltip 
            title="Close" 
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          required
          name="name"
          value={currentUserGroup?.name}
          onChange={(e) => {
            const { value } = e.target;
            setCurrentUserGroup(prev => {
              return {
                ...prev,
                name: value
              };
            });
          }}
          className={classes.nameField}
        />
        <div className={classes.instructions}>
          <Typography variant="h6">Choose users</Typography>
        </div>
        {/* <AlertRuleSetEditor 
            alertRuleSet={currentRuleSet}
            onChange={value => setCurrentRuleSet(value)}>
        </AlertRuleSetEditor> */}
        <DataGrid
          autoHeight
          checkboxSelection
          pageSize={10}
          columns={[
            {
              field: "id",
              hide: true,
            },
            {
              field: "firstName",
              headerName: "FIRST NAME",
              width: 200,
            },
            {
              field: "lastName",
              headerName: "LAST NAME",
              width: 250,
            },
            {
              field: "userName",
              headerName: "LOGIN",
              width: 250,
            },
          ]}
          rows={users}
          loading={isLoadingUsers}
          components={{
            LoadingOverlay: DataGridLoadingOverlay,
          }}
          selectionModel={currentUserGroup.userIds}
          onSelectionModelChange={(newSelectionModel) => {
            setCurrentUserGroup((prev) => {
              return {
                ...prev,
                userIds: newSelectionModel
              };
            });
          }}   
        />
        <div
        
        >
          <Button
            variant="contained"
            type="submit"
            disabled={isSaving}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            style={{
              padding: "10px",
              marginRight: "10px",
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>&nbsp;
          <Button
            className="btn primary"
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              onCancel();
            }}
            style={{
              padding: "9px"
            }}
          >Cancel</Button>
        </div>
      </Box>
    </Modal>
  );
  
}