import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import {
    Box,
    Tab,
    Tabs,
    Typography,
    makeStyles,
} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { getWidgetSettingsCollectionGroups } from "../../../../_services";
import { toast } from "react-toastify";
import Setting from "../Setting";
import { widgetSettingsValuesSelector } from "../../../../_reducers/widget.data.selectors";
import SettingsDependencyContext from "../SettingsDependencyContext";
import { widgetSettingsConstants } from "../../../../_constants/widget.settings.constants";
import { metricConstants } from "../../../../_constants/metric.constants";
import { widgetTypeConstants } from "../../../../_constants";

const widgetTypeDependentConstants = {
    [widgetTypeConstants.widgetPatronDistribution.id]: {
        defaultTab: "PatronDistributionLocationSettingsGroup",
        reduxValuesStore: "widgetPatronDistribution"
    },
};

const useStyles = makeStyles((theme) => ({
    collectionContainer: {
        marginTop: "21px"
    },

    groupContainer: {
        paddingLeft: "0px",
        paddingRight: "0px",
    },

    setting: {
        marginTop: "25px",
    },

    root: {
        "& .MuiTab-wrapper": {
            alignItems: "start",
            textTransform: "none",
            backgroundColor: "transparent",
        },
        "& .MuiButtonBase-root": {
            padding: "0px",
            minWidth: "auto",
            marginRight: "20px",
        },

        "& .MuiTab-root": {
            padding: "0px",
            minWidth: "auto",
            marginRight: "20px",
        },

        "& .MuiTab-root.Mui-selected": {
            color: theme.palette.color.primary.main,
        },
    },
}));

const SettingsGroupsCollection = ({
    widgetSettingWithValue,
    handleSettingValueChange,
    widgetTypeId,
}) => {
    const classes = useStyles();

    const settingsDependencyContextData = useContext(SettingsDependencyContext);

    const widgetSettingsValues = useSelector(state => widgetSettingsValuesSelector(state, widgetTypeDependentConstants[widgetTypeId].reduxValuesStore, widgetSettingWithValue.dashboardWidgetId));

    const [collectionGroups, setCollectionGroups] = useState([]);
    const [selectedTab, setSelectedTab] = useState(widgetTypeDependentConstants[widgetTypeId].defaultTab);

    useEffect(() => {
        getWidgetSettingsCollectionGroups(widgetSettingWithValue.widgetSettingId)
            .then((response) => {
                setCollectionGroups(response);
                setSelectedTab(response[0].parentSetting.className);
            })
            .catch((error) => {
                toast.error("Loading collection groups failed " + error, { autoClose: false });
            });
    }, [])

    useEffect(() => {
        // Handling required validation across all tabs
        if (collectionGroups.length > 0) {
            var emptyInputs = {};
            collectionGroups.forEach((group) => {
                emptyInputs[group.parentSetting.className] = [];

                group.settingsGroupChildren.forEach((setting) => {
                    if (
                        !widgetSettingsValues?.find(e => setting.className === e?.widgetSetting?.className || setting.className === e?.className)?.value
                        &&
                        !widgetSettingWithValue?.value?.find(e => setting.className === e?.className)?.value
                        &&
                        setting.defaultValue === null
                    ) {
                        // Patron distribution handling
                        if (
                            settingsDependencyContextData.selectedPatronDistributionMetric?.name === metricConstants.occupancy.name
                            &&
                            (
                                setting.className === widgetSettingsConstants.widgetSettingTimePeriod.className
                                ||
                                setting.className === widgetSettingsConstants.widgetSettingIncludeDescendants.className
                                ||
                                setting.className === widgetSettingsConstants.widgetSettingBaseLineTimePeriod.className
                                ||
                                setting.className === widgetSettingsConstants.widgetSettingBaseLineIncludeDescendants.className
                            )
                        ) {
                        } else {
                            emptyInputs[group.parentSetting.className].push(setting.className);
                        }
                    }
                });
            });
        }

        var emptyGroups = [];
        Object.keys(emptyInputs ?? {}).forEach((groupClassName) => {
            if (emptyInputs[groupClassName]?.length > 0) {
                emptyGroups.push(groupClassName);
            }
        });

        if (emptyGroups.length > 0) {
            const validateSubmit = (event) => {
                event.preventDefault();


                if (!emptyGroups.includes(selectedTab)) {
                    setSelectedTab(emptyGroups[0]);
                }
            };
            settingsDependencyContextData.setSubmitValidation(() => validateSubmit);
        } else {
            settingsDependencyContextData.setSubmitValidation(null);
        }

    }, [widgetSettingWithValue, collectionGroups])


    const handleValueChange = (setting, newValue) => {
        var groupSettingsList = [];
        if (widgetSettingWithValue.value instanceof Array) {
            groupSettingsList = widgetSettingWithValue.value.slice();
        }

        var existingChangedSetting = groupSettingsList.find(e => e?.className === setting?.className);
        if (existingChangedSetting) {
            groupSettingsList.find(e => e?.className === setting?.className).value = newValue;
        } else {
            var changedSetting = Object.assign({}, setting);
            changedSetting.value = newValue;
            changedSetting.dashboardWidgetId = widgetSettingWithValue.dashboardWidgetId;
            changedSetting.widgetSettingId = setting.id;

            groupSettingsList.push(changedSetting);
        }

        handleSettingValueChange(widgetSettingWithValue, groupSettingsList);
    };


    return (
        <Box className={classes.collectionContainer}>
            <TabContext value={selectedTab}>
                <Box>
                    <Typography variant="h6" className={classes.text}>{widgetSettingWithValue.name}</Typography>

                    <Tabs
                        TabIndicatorProps={{ style: { backgroundColor: "#00C1CE" } }}
                        classes={{ root: classes.root }}
                        value={selectedTab}
                        onChange={(e, selected) => { setSelectedTab(selected) }}
                    >
                        {
                            collectionGroups.map(group => {
                                return (
                                    <Tab
                                        disableRipple
                                        style={{ backgroundColor: "transparent" }}
                                        classes={{ root: classes.root }}
                                        label={<div>{`${group.parentSetting.name}*`}</div>}
                                        value={group.parentSetting.className}
                                        key={group.parentSetting.className}
                                    />
                                );
                            })
                        }
                    </Tabs>
                </Box>
                {
                    collectionGroups.map((group) => {
                        return (
                            <TabPanel className={classes.groupContainer} value={group.parentSetting.className} key={group.parentSetting.className}>
                                {
                                    group.settingsGroupChildren.map((setting, index) => {
                                        var settingWithValue = Object.assign({}, setting);
                                        settingWithValue.value = 
                                        widgetSettingWithValue.value?.find(e => setting.className === e?.className)?.value
                                        ??
                                        widgetSettingsValues?.find(e => setting.className === e?.widgetSetting?.className || setting.className === e?.className)?.value ?? setting.defaultValue;

                                        // Hide specific settings depending on required logic:
                                        // 1. Patron distribution - hide depending on Metric
                                        if (
                                            settingsDependencyContextData.selectedPatronDistributionMetric?.name === metricConstants.occupancy.name
                                            &&
                                            (
                                                setting.className == widgetSettingsConstants.widgetSettingTimePeriod.className
                                                ||
                                                setting.className == widgetSettingsConstants.widgetSettingIncludeDescendants.className
                                                ||
                                                setting.className == widgetSettingsConstants.widgetSettingBaseLineTimePeriod.className
                                                ||
                                                setting.className == widgetSettingsConstants.widgetSettingBaseLineIncludeDescendants.className
                                            )
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <div className={index !== 0 ? classes.setting : null} key={index}>
                                                <Setting
                                                    settingData={settingWithValue}
                                                    widgetTypeId={widgetTypeId}
                                                    handleSettingValueChange={handleValueChange}
                                                />
                                            </div>
                                        );
                                    })
                                }
                            </TabPanel>
                        );
                    })
                }
            </TabContext>
        </Box>
    );
}

export default SettingsGroupsCollection;