import { Log, UserManager, WebStorageStateStore } from 'oidc-client';
import axios from "axios";
import devconsole from "../components/_common/devconsole";

export class AuthService {
    static userManager = null;

    // constructor() {
    //     //https://github.com/IdentityModel/oidc-client-js/wiki
    //     const settings = {
    //         authority: 'https://sts-uat.melkansoftware.com',
    //         client_id: 'PMYApp',
    //         redirect_uri: 'https://pmy-uat.melkansoftware.com/signin-callback.html',
    //         silent_redirect_uri: `https://pmy-uat.melkansoftware.com/silent-renew.html`,
    //         automaticSilentRenew: true,

    //         monitorSession: false,
    //         post_logout_redirect_uri: 'https://pmy-uat.melkansoftware.com/',
    //         response_type: 'code', // for Auth Code flow
    //         scope: 'openid profile email roles offline_access Dashboard UserIdentity/Admin Services/Indicators Services/DataCollector Services/VenueManagement',
    //         accessTokenExpiringNotificationTime: 200,
    //         userStore: new WebStorageStateStore({ store: window.localStorage }) // set this to save user info in localStorage
    //     };
    //     this.userManager = new UserManager(settings);
    //     this.userManager.events.addAccessTokenExpiring(function () {
    //         console.log("token expiring...");
    //         //this.userManager.signinRedirect();
    //     });
    //     this.userManager.events.addAccessTokenExpired(function () {
    //         console.log("token expired...");
    //         //this.userManager.signinRedirect();
    //     });
    //     Log.logger = console;
    //     Log.level = Log.INFO;
    // }
    constructor() {
        Log.logger = console;
        Log.level = Log.INFO;
    }

    createUserManager() {
        const settings = {
            authority: process.env.REACT_APP_authService_authority,
            client_id: process.env.REACT_APP_authService_client_id,
            redirect_uri: process.env.REACT_APP_authService_redirect_uri,
            silent_redirect_uri: process.env.REACT_APP_authService_silent_redirect_uri,
            automaticSilentRenew: false,
            
            monitorSession: false,
            post_logout_redirect_uri: process.env.REACT_APP_authService_post_logout_redirect_uri,
            response_type: process.env.REACT_APP_authService_response_type, // for Auth Code flow
            scope: process.env.REACT_APP_authService_scope,
            accessTokenExpiringNotificationTime: 200,
            userStore: new WebStorageStateStore({ store: window.localStorage }) // set this to save user info in localStorage
        };
        const usrMgr = new UserManager(settings);
        usrMgr.events.addAccessTokenExpiring(() => {
            devconsole.log("token expiring... ", (new Date()).toString());
            usrMgr.signinSilent().then((a) => {
                devconsole.log("signin silent success");
            }).catch((error) => {
                devconsole.log("signin silent failed / token expiring", error);
                console.error(error);
            });
        });
        usrMgr.events.addAccessTokenExpired(() => {
            devconsole.log("token expired... ", (new Date()).toString() );
            usrMgr.signinRedirect().then(() => {
                devconsole.log("signin redirect success");
            }).catch((error) => {
                devconsole.log("signin redirect failed / token expired", error);
                devconsole.error(error);
            });
        });
        usrMgr.events.addSilentRenewError((e) => {
            devconsole.log("Silent Renew Error", e);
        });
        usrMgr.events.addUserUnloaded(() => {
            devconsole.log("User unloaded");
            this.clearUserManager();
        });
        usrMgr.events.addUserSignedOut(() => {
            devconsole.log("User signed out");
            this.clearUserManager();
        });

        return usrMgr;
    }

    clearUserManager() {
        AuthService.userManager?.clearStaleState();
        AuthService.userManager = null;
    }

    getUserManager() {
        return AuthService.userManager ?? (AuthService.userManager = this.createUserManager());
    }

    getUser() {
        return this.getUserManager().getUser();
    }

    login() {
        return this.getUserManager().signinRedirect();
    }


    renewToken() {
        return this.getUserManager().signinSilent();
    }

    async logout() {
        return this.getUserManager().signoutRedirect();
    }

    async getClientCredentialsToken(client_id, client_secret, scope) {
        const params = new URLSearchParams({
            grant_type: 'client_credentials',
            client_id,
            client_secret,
            scope
        });
        
        const response = await axios({
            method: 'post',
            url: `${process.env.REACT_APP_authService_authority}/connect/token`,
            data: params
        });
        return response.data.access_token;
    }
}
