import React from 'react';
import { Settings } from '../Settings';
import DataSourceSettings from "./DataSourceSettings";
import {VenueCamerasSetting} from "../VenueCamerasSetting";
import { SCOPES } from "../../../../../_constants/user.permissions.constants";

export const DataSources = () => {

  return (
    <Settings
      pageName="dataSources"
      title="Data Sources"
      tabs={[{   
        name: "cameras",
        title: "AI STREAMS",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_AI_STREAMS],
        component: VenueCamerasSetting
      }, {
        name: "dataSources",
        title: "LOCATION MAPPING",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_DATA_SOURCES],
        component: DataSourceSettings,
        //growVertically: false
      }]}
    />
  );
};

export default DataSources;