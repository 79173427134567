import React, {useState} from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {Button} from "./htmlTags";
import {Box, Link, Typography,} from "@material-ui/core";
import HomeIcon from '@material-ui/icons/Home';
import { alpha } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.scrollbar
  },
  header: {
    //...theme.mixins.toolbar,
    backgroundColor: theme.palette.menu.background
  },
  logo: {
    width: 94,
    margin: 18,
    marginBottom: "10px",
  },
  robot: {
    width: 114,
    height: 114,
    marginTop: '45px'
  },
  generalText: {
    color: theme.palette.color.primary.dark,
    fontWeight: 600,
    fontSize: '36px',
  },
  fancyDot: {
    color: theme.palette.color.primary.main
  },
  errorMessage: {
    fontWeight: 600,
    fontSize: '18px',
    color: theme.palette.errorPage.messageColor,
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    maxWidth: "700px"
  },
  stackLink: {
    fontWeight: 500,
    fontSize: "18px",
    color: theme.palette.color.primary.dark,
    marginLeft: theme.spacing(2),
    "&:hover" : {
      color: theme.palette.color.primary.dark,
    },
    width: "100px",
    overflow: "visible",
    whiteSpace: "nowrap"
  },
  stackText: {
    backgroundColor: alpha(theme.palette.errorPage.stackBackground, 0.1),
    maxHeight: "100%",
    overflow: "auto",
    whiteSpace: "normal",
    padding: theme.spacing(2),
  },
}));

export default function ErrorPage({error}) {
  const classes = useStyles();

  const [showStack, setShowStack] = useState(false);
  const history = useHistory();

  const handleStackLinkClicked = () => {
    setShowStack( prev => !prev);
  };

  return (
      <Box
        className={classes.root}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "100vh"        
        }}
      >
        <div className={classes.header} style={{width:"100%"}}>
          <img src="logo_white.png" alt="" className={classes.logo}></img>
        </div>
        <img src="icons/dizzy-robot.png" alt="" className={classes.robot}></img>
        <Box
          sx={{
            marginTop: '16px'
          }}>
          <Typography
            component="span"
            className={classes.generalText}>
            Something went wrong
          </Typography>
          <Typography
            component="span"
            className={`${classes.generalText} ${classes.fancyDot}`}>
            .
          </Typography>
        </Box>
        <Typography
          className={classes.errorMessage}
        >
          {error.toString()}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginTop: "24px"
          }}
        >
          <Button
            variant="contained"
            startIcon={<HomeIcon />}
            onClick={(e) => {
              history.push('/home');
              //setTimeout(() => {
              window.location.reload();
              //}, 100);
            }}
            >
              GO HOME
          </Button>
          <Link 
            href="#" 
            onClick={handleStackLinkClicked}
            className={classes.stackLink}
            >
            {showStack ? 'Hide error details' : 'Show error details'}
          </Link>
        </Box>
        {showStack? 
          <Box
            sx={{
              flexGrow: 1,
              width: "700px",
              minHeight: 0,
              paddingTop: "32px",
              paddingBottom: "32px"
            }}  
          >
            <Typography className={classes.stackText}>
              { error.stack? error.stack.replace(error.toString() + '\n', '') : ""}
            </Typography>
          </Box>
        : null 
        }
      </Box>
  );
}