import { factorConstants } from "../_constants/factor.constants";

const initialState = {
  factorHistoryFilter: {
    locationId: '-',
    factorTypeId: '-'
  }
};

export function factorReducer(state = initialState, action) {
  switch (action.type) {
    case factorConstants.SELECT_FACTORS_TAB:
      return {
        ...state,
        selectedTab: action.selectedTab,
      };
    case factorConstants.SELECT_FACTOR_HISTORY_FILTER:
      return {
        ...state,
        factorHistoryFilter: {
          ...state.factorHistoryFilter,
          ...action.filter
        }
      };
    case factorConstants.CLEAR_FACTOR_HISTORY_FILTER:
      return {
        ...state,
        factorHistoryFilter: {
          locationId: '-',
          factorTypeId: '-'
        }
      };
    default:
      return state;
  }
};

export default factorReducer;
