import React from "react";
import {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Checkbox, FormControlLabel, Popover } from "@material-ui/core";
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color';
import { Button, ButtonType } from "./htmlTags/Button";

const useContentStyles = makeStyles((theme) => {
  return {
    okButton: {
      margin: "10px 5px 10px 10px",
      height: "25px",
      width: "40px"
    },
    cancelButton: {
      margin: "10px 10px 10px 5px",
      height: "25px",
      width: "60px"
    },
    flashCheckbox: {
      marginLeft: 0
    }
  };
});

const ColorPickerContent = ({enableFlash, color, flash, onOk, onCancel}) => {
  const classes = useContentStyles();
  const [currentColor, setCurrentColor] = useState(color ?? "#FFFFFF");
  const [currentFlash, setCurrentFlash] = useState(!!flash);  // If flash is undefined, then the controlled <Checkbox> component will show warning.
  useEffect(() => {
    document.getElementsByClassName("sketch-picker ")[0].style.boxShadow = "";
  }, []);

  const handleChangeComplete = (newColor) => {
    setCurrentColor(newColor.hex);
  };

  const handleFlashChange = (e) => {
    setCurrentFlash(e.target.checked);
  };

  return (
    <Box
      boxShadow={20}
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <SketchPicker
        color={currentColor}
        onChangeComplete={handleChangeComplete}
      />
      {enableFlash ? 
        <FormControlLabel
          className={classes.flashCheckbox}
          label="Flash"
          control={<Checkbox 
            checked={currentFlash} 
            onChange={handleFlashChange} 
            color="primary"
          />}
        />
      : null}
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Button
          className={classes.okButton}
          onClick={() => onOk(currentColor, currentFlash)}
        >
            OK
        </Button>

        <Button
          className={classes.cancelButton}
          buttonType={ButtonType.Secondary}
          onClick={onCancel}
        >
            CANCEL
        </Button>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => {
  return {
    circle: {
      width: "40px",
      height: "40px",
      borderRadius: "20px",
      borderColor: theme.palette.common.panelBorderColor,
      borderWidth: "1px",
      borderStyle: "solid",
      "&:hover": {
        transform: "scale(1.05)",
        cursor: "pointer",
      },
    },
    "@keyframes flashEffect": {
      "0%": {
        opacity: 1
        // The filters are commented out for future experiments.
        //filter: "brightness(1)",
        //filter: "contrast(1)"
      },
      "50%" : {
        opacity: 0.5
        //filter: "brightness(0.9)",
        //filter: "contrast(0.5)"
      },
      "100%" : {
        opacity: 1
        //filter: "brightness(1)",
        //filter: "contrast(1)"
      }
    },
    flashCircle: {
      animation: `$flashEffect 1s infinite ${theme.transitions.easing.easeInOut}`
    },
    blankCircle : {
      borderWidth: "1px",
      borderColor: theme.palette.color.secondary.main,
      borderStyle: "solid"
    },
    blankIcon: {
      color: theme.palette.color.primary.main,
    },
    paper: {
      border: '1px solid',
      padding: theme.spacing(1),
      backgroundColor: theme.palette.background.paper,
    },
  };
});

// ColorPicker is implemented as a controlled component.
// enableFlash - if set to true, the Flash checkbox will be visible.
// color - not set or is a string like "#FF0000".
// flash - not set or specifies whether the color should be flashing or not.
// onChange - a callback that takes two parameters, color and flash, and is called
// when the user clicks OK in the color picker popup.
export const ColorPicker =({enableFlash, color, flash, onChange = (newColor, newFlash) => {}}) => {
  const classes = useStyles({flash});

  const [open, setOpen] = useState(false);
  const [anchorElement, setAnchorElement] = useState(null);

  const handlePopoverClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setOpen(false);
    } 
  };

  return (
    <Box
      className={`${classes.circle} ${color ? "" : classes.blankCircle} ${color && flash ? classes.flashCircle: ""}`}
      sx={{
        backgroundColor: color || "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
      onClick={(e) => {
        if (!open) {
          setOpen(true);
          setAnchorElement(e.currentTarget);
        }
      }}
    >
      <div>
        {
          color 
            ? null 
            : <ColorizeIcon
                className={classes.blankIcon}
              />
        }
        <Popover 
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open} 
          anchorEl={anchorElement} 
          onClose={handlePopoverClose}
        >
          <ColorPickerContent
            color={color}
            flash={flash}
            enableFlash={enableFlash}
            onOk={(newColor, newFlash) => {
              onChange(newColor, newFlash);
              setOpen(false);
              setAnchorElement(null);
            }}
            onCancel={() => {
              setOpen(false);
              setAnchorElement(null);
            }}
          />
        </Popover>
      </div>
    </Box>
  );
};

export default ColorPicker;