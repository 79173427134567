const htmlTagTheme = (theme) =>
({
  button: {
    primaryButton: {
      fontSize: "14px",
      background: theme.palette.color.primary.main,
      borderRadius: "3px",
      color: "#FFFFFF",
      "&:hover": {
        background: theme.palette.color.primary.main20Darker,
      }
    },
    secondaryButton: {
      fontSize: "14px",
      background: "#FFFFFF",
      borderColor: "#000000",
      borderRadius: "3px",
      color: theme.palette.color.primary.dark,
      "&:hover": {
        color: "#FFFFFF",
        background: theme.palette.color.primary.dark,
      }
    },
    primaryTextButton: {
      fontSize: "14px",
      color: theme.palette.color.primary.main,
      "&:hover": {
        color: theme.palette.color.primary.main20Darker,
        backgroundColor: "transparent"
      }
    },
    darkButton: {
      fontSize: "14px",
      background: theme.palette.color.primary.dark,
      borderRadius: "3px",
      color: "#FFFFFF",
      "&:hover": {
        background: theme.palette.color.primary.darkHover,
      }
    },
    topBarPrimary: {
      textTransform: "none",
      fontSize: "14px",
      background: theme.palette.color.primary.main,
      borderRadius: "3px",
      color: "#FFFFFF",
      "&:hover": {
        background: theme.palette.color.primary.main20Darker,
        color: "#FFFFFF",
      }
    },
    topBarSecondary: {
      textTransform: "none",
      height: "32px",
      paddingLeft: "13px",
      paddingRight: "13px",
      "& .MuiSvgIcon-root": {
        color: theme.palette.color.primary.main,
      },
    },
    normalButton: {
      height: "46px",
      paddingLeft: "22px",
      paddingRight: "22px",
    },
    smallButton: {
      height: "32px",
      paddingLeft: "13px",
      paddingRight: "13px",
    },
  },
  numberCircle: {
    'border-radius': '50%',
    'text-align': 'center',
    'margin-right': '15px',
    width: '25px',
    height: '25px',        
    background: "#00C1CE",
    color: '#fff',      
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default htmlTagTheme;
