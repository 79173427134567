import React, {useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import { getTicketMasterApiSettings, updateTicketMasterApiSettings } from '../../../../../_services/venue.ticketmaster.service';

const useStyles = makeStyles((theme) => ({
  ok: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  cancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  saveIcon: {
    color: theme.palette.color.success.main,
    height: "100%",
    marginRight: "0px"
  },
  cancelIcon: {
    color: theme.palette.color.danger.main,
    height: "100%"
  },
  textField: {
    marginTop: theme.spacing(3),
    width: '500px'
  },
}));

const getTicketMasterApiSettingsCall = async (venueId) => 
  venueId ? (await getTicketMasterApiSettings(venueId)).data : null;

export const TicketMasterApiSettings = ({venue}) => {
  const classes = useStyles();

  // The "data" here is the See Tickets API settings as they were loaded from the database.
  const [{data: initialSettings, isLoading : isLoading1}] = useApiGet(getTicketMasterApiSettingsCall, venue.id, null);

  // The settings is the same as the initialSettings until the user edits and saves
  // one for the fields. The settings variable will be set to the newly saved value.
  const [settings, setSettings] = useState("");

  // If cached[field] is null, then the field have not been edited since
  // the page loaded from the database. If the cached[field] is not null (the empty string is not null), 
  // then at least one of the fields has been edited.
  const [cachedTicketmasterVenueId, setCachedTicketmasterVenueId] = useState(null);
  
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setSettings(initialSettings);
    setCachedTicketmasterVenueId(null);
  }, [initialSettings]);

  const saveTicketmasterVenueId = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      ...settings,
      venueId: venue.id,
      ticketmasterVenueId: cachedTicketmasterVenueId ?? settings.ticketmasterVenueId,
    }

    setIsSaving(true);
    await updateTicketMasterApiSettings(settingsToSave)
      .then(() => {
        toast.success("Ticketmaster API settings have been saved.");
        setSettings(prev => ({
          ...prev,
          ticketmasterVenueId: cachedTicketmasterVenueId ?? settings.ticketmasterVenueId
        }));
        setCachedTicketmasterVenueId(null);
        setIsSaving(false);
      })
      .catch(error => {
        if (error.response.status === 409) { // Duplicate
          toast.error("Failed to save Ticketmaster API settings. " + error.response.data, {autoClose: false});
        } else {
          toast.error("Failed to save Ticketmaster API settings. " + error.message, {autoClose: false});
        }

        setIsSaving(false);
      });
  };

  
  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}>
            <Typography variant="h5">Ticketmaster API settings</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "6px"
              }}>
              <TextField
                id="ticketmasterVenueId"
                label = "Ticketmaster venue ID"
                placeholder="Ticketmaster venue ID"
                variant="outlined"
                name="ticketmasterVenueId"
                value={cachedTicketmasterVenueId === null ? settings?.ticketmasterVenueId ?? '' : cachedTicketmasterVenueId}
                onChange={(e) => {
                  const { value } = e.target;
                  setCachedTicketmasterVenueId(value);
                }}
                className={classes.textField}
                InputProps={cachedTicketmasterVenueId !== null ? {
                  endAdornment: (
                    <InputAdornment
                      position="end">
                      <IconButton 
                        edge="end" 
                        className={classes.ok}
                        onClickCapture={e => saveTicketmasterVenueId()} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <DoneIcon />
                      </IconButton>
                      <IconButton 
                        edge="end" 
                        className={classes.cancel}
                        onClickCapture={(e) => setCachedTicketmasterVenueId(null)} 
                        onMouseDown={e => e.stopPropagation()}
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                    :null
                  } 
              />
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default TicketMasterApiSettings;