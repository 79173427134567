import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";

var Constants = {
  OccupancyIndicatorApiRoot: ApiRootConstants.OccupancyIndicatorApiRoot
};

const authService = new AuthService();

export async function getLatestPMYVisionOccupancyMessage(venueId, lastNoMessage = 1) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    if (lastNoMessage < 1) lastNoMessage = 1;
    return await axios
      .get(`${Constants.OccupancyIndicatorApiRoot}api/PMYVisionMessageChecker/GetLastPMYVisionMessageData/${venueId}?lastNoMessage=${lastNoMessage}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getLatestPMYVisionTrafficInOutMessage(venueId, lastNoMessage = 1) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    if (lastNoMessage < 1) lastNoMessage = 1;
    return await axios
      .get(`${Constants.OccupancyIndicatorApiRoot}api/PMYVisionMessageChecker/GetLastPMYVisionTrafficInOutMessageData/${venueId}?lastNoMessage=${lastNoMessage}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getLatestPMYVisionQueueModelMessage(venueId, lastNoMessage = 1) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    if (lastNoMessage < 1) lastNoMessage = 1;
    return await axios
      .get(`${Constants.OccupancyIndicatorApiRoot}api/PMYVisionMessageChecker/GetLastPMYVisionQueueModelMessageData/${venueId}?lastNoMessage=${lastNoMessage}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
export async function getLatestPMYVisionDemographicMessage(venueId, lastNoMessage = 1) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    if (lastNoMessage < 1) lastNoMessage = 1;
    return await axios
      .get(`${Constants.OccupancyIndicatorApiRoot}api/PMYVisionMessageChecker/GetLastPMYVisionDemographicMessageData/${venueId}?lastNoMessage=${lastNoMessage}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
