export const widgetDataConstants = {
    LOAD_DATA_STARTED: 'LOAD_DATA_STARTED_',
    LOAD_DATA_SUCCESS: 'LOAD_DATA_SUCCESS_',
    LOAD_DATA_FAILED: 'LOAD_DATA_FAILED_',

    GET_TOKEN_STARTED: 'GET_TOKEN_STARTED',
    GET_TOKEN_SUCCESS: 'GET_TOKEN_SUCCESS',
    GET_TOKEN_FAILED: 'GET_TOKEN_FAILED',

    LOAD_WIDGET_SETTINGS_STARTED: 'LOAD_WIDGET_SETTINGS_STARTED_',
    LOAD_WIDGET_SETTINGS_SUCCESS: 'LOAD_WIDGET_SETTINGS_SUCCESS_',

    LOAD_WIDGET_SETTINGS_VALUES_STARTED: 'LOAD_WIDGET_SETTINGS_STARTED_VALUES_',
    LOAD_WIDGET_SETTINGS_VALUES_SUCCESS: 'LOAD_WIDGET_SETTINGS_SUCCESS_VALUES_',

    UPDATE_WIDGET_SETTINGS_VALUES_SUCCESS: 'UPDATE_WIDGET_SETTINGS_VALUES_SUCCESS_'
};

export default widgetDataConstants;
