import React from "react" 
import { Typography } from "@material-ui/core";
import * as occUtils from './OccupancyUtils';

const Location = props => (
    <Typography className={props.classes.location}>
        { occUtils.getLocationName(props) }
    </Typography>
  );

export default Location;
