import { notificationConstants } from "../_constants";
import initialState from "./initialState";

export function notificationReducer(state = initialState.notifications, action) {
    switch (action.type) {
        case notificationConstants.NOTIFICATION_RECEIVED:
            return {
                ...state,
                [action.message.id]: action.message,
            }
        default:
            return state;
    }
}

export default notificationReducer;
