import React, {useState, useEffect} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  IconButton,
  InputAdornment,
  TextField, 
  Typography
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import {getTableauUsername, updateTableauUsername} from "../../../../../_services/report.view.service";

const useStyles = makeStyles((theme) => ({
  tableauUsernameOk: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  tableauUsernameCancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
  usernameField: {
    width: "500px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const getTableauUsernameCall = async (venueId) => {
  if (venueId) {
    const result = await getTableauUsername(venueId);
    return result;
  } else {
    return null;
  }
};

export const TableauApi = ({venue}) => {
  const classes = useStyles();

  // The "data" here is the Tableau username as it was loaded from the database.
  const [{data: initialTableauUsername, isLoading : isLoading1}] = useApiGet(getTableauUsernameCall, venue.id, null);

  // The tableauUsername is the same as the initialTableauUsername until the user edits and saves
  // the username. The tableauUsername variable will be set to the newly saved value.
  const [tableauUsername, setTableauUsername] = useState("");

  // If cachedTableauUsername is null, then the username has not been edited since
  // the page loaded from the database. If the cachedTableauUsername is not null (it can be 
  // an empty string), then the username name has been edited.
  const [cachedTableauUsername, setCachedTableauUsername] = useState(null);

  const [isSavingTableauUsername, setIsSavingTableauUsername] = useState(false);

  useEffect(() => {
    setTableauUsername(initialTableauUsername?.data);
    setCachedTableauUsername(null);
  }, [initialTableauUsername]);

  const handleTableauUsernameSave = async () => {
    if (isSavingTableauUsername) {
      return;
    }
    if (cachedTableauUsername === null) {
      return;
    }

    setIsSavingTableauUsername(true);
    let trimmedCachedTableauUsername = cachedTableauUsername ? cachedTableauUsername.trim(): cachedTableauUsername;
    updateTableauUsername(venue.id, trimmedCachedTableauUsername)
    .then(() => {
      toast.success("Tableau username has been saved.");
      setTableauUsername(trimmedCachedTableauUsername);
      setCachedTableauUsername(null);
      setIsSavingTableauUsername(false);
    })
    .catch((error) => {
      toast.error("Failed to save Tableau username." + error.message, {autoClose: false});
      setIsSavingTableauUsername(false);
    });
  }

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading1} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start"
            }}>
            <Typography 
              variant="h5">
              Tableau API
            </Typography>
            <TextField
              id="tableauUsername"
              label = "Tableau username"
              placeholder="Tableau username"
              variant="outlined"
              name="tableaUsername"
              value={cachedTableauUsername === null ? tableauUsername : cachedTableauUsername}
              onChange={(e) => {
                const { value } = e.target;
                setCachedTableauUsername(value);
              }}
              className={classes.usernameField}
              InputProps={cachedTableauUsername !== null ? {
                endAdornment: (
                  <InputAdornment
                    position="end">
                    <IconButton 
                      edge="end" 
                      className={classes.tableauUsernameOk}
                      onClickCapture={(e) => handleTableauUsernameSave()} 
                      onMouseDown={e => e.stopPropagation()}
                    >
                      <DoneIcon />
                    </IconButton>
                    <IconButton 
                      edge="end" 
                      className={classes.tableauUsernameCancel}
                      onClickCapture={(e) => setCachedTableauUsername(null)} 
                      onMouseDown={e => e.stopPropagation()}
                    >
                      <ClearIcon />
                    </IconButton>
                  </InputAdornment>
                )}
                  :null
                } 
              />
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default TableauApi;