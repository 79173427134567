import axios from "axios";
import { AuthService, downloadFile } from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/VenueCamera`,
};

const authService = new AuthService();

export async function getCameras(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/GetCameraByVenue/${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}

export async function getCamera(cameraId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/${cameraId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}

export async function addCamera(venueCamera) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {
   
      return await axios
        .post(`${constants.api}`, venueCamera, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}


export async function updateVenueCamera(venueCamera) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {
      
      return await axios
        .put(`${constants.api}`, venueCamera, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}

export async function deleteVenueCamera(venueCameraId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {

      return await axios
        .delete(`${constants.api}/${venueCameraId}`, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}

export async function deleteVenueCameraRange(venueCameraIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {

      return await axios
        .put(`${constants.api}/DeleteCameraRange`, venueCameraIds, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}

export async function venueCamerasImport(file) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("fileName", file.name);
    bodyFormData.append("File", file);

    return await axios
      .post(`${constants.api}/Import`,
        bodyFormData,
        options
      )
      .catch((error) => {
        //console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export function venueCamerasExport(venueId) {
    return downloadFile(`${constants.api}/Export/${venueId}`, `AIstreams-${venueId}.xlsx`);
}
