import featureToggle from '../../models/featureToggle';

export const devconsole = {
  value : 5,
  log: function() {
    if (featureToggle.DEVCONSOLE_ENABLED) {
      console.log(...arguments);
    }
  }
};

export default devconsole;