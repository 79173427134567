import React from "react";
import { SettingComponentImport } from "./SettingComponentImport"

/**
 * Component returns necessary specific setting component using logic of SettingComponentImport.
 */
const Setting = (
    {
        settingData,
        widgetTypeId,
        handleSettingValueChange
    }
) => {
    return SettingComponentImport(widgetTypeId, settingData.className, settingData.dataType, settingData, handleSettingValueChange);
}


export default Setting;