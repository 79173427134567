import React, { useState } from 'react';
import { WidgetTypes } from "./WidgetTypes";
import { DraggableWidgetExpanded } from "./DraggableWidgetExpanded";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonType } from "../../../_common/htmlTags";
import { toast } from "react-toastify";
import devconsole from "../../../_common/devconsole";

const useStyles = makeStyles((theme) => ({
  panel: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panel },
  panelTitle: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitle },
  panelTitleCollapseIconCenter: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitleCollapseIcon, textAlign: "center" },
  panelTitleCollapseIconLeft: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitleCollapseIcon, textAlign: "left" },
  panelTitleText: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitleText },
  widgetListPanel: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.widgetListPanel },
  panelFooterMaximize: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelFooterMaximize },
  panelFooterMinimize: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelFooterMinimize },
}));

export const WidgetTypesPanelExpanded = ({ onSetDroppedWidgetType = (widgetType) => {},
                                           isDraggable = false,
                                           onAddingNewWidget = (widgetType) => {},
                                           onCollapseClick = () => {} }) => {
  const classes = useStyles();
  const [selectedWidgetType, setSelectedWidgetType] = useState("");
  const onSelected = (widgetType) => {
    setSelectedWidgetType(widgetType);
  }

  return (
    <div className={classes.panel} >
      <div className={classes.panelTitle} >
        <div className={classes.panelTitleCollapseIconLeft}><img src={"../../../../../icons/MenuClose.svg"} alt={"Collapse panel"} title={"Collapse panel"} onClick={(e) => { onCollapseClick(); }}/></div>
        <div className={classes.panelTitleText}>choose widget</div>
      </div>
      <div className={classes.widgetListPanel} >
        {WidgetTypes.map((w) => (
          <DraggableWidgetExpanded
            key={`drag_${w.id}`}
            isDraggable={isDraggable}
            onSetDroppedWidgetType={onSetDroppedWidgetType}
            iconSrc={w.iconSrc}
            widgetCaption={w.caption}
            widgetDescription={w.description}
            widgetType={w.id}
            isSelected={w.id === selectedWidgetType}
            onSelected={onSelected}
          />
        ))}
      </div>
      <div className={classes.panelFooterMaximize} >
        <Button
          buttonType={ButtonType.Primary}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => {
            //alert('add widget');
            devconsole.log("adding widget.... " + selectedWidgetType);
            //onSetDroppedWidgetType(selectedWidgetType);
            if (selectedWidgetType !== "")
              onAddingNewWidget(selectedWidgetType);
            else
              toast.error("Please select a widget to be added.", { autoClose: false });
          }}
        >
          Add
        </Button>
      </div>
    </div>
  );
};