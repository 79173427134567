import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {Box, Card, CardContent, Tabs, Typography} from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { selectSettingsTab } from "../../../../_actions/venue.actions";
import PermissionsGate from "../../../_common/permissions/PermissionsGate";
import WrappedTab from '../../../_common/permissions/WrappedTab';
import { ROLES } from "../../../../_constants/user.permissions.constants";
import { getLoginUser } from "../../../../_services/userManagement.service";
import { PERMISSIONS } from "../../../../_constants/user.permissions.constants";

const useStyles = makeStyles((theme) => ({
    // KF: The label of the active tab should be highlighted with the green line under the label.
  // I cannot make it work with dynamic tabs. Below is my attempt to fix this, but 
  // result does not look as beautiful, e.g. there ae silly margins on the left and
  // right of the highlighting and there's no animation. That's all I coould do
  // for now. We should give it another go before too long.
  tabRoot: {
    borderBottom: '1px solid #BCCBCE',
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent", // theme.palette.color.primary.main,
    },
    width: "100%"
  },
  title: {

  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    //flexGrow: 1,
    minHeight: 0,
    marginBottom: theme.spacing(2),
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  tabLabel: {
    color:  theme.palette.color.secondary.main,
    fontWeight: "400",
    padding: "0px 20px 12px 20px",
    marginBottom: "-11px", 
    // KF - I don't really know what I'm doing with this border bottom, but it makes it look nice.
    borderBottomColor: "transparent",
    borderBottom: '2px solid',
  },
  activeTabLabel: {
    fontWeight: "500",
    color: theme.palette.color.primary.main,
    padding: "0px 20px 10px 20px",
    marginBottom: "-11px",
    borderColor: theme.palette.color.primary.main,
    borderBottom: '4px solid',
  },
  tabPanelRoot: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: 0
  }
}));

export const Settings = ({pageName, title, tabs}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const savedSelectedTabName = useSelector((state) => state.venueReducer.selectedSettingsTabs[pageName]);

  const [userRole, setUserRole] = useState(null);
  const [selectedTabName, setSelectedTabName] = useState(savedSelectedTabName);

  const selectedTab = tabs.find(t => t.name === selectedTabName);

  useEffect(() => {
    dispatch(getLoginUser).then((user) => {
      setUserRole(user.profile.role);
    }).catch(() => {
    
    });
  }, [dispatch]);

  useEffect(() => {
    if (userRole) {
      const firstAccessibleTab = tabs.find(tab => PERMISSIONS[userRole].some(it => tab.scopes.includes(it)));
      setSelectedTabName(prev => prev || firstAccessibleTab.name);
    }
  }, [userRole]);

  const handleTabSelected = (e, selected) => {
    setSelectedTabName(selected);
    dispatch(selectSettingsTab(pageName, selected));
  };

  return (
    <>
      {selectedVenue ? 
      (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0
          }}>
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          <br/> <br />

          {selectedTab ?
            <>
              <Card 
                className={classes.cardRoot}
                style={selectedTab.growVertically ? {
                  flexGrow: 1
                }: null}
                >
                <CardContent
                  className={classes.cardContent}>
                  <TabContext value={selectedTabName}>
                    <Box
                      sx={{
                        display: "flex",
                        height: "auto",
                        alignItems: "stretch"
                      }}
                    >
                      <Tabs 
                        value={selectedTabName} 
                        classes={{ root: classes.tabRoot }} 
                        onChange={handleTabSelected} 
                        scrollButtons="off" 
                        variant="scrollable" 
                        style={{
                          flexGrow: 1
                        }}
                      >
                        {
                          tabs.map(tab => {
                            return (
                              <WrappedTab
                                scopes={tab.scopes}
                                label={<div className={selectedTabName === tab.name ? classes.activeTabLabel : classes.tabLabel}>{tab.title}</div>}
                                value={tab.name}
                                key={tab.name}
                                />        
                            );
                          })
                        }
                      </Tabs>
                    </Box>
                    {
                      tabs.map(tab => {
                        const Component = tab.component;
                        return (
                          <TabPanel value={tab.name} classes={{ root: classes.tabPanelRoot }} key={tab.name}>
                            <PermissionsGate
                              scopes={tab.scopes}>
                                <Component venue={selectedVenue} />
                            </PermissionsGate>
                          </TabPanel>
                        );
                      })
                    }
                  </TabContext>
                </CardContent>
              </Card>
              {selectedTab.growVertically ?
                null
                :
                <Box
                  sx={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    minHeight: 0
                  }} />
              }
            </>
            :
            null
          }
        </Box>
      )
      :
      (
      <div>
        <Typography variant="h4">Please select venue first</Typography>
      </div>
      )
      }
    </>
  );
};

export default Settings;