import React from "react";
import {
  Box, 
  IconButton, 
  Modal, 
  Tooltip,
  Typography,
} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => {
  return {
    popup: {
      width: "650px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
    },
    title: {
      fontSize: "24px",
      fontWeight: "500",
      flexGrow: 1,
      marginBottom: theme.spacing(1)
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    },
  };
});

export const JsonViewer = ({title, data, onClose}) => {
  const classes = useStyles();

  return (
    <Modal open={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxHeight: "calc(100vh - 32px)",
          alignItems: "stretch"
        }}
        className={classes.popup}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Typography className={classes.title}>
            {title}
          </Typography>
          <Tooltip
            title="Close"
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexGrow: 1,
            minHeight: 0,
            overflowX: "auto",
            overflowY: "auto"
          }}
        >
          <pre style={{marginBottom: 0}}>
            <Typography>
                {JSON.stringify(JSON.parse(data), null, 4)}
            </Typography>
          </pre>
        </Box>
      </Box>
    </Modal>
  );
};

export default JsonViewer;

