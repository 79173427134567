import {Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import VenueEventForm from "./VenueEventForm";
import {NoVenueSelectedError} from "../../../_constants";
import {getVenueEventById, updateVenueEvent} from "../../../_services/venue.event.service";
import {PermissionDenied} from "../../PermissionDenied";
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import {toast} from "react-toastify";
import {useHistory, useParams} from "react-router-dom";
import devconsole from "../../_common/devconsole";

export const EditVenueEvent = () => {
  const history = useHistory();
  const { venueEventId } = useParams();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const [saving, setSaving] = useState(false);
  const [venueEvent, setVenueEvent] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueEvent({...venueEvent, [name]: value});
  };
  const handleSave = (e) => {
    setSaving(true);
    updateVenueEvent(venueEvent).then((d) => {
      toast.success("Event updated successfully");
      setSaving(false);
      history.push("/events");
    }).catch((e) => {
      toast.error("Updating event failed!");
      console.error("updateVenueEvent", e);
    })
  };
  useEffect(() =>{
    devconsole.log("venueEventId", venueEventId);
    if  (venueEventId) {
      getVenueEventById(venueEventId).then(d => {
        devconsole.log("retrieve data", d.data);
        setVenueEvent(d.data);
      }).catch((e) => {
        console.log("getVenueEventById", e);
        toast.error("Cannot load event venue");
        history.push("/events");
      })
    }
  }, [history, venueEventId]);
  return (selectedVenue === null) ?
    (
      <NoVenueSelectedError></NoVenueSelectedError>
    ) : (
      <PermissionsGate
        RenderError={() => <PermissionDenied />}
        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_EVENTS]}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} container justifyContent="space-between">
            <Typography variant="h4">Edit Event for {selectedVenue.name.toLowerCase()} venue</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" >
            <Grid item xs={12} >
              <Card style={{ overflow: 'auto' }}><CardContent>
                <VenueEventForm
                  venueEvent={venueEvent}
                  onChange={handleChange}
                  onSave={handleSave}
                  saving={saving}
                />
              </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    )
};

export default EditVenueEvent;