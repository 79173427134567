import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";

var Constants = {
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
};

const authService = new AuthService();

// Returns all maps for an venue. The map objects don't contain
// the layouts (underlying pictures). This method is used to show
// the list of map names quickly. The layouts will be loaded afterwards,
// one by one, with the spinner component shown on the UI.
export async function getMaps(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
      params: {
        venueId: venueId,
      },
    };

    return await axios
      .get(
        Constants.venueManagementApiRoot + "api/VenueMap/MapsByVenue/" + venueId,
        options
      )
      .catch(async (error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          } 
          throw new Error((await error.response.data.text()).replace(/^"(.+)"$/,'$1'));
        }

        throw error;
      });
  }
}

// Returns the map together with its layout (underlying picture).
export async function getMap(mapId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const getMapByIdOptions = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    
    const getLayoutOptions ={
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
      responseType: "blob",
      params: {
        mapId: mapId
      },
    };

    try {
      const [mapWithoutLayoutResponse, layoutResponse] = await Promise.all([
        axios.get(
          Constants.venueManagementApiRoot + "api/VenueMap/" + mapId,
          getMapByIdOptions
        ),
        axios.get(
          Constants.venueManagementApiRoot + "api/VenueMap/Layout/" + mapId,
          getLayoutOptions
        ) 
      ]);
      return {
        ...mapWithoutLayoutResponse.data,
        layout: layoutResponse.data,
        layoutBlobUrl: URL.createObjectURL(layoutResponse.data)
      };
    } catch (error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }
      }
      throw error;
    }
  }
}

export async function addMap(map) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data"
      },
    };

    const formData = new FormData();
    formData.append("VenueId", map.venueId);
    formData.append("Name", map.name);
    formData.append("File", map.layout);
    map.overlays.forEach((overlay, index) => {
      formData.append(`Overlays[${index}].Markup`, overlay.markup);
      formData.append(`Overlays[${index}].LocationId`, overlay.locationId);
    });

    return await axios
      .post(
        Constants.venueManagementApiRoot + "api/VenueMap",
        formData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
};

export async function getLayout(mapId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
      responseType: "blob",
      params: {
        mapId: mapId
      },
    };

    return await axios
      .get(
        Constants.venueManagementApiRoot + "api/VenueMap/Layout/" + mapId,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
};

export async function getMapById(mapId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(
        Constants.venueManagementApiRoot + "api/VenueMap/" + mapId,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }  
};

export async function updateMap(map) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data"
      },
    };

    const formData = new FormData();
    formData.append("VenueId", map.venueId);
    formData.append("Id", map.id)
    formData.append("Name", map.name);
    formData.append("File", map.layout);
    map.overlays.forEach((overlay, index) => {
      formData.append(`Overlays[${index}].Markup`, overlay.markup);
      if (overlay.id.indexOf('new-') < 0) {
        formData.append(`Overlays[${index}].Id`, overlay.id);
      }
      formData.append(`Overlays[${index}].VenueMapId`, map.id);
      formData.append(`Overlays[${index}].LocationId`, overlay.locationId);
    });

    return await axios
      .put(
        Constants.venueManagementApiRoot + "api/VenueMap",
        formData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function deleteMap(mapId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
      responseType: "blob",
      params: {
        mapId: mapId
      },
    };

    try {
      await axios
        .delete(
          Constants.venueManagementApiRoot + "api/VenueMap/" + mapId,
          options
        );
    } catch( error) {
      console.error(error);
      if (error.response) {
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error((await error.response.data.text()).replace(/^"(.+)"$/,'$1'));
        }
      }
      throw error;
    }
  }
};