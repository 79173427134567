import * as occupancyConstants from "../_constants/indicator.constants"
import * as occupancyApi from "../_services/indicators.service";

export const addOccupancyManualAdjustmentSuccess = (occupancyManualAdjustment) => {
  return {
    type: occupancyConstants.ADD_OCCUPANCY_MANUAL_ADJUSTMENT_SUCCESS,
    occupancyManualAdjustment: occupancyManualAdjustment,
  };
};

const loadLatestOccupanciesStarted = () => {
  return {
    type: occupancyConstants.LOAD_LATEST_OCCUPANCIES_STARTED,
  };
};
export const loadLatestOccupanciesSuccess = (latestOccupancies) => {
  return {
    type: occupancyConstants.LOAD_LATEST_OCCUPANCIES_SUCCESS,
    latestOccupancies: latestOccupancies,
  };
};

const loadTrafficInOutMetricsStarted = () => {
  return {
    type: occupancyConstants.LOAD_TRAFFIC_IN_OUT_METRICS_STARTED,
  };
};
export const loadTrafficInOutMetricsSuccess = (trafficInOutMetrics) => {
  return {
    type: occupancyConstants.LOAD_TRAFFIC_IN_OUT_METRICS_SUCCESS,
    trafficInOutMetrics: trafficInOutMetrics,
  };
};

const loadFootfallTrafficMetricsStarted = () => {
  return {
    type: occupancyConstants.LOAD_FOOTFALL_TRAFFIC_METRICS_STARTED,
  };
};
export const loadFootfallTrafficMetricsSuccess = (footfallTrafficMetrics) => {
  return {
    type: occupancyConstants.LOAD_FOOTFALL_TRAFFIC_METRICS_SUCCESS,
    footfallTrafficMetrics: footfallTrafficMetrics,
  };
};

export const addOccupancyManualAdjustment = (occupancyManualAdjustment) => {
  return async (dispatch) => {
    await occupancyApi
      .addOccupancyManualAdjustment(occupancyManualAdjustment)
      .then(() => {
        dispatch(addOccupancyManualAdjustmentSuccess(occupancyManualAdjustment));
      })
      .catch((error) => {
        throw error;
    });
  };
};

export const loadLatestOccupancies = (venueId, vendorId) => {
  return async (dispatch) => {
    dispatch(loadLatestOccupanciesStarted());
    await occupancyApi
      .loadLatestOccupancies(venueId, vendorId)
      .then((latestOccupancies) => {
        dispatch(loadLatestOccupanciesSuccess(latestOccupancies));
      })
      .catch((error) => {
        throw error;
    });
  };
};

export const loadTrafficInOutMetrics = () => {
  return async (dispatch) => {
    dispatch(loadTrafficInOutMetricsStarted());
    await occupancyApi
      .loadTrafficInOutMetrics()
      .then((trafficInOutMetrics) => {
        dispatch(loadTrafficInOutMetricsSuccess(trafficInOutMetrics));
      })
      .catch((error) => {
        throw error;
      })
  }
}

export const loadFootfallTrafficMetrics = () => {
  return async (dispatch) => {
    dispatch(loadFootfallTrafficMetricsStarted());
    await occupancyApi
      .loadFootfallTrafficMetrics()
      .then((footfallTrafficMetrics) => {
        dispatch(loadFootfallTrafficMetricsSuccess(footfallTrafficMetrics));
      })
      .catch((error) => {
        throw error;
      })
  }
}