import reportConstants from "../_constants/report.constants";
import initialState from "./initialState";

export function reportingReducer(state = initialState.reporting, action) {
    switch (action.type) {
        case reportConstants.GET_REPORT_PUBLISHING_CRON_MODE_STARTED:
            return {
                ...state,
                loading: { ...state.loading, reportPublishingCronModeLoading: true }
            };
        case reportConstants.GET_REPORT_PUBLISHING_CRON_MODE_SUCCESS:
            return {
                ...state,
                reportPublishingCronMode: action.reportPublishingCronMode,
                loading: { ...state.loading, reportPublishingCronModeLoading: false }
            };
        default:
            return state;
    }
}

export default reportingReducer;
