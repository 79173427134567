import React from "react";
import {useEffect, useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import StopIcon from '@material-ui/icons/Stop';
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      cursor: "pointer"
    },
    muted: {
      color: "#627E84",
      cursor: "pointer"
    },
    unmuted: {
      color: "#3FBE5D",
      cursor: "pointer"
    }
  };
});

const AudioPlayer = ({ id = "audio", showPlayIcon = true, showPauseIcon = true, showStopIcon = true, 
                       showMuteIcon = true, muted = false, autoPlay = false, style = null,
                       playList=["/audio.wav"]}) => {
  const classes = useStyles();
  const [audioMuted, setAudioMuted] = useState(muted);
  useEffect(() => {
    if (audioMuted !== muted) {
      setAudioMuted(muted);
    }
  }, [muted]);
  return (
    <div style={style}>
      <audio id={id} muted={audioMuted} autoPlay={autoPlay}>
        {playList.map((item, index) => <source key={index} src={item} /> )}
      </audio>
      {showPlayIcon &&
        <PlayCircleOutlineIcon className={classes.icon} fontSize={"small"} onClick={(e) => {
          let audio = document.getElementById("audio");
          audio.play();
        }} /> 
      }
      {showPauseIcon &&
        <PauseCircleOutlineIcon className={classes.icon} fontSize={"small"} onClick={(e) => {
          let audio = document.getElementById("audio");
          audio.pause();
        }} />
      }
      {showStopIcon &&
        <StopIcon className={classes.icon} fontSize={"small"} onClick={(e) => {
          let audio = document.getElementById("audio");
          audio.pause();
          audio.currentTime = 0;
        }}/>
      }
      {showMuteIcon &&
        (audioMuted ? (
          <Tooltip title="Muted" >
            <VolumeMuteIcon className={classes.muted} fontSize={"small"} onClick={(e) => {
              let audio = document.getElementById("audio");
              audio.muted = false;
              setAudioMuted(false);
            }} />
          </Tooltip>
          ) : (
          <Tooltip title="Not muted" aria-label="add">
            <VolumeUpIcon className={classes.unmuted} fontSize={"small"} onClick={(e) => {
              let audio = document.getElementById("audio");
              audio.muted = true;
              setAudioMuted(true);
            }} />
          </Tooltip>
        ))
      }
    </div>
  );
};

export default AudioPlayer;