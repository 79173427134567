export const metricConstants = {
    occupancy: {
        id: "1e78431e-3915-4d3a-8143-a388f06138a4",
        name: "Occupancy",
    },
    trafficIn: {
        id: "479929fc-df92-4155-b5ec-9ba488087ebd",
        name: "Traffic In",
    },
    trafficOut: {
        id: "e0782050-4c1f-4c4c-8384-d623788a20e1",
        name: "Traffic Out",
    },
};