import widgetDataConstants from "../_constants/widget.data.constants";

const widgetDataReducer = (widgetTypeConst, state = {}, action) => {
    switch (action.type) {
        // case `${widgetDataConstants.LOAD_DATA_STARTED}${widgetTypeConst.id}`:
        //     return [...state];
        case `${widgetDataConstants.LOAD_DATA_SUCCESS}${widgetTypeConst.id}`:
            const newState = { ...state };
            (newState[action.widgetId] = { ...(newState[action.widgetId] ?? {}) }).data = action.data;
            return newState;

        case `${widgetDataConstants.LOAD_WIDGET_SETTINGS_STARTED}${widgetTypeConst.id}`:
            return {
                ...state,
                loading: { ...state.loading, settingsLoading: true }
            };
        case `${widgetDataConstants.LOAD_WIDGET_SETTINGS_SUCCESS}${widgetTypeConst.id}`:
            return {
                ...state,
                settings: action.settings,
                loading: { ...state.loading, settingsLoading: false }
            };

        case `${widgetDataConstants.LOAD_WIDGET_SETTINGS_VALUES_STARTED}${widgetTypeConst.id}_${action.widgetId}`:
            let stateForSettingsValuesLoading = {
                ...state,
            };
            (stateForSettingsValuesLoading[action.widgetId] = {
                ...(stateForSettingsValuesLoading[action.widgetId] ?? {})
            }).loading = { ...stateForSettingsValuesLoading[action.widgetId].loading, settingsValuesLoading: true };
            return stateForSettingsValuesLoading;
        case `${widgetDataConstants.LOAD_WIDGET_SETTINGS_VALUES_SUCCESS}${widgetTypeConst.id}_${action.widgetId}`:
            let stateForSettingsValues = {
                ...state,
            };
            (stateForSettingsValues[action.widgetId] = {
                ...(stateForSettingsValues[action.widgetId] ?? {})
            }).settingsValues = action.settingsValues;
            (stateForSettingsValues[action.widgetId] = {
                ...(stateForSettingsValues[action.widgetId] ?? {})
            }).loading = { ...stateForSettingsValues[action.widgetId].loading, settingsValuesLoading: false };
            return stateForSettingsValues;

        case `${widgetDataConstants.UPDATE_WIDGET_SETTINGS_VALUES_SUCCESS}${widgetTypeConst.id}_${action.widgetId}`:
            let stateForUpdateSettingsValues = { ...state };
            (stateForUpdateSettingsValues[action.widgetId] = { ...(stateForUpdateSettingsValues[action.widgetId] ?? {}) }).settingsValues = action.settingsValues;
            return stateForUpdateSettingsValues;

        default:
            return state;
    }
}

export default widgetDataReducer;
