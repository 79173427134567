export const OCCUPANCY_INDICATOR = { indicatorId: "ee578f5a-3874-4446-bad9-5960911c1e67", indicatorName: "Occupancy", locationTypeGroups: ["Location"] }
export const TRAFFIC_INDICATOR =  { indicatorId: "8cf5aa8b-5a6e-460e-bdc0-7b537145bdc4", indicatorName: "Traffic In/Out", locationTypeGroups: ["Location", "Sensor"] }
export const FOOTFALLTRAFFIC_INDICATOR = { indicatorId: "9b0206b0-6fe5-4e47-bbd6-4b337f79e9ed", indicatorName: "Footfall Traffic", locationTypeGroups: ["Location"] }
export const INDICATOR_LOCATIONTYPE_MAPPING = [ OCCUPANCY_INDICATOR, TRAFFIC_INDICATOR, FOOTFALLTRAFFIC_INDICATOR ]

export const ADD_OCCUPANCY_MANUAL_ADJUSTMENT_SUCCESS = 'ADD_OCCUPANCY_MANUAL_ADJUSTMENT_SUCCESS'
export const LOAD_LATEST_OCCUPANCIES_SUCCESS = 'LOAD_LATEST_OCCUPANCIES_SUCCESS'
export const LOAD_LATEST_OCCUPANCIES_STARTED = 'LOAD_LATEST_OCCUPANCIES_STARTED'

export const LOAD_TRAFFIC_IN_OUT_METRICS_STARTED = 'LOAD_TRAFFIC_IN_OUT_METRICS_STARTED'
export const LOAD_TRAFFIC_IN_OUT_METRICS_SUCCESS = 'LOAD_TRAFFIC_IN_OUT_METRICS_SUCCESS'

export const LOAD_FOOTFALL_TRAFFIC_METRICS_STARTED = 'LOAD_FOOTFALL_TRAFFIC_METRICS_STARTED'
export const LOAD_FOOTFALL_TRAFFIC_METRICS_SUCCESS = 'LOAD_FOOTFALL_TRAFFIC_METRICS_SUCCESS'
