import { mapConstants } from "../_constants/map.constants";
import * as mapApi from "../_services/map.service";

// ------ Loading maps ---------

const loadMapsStarted = () => {
  return {
    type: mapConstants.LOAD_MAPS_STARTED,
  };
};

 const loadMapsSuccess = (maps) => {
  return {
    type: mapConstants.LOAD_MAPS_SUCCESS,
    maps: maps,
  };
};

export const loadMaps = (venueId) => {
  return async (dispatch) => {
    dispatch(loadMapsStarted());
    await mapApi
      .getMaps(venueId)
      .then((maps) => {
        dispatch(loadMapsSuccess(maps.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

// ----- Adding map -------

export const mapAdded = (map) => {
  return {
    type: mapConstants.ADD_MAP,
    map: map,
  };
};

export const addMap = (map) => {
  return async (dispatch) => {
    await mapApi
      .addMap(map)
      .then((response) => {
        dispatch(mapAdded(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

// ------ loading layout (image) -----

export const loadLayoutStarted = (mapId) => {
  return {
    type: mapConstants.LOAD_LAYOUT_STARTED,
    mapId: mapId
  };
};

export const loadLayoutSuccess = (map) => {
  return {
    type: mapConstants.LOAD_LAYOUT_SUCCESS,
    map: map
  };
};

export const loadLayout = (mapId) => {
  return async (dispatch) => {
    dispatch(loadLayoutStarted());

    let mapLoaded = false;
    let map = null;
    let layoutLoaded = false;
    let layout = null;

    await Promise.all([
      mapApi.getMapById(mapId)
        .then((response) => {
          mapLoaded = true;
          map = response.data;
          if ( mapLoaded && layoutLoaded ) {
            map.layout = layout;
            dispatch(loadLayoutSuccess(map)); 
          }
        })
        .catch((error) => {
          throw error;
        }),
      mapApi.getLayout(mapId)
        .then((response) => {
          layoutLoaded = true;
          layout = response.data;
          if ( mapLoaded && layoutLoaded ) {
            map.layout = layout;
            dispatch(loadLayoutSuccess(map)); 
          }
        })
        .catch((error) => {
          throw error;
        })
    ]);      
  };
};

// -------- loading an existing map ------
const getMapByIdStarted = () => {
  return {
    type: mapConstants.LOAD_MAP_STARTED,
  };
};

const getMapByIdSuccess = (map) => {
  return {
    map: map,
    type: mapConstants.LOAD_MAP_SUCCESS,
  };
};

export const getMapById = (mapId) => {
  return async (dispatch) => {
    dispatch(getMapByIdStarted());

    let mapLoaded = false;
    let map = null;
    let layoutLoaded = false;
    let layout = null;

    await Promise.all([
      mapApi.getMapById(mapId)
        .then((response) => {
          mapLoaded = true;
          map = response.data;
          if ( mapLoaded && layoutLoaded ) {
            map.layout = layout;
            dispatch(getMapByIdSuccess(map)); 
          }
        })
        .catch((error) => {
          throw error;
        }),
      mapApi.getLayout(mapId)
        .then((response) => {
          layoutLoaded = true;
          layout = response.data;
          if ( mapLoaded && layoutLoaded ) {
            map.layout = layout;
            dispatch(getMapByIdSuccess(map)); 
          }
        })
        .catch((error) => {
          throw error;
        })
    ]);      
  };
};

// --------------- Updating an existing map ------
const mapUpdated = (map) => {
  return {
    type: mapConstants.UPDATE_MAP,
    map: map
  };
};

export const updateMap = (map) => {
  return async (dispatch) => {
    await mapApi
      .updateMap(map)
      .then((response) => {
        dispatch(mapUpdated(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
// ------- deleting map ----------

export const deleteMap = (mapId) => {
  return async (dispatch) => {
    await mapApi
      .deleteMap(mapId)
      .then(() => {
        dispatch(mapDeleted(mapId));
      })
      .catch((error) => {
        throw error;
      });
  };
};

const mapDeleted = (mapId) => {
  return {
    type: mapConstants.DELETE_MAP,
    mapId: mapId
  }
};