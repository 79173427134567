import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import { publishReportData, setReportPublishingCronMode, getReportPublishingCronMode } from "../../../../../_actions/report.action";
import { setTableauPublishingCronMode, getTableauPublishingCronMode } from "../../../../../_actions/tableauReport.action";
import { Button, ButtonType } from "../../../../_common/htmlTags/Button";
import { DataGrid } from "@material-ui/data-grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { getPublishJournal, getPublishingCronModeList } from "../../../../../_services/report.service";
import ErrorsListModalWindow from './ErrorsListModalWindow';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import DoneIcon from '@material-ui/icons/Done';
import CrossIcon from '@material-ui/icons/Close';
import NativeSelect from "@material-ui/core/NativeSelect";
import MenuItem from "@material-ui/core/MenuItem";
import { Height } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  description: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  textDescription: {
    display: 'flex',
    alignItems:'center',
  },
  everyHour: {
    color: theme.palette.color.secondary.main,
  },
  publishButton: {
    width: "110px",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(4),
  },
  publishingCronModeControl: {
    width: "150px",
  },
}));

const journalColumns = [
  {
    field: "id",
    hide: true,
  },
  {
    field: "publishDate",
    headerName: "DATE",
    flex: 1.5,
    valueFormatter: (params) => {
      var publishDate = new Date(params.row?.publishDate);
      return new Date(publishDate.getTime() - publishDate.getTimezoneOffset() * 60000).toLocaleString()
    }
  },
  {
    field: "publishTrigger",
    headerName: "TRIGGER",
    flex: 1.5,
  },
  {
    field: "userName",
    headerName: "USER",
    flex: 1,
  },
];

export const DataWarehouse = ({ venue }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const reportPublishingCronMode = useSelector(
    (state) => state.reporting.reportPublishingCronMode
  );
  const tableauPublishingCronMode = useSelector(
    (state) => state.tableauReporting.tableauPublishingCronMode
  );
  const [cronModeList, setCronModeList] = useState([]);
  const notifications = useSelector((state) => state.notifications);
  const error = useSelector((state) => state.scheduleTasks.error);

  const [publishing, setPublishing] = useState(false);
  const [reportPublishId, setReportPublishId] = useState(null);

  useEffect(() => {
    dispatch(getReportPublishingCronMode(venue.id));
    dispatch(getTableauPublishingCronMode(venue.id));
  }, [dispatch, venue.id]);

  useEffect(() => {
    if (
      notifications &&
      reportPublishId &&
      notifications[reportPublishId]?.type !== "processing"
    ) {
      setPublishing(false);
    }
  }, [notifications, reportPublishId]);

  const handleAutoPublishChange = (e) => {
    let publishCronMode = e.target.value;

    Promise.all([
      dispatch(setReportPublishingCronMode(venue.id, publishCronMode)),
      dispatch(setTableauPublishingCronMode(venue.id, publishCronMode)),
    ])
      .then(() => {
        dispatch(getReportPublishingCronMode(venue.id));
        dispatch(getTableauPublishingCronMode(venue.id));
      })
      .catch((err) => {
        toast.error("Saving schedule has failed. " + err, {
          autoClose: false,
        });
      });
  };

  const handleSyncNowClick = (e) => {
    e.preventDefault();
    setPublishing(true);
    setReportPublishId(null);

    publishReportData(venue.id)
      .then((publishResult) => {
        if (error) {
          toast.error(`Publish data for reports failed. ${error}`, {
            autoClose: false,
          });
          setPublishing(false);
        }
        setReportPublishId(publishResult);
      })
      .catch((err) => {
        toast.error(`Publish data for reports failed. ${err}`, {
          autoClose: false,
        });
        setPublishing(false);
      });
  };

  const [journal, setJournal] = useState([]);
  const [journalLoading, setJournalLoading] = useState(false);

  const [currentPublishingLog, setCurrentPublishingLog] = useState({});
  const [journalErrorOpen, setJournalErrorOpen] = useState(false);
  const handleJournalErrorClose = () => setJournalErrorOpen(false);

  useEffect(() => {
    async function fetchData(venueId) {
      await getPublishJournal(venueId)
        .then((response) => {
          setJournal(response.data);
          setJournalLoading(false);
        })
        .catch((err) => {
          toast.error("Loading publishing journal failed: " + err, {
            autoClose: false,
          });
          setJournalLoading(false);
        });
      await getPublishingCronModeList()
        .then((response) => {
          setCronModeList(response.data);
        })
        .catch((err) => {
          toast.error("Loading publishing journal failed: " + err, {
            autoClose: false,
          });
        });
    }

    if (venue !== null) {
      setJournalLoading(true);
      fetchData(venue.id);
    }
  }, [venue]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
      }}
    >
      <Typography variant="h5">Synchronize data</Typography>
      <Typography className={classes.description}>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box className={classes.textDescription}>
            Load data into the reporting data warehouse &nbsp;
          </Box>
          <FormControl
            variant="outlined"
            className={classes.publishingCronModeControl}
          >
            <Select
              value={reportPublishingCronMode?.toString() ?? "0"}
              onChange={handleAutoPublishChange}
              size="small"
            >
              {cronModeList.map((d) => (
                <MenuItem value={d.id.toString()}>{d.description}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Typography>
      <Button
        variant="contained"
        className={classes.publishButton}
        disabled={publishing}
        onClick={handleSyncNowClick}
        size="small"
      >
        SYNC NOW
      </Button>
      <LoadPleaseWait show={journalLoading}>
        <DataGrid
          autoHeight
          pageSize={12}
          columns={[
            ...journalColumns,
            {
              field: "status",
              headerName: "STATUS",
              flex: 1,
              renderCell: (params) => {
                return (
                  <>
                    {params.value === "Failed" ? (
                      <CrossIcon style={{ fill: "red", marginBottom: "3px" }} />
                    ) : (
                      <DoneIcon
                        style={{ fill: "green", marginBottom: "4px" }}
                      />
                    )}
                    {params.value}
                    {params.value === "Failed" ? (
                      <>
                        <>&#160;</>
                        <Link
                          href=""
                          underline="hover"
                          onClick={(e) => {
                            e.preventDefault();
                            setCurrentPublishingLog(params.row);
                            setJournalErrorOpen(true);
                          }}
                        >
                          {"View errors"}
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </>
                );
              },
            },
          ]}
          rows={journal === undefined ? [] : journal}
        />
      </LoadPleaseWait>
      <ErrorsListModalWindow
        currentPublishingLog={currentPublishingLog}
        open={journalErrorOpen}
        handleClose={handleJournalErrorClose}
      />
    </Box>
  );
};

export default DataWarehouse;