import React from "react";
import { Fragment } from "react";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%"
    // width: "calc(100% + 40px)",
    // height: "calc(100% + 40px)",
    // margin: "-20px"
  },
  loader: {
    position: "absolute",
    top: "50%",
    marginTop: "-70px",
    left: "50%",
    marginLeft: "-38px",
  },
  /* animated loading spinner from https://loading.io/css/ */
  ldsRoller: {
    display: "inline-block",
    position: "relative",
    width: "80px",
    height: "80px",
    "& div": {
      animation: "$ldsRoller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite",
      transformOrigin: "40px 40px",
      "&:after": {
        content: " ",
        display: "block",
        position: "absolute",
        width: "7px",
        height: "7px",
        borderRadius: "50%",
        background: "#fff",
        margin: "-4px 0 0 -4px",
      }
    },
    "& div:nth-child(1)": {
      animationDelay: "-0.036s",
      "&:after": {
        top: "63px",
        left: "63px",
      }
    },
    "& div:nth-child(2)": {
      animationDelay: "-0.072s",
      "&:after": {
        top: "68px",
        left: "56px",
      }
    },
    "& div:nth-child(3)": {
      animationDelay: "-0.108s",
      "&:after": {
        top: "71px",
        left: "48px",
      }
    },
    "& div:nth-child(4)": {
      animationDelay: "-0.144s",
      "&:after": {
        top: "72px",
        left: "40px",
      }
    },
    "& div:nth-child(5)": {
      animationDelay: "-0.18s",
      "&:after": {
        top: "71px",
        left: "32px",
      }
    },
    "& div:nth-child(6)": {
      animationDelay: "-0.216s",
      "&:after": {
        top: "68px",
        left: "24px",
      }
    },
    "& div:nth-child(7)": {
      animationDelay: "-0.252s",
      "&:after": {
        top: "63px",
        left: "17px",
      }
    },
    "& div:nth-child(8)": {
      animationDelay: "-0.288s",
      "&:after": {
        top: "56px",
        left: "12px",
      }
    },
  },
  "@keyframes ldsRoller": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    }
  }  
}));


export const Loader = () => {
  const classes = useStyles();
  return (
    <Fragment>
      <div className={classes.overlay} />
      <div className={classes.loader}>
        <div className={classes.ldsRoller}>
          <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div>
      </div>
    </Fragment>
  )
};

export default Loader;
