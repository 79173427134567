import React from "react";
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";

const ErrorsListModalWindow = ({ currentPublishingLog, open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={"paper"}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      maxWidth="md"
    >
      <Box
        display="flex"
        flexDirection="row"
        alignItems="center"
      >
        <Box
          display="flex"
          flex="1"
          justifyContent="center"
          height="50px"
        >
          <DialogTitle
            id="scroll-dialog-title"
            style={{ display: "flex", padding: "10px" }}
          >
            <Typography
              variant="h5"
              style={{ paddingLeft: "48px" }}
            >
              Errors list
            </Typography>
          </DialogTitle>
        </Box>

        <Box
          marginLeft="auto"
          display="flex"
        >
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>

      <DialogContent
        style={{ padding: "0px 0px 0px 0px" }}
        dividers={true}
      >
        <DialogContentText
          id="scroll-dialog-description"
          tabIndex={-1}
        >
          <List>
            {
              Object.keys(currentPublishingLog).length > 0 && currentPublishingLog.errors.length !== 0 ?
                (
                  currentPublishingLog.errors.map(error => {
                    return <>
                      {error !== currentPublishingLog.errors[0] ? <Divider style={{ marginTop: "15px", marginBottom: "5px" }} /> : <></>}
                      <ListItem style={{ padding: "10px 10px 10px 30px", marginBottom: "-10px", color: "#616161", fontSize: "17px" }}> {error.message} </ListItem>
                    </>
                  })

                ) : (
                  <ListItem style={{ padding: "30px 100px 0px 100px " }}>
                    <Typography variant="h6"
                      style={{ marginBottom: "20px", color: "#616161", textAlign: "center" }}>
                      There are no errors
                    </Typography>
                  </ListItem>
                )
            }
          </List>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ErrorsListModalWindow;