import React from 'react';
import { Settings } from '../Settings';
import BrandingSettings from '../BrandingSettings';
import VenueGeneralSetting from '../VenueGeneralSetting';
import DuplicateVenue from '../DuplicateVenue';
import ModeList from '../../../venueModes/ModeList';
import VenueUserSetting from "../VenueUserSetting";
import { SCOPES } from "../../../../../_constants/user.permissions.constants";

export const Venue = () => {

  return (
    <Settings
      pageName="venue"
      title="Venue"
      tabs={[{
        name: "overview",
        title: "OVERVIEW",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_VENUE],
        component: VenueGeneralSetting
      }, {
        name: "modes",
        title: "MODES",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_MODES],
        component: ModeList
      }, {
        name: "duplicate",
        title: "DUPLICATE",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_VENUE],
        component: DuplicateVenue
      }, {
        name: "branding",
        title: "BRANDING",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_BRANDING],
        component: BrandingSettings
      }, {
        name: "userPermissions",
        title: "USER PERMISSIONS",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_USER_VENUE_LIST],
        component: VenueUserSetting
      }]}
    />
  );
};

export default Venue;