import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";

const authService = new AuthService();

export async function getVendorIndicatorsMappings(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = null; 

    return await axios
      .get(ApiRootConstants.dataCollectorApiRoot + "api/Mappings/VendorIndicatorsMappings", {
        headers: headers,
        params: params
      })
      
  }
}

export async function getOperationIndicatorWidgetTypeMappings(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = null; 

    return await axios
      .get(ApiRootConstants.dataCollectorApiRoot + "api/Mappings/OperationIndicatorWidgetTypeMappings", {
        headers: headers,
        params: params
      })
     
  }
}
