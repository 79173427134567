import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import { toast } from "react-toastify";

var Constants = {
  reportApiRoot: ApiRootConstants.reportApiRoot,
  dataCollectorApiRoot: ApiRootConstants.dataCollectorApiRoot,
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
  armVendorApiRoot: ApiRootConstants.ARMVendorApiRoot
};

const authService = new AuthService();

async function axiosGet(uri) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(uri, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function getTableauToken(venueId) {
  return axiosGet(`${Constants.venueManagementApiRoot}api/ReportView/GetTableauToken/${venueId}`);
}

export async function publishReportData(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var data = {
      "VenueId": venueId,
      "PublishTrigger": "Triggered from PMY Platform Venue Settings page",
      "UserId": user.profile.sub,
      "UserName": user.profile.name,
      "Comments": ""
    };

    return await axios
      .post(`${Constants.reportApiRoot}api/ReportPublishing/PublishAsync`, data, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function getPublishReportDataStatus(publishId) {
  return axiosGet(`${Constants.reportApiRoot}api/ReportPublishing/${publishId}`);
}

export async function setReportPublishingCronMode(venueId, publishingCronMode) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var data = {
      "VenueId": venueId,
      "PublishTrigger": "Triggered from PMY Platform Venue Settings page for auto publishing",
      "UserId": user.profile.sub,
      "UserName": user.profile.name,
      "Comments": "Auto publish for venue " + venueId
    };

    return await axios
      .post(`${Constants.dataCollectorApiRoot}api/ReportPublishing/SetReportPublishingCron?publishingCronMode=${publishingCronMode}`, data, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function setTableauPublishingCronMode(venueId, publishingCronMode) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var data = {
      "VenueId": venueId,
      "PublishTrigger": "Triggered from PMY Platform Venue Settings page for auto tableau publishing",
      "UserId": user.profile.sub,
      "UserName": user.profile.name,
      "Comments": "Auto tableau publish for venue " + venueId
    };

    return await axios
      .post(`${Constants.dataCollectorApiRoot}api/ReportPublishing/SetTableauPublishingCron?publishingCronMode=${publishingCronMode}`, data, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function getReportPublishingCronMode(venueId) {
  return axiosGet(Constants.dataCollectorApiRoot + "api/ReportPublishing/GetReportPublishingCronMode/" + venueId);
}

export function getTableauPublishingCronMode(venueId) {
  return axiosGet(Constants.dataCollectorApiRoot + "api/ReportPublishing/GetTableauPublishingCronMode/" + venueId);
}
export function getPublishingCronModeList() {
  return axiosGet(Constants.dataCollectorApiRoot + "api/ReportPublishing/GetPublishingCronList");
}

export async function getPublishJournal(venueId) {
  return axiosGet(Constants.reportApiRoot + "api/ReportPublishing/Journal/" + venueId);
}

export function getARMReportToken(venueId) {
  return axiosGet(`${Constants.armVendorApiRoot}api/ARM/GetReportToken/${venueId}`);
}

export function getARMEvents(venueId) {
  return axiosGet(`${Constants.armVendorApiRoot}api/ARM/GetARMEvents/${venueId}`);
}

export function getLatestARMEvent(venueId) {
  return axiosGet(`${Constants.armVendorApiRoot}api/ARM/GetLatestARMEvent/${venueId}`);
}