import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";
import { WidgetCapacityMapContent } from "./WidgetCapacityMapContent";

export const WidgetMap = (props) => (
    <WidgetWrapper
        {...{...props, widgetType: widgetTypeConstants.widgetMap.id}}
    >
      <WidgetCapacityMapContent />
    </WidgetWrapper>
  );

export default WidgetMap;
