import React, {useState, useEffect} from "react";
import {Box, Checkbox, FormControlLabel, Radio, RadioGroup, TextField} from "@material-ui/core";
import {toast} from "react-toastify";
import {
  Typography
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from "@material-ui/core/styles";
import {Button} from "../../../_common/htmlTags";
import { updateARMApiSettings } from "../../../../_services/venue.service";
import {ARMCustomDataSourcePopup} from "./ARMCustomDataSourcePopup";
import * as armCustomDataSourceApi from "../../../../_services/vendor-arm-api.armcustomdatasource.service";
import * as venueApi from "../../../../_services/venue.service";

const useStyles = makeStyles((theme) => {
  return {
    infoIcon: {
      color: theme.palette.color.primary.main,
      height: "22px",
      width: "22px",
      marginRight: theme.spacing(2)
    },
  };
});

export const ARMApiSettings = ({venue}) => {
  const classes = useStyles();
  
  const[armResourceLineageId, setArmResourceId] = useState(null);
  const[armUseTestingData, setArmUseTestingData] = useState(false);
  const[isSaving, setIsSaving] = useState(false);
  const[useARMApi, setUseARMApi] = useState("true");
  const[isEnableARMResourceApi, setIsEnableARMResourceApi] = useState(true);
  const[showImportModal, setShowImportModal] = useState(false);
  
  const handleSelectMode = (e) => {
    setUseARMApi(e.target.value)
    setIsEnableARMResourceApi(e.target.value === "true");
  }

  useEffect(() => {
    // reload the venue data from database to make sure it has latest information
    venueApi.getVenueById(venue.id).then ((response) => {
      // set the setting values
      setArmResourceId(response.data.armResourceLineageId);
      setArmUseTestingData(response.data.useARMTestingData);
      setUseARMApi(response.data.useArmApi.toString());
    })
      .catch(
        (error) => {
          toast.error("Loading venue failed. " + error, { autoClose: false });
        }
      );
  },[venue]);
  
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start"
      }}>
      <Typography variant="h5">
        ARM API settings 
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          marginTop: "40px",
          marginBottom: "32px"
        }}>
        <InfoOutlinedIcon
          className={classes.infoIcon}
        />
        <Typography display="block" style={{lineHeight: 1.5}}>
          If <b>Use testing data</b> is selected, PMY Platform will use the default testing event specified in configuration.<br/>
          The <b>ARM Resource Lineage Id</b> is accepting GUID matching the Resource Lineage Id in ARM.<br />
        </Typography>
      </Box>
      <RadioGroup color="primary" aria-label="publishingMode" name="publishingMode" value={useARMApi} onChange={handleSelectMode}>
        <FormControlLabel color="primary" value={"true"} control={<Radio color="primary" />} label="Use data directly from ARM Api"/>
        <div style={{paddingLeft:"30px"}}>
          <TextField
            id="armResourceId"
            label="ARM resource lineage guid"
            type="text"
            name="armResourceId"
            value={armResourceLineageId ?? ""}
            InputLabelProps={{ shrink: true }}
            style={{width: "400px"}}
            disabled={!isEnableARMResourceApi}
            onChange={(e) => {
              setArmResourceId(e.target.value);
            }}
          />
          <FormControlLabel
            control={<Checkbox
              id=""
              checked={armUseTestingData}
              disabled={!isEnableARMResourceApi}
              onChange={(e)=> {
                setArmUseTestingData(e.target.checked);
              }}
            />}
            label="Use testing data"
          />
        </div>
        <FormControlLabel color="primary" value={"false"} control={<Radio color="primary" />} label="Use custom imported ARM Data source" />
        <div style={{paddingLeft:"30px"}}>
          <Button className={"button"} onClick={(e) => {
            setShowImportModal(true);
          }}>Import custom ARM data source</Button>&nbsp;
          <Button className={"button"} onClick={(e) => {
            armCustomDataSourceApi.armCustomDataSourceExport(venue.id);
          }}>Export custom ARM data source</Button>
        </div>
      </RadioGroup>
      <br /><br />
      <Button disabled={isSaving} onClick={(e) => {
        setIsSaving(true);
        let trimmedArmResourceLineageId = armResourceLineageId ? armResourceLineageId.trim() : armResourceLineageId;
        setArmResourceId(trimmedArmResourceLineageId);
        updateARMApiSettings(venue.id, trimmedArmResourceLineageId, armUseTestingData, useARMApi).then(() => {
          toast.success("ARM API settings updated!");
          setIsSaving(false);
        }).catch((error) => {
          toast.error("Invalid ARM resource lineage guid.", {autoClose: false});
          console.error("Updating ARM API settings failed. Invalid ARM resource lineage guid.", error);
          setIsSaving(false);
        })
      }}>
        Save
      </Button>
      <ARMCustomDataSourcePopup
        showModal={showImportModal}
        setShowModal={setShowImportModal}
        refreshParent={() => { }}/>
    </Box>
    
  )
}