import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  SvgIcon
} from "@material-ui/core";
//import { Button, ButtonType } from "../../../../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: "none",
    fontSize: 15,
    fontWeight: 400,
    borderRadius: "3px",
    borderWidth: 1,
    height: "30px",
    width: "100px"
  },
  checked: {
    color: theme.palette.color.primary.main,
    borderColor: theme.palette.color.primary.main,
  },
  unchecked: {
    color: theme.palette.color.secondary.main,
    borderColor: theme.palette.color.secondary.main,
  }
}));

export const CalculatedOccupancyButton = ({checked, onClick}) => {
  const classes = useStyles();

  return (
    <Button
      variant="outlined"
      className={`${classes.button} ${checked ? classes.checked : classes.unchecked}`}
      onClick={onClick}
      startIcon={
        <SvgIcon style={{marginLeft: 0, marginBottom: 1, transform: "scale(0.9)"}}>
          {checked ?
            <path d="M12.5439 20C10.4222 20 8.38738 19.1571 6.88709 17.6569C5.3868 16.1566 4.54395 14.1217 4.54395 12C4.54395 9.87827 5.3868 7.84344 6.88709 6.34315C8.38738 4.84285 10.4222 4 12.5439 4C14.6657 4 16.7005 4.84285 18.2008 6.34315C19.7011 7.84344 20.5439 9.87827 20.5439 12C20.5439 14.1217 19.7011 16.1566 18.2008 17.6569C16.7005 19.1571 14.6657 20 12.5439 20ZM12.5439 2C11.2307 2 9.93037 2.25866 8.71711 2.7612C7.50385 3.26375 6.40146 4.00035 5.47288 4.92893C3.59751 6.8043 2.54395 9.34784 2.54395 12C2.54395 14.6522 3.59751 17.1957 5.47288 19.0711C6.40146 19.9997 7.50385 20.7362 8.71711 21.2388C9.93037 21.7413 11.2307 22 12.5439 22C15.1961 22 17.7396 20.9464 19.615 19.0711C21.4904 17.1957 22.5439 14.6522 22.5439 12C22.5439 10.6868 22.2853 9.38642 21.7827 8.17317C21.2802 6.95991 20.5436 5.85752 19.615 4.92893C18.6864 4.00035 17.584 3.26375 16.3708 2.7612C15.1575 2.25866 13.8572 2 12.5439 2ZM12.5439 7C11.2179 7 9.94609 7.52678 9.00841 8.46447C8.07073 9.40215 7.54395 10.6739 7.54395 12C7.54395 13.3261 8.07073 14.5979 9.00841 15.5355C9.94609 16.4732 11.2179 17 12.5439 17C13.87 17 15.1418 16.4732 16.0795 15.5355C17.0172 14.5979 17.5439 13.3261 17.5439 12C17.5439 10.6739 17.0172 9.40215 16.0795 8.46447C15.1418 7.52678 13.87 7 12.5439 7Z" />
            :
            <path d="M12.5439 20C10.4222 20 8.38738 19.1571 6.88709 17.6569C5.3868 16.1566 4.54395 14.1217 4.54395 12C4.54395 9.87827 5.3868 7.84344 6.88709 6.34315C8.38738 4.84285 10.4222 4 12.5439 4C14.6657 4 16.7005 4.84285 18.2008 6.34315C19.7011 7.84344 20.5439 9.87827 20.5439 12C20.5439 14.1217 19.7011 16.1566 18.2008 17.6569C16.7005 19.1571 14.6657 20 12.5439 20ZM12.5439 2C11.2307 2 9.93037 2.25866 8.71711 2.7612C7.50385 3.26375 6.40146 4.00035 5.47288 4.92893C3.59751 6.8043 2.54395 9.34784 2.54395 12C2.54395 14.6522 3.59751 17.1957 5.47288 19.0711C6.40146 19.9997 7.50385 20.7362 8.71711 21.2388C9.93037 21.7413 11.2307 22 12.5439 22C15.1961 22 17.7396 20.9464 19.615 19.0711C21.4904 17.1957 22.5439 14.6522 22.5439 12C22.5439 10.6868 22.2853 9.38642 21.7827 8.17317C21.2802 6.95991 20.5436 5.85752 19.615 4.92893C18.6864 4.00035 17.584 3.26375 16.3708 2.7612C15.1575 2.25866 13.8572 2 12.5439 2Z" />
          }
        </SvgIcon>
      }
    >
      Settings
    </Button>
  )
};

export default CalculatedOccupancyButton;
