import { AuthService } from ".";

const authService = new AuthService();

export async function downloadFile(apiUrl, fileName) {
    const user = await authService.getUser();
    if (user?.access_token) {
        return await fetch(apiUrl,
            {
                headers: {
                    Authorization: "Bearer " + user.access_token,
                }
            })
            .then(response => response.ok ? response.blob() : null)
            .then(blob => {
              if (!blob || blob.type === "text/html") {
                throw({ message: "There are no data available" });
              }

              const url = window.URL.createObjectURL(new Blob([blob]),);
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', fileName);
      
              // Append to html link element page
              document.body.appendChild(link);
      
              // Start download
              link.click();
      
              // Clean up and remove the link
              link.parentNode.removeChild(link);
            });
    }
}
