import React from "react";
import { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => theme.map.polygonCreator);

const buildPointsAttr = (points) => {
  return points.map((p) => p.x + "," + p.y).join(" ");
};

const buildPolygonSvg = (points) => {
  return '<polygon points="' + buildPointsAttr(points) + '" />';
};

const checkFirstVertexProximity = (vertices, x, y, scaleFactor) => {
  // Check if we have 3 or more vertices defined
  // and we are close to the first vertex.
  const proximity = 15 * scaleFactor;
  if ( vertices.length >= 3) {
    const firstVertex = vertices[0];
    if (Math.abs(x - firstVertex.x ) < proximity && Math.abs(y - firstVertex.y ) < proximity) {
      return true;
    }
  }
  return false;
};

export const PolygonCreator = ({layoutSize, scaleFactor, onPolygonCreated, onPolygonCreationCancelled}) => {
  const classes = useStyles({scaleFactor: scaleFactor});

  const [vertices, setVertices] = useState([]);
  const [provisionalVertex, setProvisionalVertex] = useState(null);
  const [currentPhase, setCurrentPhase] = useState("blank");

  const layoutRef = useRef();

  // Listen for the Esc key. If pressed, cancel creating the 
  // polygon and return null in the onPolygonCreated handler.
  useEffect(() => {
    const onEscDown = (e) => {
      if (e.key === "Escape" ) {
        setVertices([]);
        setProvisionalVertex(null);
        setCurrentPhase("blank");
        onPolygonCreationCancelled();
      }
    };
    document.addEventListener("keydown", onEscDown);
    return () => {
      document.removeEventListener("keydown", onEscDown);
    }
  }, [onPolygonCreationCancelled]);

  const getPoint = ({clientX, clientY}) => {
    const {top, left, width, height} = layoutRef.current.getBoundingClientRect();
    return {
      x: Math.round((clientX - left) * layoutSize.width / width), 
      y: Math.round((clientY - top) * layoutSize.height / height)
    };
  };

  const onMouseDown = (e) => {
    if (e.button === 0) {
      const {x, y} = getPoint(e);
      switch (currentPhase) {
        case "blank":
          setVertices((arr) => [...arr, {x: x, y: y}]);
          setProvisionalVertex({x,y});
          setCurrentPhase("progress");
          break;
        case "progress":
          setVertices((arr) => [...arr, {x: x, y: y}]);
          setProvisionalVertex({x,y});          
          break;
        case "overFirstVertex":
          onPolygonCreated({markup: buildPolygonSvg(vertices)});
          setCurrentPhase("finished");
          break;
        default: 
      }
    }
  };

  const onMouseMove = (e) => {
    if (e.button === 0) {
      const {x, y} = getPoint(e);
      switch (currentPhase) {
        case "progress":
          if (checkFirstVertexProximity(vertices, x, y, scaleFactor)) {
            const firstVertex = vertices[0];
            setProvisionalVertex({x: firstVertex.x, y: firstVertex.y});
            setCurrentPhase("overFirstVertex");
          } else {
            setProvisionalVertex({x,y});
          }
          break;
        case "overFirstVertex":
          if (!checkFirstVertexProximity(vertices, x, y, scaleFactor)) {
            setProvisionalVertex({x,y});
            setCurrentPhase("progress");
          }
          break;
        default:
      }
    }
  };

  const onMouseUp = (e) => {
  };

  let points = [];
  if ( vertices ) {
    points =  provisionalVertex ? [...vertices, provisionalVertex] : vertices;
  }

  const renderedMouseOverFirstVertexHighlight = currentPhase === "overFirstVertex" ?
    <ellipse
      cx={vertices[0].x}
      cy={vertices[0].y}
      className={classes.firstVertexHighlight}
    /> : null;

  const renderedFirstVertex = vertices.length > 0 ?
    <ellipse
      cx={vertices[0].x}
      cy={vertices[0].y}
      className={classes.firstVertex}
      />
  : null;

  const renderedVertices = vertices.map((v,i) => {
    return (
      i === 0 ? null :
      <ellipse 
        key={i}
        cx={v.x}
        cy={v.y}
        className={classes.vertex} />
    );
  });

  return (
    <g>
      <polyline 
        points={buildPointsAttr(points)} 
        className={classes.polygon}
      />
      <g id="vertices">
        {renderedMouseOverFirstVertexHighlight}
        {renderedFirstVertex}
        {renderedVertices}
      </g>
      <rect 
        ref={layoutRef}
        x="0" y="0" 
        width={layoutSize.width} 
        height={layoutSize.height} 
        fill="transparent"
        opacity="0.1"
        onMouseDown={onMouseDown}
        onMouseUp={onMouseUp}
        onMouseMove={onMouseMove} 
      />
    </g>
  );
};

export default PolygonCreator;