import React from "react";
import { useEffect, useState } from "react";
import { Box, CircularProgress, Tooltip} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import {useApiGet} from "../../_helpers/useApiGet";
import {useResizeObservedRef} from "../../_helpers/useResizeObservedRef";
import {getMap} from "../../_services/map.service";
import { parsePolygonSvg } from "./PolygonEditor";

const useStyles = makeStyles( theme => { 
  return {
    hiddenLayout : {
      visibility: "hidden",
      maxWidth: 0,
      maxHeight: 0
    },
    ...theme.map.preview,
  };
});

const getMapCall = (mapId) => {
  return getMap(mapId);
};

export const MapPreview = ({mapId, meetOrSlice = "meet", showOverlays = true, showOverlayStroke = true}) => {
  // To convert from pixels in the viewport to the pixels in <svg> viewBox.
  // Example: if we want to see a circle of radius 5px on the screen,
  // then we need to draw a circle with radius 5*scaleFactor in the <svg>.
  const [scaleFactor, setScaleFactor] = useState(0);

  const classes = useStyles({scaleFactor});

  const emptyMap = {
    venueId: "",
    name: "",
    layoutBlobUrl: null,
    overlays: []
  };

  const [{data: map, isLoading: isMapLoading, isError: isMapLoadingError, errorMessage: mapLoadingErrorMessage}, setGetMapParams] = useApiGet( getMapCall, mapId, emptyMap);

  useEffect(() => {
    setGetMapParams(mapId);
  }, [mapId, setGetMapParams]);

  useEffect(() => {
    if (isMapLoadingError) {
      toast.error("Loading map failed. " + mapLoadingErrorMessage, { autoClose: true });
    }
  }, [isMapLoadingError, mapLoadingErrorMessage]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(map?.layoutBlobUrl);
    }
  }, [map]);

    // This is the natural size of the map image.
  const [layoutSize, setLayoutSize] = useState({
    width: 0,
    height: 0,
  });
  
  const onHiddenLayoutLoad = (e) => {
    const {naturalHeight, naturalWidth} = e.target;
    setLayoutSize({
      height: naturalHeight,
      width: naturalWidth
    });
  };

  const [viewPortSize, setViewPortSize] = useState({
    width: 0,
    height: 0,
  });

  // This viewPortRef is a function that is used as a ref for the div that contains the "map preview".
  // We use the width and the height of this div to calculate scaleFactor.
  const viewPortRef = useResizeObservedRef(({width, height}) => {
    setViewPortSize({width, height});
  });

  // Re-calculate scaleFactor when the viewport or the viewBox of the <svg> element changes.
  useEffect(() => {
    if (layoutSize.width > 0 && layoutSize.height > 0 
      && viewPortSize.width > 0 && viewPortSize.height > 0 ) {
      //const {width: viewPortWidth, height: viewPortHeight} = viewPort.getBoundingClientRect();
      const viewPortAspectRatio = viewPortSize.width / viewPortSize.height;
      const viewBoxAspectRatio = layoutSize.width / layoutSize.height;
      let scaleFactor = 0;
      if (meetOrSlice === "meet") {
        if (viewPortAspectRatio > viewBoxAspectRatio ) {
          scaleFactor = layoutSize.height / viewPortSize.height;
        } else {
          scaleFactor = layoutSize.width / viewPortSize.width;
        }
      } else {
        if (viewPortAspectRatio > viewBoxAspectRatio ) {
          scaleFactor = layoutSize.width / viewPortSize.width;
        } else {
          scaleFactor = layoutSize.height / viewPortSize.height;
        }
      }
      setScaleFactor(scaleFactor);
    }
  }, [layoutSize, viewPortSize, meetOrSlice]);
  
  const renderedOverlays = showOverlays && map && map.overlays ? 
    map.overlays.map((overlay) => {
      let points = parsePolygonSvg( overlay.markup );
      return (
        <g
          key={overlay.id}>
          <polygon 
            className={`${classes.polygon} ${showOverlayStroke ? classes.showStrokeAddendum : ""}`}
            points={points}
          />
        </g>
      );
    }) : null;
  
  return (
    isMapLoading ?
      <Box
        sx={{
          display: "flex",
          flexGrow: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <CircularProgress style={{width: "30px", height: "30px"}} /> 
      </Box>
      :
      <Box
        //bgcolor="green"
        ref={viewPortRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
        }}>
        <img 
          alt="Underlying map"
          src={map?.layoutBlobUrl} 
          className={classes.hiddenLayout} 
          onLoad={onHiddenLayoutLoad} />
        <Tooltip 
          title={map.fileName} 
          placement="right-start"
          enterDelay={1000}
          leaveDelay={200}
          enterNextDelay={1000}
        >
          <svg 
            width="100%" 
            height="100%" 
            preserveAspectRatio = {`xMidYMid ${meetOrSlice}`}
            viewBox={`0 0 ${layoutSize.width} ${layoutSize.height}`}
            >
            <image 
              href={map?.layoutBlobUrl} 
              x="0" 
              y="0"
              width={layoutSize.width}
              height={layoutSize.height}
              />
            {renderedOverlays}
          </svg>
        </Tooltip>
      </Box>
  );
};

export default MapPreview;