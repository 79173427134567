import * as locationMappingConstants from "../_constants/location.mapping.constants";
import initialState from "../_reducers/initialState";

export function locationMappingReducer(
  state = initialState.locationMapping,
  action
) {
  switch (action.type) {
    case locationMappingConstants.LOAD_LOCATION_MAPPING_STARTED:
      return {
        ...state,
        loading: { ...state.loading, locationMappingsLoading: true }
      };
    case locationMappingConstants.LOAD_LOCATION_MAPPING_SUCCESS:
      return {
        ...state,
        locationMappings: [...action.locationMappings],
        loading: { ...state.loading, locationMappingsLoading: false }
      };
    case locationMappingConstants.ADD_LOCATION_MAPPING_SUCCESS:
      return { ...state, locationMappings: [...state.locationMappings, action.locationMapping] };
    case locationMappingConstants.UPDATE_LOCATION_MAPPING_SUCCESS:
      const newLocationMappings = state.locationMappings.map((t) =>
        t.id === action.locationMapping.id ? action.locationMapping : t
      );
      return { ...state, locationMappings: newLocationMappings };
    case locationMappingConstants.REMOVE_LOCATION_MAPPING_OPTIMISTIC:
      return {
        ...state,
        locationMappings: state.locationMappings.filter((t) => t.id !== action.locationMappingId),
      };
    case locationMappingConstants.LOAD_LOCATIONS_SUCCESS:
      return { ...state, locations: action.locations };
    default:
      return state;
  }
}

export default locationMappingReducer;
