import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import LoadPleaseWait from "../../../notification/LoadingPleaseWait/LoadingMessage"
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid } from "@material-ui/data-grid";
import { AlertDialog } from "../../../../_helpers/AlertDialog";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  deleteVenueUser,
  loadVenueUsers
} from '../../../../_actions/venue.user.actions';
import {loadUsers} from "../../../../_actions";
import useLocalStorage from "../../../../_helpers/useLocalStorage";
import useSortModel from "../../../../_helpers/useSortModel";
import devconsole from "../../../_common/devconsole";

const useStyles = makeStyles((theme) => {
  var style = {};
  style.icon = {
    color: theme.palette.color.primary.dark
  }
  style.deleteIcon = {
    color: theme.palette.color.primary.red,
    marginLeft: theme.spacing(1)
  }
  return style;
});

const UserSetting = ({venue}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.venueUser?.isLoading);
  const venueUsers = useSelector((state) => state.venueUser?.data);
  const allUsers = useSelector((state) => state.userManagement.users);
  const isLoadingAllUsers = useSelector((state) => state.userManagement.loading.usersLoading);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [pageSize, setPageSize] = useLocalStorage("pmy-venueUserSettings-pageSize", 5);
  const [sortModel, setSortModel] = useSortModel("pmy-venueUserSettings-sortModel", [{field: 'userName', sort: 'asc'}]);

  useEffect(() => {
    dispatch(loadVenueUsers(venue.id))
      .catch((error) => {
        toast.error("Loading venue users failed" + error, { autoClose: false });
      });

    dispatch(loadUsers()).catch((error) => {
      toast.error("Loading users failed" + error, { autoClose: false });
    });

  }, [dispatch, venue]);

  const handleDelete = (id) => {
    try {
      const found = venueUsers.find((e) => e.id === id);
      if (!found) {
        toast.error("user not found!");
        return false;
      }

      dispatch(deleteVenueUser(found))
        .then(() => {
          dispatch(loadVenueUsers(venue.id))
            .then(() =>
              toast.success("User has been deleted.")
            );
        })
        .catch((err) => {
          toast.error("Deleting user failed. " + err.message, { autoClose: false });
        });
    } catch (error) {
      toast.error("Deleting user failed. " + error.message, {
        autoClose: false,
      });
    }
  };

  var combined_data = venueUsers.map(d => { return {
    id: d.id,
    firstName:  d.firstName,
    lastName: d.lastName,
    userName: d.userName,
    role: d.roles[0].name 
  }});
  var globalAdmins = allUsers.filter(d => d.role.name.toLowerCase() === "global administrator").map(d => { return {

    id: d.id,
    firstName:  d.firstName,
    lastName: d.lastName,
    userName: d.email,
    role: d.role.name
  }});
  /// combined both 
  var combinedUsers = [...combined_data, ...globalAdmins];
  devconsole.log("combinedUsers", (combinedUsers));
  
  const columns = [
    {
      field: "id",
      hide: true,
    },  
    {
      field: "firstName",
      headerName: "FIRST NAME",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "LAST NAME",
      flex: 1,
    },
    {
      field: "userName",
      headerName: "LOGIN/EMAIL",
      flex: 1,
    },
    {
      field: "role",
      headerName: "ROLE",
      flex: 1,
      // renderCell: (params) => {
      //   return (
      //     <>
      //       <div>
      //         {params?.row?.roles[0]?.name}
      //       </div>
      //     </>
      //   )
      // }
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 150,
      headerAlign: "left",
      renderCell: (params) => {
        return (
          (params.row.role.toLowerCase() !== "global administrator" ?
              <>
                <Tooltip title="Revoke access">
              <span>
                <IconButton
                  aria-label="delete"
                  color="primary"
                  className={classes.deleteIcon}
                  onClick={() => {
                    setDeleteOpen(true);
                    setUserId(params.row.id);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
                </Tooltip>
              </>
              : ""
          )
        );
      },
    },
  ];

  return (
    <div>
      <Typography variant="h5">
        Users, who can access venue
      </Typography>
           
      <AlertDialog 
        open={deleteOpen} 
        showTitle
        title="Revoke access"
        contentText={`Do you want to revoke access for this user?`}
        highPriority
        onNoButtonClicked={() => {
          setDeleteOpen(false);
        }}
        onYesButtonClicked={(e) => {
          handleDelete(userId);
          setDeleteOpen(false);
        }} />

      <LoadPleaseWait show={isLoading || isLoadingAllUsers} >
        <div style={{ display: "flex", height: "100%", width: "70%", paddingTop: 20 }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              autoHeight
              pageSize={pageSize}
              rowsPerPageOptions={[5, 10, 20]}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              sortModel={sortModel}
              onSortModelChange={(model) => setSortModel(model)}
              sortingOrder={['desc','asc']}
              columns={columns}
              rows={combinedUsers} />
          </div>
        </div>
      </LoadPleaseWait>
    </div>
  );
}

export default UserSetting;
