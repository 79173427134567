import React, { useState } from "react";
//import { useDispatch } from "react-redux";
import {Typography, Box, Card, CardContent,} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { template } from "../../../../_constants";
import { venueLocationsImport as importVenues } from "../../../../_services/venue.locations.service";
import {Button, ButtonType} from "../../../_common/htmlTags";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  hiddenUploadButton: {
    display: "none"
  },
  margin: {
    widows: theme.spacing(1),
  },
  buttonIcon: {
    marginRight: 10
  },
}));

export const VenueLocationsImport = () => {
//  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [result, setResult] = useState("");
  const [errors, setErrors] = useState([]);
  const [isImporting, setIsImporting] = useState(false);

  const changeHandler = (venue) => {
    setSelectedFile(venue.target.files[0]);    
    setIsFileSelected(
      typeof venue.target.files[0] === "undefined" ? false : true
    );
    setResult("");
    setErrors([]);

    venue.target.value = null;
  };

  const handleSubmission = (venue) => {
    setIsImporting(true);
    setErrors([]);
    importVenues(selectedFile)
      .then((response) => {
        setResult("Locations Bulk Import completed successfully.");
        toast.success("Locations Bulk Import completed successfully.");
        setIsImporting(false);
      })
      .catch((error) => {
        setIsImporting(false);
        toast.error("Locations Bulk Import failed with errors", { autoClose: false });
        setResult("Locations Bulk Import failed with errors: ");
        // check if error response is not an array then add response to array
        if (error.response && Array.isArray(error.response.data))
          setErrors(error.response.data);
        else if (error.response && error.response.data)
          setErrors(new Array(error.response.data));
        else if (error.message) setErrors(new Array(error.message));
        else setErrors(["Unknown error"]);
      });

      setIsFileSelected(false);
  };

  return (
    <Card><CardContent>
    <div className={classes.root}>
      <Typography variant="h4">Locations Bulk Import</Typography>
      <Typography variant="h6">
        Please, select an locations bulk update file to import. Accepted
        file type is XLSX and the file must have the following columns:
      </Typography>
      <ul>
        <li>Venue Name</li>
        <li>Location Name</li>
        <li>Location Description</li>
        <li>Location Type</li>
        <li>Parent Location Name</li>
        <li>Max Capacity</li>
      </ul>
      <div> 
      <Box>
        <input
          id="upload-file-button"
          type="file"
          name="file"
          onChange={changeHandler}
          accept=".xlsx,.xls"
          className={classes.hiddenUploadButton}
        />
        <label htmlFor="upload-file-button">
          <Button
            variant="contained"
            component="span"
            buttonType={ButtonType.Primary}>
            Upload File
          </Button>
        </label>
        <label>
          &nbsp;{selectedFile?.name}
        </label>
      </Box>
      <br />
      <Link onClick={()=> window.open(template.path + 'VenueLocationBulkUpdate.xlsx')}>Download Template</Link>
      </div>
      <Button
        variant="contained"
        onClick={handleSubmission}
        disabled={!isFileSelected}
      >
        Import
      </Button>

      <Button
        variant="outlined"
        className="btn primary"
        buttonType={ButtonType.Secondary}
        component={Link}
        to={"/VenueLocations"}
      >
        Go back to locations
      </Button>
      {isImporting ? <div>Importing...</div> : ""}
      <div name="results">{result}</div>
      <ul>
        {errors.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
      </div>
      </CardContent></Card>
  );
};

export default VenueLocationsImport;
