import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from 'react-redux';
import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  RadioGroup, Radio, FormControlLabel,
  Typography,
} from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import {getTwilioApiSettings, updateTwilioPhoneNumber, updateTwilioIsUsingPhoneNumber, updateTwilioMessagingServiceSid, updateTwilioAccountSid, updateTwilioAuthToken, testSms} from "../../../../../_services/venue.twilio.service";
import { Button } from "../../../../_common/htmlTags/Button";
import { getLoginUser } from "../../../../../_services/userManagement.service";
import { ROLES} from "../../../../../_constants/user.permissions.constants";


const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(3),
    width: "500px",
  },
  radio: {
    marginBottom: theme.spacing(-3),
    marginTop: theme.spacing(3),
    width: "500px",
  },
  ok: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px",
  },
  cancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%",
  },
  readonlyLabel: {
    verticalAlign: "middle",
    marginTop: theme.spacing(3),
  },
  testButton: {
    marginLeft: theme.spacing(3),
  },
}));

const getTwilioApiSettingsCall = async (venueId) => {
  if (venueId) {
    const result = await getTwilioApiSettings(venueId);
    return result;
  } else {
    return null;
  }
};

export const TwilioApiSettings = ({venue}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // The "data" here is the Twilio phone number as it was loaded from the database.
  const [{ data: initialTwilioApiSettings, isLoading: isLoading }] = useApiGet(
    getTwilioApiSettingsCall,
    venue.id,
    null
  );

  // The twilioPhoneNumber is the same as the initialTwilioPhoneNumber until the user edits and saves
  // the phone number. The twilioPhoneNumber variable will be set to the newly saved value.
  const [twilioPhoneNumber, setTwilioPhoneNumber] = useState("");

  // If cachedTwilioPhoneNumber is null, then the phone number has not been edited since
  // the page loaded from the database. If the cachedTwilioPhoneNumber is not null (it can be
  // an empty string), then the phone number name has been edited.
  const [cachedTwilioPhoneNumber, setCachedTwilioPhoneNumber] = useState(null);

  const [twilioMessagingServiceSid, setTwilioMessagingServiceSid] = useState("");
  // If cachedTwilioMessagingServiceSid is null, then the messaging service sid has not been edited since
  // the page loaded from the database. If the cachedTwilioMessagingServiceSid is not null (it can be
  // an empty string), then the messaging service sid has been edited.
  const [cachedTwilioMessagingServiceSid, setCachedTwilioMessagingServiceSid] = useState(null);

  
  const [twilioIsUsingPhoneNumber, setTwilioIsUsingPhoneNumber] = useState("false");

  const [accountSid, setAccountSid] = useState("");
  const [cachedAccountSid, setCachedAccountSid] = useState(null);

  const [authToken, setAuthToken] = useState("");
  const [cachedAuthToken, setCachedAuthToken] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const [loginUser, setLoginUser] = useState();

  useEffect(() => {
    dispatch(getLoginUser).then((user) => {
      setLoginUser(user);
    });
  }, [dispatch]);

  useEffect(() => {
    console.log("--------------test ", initialTwilioApiSettings?.data);
    setTwilioPhoneNumber(initialTwilioApiSettings?.data.twilioPhoneNumber);
    setTwilioMessagingServiceSid(initialTwilioApiSettings?.data.twilioMessagingServiceSid);
    setTwilioIsUsingPhoneNumber(initialTwilioApiSettings?.data.twilioIsUsingPhoneNumber.toString());

    if (initialTwilioApiSettings) {
      if (initialTwilioApiSettings.data.accountSid !== null) {
        setAccountSid(
          "*".repeat(initialTwilioApiSettings.data.accountSid.length)
        );
      }

      if (initialTwilioApiSettings.data.authToken !== null) {
        setAuthToken(
          "*".repeat(initialTwilioApiSettings.data.authToken.length)
        );
      }
    }

    setCachedTwilioPhoneNumber(null);
    setCachedTwilioMessagingServiceSid(null);
    setCachedAccountSid(null);
    setCachedAuthToken(null);
  }, [initialTwilioApiSettings]);

  const handleTwilioPhoneNumberSave = async () => {
    if (isSaving) {
      return;
    }
    if (cachedTwilioPhoneNumber === null) {
      return;
    }

    setIsSaving(true);
    updateTwilioPhoneNumber(venue.id, cachedTwilioPhoneNumber)
      .then(() => {
        toast.success("Twilio phone number has been saved.");
        setTwilioPhoneNumber(cachedTwilioPhoneNumber);
        setCachedTwilioPhoneNumber(null);
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Failed to save Twilio phone number." + error.message, {
          autoClose: false,
        });
        setIsSaving(false);
      });
  };

  const handleTwilioMessagingServiceSidSave = async () => {
    if (isSaving) {
      return;
    }
    if (cachedTwilioMessagingServiceSid === null) {
      return;
    }

    setIsSaving(true);
    updateTwilioMessagingServiceSid(venue.id, cachedTwilioMessagingServiceSid)
      .then(() => {
        toast.success("Twilio messaging service sid has been saved.");
        setTwilioMessagingServiceSid(cachedTwilioMessagingServiceSid);
        setCachedTwilioMessagingServiceSid(null);
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Failed to save Twilio phone number." + error.message, {
          autoClose: false,
        });
        setIsSaving(false);
      });
  };

  const handleTwilioIsUsingPhoneNumber = (e, s) => {
    if (isSaving) {
      return;
    }
    let value = e.target.value;
    setIsSaving(true);
    updateTwilioIsUsingPhoneNumber(venue.id, value)
      .then(() => {
        toast.success(`Twilio setting is set to use ${value ? "phone number" : "messaging service sid"}`);
        setTwilioIsUsingPhoneNumber(value.toString());
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Failed to save Twilio settings to use phone number or messaging service sid." + error.message, {
          autoClose: false,
        });
        setIsSaving(false);
      });
  };

  const handleAccountSidSave = async () => {
    if (isSaving) {
      return;
    }
    if (cachedAccountSid === null) {
      return;
    }

    setIsSaving(true);
    updateTwilioAccountSid(cachedAccountSid)
      .then(() => {
        toast.success("Twilio account SID has been saved.");
        setAccountSid(cachedAccountSid);
        setCachedAccountSid(null);
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Failed to save Twilio account SID." + error.message, {
          autoClose: false,
        });
        setIsSaving(false);
      });
  };

  const handleAuthTokenSave = async () => {
    if (isSaving) {
      return;
    }
    if (cachedAuthToken === null) {
      return;
    }

    setIsSaving(true);
    updateTwilioAuthToken(cachedAuthToken)
      .then(() => {
        toast.success("Twilio auth token has been saved.");
        setAuthToken(cachedAuthToken);
        setCachedAuthToken(null);
        setIsSaving(false);
      })
      .catch((error) => {
        toast.error("Failed to save Twilio auth token." + error.message, {
          autoClose: false,
        });
        setIsSaving(false);
      });
  };

  const [testPhoneNumber, setTestPhoneNumber] = useState("");

  const handleSendClick = async () => {
    testSms(venue.id, testPhoneNumber)
      .then((response) => {
        toast.success(`Test SMS sent. ${response.data}`);
      })
      .catch((error) => {
        toast.error("Failed to send test SMS. " + error.message, {
          autoClose: false,
        });
      });
  };

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            <Typography variant="h5">Twilio API settings</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "0px",
              }}
            >
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={twilioIsUsingPhoneNumber}
                onChange={handleTwilioIsUsingPhoneNumber}
              >
                <FormControlLabel
                  className={classes.radio}
                  value={"true"}
                  control={<Radio color="primary" />}
                  label="Use Twilio phone number"
                />
                <TextField
                  id="twilioPhoneNumber"
                  label="Twilio phone number"
                  placeholder="Twilio phone number"
                  disabled={twilioIsUsingPhoneNumber === "false"}
                  variant="outlined"
                  name="twilioPhoneNumber"
                  value={
                    cachedTwilioPhoneNumber === null
                      ? twilioPhoneNumber ?? ""
                      : cachedTwilioPhoneNumber
                  }
                  onChange={(e) => {
                    const { value } = e.target;
                    setCachedTwilioPhoneNumber(value);
                  }}
                  className={classes.textField}
                  InputProps={
                    cachedTwilioPhoneNumber !== null
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                className={classes.ok}
                                onClickCapture={(e) =>
                                  handleTwilioPhoneNumberSave()
                                }
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                edge="end"
                                className={classes.cancel}
                                onClickCapture={(e) =>
                                  setCachedTwilioPhoneNumber(null)
                                }
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : null
                  }
                />
                <FormControlLabel
                  className={classes.radio}
                  value={"false"}
                  control={<Radio color="primary" />}
                  label="Use Twilio messaging service SID"
                />
                <TextField
                  id="twilioMessagingServiceSid"
                  label="Twilio messaging service sid"
                  placeholder="Twilio messaging service SID"
                  variant="outlined"
                  name="twilioMessagingServiceSid"
                  disabled={twilioIsUsingPhoneNumber === "true"}
                  value={
                    cachedTwilioMessagingServiceSid === null
                      ? twilioMessagingServiceSid ?? ""
                      : cachedTwilioMessagingServiceSid
                  }
                  onChange={(e) => {
                    const { value } = e.target;
                    setCachedTwilioMessagingServiceSid(value);
                  }}
                  className={classes.textField}
                  InputProps={
                    cachedTwilioMessagingServiceSid !== null
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                className={classes.ok}
                                onClickCapture={(e) =>
                                  handleTwilioMessagingServiceSidSave()
                                }
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                edge="end"
                                className={classes.cancel}
                                onClickCapture={(e) =>
                                  setCachedTwilioMessagingServiceSid(null)
                                }
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : null
                  }
                />
              </RadioGroup>{" "}
              {loginUser?.profile.role === ROLES.ADMINISTRATOR ? (
                <TextField
                  id="accountSid"
                  label="Account SID (applied to all venues)"
                  placeholder="Account SID (applied to all venues)"
                  variant="outlined"
                  name="accountSid"
                  value={
                    cachedAccountSid === null
                      ? accountSid ?? ""
                      : cachedAccountSid
                  }
                  onChange={(e) => {
                    const { value } = e.target;
                    setCachedAccountSid(value);
                  }}
                  onFocus={(e) => {
                    if (
                      accountSid &&
                      accountSid === initialTwilioApiSettings?.accountSid
                    ) {
                      setCachedAccountSid("");
                    }
                  }}
                  className={classes.textField}
                  InputProps={
                    cachedAccountSid !== null
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                className={classes.ok}
                                onClickCapture={(e) => handleAccountSidSave()}
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                edge="end"
                                className={classes.cancel}
                                onClickCapture={(e) =>
                                  setCachedAccountSid(null)
                                }
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : null
                  }
                />
              ) : (
                <Typography variant="body1" className={classes.readonlyLabel}>
                  Account SID: {accountSid}
                </Typography>
              )}
              {loginUser?.profile.role === ROLES.ADMINISTRATOR ? (
                <TextField
                  id="authToken"
                  label="Auth token (applied to all venues)"
                  placeholder="Auth token (applied to all venues)"
                  variant="outlined"
                  name="authToken"
                  value={
                    cachedAuthToken === null ? authToken ?? "" : cachedAuthToken
                  }
                  onChange={(e) => {
                    const { value } = e.target;
                    setCachedAuthToken(value);
                  }}
                  onFocus={(e) => {
                    if (
                      authToken &&
                      authToken === initialTwilioApiSettings?.authToken
                    ) {
                      setCachedAuthToken("");
                    }
                  }}
                  className={classes.textField}
                  InputProps={
                    cachedAuthToken !== null
                      ? {
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                edge="end"
                                className={classes.ok}
                                onClickCapture={(e) => handleAuthTokenSave()}
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <DoneIcon />
                              </IconButton>
                              <IconButton
                                edge="end"
                                className={classes.cancel}
                                onClickCapture={(e) => setCachedAuthToken(null)}
                                onMouseDown={(e) => e.stopPropagation()}
                              >
                                <ClearIcon />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }
                      : null
                  }
                />
              ) : (
                <Typography variant="body1" className={classes.readonlyLabel}>
                  Auth token: {authToken}
                </Typography>
              )}
              <Box
                sx={{
                  width: "1200px",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "50px",
                  marginBottom: "16px",
                  justifyContent: "flex-end",
                }}
              >
                <Typography
                  style={{
                    marginBottom: "16px",
                  }}
                  variant="h6"
                >
                  Send test SMS
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    id="testPhoneNumber"
                    label="Phone number to send SMS to"
                    placeholder="Phone number to send SMS to"
                    variant="outlined"
                    name="testPhoneNumber"
                    value={testPhoneNumber}
                    onChange={(e) => {
                      const { value } = e.target;
                      setTestPhoneNumber(value);
                    }}
                    style={{ width: "500px" }}
                  />
                  <Button
                    variant="contained"
                    className={classes.testButton}
                    disabled={!testPhoneNumber}
                    onClick={handleSendClick}
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default TwilioApiSettings;