import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    noLogo: {
        width: "100%",
        height: "80px",

        backgroundColor: theme.palette.color.primary.background,
        color: theme.palette.color.secondary.main,

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
}));

export const NoLogo = () => {
    const classes = useStyles();

    return (
        <Box className={classes.noLogo}>
            <>NO LOGO</>
        </Box>
    );
}

export default NoLogo;