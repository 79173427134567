import { AuthService, downloadFile } from ".";
import { ApiRootConstants } from "../_constants";
import axios from "axios";
import devconsole from "../components/_common/devconsole";

const authService = new AuthService();

export async function getAlertRuleSetsByVenueId(venueId) {
  const user = await authService.getUser();
  devconsole.log(`${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/${venueId}`);
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/GetByVenueId/${venueId}`, {
        headers,
      })
      .catch(error => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

export async function getAlertRuleSetById(id) {
  const user = await authService.getUser();
  let userId = user?.profile.sub;
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };  
  
    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/${id}`, {
        headers,
      })
      .catch((error) => {
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error.response.data.message;
      });
  }
}


export async function addAlertRuleSet(alertRuleSet) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet`,
        alertRuleSet,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateAlertRuleSet(alertRuleSet) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet`,
        alertRuleSet,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function removeAlertRuleSet(id) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .delete(
        `${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/${id}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else
          throw new Error(error.response.data.message)
      });
  }
}

export async function removeAlertRuleSetRange(ids) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .put(
        `${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/DeleteRange`,
        ids,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else
          throw new Error(error.response.data.message)
      });
  }
}

export function venueAlertsExport(venueId) {
    return downloadFile(`${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/Export/${venueId}`, `Alerts-${venueId}.xlsx`);
}

export async function publishOfflineAlerts() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/OfflineAlert/PublishOfflineAlerts`,
        null,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function duplicateAlerts(venueId, alertRuleSetIds) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    var postData = alertRuleSetIds;

    return await axios
      .post(
        `${ApiRootConstants.dashboardApiRoot}api/AlertRuleSet/DuplicateAlertRuleSets/${venueId}`,
        postData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}
