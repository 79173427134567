import {Card, CardContent, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";

import VenueEventForm from "./VenueEventForm";
import {NoVenueSelectedError} from "../../../_constants";
import {addVenueEvent} from "../../../_services/venue.event.service";
import {PermissionDenied} from "../../PermissionDenied";
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

export const AddVenueEvent = () => {
  const history = useHistory();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const [saving, setSaving] = useState(false);
  const [venueEvent, setVenueEvent] = useState({
    id: "00000000-0000-0000-0000-000000000000",
    venueId: "00000000-0000-0000-0000-000000000000",
    eventName: "",
    eventDescription: "",
    startDate: "2020-01-01",
    endDate: "2020-01-01",
    businessHoursFrom: "00:00:00",
    businessHoursTo: "00:00:00",
    isDeleted: false
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setVenueEvent({...venueEvent, [name]: value});
  };
  const handleSave = (e) => {
    setSaving(true);
    addVenueEvent(venueEvent).then((d) => {
      toast.success("Event added successfully");
      setSaving(false);
      history.push("/events");
    }).catch((e) => {
      toast.error("Adding event failed!");
      console.error("addVenueEvent", e);
    })
  };
  useEffect(() => {
    if (selectedVenue != null) {
      venueEvent.venueId = selectedVenue.id;
      setVenueEvent(venueEvent);
    }
  }, [venueEvent, selectedVenue]);
  return selectedVenue === null ?
    (
      <NoVenueSelectedError></NoVenueSelectedError>
    ) : (
      <PermissionsGate
        RenderError={() => <PermissionDenied />}
        scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_EVENTS]}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} container justifyContent="space-between">
              <Typography variant="h4">Add Event for {selectedVenue.name.toLowerCase()} venue</Typography>
          </Grid>
          <Grid item xs={12} container justifyContent="space-between" >
            <Grid item xs={12} > 
              <Card style={{ overflow: 'auto' }}><CardContent>
                <VenueEventForm
                  venueEvent={venueEvent}
                  onChange={handleChange}
                  onSave={handleSave}
                  saving={saving}
                />
              </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </PermissionsGate>
    )
};
        
export default AddVenueEvent;