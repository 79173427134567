import React, { useEffect, useState, useContext } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Transition } from 'react-transition-group';
import ClipLoader from "react-spinners/ClipLoader";
import { widgetTypeConstants, dynamicCaptionWidgets } from "../../_constants/widget.type.constants";
import { appViewModeConstants } from "../../_constants/app.view.mode.constants";
import { WidgetBox } from './WidgetBox';
import { widgetDataSelector, widgetRefValuesSettingsSelector } from "../../_reducers/widget.data.selectors";
import WidgetSettingsWrapper from "./settings/SettingsWrapper";
import devconsole from "../_common/devconsole";
import WidgetContext from "./WidgetContext";

const log = function () { devconsole.log('WidgetWrapper', ...arguments); }

const widgetTypesById = Object.values(widgetTypeConstants).reduce((map, obj) => { map[obj.id] = obj; return map; }, {});
const nameOfWidget = (widgetTypeId) => Object.entries(widgetTypeConstants).find(entry => entry[1].id === widgetTypeId)[0];
const isAdminMode = (mode) => mode === appViewModeConstants.ADMIN_MODE;

const complexDataWidgetTypes = [
    widgetTypeConstants.widgetOccupancy.id,
    widgetTypeConstants.widgetTrafficInOut.id,
    widgetTypeConstants.widgetMap.id,
    widgetTypeConstants.widgetFootfall.id,
    widgetTypeConstants.widgetNumber.id,
    widgetTypeConstants.widgetDemographic.id,
    widgetTypeConstants.widgetQueueModel.id,
    widgetTypeConstants.widgetPatronDistribution.id,
];

const fadeInDuration = 500;

const useStyles = makeStyles((theme) => ({
    fadingEmptyWidget: {
        ...theme.widget.fadingEmptyWidget,
        transitionDuration: `${fadeInDuration}ms`
        },
    renewIcon: {
        position: "absolute",
        top: 5,
        right: 5,
        },
    }));

const transitionStyles = {
  entering: { opacity: 1 },
  entered:  { opacity: 1 },
  exiting:  { opacity: 0 },
  exited:  { opacity: 0 },
};

export const WidgetWrapper = (props) => {
    const { widgetType, widgetId, viewMode, isPublicViewMode, width, height, bgcolor, border, clientType,
        onDelete, children, setIsDraggable = _ => {}, brandingSettings, onAudioPlay = () => {} } = props;

    const dataStore = nameOfWidget(widgetType);
    const widgetData = useSelector(state => widgetDataSelector(state, dataStore, widgetId), shallowEqual);
    const widgetSettingsRefValues = useSelector(state => widgetRefValuesSettingsSelector(state, dataStore, widgetId), shallowEqual);
    const selectedVenue = useSelector(state => state.venueReducer.selectedVenue);
    // const isDataLoading = useSelector(state => state.dashboards.loading.dashboardDataLoading);

    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    const [isFading, setIsFading] = useState(false);
    const [widgetCaption, setWidgetCaption] = useState(null);
    
    const classes = useStyles();
    
    const handleDelete = () => onDelete?.call(widgetId);
    const handleSettings = () => setIsSettingsOpen(state => !state);
    const handleCloseSettings = () => {
        setIsDraggable(true);
        setIsSettingsOpen(false);
    }

    log({widgetType, viewMode, isPublicViewMode, widgetData, widgetSettingsRefValues, classes});
    const widgetContextData = useContext(WidgetContext);

    useEffect(() => {
        if (!isAdminMode(viewMode)) {
            setIsFading(true);
        }
    }, [viewMode, setIsFading]);

    const childContent = React.Children.only(children);

    const widgetProps = {
        venueId: selectedVenue?.id,
        widgetId,
        width,
        height,
        viewMode,
        isPublicViewMode,
        clientType,
        data: widgetData,
        brandingSettings,
        onAudioPlay,
        onGetCaption: caption => setWidgetCaption(caption),
        settings: childContent.props.settings ?
            { ...(widgetSettingsRefValues ?? {}), ...childContent.props.settings } : widgetSettingsRefValues
    };

    const isCaptionTextVisible = props.isCaptionTextVisible ?? true;
    const isViewLoading = !isAdminMode(viewMode) && widgetData === undefined;
    const isLoadingIcon = (widgetData?.isRefreshing ?? false) && complexDataWidgetTypes.includes(widgetType) && !isAdminMode(viewMode);

    // Temporal solution for displaying caption depending on specific conditions (below):
    const getCaption = () => {
        // Check if this widget is one of widgets that should have dynamic caption:
        if (dynamicCaptionWidgets.hasOwnProperty(widgetType)) {

            // Check if this widget should have caption that is visible in all modes, without any conditions
            if (dynamicCaptionWidgets[widgetType].hasOwnProperty("constantCaptionValue")) {
                return dynamicCaptionWidgets[widgetType].constantCaptionValue;
            }

            // Check if widget should display selected value from corresponding setting (specific setting for each dynamicCaptionWidget)
            if (widgetProps?.settings?.hasOwnProperty(dynamicCaptionWidgets[widgetType]?.setting)) {
                // Check if setting value (from server) should be converted in some other predefined format (example: setting name from server is "Traffic In only", but "Traffic In" should be displayed in caption)
                // else just return corresponding setting value instead of default widget caption
                return dynamicCaptionWidgets[widgetType].hasOwnProperty("predefinedSettingValuesMapping") 
                ? dynamicCaptionWidgets[widgetType]?.predefinedSettingValuesMapping[widgetProps?.settings[dynamicCaptionWidgets[widgetType].setting]?.id] 
                : widgetProps?.settings[dynamicCaptionWidgets[widgetType].setting]?.name
            } 
            
            // Default value
            else if (widgetProps.settings === null && dynamicCaptionWidgets[widgetType].hasOwnProperty("defaultValue")) {
                return dynamicCaptionWidgets[widgetType].defaultValue;
            }
        } 
        
        // Else return regular caption
        else {
            return isAdminMode(viewMode) && isCaptionTextVisible && (widgetCaption || widgetTypesById[widgetType].caption);
        }
    }

    return (
        <>
            <WidgetBox
                caption={getCaption()}
                isDynamicCaption={dynamicCaptionWidgets.hasOwnProperty(widgetType)}
                captionColor={widgetContextData?.captionColor}
                isAdminMode={isAdminMode(viewMode)}
                width={width}
                height={height}
                bgcolor={bgcolor}
                border={border}
                canDelete={isAdminMode(viewMode)}
                canConfigure={isAdminMode(viewMode)}
                onDelete={handleDelete}
                onConfigure={handleSettings}
                setIsDraggable={setIsDraggable}
            >
                {
                    isViewLoading ?
                        <Transition in={isFading} timeout={fadeInDuration}>
                            {state => (
                                <Box className={classes.fadingEmptyWidget} style={{...transitionStyles[state]}} />
                            )}
                        </Transition>
                    :
                        <>
                            <ClipLoader loading={isLoadingIcon}
                                        size="20px" color="#00C1CE" className={classes.renewIcon} />
                            {React.cloneElement(childContent, widgetProps)}
                        </>
                }
            </WidgetBox>

            {
                (!isPublicViewMode) &&

                <WidgetSettingsWrapper
                    isSettingsOpen={isSettingsOpen}
                    handleCloseSettings={handleCloseSettings}
                    widgetId={widgetId}
                    widgetTypeId={widgetTypesById[widgetType].id}
                    widgetTypeName={dataStore}
                />
            }
        </>
    );
}
