import React from 'react';
import { useDispatch } from 'react-redux';
import { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { getLoginUser } from "../../_services/userManagement.service";
import { ROLES } from '../../_constants/user.permissions.constants';
import { toast } from "react-toastify";
import GhostTextField from "../_common/GhostTextField";
import { occupancyWidgetFactorUpdate } from "../../_services/factors.service";

export const EditableNumber = (props) => {
  const {value, venueId, locationId, factorTypeId, className, textColor} = props;

  const dispatch = useDispatch();
  const [canEdit, setCanEdit] = useState(false);
  const [editedValue, setEditedValue] = useState(value);

  useEffect(() => {
    dispatch(getLoginUser).then((user) => { 
      if (user?.profile.role === ROLES.ADMINISTRATOR || user?.profile.role === ROLES.VENUE_ADMINISTRATOR) {
        setCanEdit(true);
      }
    }).catch((error) => {
      toast.error("Get login user failed" + error, { autoClose: false });
    });

  }, [dispatch]);

  useEffect(() => {
    setEditedValue(value);
  }, [value]);

  const handleValueEdited = (newValue) => {
    setEditedValue(newValue);
    // Send newValue to the database.
    occupancyWidgetFactorUpdate(venueId, locationId, newValue)
    .then(() => {
      toast.success("Factor applied successfully.");
    })
    .catch((error) => {
      toast.error("Failed to apply factor. " + error, { autoClose: false });
    });
  };

  // Adjusting margin depending on value (or edited value) length.
  // Numbers below were chosen manually.
  const margin = `${-102 + (editedValue !== value ? editedValue : value).toString().length * 14}px`;

  return (
    <>
      {canEdit ? 
        <GhostTextField 
          value={editedValue}
          onChange={handleValueEdited}
          textAlign="right"
          width="120px"
          marginLeft={margin}
          marginRight="0"
          paddingRight="3px"
          fontSize="clamp(24px, 2.3em, 32px)"
          fontWeight="500"
          textColor={textColor}
          intOnly
        />
        :
        <Typography className={className}>
          {value.toLocaleString("en-US")}
        </Typography>
      }
    </>
  );
};

export default EditableNumber;