import constants from "../_constants/map.widget.constants";
import initialState from "./initialState";
import devconsole from "../components/_common/devconsole";

export function mapWidgetReducer(state = initialState.mapWidget, action) {
  devconsole.log(action, state);

  switch (action.type) {
    case constants.NEW_MAP_WIDGET: return {...state, widget: action.widget, loading: false };
    case constants.UPDATE_MAP_WIDGET: return {...state, widget: action.widget, loading: false };
    case constants.DELETE_MAP_WIDGET: return {...state, widget: null, loading: false };
    default:
      return state;
  }
}

export default mapWidgetReducer;
