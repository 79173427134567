import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import NotificationsRounded from '@material-ui/icons/NotificationsRounded';
import Badge from '@material-ui/core/Badge';
import { Grid, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import NotificationMessage from "./NotificationMessage";
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close'
import { AuthService } from "../../_services/auth.service";
import { ApiRootConstants } from "../../_constants/apiRoot.constants";
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import ToastNotification from "./ToastNotification"
import "./animation.css";
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { addNotification } from "../../_actions/notification.action";
import devconsole from "../_common/devconsole";

const useStyles = makeStyles(({ spacing, palette }) => {
    return {
        iconContainer: {
            marginRight: "18px"
        },
        notificationIcon: {
            fontSize:"default" ,
            fill: palette.color.primary.dark,
            cursor: 'pointer',    
            width: "21.6px",
        },
        notificationPanelContainer: {
            border: "1px solid rgba(100, 100, 100, 0.4)",
            height: "calc(100vh - 64px)",
            width: "500px",
            maxWidth: "100%",
            backgroundColor: "white",
            right: 0,
            position: "absolute",
            zIndex: 1,
            top: "64px",
            overflow: "overlay"
        },
        header: {
            alignItems: "center",
            justifycontent: "center",
            textAlign: "center",
            verticalAlign: "middle",
            margin: "auto",
            color: "black", 
            marginLeft: "55px", 
            marginTop: "13px"
        },
        closeIcon: {
            float:"right", 
            fill: "#616161", 
            cursor:'pointer', 
            margin: "5px 17px 0px 0px"
        },
        dismissAll: {
            color: "#6666ff", 
            margin: "5px 20px 11px 0px", 
            right: "0", 
            float: "right", 
            cursor: "pointer"
        },
        gridItem: {
            padding: "0 20px"
        },
        closeNotificationMessageContainer: {
            float:"right",  
            right: "0",
            position: "absolute",
            paddingRight: "20px",
            paddingLeft: "20px",
            paddingTop: "6px",
            marginLeft: "50px"
        },
        closeNotificationMessageIcon: {
            fill: "#9e9e9e", 
            cursor:'pointer',
            fontSize: "18px", 
            marginTop: "4px"
        },
        emptyNotificationPanelContainer: {
            marginTop: "50px",
            marginLeft: "10px"
        },
        emptyNotificationPanel: {
            alignItems: "center",
            justifycontent: "center",
            textAlign: "center",
            verticalAlign: "middle",
            margin: "auto",
            position: "relative",
            top: "33%",
        },          
        bigEmptyNotificationIcon: {
            fill: "#e6e6e6", 
            width: 130,
            height: 130,
        },            
        emptyNotificationPanelText: {
            alignItems: "center",
            justifycontent: "center",
            textAlign: "center",
            verticalAlign: "middle",
            margin: "auto",
            position: "relative",
            top: "33%",
            color: "#333333",
            marginTop: "13px"
        }            
    }
});

const REACT_TOSTIFY_TOAST_NOTIFICATION_CONTAINER_ID = "ToastNotificationsContainer";
const NOTIFICATION_PANEL_CONTAINER_ID = "NotificationPanelContainer";

const ToastNotificationMessage = ({ notificationMessage }) => (
    <ToastNotification notificationMessage={notificationMessage}/>
);

const authService = new AuthService();
async function getUser() {
    return authService.getUser();
}

export const NotificationPanel = () => {
    const styles = useStyles();
    
    const [notificationMessagesList, setNotificationMessagesList] = useState([]);
    const [isNotificationPanelVisible, setIsNotificationPanelVisible] = useState(false);
    const [ signalRConn, setSignalRConn ] = useState(null);
    const [ user, setUser ] = useState(null);
    const dispatch = useDispatch();

    const handleCloseClick = () => {
        if (! isNotificationPanelVisible) {
            toast.dismiss();
        }       
        setIsNotificationPanelVisible(! isNotificationPanelVisible);
    }

    const handleCloseNotificationMessage = (id) => {
        setNotificationMessagesList(
            notificationMessagesList.filter((notificationMessage) => {
                return notificationMessage.id !== id;
            })
        );
    }

    const handleDismissAll = () => {
        setNotificationMessagesList([]);
    }

    if (notificationMessagesList !== 0) {
        notificationMessagesList.sort((a, b) => b.timestamp - a.timestamp);
    }

    useEffect(() => {
        getUser().then(a => {
            setUser(a);
        });
    }, []);

    useEffect(() => {
        if (user != null) {
            const newConnection = new HubConnectionBuilder()
            .withUrl(`${ ApiRootConstants.notificationApiRoot }Hubs/Notification`, { accessTokenFactory: async () => {
                let currUser = await getUser();
                //console.log(`Access Token Factory user token: ${ currUser.access_token }`);
                return currUser.access_token;
            }
            })
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();
            newConnection.onreconnecting(error => { devconsole.log("reconnecting..."); });
            newConnection.onclose(error => { devconsole.log("connection closed"); });
            setSignalRConn(newConnection);
        }
    }, [user]);

    const Notify = useCallback((data) => {
        var localTime = new Date(data.lastUpdatedDateInUTC);
        var messageType = "information";
        switch(data.type) {
            case 1: messageType = "information"; break;
            case 2: messageType = "error"; break;
            case 3: messageType = "success"; break;
            case 4: messageType = "warning"; break;
            case 5: messageType = "processing"; break;
            default: messageType = ""; break;
        }

        const withoutAnimationTransition = cssTransition({
            exit: "toast-notification-disappear"
        });
          
        var msg = {
            id: data.id,
            title: data.title,
            content: data.content,
            timestamp: localTime,
            type: messageType
        };

        dispatch(addNotification(msg));

        var hasToPushToastNotification = window.getComputedStyle(document.getElementById(NOTIFICATION_PANEL_CONTAINER_ID))?.visibility === "hidden";
        if (hasToPushToastNotification) {
            toast(
                <ToastNotificationMessage notificationMessage={msg} />,
                { autoClose: 4000, containerId: REACT_TOSTIFY_TOAST_NOTIFICATION_CONTAINER_ID, transition: withoutAnimationTransition }
            );
        }
        
        // add new data to the top
        setNotificationMessagesList(prev => [ ...prev.filter(n => n.id !== data.id), msg]);
    }, []);
    
    useEffect(() => {
        if(signalRConn !== null) {
            if (signalRConn.connectionId === null) {
            signalRConn.start()
            .then(function() {
                devconsole.log(`ConnectionId: ${signalRConn.connectionId }`);
                devconsole.log("Signalr Connected successfully!");
                signalRConn.on('notify', Notify); 
            })
            .catch(function(err, status, code) { 
                console.log('Connection to signalr failed: ', err);
            });
            }
        }
        return function cleanup() {
            devconsole.log("Unmount notification component!");
            if (signalRConn != null) {
            devconsole.log("remove notify method from Notification!");
            signalRConn.off("notify");
            devconsole.log(`stop connection!`);
            signalRConn.stop();
            }
        }
    }, [signalRConn, Notify]);

    useEffect(() => {
        if(signalRConn !== null) {
            if (signalRConn.connectionId !== null) {
                devconsole.log("off-on method");
                signalRConn.off('notify'); 
                signalRConn.on('notify', Notify); 
            }
        }
    }, [signalRConn, Notify]);

    return (
        <div>
            <div className={styles.iconContainer}>
                {notificationMessagesList.length !== 0 ? (                
                    <Badge 
                        badgeContent={notificationMessagesList.length} 
                        color="primary"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <NotificationsRounded
                            className={styles.notificationIcon}
                            onClick={handleCloseClick}
                        />
                    </Badge>
                ) : (
                    <NotificationsRounded 
                        className={styles.notificationIcon}
                        onClick={handleCloseClick}
                    />
                )}
            </div>

            <Box
                className={styles.notificationPanelContainer}
                visibility={isNotificationPanelVisible ? "visible" : "hidden"}
                boxShadow={20}
                id={NOTIFICATION_PANEL_CONTAINER_ID}
            >
                <Box>
                    <CloseIcon className={styles.closeIcon} onClick={handleCloseClick}/>

                    <Typography className={styles.header} variant="h5">
                        Notifications
                    </Typography>
                </Box>
                <br/>

                {notificationMessagesList.length !== 0 ? (
                    <>
                        <Typography 
                            className={styles.dismissAll}
                            onClick={handleDismissAll}
                        >
                            Dismiss all
                        </Typography>                    

                        <Grid
                            container
                            direction="column"
                            justifycontent="space-evenly"
                            alignItems="stretch"
                        >
                            {notificationMessagesList.map(function (notificationMessage) {
                                return (
                                    <Grid key={notificationMessage.id} item xs={12} className={styles.gridItem}>                                                                                                
                                        <Box className={styles.closeNotificationMessageContainer}>
                                            <CloseIcon 
                                                className={styles.closeNotificationMessageIcon}
                                                onClick={() => {handleCloseNotificationMessage(notificationMessage.id)}}
                                            />
                                        </Box>

                                        <NotificationMessage notificationMessage={notificationMessage}/>
                                    </Grid>
                                );
                            })} 
                        </Grid>
                    </>
                ) : (
                    <div className={styles.emptyNotificationPanelContainer}>
                        <div className={styles.emptyNotificationPanel}>
                            <NotificationsRounded className={styles.bigEmptyNotificationIcon}/>
                        </div>
                        <Typography className={styles.emptyNotificationPanelText} variant="h6">
                            No new notifications in this session
                        </Typography>
                    </div>
                )}
            </Box>    

            <ToastContainer 
                enableMultiContainer 
                containerId={REACT_TOSTIFY_TOAST_NOTIFICATION_CONTAINER_ID} 
                position={toast.POSITION.BOTTOM_LEFT} 
                newestOnTop
                hideProgressBar
                style={{
                    bottom: "-5px"
                }}
            />
        </div>
    );
}

export default NotificationPanel;