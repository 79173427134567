import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { loadTasks, disableTask, enableTask } from "../../_actions/schedule.task.actions";
import { DataGrid } from "@material-ui/data-grid";
import {
  Typography,
  IconButton,
  Tooltip,
  Grid,
  Card,
  CardContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import {TextLink} from "../_common/htmlTags/TextLink";
import EditIcon from "@material-ui/icons/Edit";
import TimerIcon from '@material-ui/icons/Timer';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import { toast } from "react-toastify";
import { AlertDialog } from "../../_helpers/AlertDialog";
import { DataGridLoadingOverlay } from '../_common/DataGridLoadingOverlay';
import {Button} from "../_common/htmlTags/Button";
import AddIcon from "@material-ui/icons/Add";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";

const useStyles = makeStyles((theme) => {
  var style = {};
  style.icon = {
    color: theme.palette.color.primary.dark
  }
  style.deleteIcon = {
    color: theme.palette.color.primary.red
  }
  return style;
});

const ScheduledTasks = () => {
  const classes = useStyles();
  const loading = useSelector((state) => state.scheduleTasks.loading);

  const tasks = useSelector((state) => state.scheduleTasks.tasks);
  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );
  const dispatch = useDispatch();
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [restoreOpen, setRestoreOpen] = React.useState(false);
  const [scheduleTaskId, setScheduleTaskId] = React.useState(null);
  const [pageSize, setPageSize] = useLocalStorage("pmy-scheduledTasks-pageSize", 5);
  const [sortModel, setSortModel] = useSortModel("pmy-scheduledTasks-sortModel", [{field: 'name', sort: 'asc'}]);

  useEffect(() => {
    if (selectedVenue !== null) {
      dispatch(loadTasks(selectedVenue.id)).catch((error) => {
        toast.error("Loading tasks failed. " + error, { autoClose: false });
      });
    }
  }, [dispatch, selectedVenue]);

  const handleDisableTask = (taskId) => {
    try {
      dispatch(disableTask(taskId)).catch((error) => {
        toast.error("Disabling task failed. " + error.message, { autoClose: false });
      });
      toast.success("Task disabled");
    } catch (error) {
      toast.error("Disabling task failed. " + error.message, { autoClose: false });
    }
  };

  const handleRestoreTask = (taskId) => {
    toast.success("Task enabled");
    try {
      dispatch(enableTask(taskId)).catch((error) => {
        toast.error("Enabling task failed. " + error.message, { autoClose: false });
      });
    } catch (error) {
      toast.error("Enabling task failed. " + error.message, { autoClose: false });
    }
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
    },
    {
      field: "description",
      headerName: "DESCRIPTION",
      flex: 1.5,
    },
    {
      field: "operationalIndicator",
      headerName: "INDICATOR",
      flex:1,
      width: 200,
      valueGetter: (params) => params.row?.operationalIndicator?.name,
    },
    {
      field: "vendor",
      headerName: "DATA SOURCE",
      width: 150,
      valueGetter: (params) => params.row?.vendor?.name,
    },
    {
      field: "scheduled",
      headerName: "SCHEDULE",
      width: 150,
      valueGetter: (params) => params.row?.frequency?.name,
    },
    {
      field: "startProcessDateTimeUTC",
      headerName: "START",
      width: 200,
      valueGetter: (params) => {
        var startProcessDateTimeUTC = new Date(
          params.row.startProcessDateTimeUTC
        );
        return new Date(
          startProcessDateTimeUTC.getTime() -
            startProcessDateTimeUTC.getTimezoneOffset() * 60000
        ).toLocaleString();
      }
    },
    {
      field: "createdDateUTC",
      headerName: "CREATED",
      flex:1,
      width: 200,
      valueGetter: (params) => {
        var createdDateUTC = new Date(params.row.createdDateUTC);
        return new Date(
          createdDateUTC.getTime() - createdDateUTC.getTimezoneOffset() * 60000
        ).toLocaleString();
      },
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 110,
      valueGetter: (params) => (params.row?.enabled ? "Enabled" : "Disabled"),
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      sortable: false,
      width: 120,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                color="primary"
                className={classes.icon}
                component={Link}
                to={`/edit-task/${params.row.id}`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            {params.row?.enabled ? (
              <Tooltip title="Disable">
                <span>
                  <IconButton
                    aria-label="disable"
                    color="primary"
                    disabled={!params.row?.enabled}
                    className={classes.icon}
                    onClick={() => {
                      setDeleteOpen(true);
                      setScheduleTaskId(params.row.id);
                    }}
                  >
                    <TimerOffIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
            {!params.row?.enabled ? (
              <Tooltip title="Enable">
                <span>
                  <IconButton
                    aria-label="enable"
                    className={classes.icon}
                    color="primary"
                    disabled={params.row?.enabled}
                    onClick={() => {
                      setRestoreOpen(true);
                      setScheduleTaskId(params.row.id);
                    }}
                  >
                    <TimerIcon />
                  </IconButton>
                </span>
              </Tooltip>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      {selectedVenue !== null ? (
        <>
          <AlertDialog open={deleteOpen} contentText={`Do you want to disable this scheduled task?`}
            onNoButtonClicked={() => {
              setDeleteOpen(false);
            }}
            onYesButtonClicked={(e) => {
              handleDisableTask(scheduleTaskId);
              setDeleteOpen(false);
            }} />
          <AlertDialog open={restoreOpen} contentText={`Do you want to enable this scheduled task?`}
            onNoButtonClicked={() => {
              setRestoreOpen(false);
            }}
            onYesButtonClicked={(e) => {
              handleRestoreTask(scheduleTaskId);
              setRestoreOpen(false);
            }} />
          <Grid container direction="row" justifyContent="space-between">
            <Typography variant="h4">Scheduled tasks</Typography>
            <Button
              variant="contained"
              component={TextLink}
              to="/add-new-task"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Grid>

          <div style={{ display: "flex", width: "100%", height: "100%", paddingTop: 15 }}>
              <div style={{ flexGrow: 1 }}>
              <Card style={{ overflow: 'auto' }}>
                <CardContent>
              <DataGrid
                autoHeight
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[5,10,20]}
                sortModel={sortModel}
                onSortModelChange={(model) => setSortModel(model)}
                sortingOrder={['desc','asc']}
                columns={columns}
                rows={
                  tasks === undefined || (loading && loading.tasksLoading) ? [] : tasks
                }
                loading={loading && loading.tasksLoading}
                components={{
                  LoadingOverlay: DataGridLoadingOverlay,
                }}
                  />
                  </CardContent></Card>
            </div>
          </div>
        </>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  );
};

export default ScheduledTasks;
