import { Box } from "@material-ui/core";
import {widgetTypeConstants } from "../../_constants";
import { WidgetWrapper } from "./WidgetWrapper";
import devconsole from "../_common/devconsole";

const WidgetSampleContent = (props) => {
    const {venueId, widgetId, data, viewMode, width, height} = props;

    devconsole.log('WidgetSampleContent', data);
    return (
        <Box>
            <Box>widgetId: {widgetId}</Box>
            <Box>venueId: {venueId}</Box>
            <Box>viewMode: {viewMode}</Box>
            <Box>width: {width}</Box>
            <Box>height: {height}</Box>
        </Box>
    );
}

export const WidgetSample = (props) => {
    const {widgetId, viewMode, width, height, onDelete} = props;

    return (
        <WidgetWrapper
            widgetType={widgetTypeConstants.widgetSample.id}
            widgetId={widgetId} 
            viewMode={viewMode}
            width={width} 
            height={height}
            bgcolor="whitesmoke"
            border="1px solid lightgray"
            onDelete={onDelete}
        >
            <WidgetSampleContent />
        </WidgetWrapper>
    );
}

export default WidgetSample;
