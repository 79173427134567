import axios from "axios";
import { AuthService} from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/VenueTwilio`,
};

const authService = new AuthService();

export async function getTwilioApiSettings(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/GetTwilioApiSettings/${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function updateTwilioPhoneNumber(venueId, twilioPhoneNumber) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = {
      venueId: venueId,
      twilioPhoneNumber: twilioPhoneNumber
    };

    return await axios
      .put(`${constants.api}/UpdateTwilioPhoneNumber`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

export async function updateTwilioMessagingServiceSid(venueId, twilioMessagingServiceSid) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = {
      venueId: venueId,
      twilioMessagingServiceSid: twilioMessagingServiceSid,
    };

    return await axios
      .put(
        `${constants.api}/UpdateTwilioMessagingServiceSid`,
        formData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

export async function updateTwilioIsUsingPhoneNumber(venueId, twilioIsUsingPhoneNumber) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = {
      venueId: venueId,
      twilioIsUsingPhoneNumber: twilioIsUsingPhoneNumber,
    };

    return await axios
      .put(`${constants.api}/UpdateTwilioIsUsingPhoneNumber`, 
          formData, 
          options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};
export async function updateTwilioAccountSid(accountSid) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = {
       accountSid: accountSid
    };

    return await axios
      .put(`${constants.api}/UpdateTwilioAccountSid`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

export async function updateTwilioAuthToken(authToken) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = {
      authToken: authToken
    };

    return await axios
      .put(`${constants.api}/UpdateTwilioAuthToken`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};

// Returns a string that contains message ID.
export async function testSms(venueId, phoneNumber) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = {
      venueId: venueId,
      phoneNumber: phoneNumber
    };

    return await axios
      .put(`${constants.api}/TestSms`, 
        formData,
        options)
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
};
