import React, { createContext, useState } from "react";
export const WidgetContext = createContext();

export default WidgetContext;

export const WidgetContextProvider = ({ children }) => {
    const [captionColor, setCaptionColor] = useState("rgba(98, 126, 132, 1)");

    const contextData = {
        captionColor,
        setCaptionColor,
    };

    return (
        <WidgetContext.Provider value={contextData}>
            {children}
        </WidgetContext.Provider>
    );
};