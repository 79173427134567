import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import {
    Box,
    Tab,
    Typography,
    Card,
    CardContent,
} from "@material-ui/core";
import UserInfo from './userInfo/UserInfo';
import ChangePassword from './userInfo/ChangePassword';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTabs-indicator': {
            backgroundColor: theme.palette.color.primary.main,
        },
    },
    tabLabel: {
        color: theme.palette.color.primary.dark,
        fontWeight: "400",
        padding: "0px 36px 0px 15px"
    },
    activeTabLabel: {
        fontWeight: "400",
        color: theme.palette.color.primary.main,
        padding: "0px 36px 0px 15px"
    }
}));

export const AccountSettings = () => {
    const classes = useStyles();

    const selectedTabIndex = useSelector((state) => state.venueReducer.selectedTab);

    const [selectedTab, setSelectedTab] = useState(selectedTabIndex ?? "1");

    const handleTabSelectionChange = (e, selected) => {
        setSelectedTab(selected);
    };

    return (
        <>
            <Box>
                <Typography variant="h4">
                    Account settings
                </Typography>
                <br></br>

                <Card>
                    <CardContent>
                        <TabContext value={selectedTab}>
                            <Box>
                                <TabList className={classes.root} onChange={handleTabSelectionChange}>
                                    <Tab label={<div className={selectedTab === "1" ? classes.activeTabLabel : classes.tabLabel}>USER INFO</div>} value="1" />
                                    <Tab label={<div className={selectedTab === "2" ? classes.activeTabLabel : classes.tabLabel}>CHANGE PASSWORD</div>} value="2" />
                                </TabList>
                            </Box>
                            <TabPanel value="1">
                                <UserInfo />
                            </TabPanel>
                            <TabPanel value="2">
                                <ChangePassword />
                            </TabPanel>
                        </TabContext>
                    </CardContent>
                </Card>
            </Box>
        </>
    )
}

export default AccountSettings;