import React from "react";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { Typography, Box } from "@material-ui/core";

const OccupancyPercentage = (props) => {
    const { data, viewMode, classes, textColor, height, settings } = props;

    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    const percentage = isViewMode ? Math.round(data?.percentageValue ?? 0) : 0;

    const location = data?.location ?? settings?.RefLocation;
    const baseLineLocation = data?.baseLineLocation ?? settings?.RefBaseLineLocation;

    const locationCustomName = settings?.WidgetSettingCustomName;
    const baseLineLocationCustomName = settings?.WidgetSettingBaseLineCustomName;

    return (
        <Box display="flex" height={height}>
            <Box className={classes.containerBox}>
                <Typography className={classes.commonLayoutName} style={{ color: textColor }}>
                    {!locationCustomName ? location?.name : locationCustomName}
                </Typography>

                <Typography className={classes.percentageValue} style={{ color: textColor }}>
                    {Number.isFinite(percentage) ? `${percentage?.toLocaleString("en-US")}%` : "N/A"}
                </Typography>

                <Box className={classes.percentageSubTitleContainer} >
                    <Typography
                        className={classes.commonLayoutName}
                        style={{
                            color: textColor,
                            opacity: "0.5",
                            marginRight: "8px",
                        }}
                    >
                        of
                    </Typography>

                    <Typography className={classes.commonLayoutName} style={{ color: textColor }}>
                        {!baseLineLocationCustomName ? baseLineLocation?.name : baseLineLocationCustomName}
                    </Typography>
                </Box>
            </Box>
        </Box >
    );
}

export default OccupancyPercentage;