import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  IconButton, 
  MenuItem, 
  Modal,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import ClearIcon from "@material-ui/icons/Clear";
import {Button, ButtonType} from "../../../../_common/htmlTags";
import {getMetrics, getIndicatorMetrics, getDataSourcePoints, getDataSourcePointLocationMetrics, replaceDataSourcePointLocationMetrics} from "../../../../../_services/dataSourcePoint.service";
import { NIL as NIL_UUID } from "uuid";
import {toast} from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";

const useStyles = makeStyles((theme) => ({
  modal: {
    width: "800px",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(3)
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
  },
  subTitle: {
    fontSize: "16px",
    fontWeight: 400,
    color: theme.palette.color.secondary.main
  },
  closeIcon: {
    marginTop:"-20px",
    marginRight: "-20px"
  },
  addButton: {
    alignSelf: "flex-end",
    marginTop: "-20px"
  },
  gridHeaderBackground: {
      background: theme.palette.common.gridColumnHeaderBackground,
      marginTop: theme.spacing(1),
      //marginBottom: theme.spacing(2)
  },
  gridHeaderText: {
    marginLeft: theme.spacing(3),
    color: theme.palette.color.primary.dark,
    fontWeight: 500,
    fontSize: 14
  },
  dataSourceItemPrimary:{
    flexGrow: 1
  },
  dataSourceItemSecondary: {
    color: theme.palette.color.secondary.main
  },
  deleteIcon: {
    color:  theme.palette.color.danger.main,
    padding: "12px",
    margin: theme.spacing(1)
  },
  toggleButtonGroupRoot: {
    background: theme.palette.color.primary.background,
    height: "30px",
    borderRadius: "15px",
    "& .Mui-selected": {
      borderRadius: "15px"
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:first-child" :{
      marginLeft: "0",
      paddingLeft: "15px",
      marginRight: "-15px",
      paddingRight: "30px",
      zIndex: 0,
      borderRadius: "15px",
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:first-child.Mui-selected" :{
      marginLeft: "0",       
      paddingLeft: "15px",
      marginRight: "0",
      paddingRight: "15px",
      zIndex: 1,
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:last-child" :{
      marginLeft: "-15px",
      paddingLeft: "30px",
      marginRight: "0px",
      paddingRight: "15px",
      zIndex: 0,
      borderRadius: "15px",
    },
    "& .MuiToggleButtonGroup-groupedHorizontal:last-child.Mui-selected" :{
      marginLeft: "0",
      paddingLeft: "15px",
      marginRight: "0",
      paddingRight: "15px",
      zIndex: 1
    }

  },
  toggleButtonRoot: {
    textTransform: "none",
    fontWeight: 500,
    borderRadius: "15px",
    paddingTop: "5px",
    paddingBottom: "5px",
    color: theme.palette.color.primary.main,
    background: "transparent",
    borderColor: "transparent",
    marginLeft: "-15px",
    marginRight: "-15px",
    "&.Mui-selected" : {
      //background: theme.palette.common.toggleButtonBackground,
      background: "white",
      color: theme.palette.color.primary.main,
      borderColor: theme.palette.color.primary.main,
      "&:hover": {
        background: theme.palette.common.panelBorderColor
      }
    }
  },
  selectDataSourceControl: {
    marginLeft: theme.spacing(4),
    width: "550px"
  },
  saveCancelButtonBox: {
    marginTop: theme.spacing(2)
  }
}));

const trafficInOutIndicatorId = '8cf5aa8b-5a6e-460e-bdc0-7b537145bdc4';
const seeTicketsCountIndicatorId = 'a91441a2-9383-400a-8d06-f457dca7e5ec';
const todayBusinessHoursAggregationTypeId = '8811a173-c306-45dd-90ff-b2ada6d84388';
const occupancyMetricId = '1e78431e-3915-4d3a-8143-a388f06138a4';

export const CalculatedOccupancySettings = ({onSave, onCancel, venueId, locationId, vendorId, indicatorId, vendors, indicatorVendorMappings}) => {
  const classes = useStyles();

  const [dataSourceItems, setDataSourceItems] = useState([]);
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // indicates the initial loading of data source points, metrics, indicator metrics and data source point location metric links.

  useEffect(() => {
    async function getData() {
      const [metricsResponse, indicatorMetricsResponse, dataSourcePointsResponse, dataSourcePointLocationMetricsResponse] = await Promise.all([
        await getMetrics(),
        await getIndicatorMetrics(),
        await getDataSourcePoints(venueId),
        await getDataSourcePointLocationMetrics(venueId)
      ]);

      const metrics = metricsResponse.data;
      const indicatorMetrics = indicatorMetricsResponse.data;
      const dataSourcePoints = dataSourcePointsResponse.data;

      const newDataSourceItems = [];
      dataSourcePoints.forEach(dataSourcePoint => {
        // Out of all data source points that belong to the current venue, we need to selected
        // only those that has the vendor linked to Traffic In/Out or See Tickets Count indicators.
        // For each of such data source points we should add one or many items in the drop-down
        // list in the "Data source" column, one item for each metric linked to the indicator.
        const vendor = vendors.find(v => v.id == dataSourcePoint.vendorId);
        if (!vendor) return;

        const indicatorVendorMapping = indicatorVendorMappings.find(it => it.vendorId == dataSourcePoint.vendorId
          && (it.indicatorId == trafficInOutIndicatorId || it.indicatorId == seeTicketsCountIndicatorId));

        if (!indicatorVendorMapping) return;

        const foundIndicatorMetrics = indicatorMetrics.filter(it => it.operationalIndicatorId == indicatorVendorMapping.indicatorId);
        foundIndicatorMetrics.forEach( foundIndicatorMetric => {
          const metric = metrics.find(it => it.id == foundIndicatorMetric.metricId);
          if (!metric) return;

          newDataSourceItems.push({
            dataSourcePointId: dataSourcePoint.id,
            vendorName: vendor.name,
            metricId: metric.id,
            metricName: metric.name,
            locationName: dataSourcePoint.name,
          });
        });
      });
      setDataSourceItems(newDataSourceItems);
      
      // Filter all DataSourcePOintLocationMetrics by the current locationId.
      let loadedRows = dataSourcePointLocationMetricsResponse.data.filter(it => it.locationId == locationId);
      if (loadedRows.length == 0) {
        loadedRows.push({
          id: NIL_UUID,
          metricAggregationTypeId: todayBusinessHoursAggregationTypeId,
          isIncrement: true,
          dataSourceId: '', // `${NIL_UUID}|${NIL_UUID}`,
          dataSourcePointId: NIL_UUID,
          dataSourcePointMetricId: NIL_UUID,
          locationId: locationId,
          locationMetricId: occupancyMetricId
        });
      }
      setRows(loadedRows);
      setIsLoading(false);
    };

    getData();
  }, []);

  const handleAddRowClick = () => {
    const newRows = [
      ...rows,
      {
        id: NIL_UUID,
        metricAggregationTypeId: todayBusinessHoursAggregationTypeId,
        isIncrement: true,
        dataSourceId: '', // `${NIL_UUID}|${NIL_UUID}`,
        dataSourcePointId: NIL_UUID,
        dataSourcePointMetricId: NIL_UUID,
        locationId: locationId,
        locationMetricId: occupancyMetricId
      }
    ];
    //setScrollToLastRow(true);
    setRows(newRows);
  };

  // When a new row has been added, we need to scroll into view.
  const [scrollToLastRow, setScrollToLastRow] = useState(false);
  const scrollRef = useRef(null);
  const lastRowSelectRef = useRef(null);

  useEffect(() => {
    if (scrollToLastRow) {
      if (scrollRef.current) {
        scrollRef.current.scrollIntoView({ behaviour: "smooth" });
      }
      if (lastRowSelectRef.current) {
        lastRowSelectRef.current.focus();
      }
      setScrollToLastRow(false);
    }
  }, [scrollToLastRow]);

  const handleRowDeleteClick = (index) => {
    setRows(prev => {
      return prev.filter((prevRow, i) => i != index);
    });
  };

  const handleRowIsIncrementChange = (index, newIsIncrementValue) => {
    setRows(prev => {
      return prev.map((prevRow, i) => {
        if (i == index) {
          return {
            ...prevRow,
            isIncrement: newIsIncrementValue == "+"
          }
        } else {
          return prevRow;
        }
      });
    });
  };

  const handleDataSourceChange = (index, newDataSourceId) => {
    setRows(prev => {
      return prev.map((prevRow, i) => {
        if (i == index) {
          return {
            ...prevRow,
            dataSourceId: newDataSourceId,
            dataSourcePointId: newDataSourceId.split('|')[0],
            dataSourcePointMetricId: newDataSourceId.split('|')[1]
          }
        } else {
          return prevRow;
        }
      });
    });
  };

  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async (e) => {
    e.preventDefault();
    setIsSaving(false);

    rows.forEach((row, i) => {
      if(row.dataSourceId == '') {
        toast.error(`Data source is not selected in row ${i+1}`, {autoClose: false});
        return;  
      }
    });

    replaceDataSourcePointLocationMetrics(venueId, locationId, indicatorId, rows)
      .then(result => {
        setIsSaving(false);
        onSave();
        toast.success("Calculated metric settings saved successfully.");
      })
      .catch((error) => {
        setIsSaving(false);
        toast.error("Failed to save calculated metric settings. " + error, {autoClose: false});
      });
  };

  const renderedRows =  rows.map((row, index) => {
    const isLastRow = (rows.length - 1 === index );
    const renderedDataSourceItems = dataSourceItems.map(it => {
      return (
        <MenuItem
          key={`${it.dataSourcePointId}|${it.metricId}`}
          value={`${it.dataSourcePointId}|${it.metricId}`}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%"
            }}
          >
            <Typography className={classes.dataSourceItemPrimary}>{it.locationName}</Typography>
            <Typography className={classes.dataSourceItemSecondary}>{`${it.metricName} | ${it.vendorName}`} </Typography>
          </Box>
        </MenuItem>
      )
    });

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center"
        }}
        key={index}
        ref={isLastRow ? scrollRef : null}
      >
        <ToggleButtonGroup
          value={row.isIncrement ? "+" : "-"}
          exclusive
          onChange={(event, newValue) => handleRowIsIncrementChange(index, newValue)}
          classes={{
            root: classes.toggleButtonGroupRoot
          }}
        >
          <ToggleButton 
            value="+"
            aria-label="left aligned"
            classes={{
              root: classes.toggleButtonRoot
            }}
          >
            +
          </ToggleButton>
          <ToggleButton 
            value="-" 
            aria-label="centered"
            classes={{
              root: classes.toggleButtonRoot
            }}
          >
            –
          </ToggleButton>
        </ToggleButtonGroup>

        <FormControl
          variant="outlined"
          className={classes.selectDataSourceControl}
        >
          <Select
            value={`${row.dataSourcePointId}|${row.dataSourcePointMetricId}`}
            onChange={(e) => {
              const newDataSourceId = e.target.value;
              handleDataSourceChange(index, newDataSourceId);
            }}
            classes={{
              //select: classes.selectUserGroup
            }}
            variant="outlined"
            inputRef={isLastRow? lastRowSelectRef : null}
          >
            {renderedDataSourceItems}
          </Select>
        </FormControl>

        <Tooltip title={"Remove data source"} >
          <IconButton 
            edge="end" 
            aria-label="Remove"
            className={classes.deleteIcon}
            onClick={() => handleRowDeleteClick(index)}
          >
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </Box>
    );
  });

  return (
    <Modal open={true}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "300px",
          maxHeight: "920px",
          alignItems: "stretch"
        }}
        className={classes.modal}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start"
          }}
        >
          <Box
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column"
            }}
          >
          <Typography className={classes.title}>
            Calculated metric
          </Typography>
          <Typography className={classes.subTitle}>
            Occupancy
          </Typography>
          </Box>
          <Tooltip 
            title="Close" 
          >
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                onCancel();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Box>

        <Button
          className={classes.addButton}
          buttonType={ButtonType.TopBarPrimary}
          variant="contained"
          color="primary"
          size="small"
          startIcon={<AddIcon />}
          onClick={handleAddRowClick}
        >
          Add
        </Button>

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            height: "37px",
            alignItems: "center"
          }}
          className={classes.gridHeaderBackground}
        >
          <Typography 
            className={classes.gridHeaderText}
            style={{width: "85px"}}
          >
            OPERATOR
          </Typography>
          <Typography 
            className={classes.gridHeaderText}
            style={{width: "500px"}}
          >
            DATA SOURCE
          </Typography>
        </Box>

        {isLoading ? (
          <LoadPleaseWait show={true} />
        ): null}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            minHeight: 0,
            overflowY: "auto",
            maxHeight: "220px",
            overflow: "auto",
            overflowX: "hidden",
            ml: "16px",
            flexGrow: 1
          }}
        >
          {renderedRows}
        </Box>

        <div className={classes.saveCancelButtonBox}>
          <Button
            variant="contained"
            type="submit"
            disabled={isSaving}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            style={{
              padding: "10px",
              marginRight: "10px",
              width: "100px"
            }}
          >
            {isSaving ? "Saving..." : "Save"}
          </Button>&nbsp;
          <Button
            className="btn primary"
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              onCancel();
            }}
            style={{
              padding: "9px",
              width: "100px"
            }}
          >Cancel</Button>
        </div>
      </Box>
    </Modal>
  )
};

export default CalculatedOccupancySettings;
