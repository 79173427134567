import React, { useState } from 'react';
import { WidgetTypes } from "./WidgetTypes";
import { DraggableWidgetCollapsed  } from "./DraggableWidgetCollapsed";
import AddIcon from "@material-ui/icons/Add";
import { makeStyles } from "@material-ui/core/styles";
import { Button, ButtonType } from "../../../_common/htmlTags";
import { toast } from "react-toastify";
import devconsole from "../../../_common/devconsole";

const useStyles = makeStyles((theme) => ({
  panel: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panel },
  panelTitle: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitle },
  panelTitleCollapseIconCenter: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitleCollapseIcon, textAlign: "center" },
  panelTitleCollapseIconLeft: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitleCollapseIcon, textAlign: "left" },
  panelTitleText: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelTitleText },
  widgetListPanel: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.widgetListPanel },
  panelFooterMaximize: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelFooterMaximize },
  panelFooterMinimize: { ...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.panelFooterMinimize },
  widgetTypeIconStyle: {...theme.dashboard.gridLayoutDashboard.widgetTypesPanel.widgetTypeIconStyle },
}));

export const WidgetTypesPanelCollapsed = ({ onSetDroppedWidgetType = (widgetType) => {},
                                            isDraggable = false,
                                            onAddingNewWidget = (widgetType) => {}, 
                                            onExpandClick = () => {} }) => {
  const classes = useStyles();
  const [selectedWidgetType, setSelectedWidgetType] = useState("");
  const onSelected = (widgetType) => {
    setSelectedWidgetType(widgetType);
  }
  
  return (
    <div className={classes.panel} >
      <div className={classes.panelTitle} >
        <div className={classes.panelTitleCollapseIconCenter}><img src={"../../../../../icons/MenuOpen.svg"} alt={"Expand panel"} title={"Expand panel"} onClick={(e) => { onExpandClick(); }} /></div>
        {/*<div className={classes.panelTitleText}>choose widget</div>*/}
      </div>
      <div className={classes.widgetListPanel} >
        {WidgetTypes.map((w) => (
          <DraggableWidgetCollapsed            
            key={`drag_${w.id}`}
            isDraggable={isDraggable}
            onSetDroppedWidgetType={onSetDroppedWidgetType}
            iconSrc={w.iconSrc}
            widgetCaption={w.caption}
            widgetDescription={w.description}
            widgetType={w.id}
            isSelected={w.id === selectedWidgetType}
            onSelected={onSelected}
          />
        ))}
      </div>
      <div className={classes.panelFooterMinimize} >
        <Button
          className={classes.addButton}
          buttonType={ButtonType.Primary}
          variant="contained"
          onClick={() => {
            //alert('add widget');
            devconsole.log("adding widget.... " + selectedWidgetType);
            //onSetDroppedWidgetType(selectedWidgetType);
            if (selectedWidgetType !== "")
              onAddingNewWidget(selectedWidgetType);
            else
              toast.error("Please select a widget to be added!", { autoClose: false });
          }}
        ><AddIcon /></Button>
      </div>
    </div>
  );
};