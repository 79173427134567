import React , { useEffect, useState } from 'react';
import LoadSpinner from './Loader.js';


function LoadPleaseWait(props) {
  const show = props.show;
  const [isLoading, setIsLoaded] = useState(false);
  useEffect(() => {
    (show ?? false) === true ? setIsLoaded(true) : setIsLoaded(false);  
  }, [isLoading, show]);

  return ( 
   <>
    <div>     
      {isLoading ? <LoadSpinner text={ (props.text ?? "") === "" ? "Loading...." : props.text }  /> : props.children }  
    </div>   
    </>
  );
  }
  
  export default LoadPleaseWait;

