import { createSvgIcon } from "@material-ui/core/utils";

export const icon = {
    path: process.env.PUBLIC_URL + '/icons/'
};

export const template = {
    path: process.env.PUBLIC_URL + '/template/'
};

// --------------------------------------- ADDITIONAL ICONS ---------------------------------------
// Below are contained icons which are supposed to be used as MUI icons, but there are no such icons in MUIv4
export const PinIcon = createSvgIcon(
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16 12V4H17V2H7V4H8V12L6 14V16H11.2V22H12.8V16H18V14L16 12Z"
        fill="currentColor"
      />
    </svg>,
    'PinIcon',
  );

export default icon;