import React from "react";
import { Tooltip } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    infoIcon: {
        color: theme.palette.color.primary.main,
        height: "21px",
        width: "21px",
        marginLeft: 5,
    },
    popoverLikeTooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: "0px 0px 4px 0px #00000040",
        width: "200px",
    },
    popoverLikeTooltipArrow: {
        "&::before": {
            backgroundColor: theme.palette.common.white,
            boxShadow: "0px 0px 4px 0px #00000040",
        }
    },
}));

export const InformationTooltip = ({ title }) => {
    const classes = useStyles();

    return (
        <Tooltip
            title={title}
            arrow
            placement="top-start"
            classes={{
                tooltip: classes.popoverLikeTooltip,
                arrow: classes.popoverLikeTooltipArrow
            }}
        >
            <InfoOutlinedIcon className={classes.infoIcon} />
        </Tooltip>
    );
};

export default InformationTooltip;