import React, { useState } from "react";
import {
  Box,
  Tab,
  Tabs,
  Typography,
  Card,
  CardContent, } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import EditUserForm from "./EditUserForm";
import ChangePassword from "./ChangePassword";
import { useParams  } from "react-router";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: '1px solid #BCCBCE',
    alignItems: "flex-end",
    '& .MuiTabs-indicator': {
      backgroundColor: "transparent", 
    },
  },
  cardRoot: {
    display: "flex",
    flexDirection: "column",
    minHeight: 0,
    marginBottom: theme.spacing(3)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
  },
  tabLabel: {
    color: "#031820",
    fontWeight: "400",
    padding: "0px 20px 12px 20px",
    marginBottom: "-11px",
  },
  activeTabLabel: {
    fontWeight: "400",
    color: theme.palette.color.primary.main,
    padding: "0px 20px 10px 20px",
    marginBottom: "-11px",
    borderColor: theme.palette.color.primary.main,
    borderBottom: '2px solid',
  },
  
}));

const EditUser = () => {
  const classes = useStyles();
  const { id } = useParams();
  
  const [selectedTab, setSelectedTab] = useState("userinfo");


  return (
    <>
      
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0
        }}>
        <Typography variant="h4">
          User details
        </Typography>
        <br></br>
        <Card
          className={classes.cardRoot}        
        >
          <CardContent
            className={classes.cardContent}>
            <TabContext value={selectedTab}>
              <Box>
                <Tabs value={selectedTab} classes={{ root: classes.root }} onChange={(e, selected) => { setSelectedTab(selected) }} >
                  <Tab
                    label={<div className={selectedTab === "userinfo" ? classes.activeTabLabel : classes.tabLabel}>USER INFO</div>}
                    value="userinfo"
                  />
                  <Tab
                    label={<div className={selectedTab === "password" ? classes.activeTabLabel : classes.tabLabel}>CHANGE PASSWORD</div>}
                    value="password"
                  />
                </Tabs>
              </Box>
              <TabPanel value="userinfo">
                <EditUserForm userId={id} />
              </TabPanel>              
              <TabPanel value="password">               
                  <ChangePassword userId={id} />                
              </TabPanel>
            </TabContext>
          </CardContent>
        </Card>
      </Box>
    



    </>
  );
};

export default EditUser;
