import React from "react";
import {useEffect, useState} from 'react';
import {Box} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {useApiGet} from "../../_helpers/useApiGet";
import {useResizeObservedRef} from "../../_helpers/useResizeObservedRef";
import {getMap} from "../../_services/map.service";
import { parsePolygonSvg } from "./PolygonEditor";

const useStyles = makeStyles( theme => { 
  return {
    hiddenLayout : {
      visibility: "hidden",
      maxWidth: 0,
      maxHeight: 0
    },
    ...theme.map.colorMap,
  };
});

// The ColorMap component expects a map object like this (only properties that are actually used are shown here):
// {
//   layoutBlobUrl: "blob:http://localhost:3000/efb2d685-15d5-4b61-8e82-4486b281f77b"
//   overlays: [
//     {
//       locationId: "527e7b2d-cf90-4a5e-90e7-4a4352f44cb3", // This property is needed to support clicking and selecting locations on the map.
//       markup: "<polygon points=\"134,109 236,128 182,205\" />",
//       color: "{value: '#BFA6D2', flash: true}"   // If the color property is not set, the location will appear in the default (blue-ish) color on the map.
//       capacity: "green",   // Or "amber", or "red". This property is supported but only if color is not specified.
//     },
//     ..... // more overlays here.
//  ]
//  venueId: "24ea7b27-c338-4908-aa5c-83cf79e43296",
//  name: "",
//  layout: null,
//  layoutBlobUrl: null,
//  overlays: []
// }
export const ColorMap = ({map, selectedLocationId, onLocationSelected, onAudioPlay}) => {
  // To convert from pixels in the viewport to the pixels in <svg> viewBox.
  // Example: if we want to see a circle of radius 5px on the screen,
  // then we need to draw a circle with radius 5*scaleFactor in the <svg>.
  const [scaleFactor, setScaleFactor] = useState(0);
  const [playSound, setPlaySound] = useState(false);

  const classes = useStyles({scaleFactor});

  // This is the natural size of the map image. 
  // In our SVG, this is the width and height of the viewBox (the viewBox left and top are zeros).
  const [layoutSize, setLayoutSize] = useState({
    height: 0,
    width: 0
  });

  const onHiddenLayoutLoad = (e) => {
    const {naturalHeight, naturalWidth} = e.target;
    setLayoutSize({
      height: naturalHeight,
      width: naturalWidth
    });
  };

  const [viewPortSize, setViewPortSize] = useState({
    width: 0,
    height: 0,
  });

  // This viewPortRef is a function that is used as a ref for the div that contains the "map preview".
  // We use the width and the height of this div to calculate scaleFactor.
  const viewPortRef = useResizeObservedRef(({width, height}) => {
    setViewPortSize({width, height});
  });

  // Re-calculate scaleFactor when the viewport or the viewBox of the <svg> element changes.
  useEffect(() => {
    if (layoutSize.width > 0 && layoutSize.height > 0 
      && viewPortSize.width > 0 && viewPortSize.height > 0 ) {
      //const {width: viewPortWidth, height: viewPortHeight} = viewPort.getBoundingClientRect();
      const viewPortAspectRatio = viewPortSize.width / viewPortSize.height;
      const viewBoxAspectRatio = layoutSize.width / layoutSize.height;
      let scaleFactor = 0;
      if (viewPortAspectRatio > viewBoxAspectRatio ) {
        scaleFactor = layoutSize.height / viewPortSize.height;
      } else {
        scaleFactor = layoutSize.width / viewPortSize.width;
      }
      setScaleFactor(scaleFactor);
    }
  }, [layoutSize, viewPortSize]);

useEffect(() => {
    setPlaySound(map?.overlays?.findIndex(it => it.sound) >= 0);
}, [map?.overlays, setPlaySound]);

useEffect(() => {
    if (playSound) {
        onAudioPlay();
    }
}, [playSound, onAudioPlay]);

  const renderedOverlays = map && map.overlays ? 
    map.overlays.map((overlay) => {
      const points = parsePolygonSvg( overlay.markup );
      let capacityClass = "";
      switch (overlay.capacity) {
        case "green": capacityClass = classes.greenPolygonAddendum; break;
        case "amber": capacityClass = classes.amberPolygonAddendum; break;
        case "red": capacityClass = classes.redPolygonAddendum; break;
        default: capacityClass = ""; break;
      }

      const overlayColor = overlay?.color ?? {};

      return (
        <g
          key={overlay.id}>
          <polygon 
            className={`${classes.polygon} ${overlayColor.flash ? classes.flashPolygonAddendum: ''} ${capacityClass} ${selectedLocationId && overlay.locationId !== selectedLocationId ? classes.nonSelectedLocationAddendum : ""}`}
            style={overlayColor.value? {fill: overlayColor.value, stroke: overlayColor.value} : null}
            points={points}
            onMouseDown={() => onLocationSelected(overlay.locationId)}
          />
        </g>
      );
    }) : null;

  return (
    <Box
      width="100%"
      height="100%"
      ref={viewPortRef}
      >
      <img 
        alt="Underlying map"
        src={map?.layoutBlobUrl} 
        className={classes.hiddenLayout} 
        onLoad={onHiddenLayoutLoad} />
      <svg
        width="100%"
        height="100%"
        preserveAspectRatio = "xMidYMid meet"
        viewBox={`0 0 ${layoutSize.width} ${layoutSize.height}`}
        >
          <image 
            href={map?.layoutBlobUrl} 
            x="0" 
            y="0"
            width={layoutSize.width}
            height={layoutSize.height}
            />
          <rect  // This rectangle catches mouse venue when the user clicks outside of any polygon.
            x="0"
            y="0"
            width={layoutSize.width}
            height={layoutSize.height}
            fill="transparent"
            onMouseDown={(e) => onLocationSelected(null)}
          />
          {renderedOverlays}
      </svg>
    </Box>
  );
};

export default ColorMap;


// The code below is test rig for ColorMap compoenent.
// All of that can be safely deleted after the mapping
// has been implemented in the dashboard.

const getMapCall = (mapId) => {
  return getMap(mapId);
};

export const ColorMapTest = () => {
  const mapId = "5289e776-3d10-4373-83af-ed317fab9950";

  const emptyMap = {
    venueId: "",
    name: "",
    layout: null,
    layoutBlobUrl: null,
    overlays: []
  };

  const [map, setMap] = useState(emptyMap);

  const [{data: initialMap, isError: isMapLoadingError}, setGetMapParams] = useApiGet( getMapCall, mapId, emptyMap);

  useEffect(() => {
    setGetMapParams(mapId);
  }, [mapId, setGetMapParams]);

  useEffect(() => {
    if (!isMapLoadingError) {
      setMap({
        ...initialMap,
        overlays: initialMap.overlays.map((overlay, index) => {
          return {
            ...overlay,
            //capacity: "green",
          };
        }),
      });
    }
  }, [initialMap, isMapLoadingError]);

  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [randomOverlay, setRandomOverlay] = useState(0);
  const [randomCapacity, setRandomCapacity] = useState("green");
  const [randomColorJson, setRandomColorJson] = useState(null);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRandomOverlay(Math.random());
      switch (Math.floor(Math.random() * 3)) {
        case 0: setRandomCapacity("green"); break;
        case 1: setRandomCapacity("amber"); break;
        case 2: setRandomCapacity("red"); break;
        default:
          setRandomCapacity("green");
      }
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  },[]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRandomOverlay(Math.random());
      switch (Math.floor(Math.random() * 3)) {
        case 0: setRandomColorJson('{"value":"#7763B6", "flash": true}'); break;
        case 1: setRandomColorJson('{"value":"#BFA6D2", "flash": false}'); break;
        case 2: setRandomColorJson('{"value":"#7E95AA", "flash": true}'); break;
        //case 2: setRandomColorJson('{}'); break;
        default:
          setRandomColorJson(null);
      }
    }, 2000);
    return () => {
      clearInterval(intervalId);
    };
  },[]);

  useEffect(() => {
    setMap(prev => { 
      return {
        ...prev,
        overlays: prev.overlays.map( (overlay, index) => {
          return {
            ...overlay,
            //capacity: index === Math.floor( randomOverlay * prev.overlays.length) ? randomCapacity : overlay.capacity,
            colorJson: index === Math.floor( randomOverlay * prev.overlays.length) ? randomColorJson : overlay.colorJson,
          };
        }),
      };
    });    
  }, [randomOverlay, randomCapacity, randomColorJson]);

  return (
    <ColorMap
      map={map}
      selectedLocationId={selectedLocationId}
      onLocationSelected={setSelectedLocationId}
    />
  );
};

