import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { alpha } from '@material-ui/core';
import PermissionsGate from "../../../_common/permissions/PermissionsGate";
import {SCOPES} from "../../../../_constants/user.permissions.constants";
import { Box, Typography } from '@material-ui/core';
import { icon } from "../../../../_constants";
import AddIcon from "@material-ui/icons/Add";
import {Button} from "../../../_common/htmlTags";
import devconsole from "../../../_common/devconsole";

const useStyles = makeStyles((theme) => ({
  grid: {
    borderStyle: "solid none none solid",
    borderColor: theme.palette.eventCalendar.border,
    borderWidth: "0.5px",
  },
  cell: {
    borderStyle: "none solid solid none",
    borderColor: theme.palette.eventCalendar.border,
    borderWidth: "0.5px",
  },
  headerText: {
    fontWeight: 500,
    fontSize: "18px",
    padding: "10px 0",
  },
  dateText: {
    margin: "8px 16px 8px 16px",
    fontSize: "20px",
    fontWeight: 600,
    color: theme.palette.color.primary.dark
  },
  currentMonthCell: {
    background: "white"
  },
  otherMonthCell: {
    background: alpha(theme.palette.color.primary.background, 0.8),
    "& $dateText": {
      color: alpha(theme.palette.color.secondary.main, 0.5),
      //fontSize: "320px"
    }
  },
  todayCell: {
    background: alpha(theme.palette.color.primary.main, 0.1)
  },
  partBox: {
    zIndex: 2,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "30px",
    backgroundColor: theme.palette.eventCalendar.background,
    overflow: "hidden",
    borderColor: alpha(theme.palette.eventCalendar.text, 0.5),
    borderWidth: "3px",
    boxShadow: "0px 3px 7px 0px #C2C2C240",
  },
  partName: {
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    color: theme.palette.eventCalendar.text
  },
  partNameContainer: {
    minWidth: "5px"
  },
  partNamePadding: {
    flexGrow: 1,
    minWidth: theme.spacing(1)
  },
  prevMonthBreakIcon: {
    marginLeft: "8px",
    marginRight: "16px"
  },
  nextMonthBreakIcon: {
    marginLeft: "16px",
    marginRight: "8px"
  },
  addButton: {
    width: "44px",
    height: "32px",
    padding: 0,
    minWidth: "44px",
    borderRadius: "5px",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  }
}));

function getFirstAvailableRowIndex(rows) {
  for (let rowIndex = 0; rowIndex < rows.length; rowIndex++) {
    if (!rows[rowIndex] ) {
      rows[rowIndex] = true;
      return rowIndex;
    }
  }
}

export const Calendar = ({year, month, items, onItemClick, onItemAdd}) => {
  const classes = useStyles();
  // Sort events by start date.
  const sortedItems = items?.sort((a, b) => a.start.getTime() - b.start.getTime());

  let firstDayOfWeek = 1; // KF: That is Monday. For Sunday it'd be 0.
  try {
    firstDayOfWeek = new Intl.Locale(navigator.language).weekInfo.firstDay; 
  }
  catch (error) {
    devconsole.log("Error when reading the first day of the week.", error);
  }
  
  let daysOfWeek = [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  daysOfWeek = daysOfWeek.slice(firstDayOfWeek).concat( daysOfWeek.slice(0, firstDayOfWeek));

  const firstOfMonth = new Date(year, month - 1, 1); // month here is 1 - January, 2 - February etc. The Date expects 0 - January.
  const calendarStartOffset = (firstOfMonth.getDay() - firstDayOfWeek + 7) % 7;
  const calendarStartDate = new Date();  // This date should appear in the top left corner of the calendar grid.
  calendarStartDate.setTime(firstOfMonth.getTime() - calendarStartOffset * 3600 * 24 * 1000);

  const calendarDays = [];
  const dateCounter = new Date(calendarStartDate.getTime());
  const today = new Date(Date.now());
  let weekCounter = 0;
  while( true) {
    if ((year* 12 + month -1) < (dateCounter.getFullYear() * 12 + dateCounter.getMonth())) {
      break;
    }
    weekCounter++;
    for(let columnIndex = 0; columnIndex < 7; columnIndex++) {
      calendarDays.push({
        week: weekCounter,
        column: columnIndex,
        date: new Date( dateCounter.getTime()),
        otherMonth: dateCounter.getFullYear() !== year || dateCounter.getMonth() !== month -1,
        rows: Array(sortedItems.length).fill(false),
        isToday: dateCounter.getFullYear() === today.getFullYear() 
              && dateCounter.getMonth() === today.getMonth()
              && dateCounter.getDate() === today.getDate()
      });
      dateCounter.setTime( dateCounter.getTime() + 24 * 3600 * 1000);
    }
  }

  const parts = [];
  if (sortedItems) {
    sortedItems.forEach(item => {
      // Check if this item is to be displayed in the current month calendar.
      if (item.end.getTime() < calendarDays[0].date.getTime() || calendarDays[calendarDays.length - 1].date.getTime() + 24 * 3600 * 1000 <= item.start.getTime()) {
        return;
      }

      const prevMonthBreak = item.start.getTime() < calendarDays[0].date.getTime();
      const nextMonthBreak =  calendarDays[calendarDays.length - 1].date.getTime() + 24 * 3600 * 1000 <= item.end.getTime();

      let state = "have not reached the item date range yet";
      let part;
      calendarDays.forEach(calendarDay => {
        if ( state === "have not reached the item date range yet") {
          // Check if the calendarDay is within the item dates.
          if (item.start.getTime() < calendarDay.date.getTime() + 24 * 3600 * 1000) {
            const rowIndex = getFirstAvailableRowIndex(calendarDay.rows);
            part = {
              item: item,
              name: item.name,
              start: new Date(calendarDay.date.getTime()),
              week: calendarDay.week,
              column: calendarDay.column,
              prevMonthBreak: prevMonthBreak,
              nextMonthBreak: false,
              prevWeekBreak: false,
              nextWeekBreak: false,
              itemStart: ( calendarDay.date.getTime() <= item.start.getTime()),
              itemEnd: false,
              length: 1,
              rowIndex: rowIndex,
              calendarDays: [calendarDay]
            };
            parts.push(part);
            state = "within item date range";
          }
        } else if (state === "within item date range") {
          // Check if the calendarDay is still within the item date range.
          if ( calendarDay.date.getTime() <= item.end.getTime()) {
            // Check if the week is the same.
            if (calendarDay.week === part.week) {
              part.length++;
              calendarDay.rows[part.rowIndex] = true;
              part.calendarDays.push(calendarDay);
            } else {
              part.nextWeekBreak = true;
              part = {
                item: item,
                name: item.name,
                start: new Date(calendarDay.date.getTime()),
                week: calendarDay.week,
                column: calendarDay.column,
                prevMonthBreak: false,
                nextMonthBreak: false,
                prevWeekBreak: true,
                nextWeekBreak: false,
                itemStart: false,
                itemEnd: false,
                length: 1,
                rowIndex: part.rowIndex,
                calendarDays: [calendarDay]
              }
              calendarDay.rows[part.rowIndex] = true;
              parts.push(part);
            }
          } else {
            // The calendarDay is not within the item date rage any more.
            state = "completed the item date range";
            part.itemEnd = true;
            //part.nextMonthBreak = nextMonthBreak;
          }
        }        
      });
      if (state === "within item date range") {
        // KF: If we are here, it means that nextMnthBreak is true, but I'll leave it like this.
        part.nextMonthBreak = nextMonthBreak;
      }
    });

    // Remove unused rows from each week.
    for (let week = 1; week <= weekCounter; week++) {
      const partsInWeek = parts.filter(part => part.week === week);
      const rowIndices = partsInWeek.map(it => it.rowIndex).filter((x, i, a) => a.indexOf(x) == i).sort();
      partsInWeek.forEach(part => {
        const newRowIndex = rowIndices.indexOf(part.rowIndex);
        part.rowIndex = newRowIndex;
      });
    }
  }

  return (
    <Box
      sx={{
        display: "grid",
        width: "100%",
        maxWidth: "100%",
        gridTemplateColumns: "14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857% 14.2857%",
        gridTemplateRows: `repeat (${weekCounter}, auto)`,
        gridTemplateAreas: "header header header header header header header"
      }}
      className={classes.grid}
    >
      {daysOfWeek.map((dayOfWeek, index) => {
        return (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gridColumn: `${index + 1} / span 1`,
              gridRow: `${1} / span 1`,
            }}            
            className={classes.cell}
            key={index}
          >
            <Typography className={classes.headerText}>
              {dayOfWeek}
            </Typography>
          </Box>
        );
      })}

      {
        calendarDays.map((calendarDay, index) => {
          return (
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                minHeight: "130px",
                gridColumn: `${calendarDay.column + 1} / span 1`,
                gridRow: `${calendarDay.week + 1} / span 1`,
                zIndex: 1
              }}            
              className={`${classes.cell} ${calendarDay.otherMonth ? classes.otherMonthCell : classes.currentMonthCell} ${calendarDay.isToday? classes.todayCell : ""}`}
              key={index}
            >
              <Typography className={classes.dateText}>
                {calendarDay.date.getDate()}
              </Typography>
              <div style={{flexGrow: 1}} />
              <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_EVENTS]}>
                <Button 
                  variant="contained" 
                  size="small"
                  className={classes.addButton}
                  onClick={() => { onItemAdd(calendarDay.date); }}
                >
                  <AddIcon />
                </Button>
              </PermissionsGate>
            </Box>
          );
        })
      }

      {
        parts.map((part, index) => {
          return (
            <Box
              className={classes.partBox}
              style={{
                gridColumn: `${part.column + 1} / span ${part.length}`,
                gridRow: `${part.week + 1} / span 1`,
                marginTop: `${48 + (30 + 8) * part.rowIndex}px`,
                marginLeft: `${part.prevMonthBreak || part.prevWeekBreak? 0 : 12}px`,
                marginRight: `${part.nextMonthBreak || part.nextWeekBreak? 0 : 12}px`,
                marginBottom: "8px",
                borderStyle: `none ${part.itemEnd? "solid" : "none"} none ${part.itemStart? "solid" : "none"}`,
              }}
              onClick={() => {onItemClick(part.item);}}
              key={index}
            >
              {part.prevMonthBreak ?
                <img 
                  className={classes.prevMonthBreakIcon}
                  alt=""
                  src={icon.path + 'chevron-double-left.svg'} />
                : null
              }
              <div className={classes.partNamePadding}/>
              <Box component="div" className={classes.partNameContainer}>
                <Typography
                  className={classes.partName}>
                  {part.name}
                </Typography>
              </Box>
              <div className={classes.partNamePadding}/>
              {part.nextMonthBreak ?
                <img 
                  className={classes.nextMonthBreakIcon}
                  alt=""
                  src={icon.path + 'chevron-double-right.svg'} />
                : null
              }
            </Box>
          );
        })
      }
    </Box>
  );
};

export default Calendar;
