import WidgetNumberContent from './WidgetNumberContent';
import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";
import { WidgetContextProvider } from '../WidgetContext';

export const WidgetNumber = (props) => (
    <WidgetContextProvider>
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetNumber.id}}
        >
            <WidgetNumberContent />
        </WidgetWrapper>
    </WidgetContextProvider>
);

export default WidgetNumber;