export const LOAD_TASKS_STARTED = 'LOAD_TASKS_STARTED'
export const LOAD_TASKS_SUCCESS = 'LOAD_TASKS_SUCCESS'
export const LOAD_TASK_BY_ID_STARTED = 'LOAD_TASK_BY_ID_STARTED'
export const LOAD_TASK_BY_ID_SUCCESS = 'LOAD_TASK_BY_ID_SUCCESS'
export const LOAD_INDICATORS_SUCCESS = 'LOAD_INDICATORS_SUCCESS'
export const LOAD_VENDORS_SUCCESS = 'LOAD_VENDORS_SUCCESS'
export const LOAD_SCHEDULES_SUCCESS = 'LOAD_SCHEDULES_SUCCESS'
export const LOAD_INDICATORS_WITH_VENDOR_STARTED = 'LOAD_INDICATORS_WITH_VENDOR_STARTED'
export const LOAD_INDICATORS_WITH_VENDOR_SUCCESS = 'LOAD_INDICATORS_WITH_VENDOR_SUCCESS'
export const LOAD_INDICATOR_VENDOR_MAPPINGS_STARTED = 'LOAD_INDICATOR_VENDOR_MAPPINGS_STARTED'
export const LOAD_INDICATOR_VENDOR_MAPPINGS_SUCCESS = 'LOAD_INDICATOR_VENDOR_MAPPINGS_SUCCESS'
export const LOAD_INDICATOR_SCHEDULE_MAPPINGS_STARTED = 'LOAD_INDICATOR_SCHEDULE_MAPPINGS_STARTED'
export const LOAD_INDICATOR_SCHEDULE_MAPPINGS_SUCCESS = 'LOAD_INDICATOR_SCHEDULE_MAPPINGS_SUCCESS'

export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS'
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS'

export const DISABLE_TASK_OPTIMISTIC = 'REMOVE_TASK_OPTIMISTIC'
export const ENABLE_TASK_OPTIMISTIC = 'ENABLE_TASK_OPTIMISTIC'