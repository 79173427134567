import React from "react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Card,
  CardContent
} from "@material-ui/core";
import {Button} from "../_common/htmlTags/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";  
import { DataGrid } from "@material-ui/data-grid";
import { DataGridLoadingOverlay } from '../_common/DataGridLoadingOverlay';
import { toast } from "react-toastify";
import { AlertDialog } from "../../_helpers/AlertDialog";
import MapThumbnail from "./MapThumbnail";
import {useApiGet} from "../../_helpers/useApiGet";
import {getMaps, deleteMap} from "../../_services/map.service";
import {TextLink} from "../_common/htmlTags/TextLink";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";

/* KF - I use this to test ColorMap.
import { ColorMapTest } from "./ColorMap";
*/


const useStyles = makeStyles((theme) => {
  return {
    icon : {
      color: theme.palette.color.primary.dark,
      "&:hover": {
        color: theme.palette.color.primary.main,
      },   
    },
    deleteIcon: {
      color: theme.palette.color.primary.red
    },
    thumbnailBorder: {
      borderColor: theme.palette.map.thumbnail.borderColor,
      borderWidth: "1px",
      borderStyle: "solid"
    }
  };
});

const getMapsCall = async (venueId) => {
  if (venueId ) {
    const result = await getMaps(venueId);
    return result.data;
  } else {
    return [];
  }
};

const deleteMapCall = async (mapId) => {
  const result = await deleteMap(mapId);
  return result;
}

export const Maps = () => {
  const classes = useStyles();

  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );

  // InitialMaps here is the arrya of maps as they are loaded from the database,
  // before the user started adding new maps, deleting or editing existing ones.
  const [{data: initialMaps, isLoading, isError: isLoadingError, errorMessage: loadingErrorMessage}] = useApiGet(getMapsCall, selectedVenue?.id, []);

  const [maps, setMaps] = useState([initialMaps]);
  const [pageSize, setPageSize] = useLocalStorage("pmy-maps-pageSize", 5);
  const [sortModel, setSortModel] = useSortModel("pmy-maps-sortModel", [{field: 'name', sort: 'asc'}]);
  
  useEffect(() => {
    if( !isLoadingError) {
      setMaps(initialMaps);
    }
  }, [initialMaps, isLoadingError]);

  useEffect(() => {
    if( isLoadingError) {
      toast.error("Loading maps failed. " + loadingErrorMessage, { autoClose: false });
    }
  }, [isLoadingError, loadingErrorMessage])

  const [deleteOpen, setDeleteOpen] = useState(false);
  const [mapId, setMapId] = useState(null);

  const onDeleteMap = async (mapId) => {
    try {
      await deleteMapCall(mapId);
      setMaps(prev => {
        return prev.filter(m=> m.id !== mapId );
      });
      toast.success("Map removed");
    } catch (error) {
      toast.error(<div>Removing map failed.<br />{error.message}</div>, { autoClose: false });
    }
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "image",
      headerName: "MAP",
      width: 170,
      sortable: false,
      renderCell: (params) => {
        return (
          // This Box positions the thumbnail inside the grid cell.
          <Box
            component="div"
            width="100%"
            height="100%"
            // bgcolor="yellow"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "stretch",
              padding: "10px",
            }}
            >
              {/* This Box adds the thin grey border to the thumbnail. */}
              <Box
                className={classes.thumbnailBorder}
                sx={{
                  display: "flex",
                  width: "auto",
                  height: "auto",
                  minHeight: 0,
                  flexGrow: 1,
                  alignItems: "stretch"
                }} 
              >
                <MapThumbnail 
                  mapId={params.row.id} />
              </Box>
          </Box>
        );
      }
    },
    {
      field: "name",
      headerName: "NAME",
      flex:1,
      width: 300
    },
    {
      field: "actions",
      headerName: "ACTIONS",
      width: 200,
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                aria-label="edit"
                classes={{
                  root: classes.icon
                }}
                component={Link}
                to={`/EditMap/${params.row.id}`}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
                <span>
                  <IconButton
                    aria-label="delete"
                    className={classes.deleteIcon}
                    color="primary"
                    onClick={() => {
                      setDeleteOpen(true);
                      setMapId(params.row.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </span>
              </Tooltip>
          </>
        );
      },
    },
  ];

  // KF: I use this code to debug my useResizeObserverRef custom hook.
  /* const [rowHeight, setRowHeight] = useState(150);
  useEffect(() => {
    setTimeout(() =>{
      setRowHeight(250);
    }, 10000);
  }, []); */
  return (
    <>
      {selectedVenue !== null ? (
        <div>
          <AlertDialog 
            showTitle
            title="Delete map"
            highPriority
            open={deleteOpen} 
            contentText={`Do you want to delete this map?`}
            onNoButtonClicked={() => {
              setDeleteOpen(false);
            }}
            onYesButtonClicked={(e) => {
              onDeleteMap(mapId);
              setDeleteOpen(false);
            }} />
          <Grid container spacing={2}>
            {/* KF - I use the following Box element for debugging ColorMapTest.*/}
            {/* <Box
              width={700}
              height={300}>
              <ColorMapTest />
            </Box> */}
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="space-between"
            >
              <Typography variant="h4">Maps</Typography>
              <Button
                variant="contained"
                component={TextLink}
                to="/addMap"
                style={{width: "100px", height: "46px"}}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Grid>
            <div style={{ display: "flex", width: "100%", height: "100%", paddingTop: 15 }}>
              <div style={{ flexGrow: 1 }}>
                <Card style={{ overflow: 'auto'}}>
                  <CardContent>
                    <DataGrid
                      autoHeight
                      rowHeight={120}
                      pageSize={pageSize}
                      rowsPerPageOptions={[5, 10, 20]}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      sortModel={sortModel}
                      onSortModelChange={(model) => setSortModel(model)}
                      sortingOrder={['desc','asc']}
                      columns={columns}
                      rows={
                        maps === undefined || (isLoading) ? [] : maps
                      }
                      loading={isLoading}
                      components={{
                        LoadingOverlay: DataGridLoadingOverlay,
                      }}
                    />
                  </CardContent>
                </Card>
              </div>
            </div>
          </Grid>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  );
};

export default Maps;
