import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, InputLabel, Card, CardContent } from "@material-ui/core";
import {
  getLocationTypes,
  getLocationById,
  updateLocation
} from "../../../_actions/venue.location.actions";
import VenueLocationValidator from "../../../_helpers/_venueLocation/venueLocationValidator";
import { useDispatch, useSelector } from "react-redux";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { selectLocationTypes } from "../../../_reducers/selectors";
import { Button, ButtonType } from "../../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  container: {},
  button: {
    margin: theme.spacing(1),
  },
  title: {
    marginBottom: theme.spacing(2),
  }
}));

export const EditLocation = (props) => {
  const locationTypes = useSelector(selectLocationTypes);
  const selectedLocation = useSelector((state) => state.venueLocationReducer.selectedLocation);
  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  //const location = useLocation();
  const { id } = useParams();

  const [currentLocation, setCurrentLocation] = useState({
    name: "",
    //parentId: location.state.parentId,
    venueId: selectedVenue.id,
    description: null,
    locationTypeId: "",
    prevLocationTypeId: "",
    maximumCapacity: "",
  });

  const locationTypesLoaded = locationTypes.length > 0;

  useEffect(() => {
    if (!locationTypesLoaded) {
      dispatch(getLocationTypes()).catch((error) => {
        toast.error("Loading location types failed. " + error, { autoClose: false });
      });
    }

    if (id !== null) {
      dispatch(getLocationById(id)).catch((error) => {
        toast.error("Loading location failed. " + error, { autoClose: false });
      })
    }
  }, [dispatch, id, locationTypesLoaded]);

  useEffect(() => {
    if (selectedLocation !== null) setCurrentLocation(selectedLocation);
  }, [selectedLocation])
  

  const handleSave = async (e) => {
    e.preventDefault();
 
    var validator = new VenueLocationValidator(currentLocation);
    let error = await validator.validate();
 
    if (currentLocation !== {}) {
      if (error !== undefined) {
        if (error.length > 0) {
          toast.error("Cannot update location.\n" + error.map((err) => err), { autoClose: true });
          return;
        }
      }

      dispatch(updateLocation(currentLocation))
        .then(() => {
          if (props.error !== null && typeof props.error !== "undefined")
            toast.error("Updating location failed. " + props.error, { autoClose: false });
          else {
            toast.success("Location updated successfully.");
            history.push("/venueLocations");
          }
        })
        .catch((err) => {
          toast.error("Updating location failed. " + err.response.data, {
            autoClose: false,
          });
        });
    } else {
      toast.error("Please, fill the required fields!", { autoClose: false });
    }
  }

  const handleChange = (venue) => {
    const { name, value } = venue.target;
    setCurrentLocation((prevTask) => ({
      ...prevTask,
      [name]: value,
      prevLocationTypeId: prevTask.locationTypeId,
    }));
  };

  const handleLocationTypeChange = (venue) => {
    // If the location type changed to a sensor, reset Factor to 1.
    const newLocationTypeId = venue.target.value;
    const locationType = locationTypes.find((lt) => lt.id === newLocationTypeId);
    setCurrentLocation((prevTask) => ({
      ...prevTask,
      locationType: locationType,
      prevLocationTypeId: prevTask.locationTypeId,
    }));

    handleChange(venue);
  };

  return (
    <div>
      <Card style={{ overflow: 'auto' }}><CardContent>
        <Typography variant="h4" className={classes.title}>
          Location details
        </Typography>
        <form className={classes.container} onSubmit={handleSave}>
          <Grid container spacing={3}>
            <Grid container item xs={12} spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="locationName"
                  label="Location name"
                  variant="outlined"
                  required
                  fullWidth
                  name="name"
                  value={currentLocation.name ?? ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container item xs={12} spacing={3}>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  id="locationDescription"
                  label="Location description"
                  variant="outlined"
                  name="description"
                  fullWidth
                  value={currentLocation.description || ""}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container item xs={12} spacing={3}>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl
                  variant="outlined"
                  fullWidth
                  required
                >
                  <InputLabel id="role-label">Type</InputLabel>
                  <Select
                    labelId="locationType-label"
                    id="locationType-select"
                    name="locationTypeId"
                    value={currentLocation.locationTypeId ?? ""}
                    onChange={handleLocationTypeChange}
                    label="Type"
                  >
                    {locationTypes.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {currentLocation.locationType && currentLocation.locationType.isSensor ? <div /> :
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={12} md={12}>
                  <TextField
                    id="maximumCapacity"
                    label="Maximum capacity"
                    variant="outlined"
                    required
                    type={"number"}
                    name="maximumCapacity"
                    value={currentLocation.maximumCapacity ?? ""}
                    onChange={handleChange}
                  />
                </Grid>
              </Grid>
            }
            <Grid item xs={12}>
              <Button
                variant="contained"
                //disabled={updating}
                type="submit"
              >
                {/* {updating ? "Updating..." : "Update venue location"} */}Save
              </Button>
              <Button
                variant="outlined"
                className={classes.button}
                component={Link}
                buttonType={ButtonType.Secondary}
                to={"/VenueLocations"}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent></Card>
    </div>
  );
};

export default EditLocation;
