import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {AlertDialog} from "../../../_helpers/AlertDialog";
import {Card, CardContent, Grid, IconButton, Typography,} from "@material-ui/core";
import {Button} from "../../_common/htmlTags";
import AddIcon from "@material-ui/icons/Add";
import {DataGrid} from "@material-ui/data-grid";
import {DataGridLoadingOverlay} from "../../_common/DataGridLoadingOverlay";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {UserGroupPopup} from "./UserGroupPopup";
import { NIL as NIL_UUID} from "uuid";
import {getUserGroups, deleteUserGroup} from "../../../_services/venue.userGroup.service";
import {toast} from "react-toastify";
import useLocalStorage from "../../../_helpers/useLocalStorage";
import useSortModel from "../../../_helpers/useSortModel";

const useStyles = makeStyles((theme) => {
  return {
    deleteIcon: { 
      color: theme.palette.color.primary.red
    },
    editIcon: {
      color: theme.palette.color.primary.dark,
    },
  };
});

export const UserGroups = () => {
  const classes = useStyles();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const [userGroups, setUserGroups] = useState(null);
  const [userGroupToDelete, setUserGroupToDelete] = useState(null);
  const [deletePromptOpen, setDeletePromptOpen] = useState(false);
  const [userGroupToEdit, setUserGroupToEdit] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useLocalStorage("pmy-userGroups-pageSize", 10);
  const [sortModel, setSortModel] = useSortModel("pmy-userGroups-sortModel", [{field: 'name', sort: 'asc'}]);

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
    },
    {
      field: "userIds",
      headerName: "USER COUNT",
      width: 200,
      renderCell: (params) => {
        return (
          <>
            {params.row.userIds.length}
          </>
        );
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      sortable: false,
      width: 120,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                setUserGroupToEdit(params.row);
                setShowPopup(true);
              }}
            >
              <EditIcon fontSize="small" className={classes.editIcon}/>
            </IconButton>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={(e) => {
                setUserGroupToDelete(params.row);
                setDeletePromptOpen(true);
              }}
            >
              <DeleteIcon fontSize="small" className={classes.deleteIcon} />
            </IconButton>
          </>
        )
      }
    },
  ];

  useEffect(() => {
    if (userGroups === null && selectedVenue?.id ) {
      getUserGroups(selectedVenue?.id)
        .then((result) => {
          setIsLoading(false);
          setUserGroups(result.data);
        })
        .catch(error => {
          console.error(error);
          toast.error("Unable to load user groups.", {autoClose: false});
        });
    }
  }, [selectedVenue?.id, userGroups] );

  return (
    <>
      {selectedVenue !== null ? (
        <div>
          <AlertDialog open={deletePromptOpen} contentText={`Do you want to delete this user group?`}
            onNoButtonClicked={() => {
              setDeletePromptOpen(false);
              setUserGroupToDelete(null);
            }}
            onYesButtonClicked={(e) => {
              deleteUserGroup(userGroupToDelete.id).then((result) => {
                setDeletePromptOpen(false);
                setUserGroupToDelete(null);
                setUserGroups(null);//reset grid
              });
            }} />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="space-between"
            >
              <Typography variant="h4">User groups</Typography>
              <Grid
                item
                container
                xs={6}
                md={6}
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={(e) => {
                      setUserGroupToEdit({id: NIL_UUID, venueId: selectedVenue?.id, name: "", userIds: []});
                      setShowPopup(true);
                      }}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ display: "flex", width: "100%", height: "100%", paddingTop: 15 }}>
              <div style={{ flexGrow: 1 }}>
                <Card style={{ overflow: 'auto'}}>
                  <CardContent>
                    <DataGrid
                      autoHeight={true}
                      pageSize={pageSize}
                      onPageSizeChange={(value) => setPageSize(value)}
                      rowsPerPageOptions={[5,10,20]}
                      sortModel={sortModel}
                      onSortModelChange={(model) => setSortModel(model)}
                      sortingOrder={['desc','asc']}
                      columns={columns}
                      rows={
                        (userGroups === null || isLoading) ? [] : userGroups
                      }
                      components={{
                        LoadingOverlay: DataGridLoadingOverlay,
                      }}
                    ></DataGrid>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Grid>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
      {userGroupToEdit? 
        <UserGroupPopup 
          show={showPopup} 
          onCancel={() => { 
            setShowPopup(false);
            setUserGroupToEdit(null);
          }} 
          onSave={() => {
            setShowPopup(false);
            setUserGroups(null); // refresh
            setUserGroupToEdit(null);
          }}
          isDuplicateName={(name) => {
            let duplicate = false;
            if (userGroups) {
              duplicate = userGroups.filter( ug => {
                return (ug.id !== userGroupToEdit.id) && (ug.name.trim().toLowerCase() === name.trim().toLowerCase());
              })?.length > 0;
            }
            return duplicate;
          }}
          userGroup={userGroupToEdit}       
        /> : null }
    </>
  );
};

export default UserGroups;