import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';

// This component shows the browser's prompt dialog, when the "when" 
// prop is true. This is used to prevent the user from navigating
// to another router link or to a completely different page when
// there are unsaved (dirty) changes.
// The rounter navigation is prevented via the <Prompt /> component.
// The browser navigation via address bar is prevented by listening
// to the beforeload event. While the <Prompt /> component shows
// the specifed message, with the beforeload event, we cannot 
// control the message. Everby browser shows its specific message
// if the event.returnValue is true for beforeunload event.
const LeaveBlocker = ({ when, message }) => {
  useEffect(() => {
    if (!when) {
      return () => {};
    }

    const beforeUnloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.addEventListener('beforeunload', beforeUnloadCallback)
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadCallback)
    }
  }, [when, message])

  return <Prompt when={when} message={message} />
};

export default LeaveBlocker;