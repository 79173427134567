import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";

var Constants = {
  armVendorApiRoot: ApiRootConstants.ARMVendorApiRoot
};

const authService = new AuthService();

export async function getLogStat(venueId, startDateUtc) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    
    return await axios
      .get(`${Constants.armVendorApiRoot}api/ARM/LogStat/${venueId}/${startDateUtc}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getLogs(venueId, startDateUtc, endDateUtc) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    
    return await axios
      .get(`${Constants.armVendorApiRoot}api/ARM/Logs/${venueId}/${startDateUtc}/${endDateUtc}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}