import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
    Card,
  CardContent,
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { Button, ButtonType } from "../../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),     
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  selectControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
  buttons: {
    marginLeft: theme.spacing(1),
  },
}));

const ModeForm = ({
    mode,   
    onSave, 
    onChange
}) => {

    const classes = useStyles();
    const history = useHistory();  

    const handleSave = (e) => {
        e.preventDefault();
        onSave(e);
    }

    return (
        <Card><CardContent>
            <form className={classes.root} autoComplete="off" onSubmit={handleSave}>
                <div>
                    <Container fluid>
                        <Typography variant="h4" className={classes.margin}>
                            Venue mode details
                        </Typography>
                        <div className={classes.formInput}>
                            <Row>
                                <Col md>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        name="name"
                                        value={mode?.name}
                                        onChange={onChange}
                                        variant="outlined"
                                        required
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md>
                                    <TextField
                                        id="outlined-lastName"
                                        label="Description"
                                        name="description"
                                        value={mode?.description}
                                        variant="outlined"
                                        onChange={onChange}
                                        required
                                        fullWidth
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <div className={classes.buttons}>
                                        <Button
                                            type="submit"
                                            className="btn btn-primary"
                                            variant="contained"
                                        >
                                            {"SAVE"}
                                        </Button>
                                        <Button
                                            variant="outlined"
                                            buttonType={ButtonType.Secondary}
                                            className={classes.margin}
                                            onClick={() => {
                                                history.push("/venue");
                                            }}
                                        >
                                            CANCEL
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            </form>
        </CardContent></Card>
    );
};

export default ModeForm;
