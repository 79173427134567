import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Typography} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { Button, ButtonType } from "../components/_common/htmlTags";

const useStyles = makeStyles( theme => { 
  const highPriorityColor = theme.palette.dashboard.trafficLight.amber;

  return {
    root: {
    },
    paper: {
      borderRadius: 0
    },
    title: {
      fontSize: 18,
      fontWeight: 500
    },
    highPriorityTitle : {
      color: highPriorityColor,
    },
    highPriorityIcon : {
      color: highPriorityColor,
      marginRight: theme.spacing(1)
    },
    content: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    button: {
      margin: theme.spacing(1)
    }
  };
});

export const AlertDialog = ({open = false, title ="Dialog Title", showTitle = false, contentText = "Dialog Content", 
      highPriority = false,
      customYesButtonText="YES", customNoButtonText="NO", onNoButtonClicked = () => {}, onYesButtonClicked = () => {}}) => {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        open={open}
        onClose={onNoButtonClicked}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        classes={{ 
          root: classes.root, 
          paper: classes.paper 
        }}
      >
        {showTitle ? 
        (
          <DialogTitle id="alert-dialog-title">
            { highPriority ? <PriorityHighIcon className={classes.highPriorityIcon}/> : null }
            <Typography component="span" className={`${classes.title} ${highPriority? classes.highPriorityTitle: ""}`}>
              { title }
            </Typography>
          </DialogTitle>) 
        : (<></>)}
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={classes.content}>
            { contentText }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        <Button
            variant="contained"
            buttonType={ButtonType.Primary}
            size="small"
            type="submit"
            onClick={onYesButtonClicked}
            className={classes.button}
          >
            {/* {isEditing ? "Save" : "Add"} */}
            {customYesButtonText}
          </Button>
          <Button
            variant="outlined"
            buttonType={ButtonType.Secondary}
            size="small"
            onClick={onNoButtonClicked}
            autoFocus
            className={classes.button}
          >
            {customNoButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}