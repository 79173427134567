import React from 'react';
import { Settings } from '../Settings';
import {TwilioApiSettings} from "./TwilioApiSettings";
import {SmsEmailThreshold} from "./SmsEmailThreshold";
import { SCOPES } from "../../../../../_constants/user.permissions.constants";

export const Alerts = () => {

  return (
    <Settings
      pageName="alerts"
      title="Alerts"
      tabs={[{
        name: "twilio",
        title: "TWILIO API",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_TWILIO_API_SETTINGS],
        component: TwilioApiSettings
      }, {
        name: "smsEmailThreshold",
        title: "SMS/EMAIL THRESHOLD",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_SMS_EMAIL_THRESHOLD],
        component: SmsEmailThreshold
      }]}
    />
  );
};

export default Alerts;