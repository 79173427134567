import * as taskConstants from "../_constants/schedule.tasks.constants";
import initialState from "../_reducers/initialState";

// sorting method for frequencies
function scheduleSort(a, b) {
  if (a.intervalInSec < b.intervalInSec) {
    return -1;
  }
  if (a.intervalInSec > b.intervalInSec) {
    return 1;
  }
  return 0;
}

export function scheduleTaskReducer(
  state = initialState.scheduleTasks,
  action
) {
  switch (action.type) {
    case taskConstants.ADD_TASK_SUCCESS:
      return { ...state, tasks: [...state.tasks, action.tasks] };
    case taskConstants.UPDATE_TASK_SUCCESS:
      const newTasks = state.tasks.map((t) =>
        t.id === action.task.id ? action.task : t
      );
      return { ...state, tasks: newTasks };
    case taskConstants.LOAD_TASKS_STARTED:
      return {
        ...state,
        loading: { ...state.loading, tasksLoading: true }
      };
    case taskConstants.LOAD_TASKS_SUCCESS:
      return {
        ...state,
        tasks: [...action.tasks],
        loading: { ...state.loading, tasksLoading: false }
      };
    case taskConstants.LOAD_TASK_BY_ID_STARTED:
      return {
        ...state,
        loading: { ...state.loading, taskByIdLoading: true }
      };
    case taskConstants.LOAD_TASK_BY_ID_SUCCESS:
      return {
        ...state,
        task: action.task,
        loading: { ...state.loading, taskByIdLoading: false }
      };
    case taskConstants.LOAD_INDICATORS_SUCCESS:
      return { ...state, indicators: [...action.indicators] };
    case taskConstants.LOAD_SCHEDULES_SUCCESS:
      return { ...state, schedules: [...action.schedules.sort(scheduleSort)] };
    case taskConstants.LOAD_VENDORS_SUCCESS:
      return { ...state, vendors: [...action.vendors] };
    case taskConstants.LOAD_INDICATORS_WITH_VENDOR_STARTED:
      return {
        ...state,
        loading: { ...state.loading, loadIndicatorsWithVendorList: true }
      };
    case taskConstants.LOAD_INDICATORS_WITH_VENDOR_SUCCESS:
      return {
        ...state,
        indicatorsWithVendorList: [...action.indicatorsWithVendorList],
        loading: { ...state.loading, loadIndicatorsWithVendorList: false }
      };
    case taskConstants.LOAD_INDICATOR_VENDOR_MAPPINGS_STARTED:
      return {
        ...state,
        loading: { ...state.loading, loadIndicatorVendorMappings: true }
      };
    case taskConstants.LOAD_INDICATOR_VENDOR_MAPPINGS_SUCCESS:
      return {
        ...state,
        indicatorVendorMappings: [...action.indicatorVendorMappings],
        loading: { ...state.loading, loadIndicatorVendorMappings: false }
      };
    case taskConstants.LOAD_INDICATOR_SCHEDULE_MAPPINGS_STARTED:
      return {
        ...state,
        loading: { ...state.loading, loadingIndicatorScheduleMappings: true }
      };
    case taskConstants.LOAD_INDICATOR_SCHEDULE_MAPPINGS_SUCCESS:
      return {
        ...state,
        indicatorScheduleMappings: [...action.indicatorScheduleMappings.sort(scheduleSort)],
        loading: { ...state.loading, loadingIndicatorScheduleMappings: false }
      };
    case taskConstants.DISABLE_TASK_OPTIMISTIC:
      const disabledTask = state.tasks.find((e) => e.id === action.taskId);
      if (disabledTask) {
        disabledTask.enabled = false;
      }
      return {
        ...state,
        tasks: state.tasks.map((t) =>
          t.id === action.taskId ? disabledTask : t
        ),
      };
    case taskConstants.ENABLE_TASK_OPTIMISTIC:
      const enableTask = state.tasks.find((e) => e.id === action.taskId);
      if (enableTask) {
        enableTask.enabled = true;
      }
      return {
        ...state,
        tasks: state.tasks.map((t) =>
          t.id === action.taskId ? enableTask : t
        ),
      };
    default:
      return state;
  }
}

export default scheduleTaskReducer;
