import { Box, IconButton, Modal, Tooltip, Typography } from "@material-ui/core";
import { Button, ButtonType } from "../_common/htmlTags";
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import { alpha } from '@material-ui/core';
import { toast } from "react-toastify";
import devconsole from "../_common/devconsole";

const useStyles = makeStyles((theme) => ({
  formInput: {
    minWidth: 120,
  },
  configurationPopup: {
    width: "566px",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(3),
  },
  hiddenUploadButton: {
    display: "none"
  },
  configPopupTitle: {
    textTransform: "uppercase",
    fontSize: "24px",
    fontWeight: 500,
  },
  downloadTemplateLink: {
    color: theme.palette.color.primary.main,
    fontSize: "14px",
    "&:hover": {
      color: theme.palette.color.primary.main,
    }
  },
  subtext: {
    color: "#627E84",
    fontSize: "14px",
  },
  result: {
    maxHeight: "100px",
    overflowX: "hidden",
    overflowY: "scroll",
    backgroundColor: alpha(theme.palette.color.primary.red, 0.05),
    color: theme.palette.color.primary.red,
    padding: "10px 20px"
  },
  closeIcon: {
    marginTop: "-20px",
    marginRight: "-20px"
  }
}));

export const ImportFileModal = ({
  importingEntityName,

  showModal,
  setShowModal,

  refreshParent = () => { },
  onTemplateDownloadClick = () => { },

  apiImport,
  selectedFile,
  setSelectedFile,
}) => {
  const classes = useStyles();
  const [importing, setImporting] = useState(false);
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [errors, setErrors] = useState([]);
  const [result, setResult] = useState("");

  function handleSave(evt) {
    evt.preventDefault();
    setImporting(true);
    setErrors([]);
    apiImport()
      .then((response) => {
        setResult(`${importingEntityName} bulk import completed successfully.`);
        toast.success(`${importingEntityName} bulk import completed successfully.`);
        setImporting(false);
        refreshParent();
        setShowModal(false);
      })
      .catch((error) => {
        setImporting(false);
        toast.error(`${importingEntityName} bulk import failed with errors`, { autoClose: false });
        setResult(`${importingEntityName} bulk import failed with errors: `);
        // check if error response is not an array then add response to array
        if (error.response && Array.isArray(error.response.data))
          setErrors(error.response.data);
        else if (error.response && error.response.data)
          setErrors(new Array(error.response.data));
        else if (error.message) setErrors(new Array(error.message));
        else setErrors(["Unknown error"]);
      });

    setIsFileSelected(false);
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    devconsole.log("Selected File: ", e.target.files[0]);
    setIsFileSelected(typeof e.target.files[0] === "undefined" ? false : true);
    setResult("");
    setErrors([]);

    e.target.value = null;
  };

  return (
    <Modal open={showModal}>
      <form autoComplete="off" onSubmit={handleSave}>
        <div className={classes.configurationPopup}><Tooltip title="Close" style={{ float: "right" }}>
          <IconButton
            aria-label="view"
            className={classes.closeIcon}
            onClick={(e) => {
              setShowModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
          <Typography className={classes.configPopupTitle} >Import file</Typography>
          <span className={classes.subtext}>Download template before import.&nbsp;</span>
          <Link
            href="#"
            onClick={onTemplateDownloadClick}
            className={classes.downloadTemplateLink}
            to="#"
          >
            Download here
          </Link>
          <br /><br />
          <div>
            <input
              id="upload-file-button"
              type="file"
              name="file"
              onChange={handleFileChange}
              accept=".xlsx,.xls"
              className={classes.hiddenUploadButton}
            />
            <label htmlFor="upload-file-button">
              <Button
                variant="contained"
                component="span"
                buttonType={ButtonType.Dark}>
                Upload File
              </Button>
            </label>
            <label style={{ marginLeft: "10px" }}>
              {selectedFile?.name}
            </label>
          </div>
          <span className={classes.subtext}>You can upload any .xls, .xlsx file</span><br />
          <br />
          {errors && errors.length > 0 ?
            <>
              <Box
                className={classes.result}>
                {result}<br />
                <ul>
                  {errors.map(err => (<li>{err}</li>))}
                </ul>
              </Box><br />
            </>
            : null
          }
          <div className={classes.buttons}>
            <Button
              variant="contained"
              type="submit"
              style={{ marginRight: "15px" }}
              disabled={importing || !isFileSelected}
              buttonType={ButtonType.Primary}
            >
              {importing ? "Importing..." : "Import"}
            </Button>&nbsp;
            <Button
              variant="outlined"
              type="button"
              buttonType={ButtonType.Secondary}
              onClick={() => {
                setSelectedFile(null);
                setShowModal(false);
                setResult("");
                setErrors([]);
              }}
            >Cancel</Button>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default ImportFileModal;