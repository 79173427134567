import React, {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {AlertDialog} from "../../_helpers";
import {Card, CardContent, Grid, IconButton, Tooltip, Typography,} from "@material-ui/core";
import {Button, ButtonType} from "../_common/htmlTags";
import AddIcon from "@material-ui/icons/Add";
import {DataGrid} from "@material-ui/data-grid";
import {DataGridLoadingOverlay} from "../_common/DataGridLoadingOverlay";
import {makeStyles} from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import {AlertRuleSetPopup} from "./AlertRuleSetPopup";
import { NIL as NIL_UUID} from "uuid";
import {getAlertRuleSetsByVenueId, removeAlertRuleSet, removeAlertRuleSetRange, venueAlertsExport, publishOfflineAlerts, duplicateAlerts} from "../../_services/dashboard.alertRuleSet.service";
import {toast} from "react-toastify";
import {AlertRuleViewer} from "../_common/alertRules/AlertRuleViewer";
import { getUserGroups } from "../../_actions/venue.actions";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';

const useStyles = makeStyles((theme) => {
  return {
    deleteIcon: { 
      color: theme.palette.color.primary.red
    },
    editIcon: {
      color: theme.palette.color.primary.dark,
    },
    deleteSelectedButton: {
      transform: "translate(0, -40px)",
      position: "absolute",
    },
    duplicateIcon: {
      color: theme.palette.color.primary.dark
    }
  };
});

export const AlertRuleSets = () =>
{
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const [currentSelectedRuleId, setCurrentSelectedRuleId ] = useState(null);
  const [ruleSets, setRuleSets] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [pageSize, setPageSize] = useLocalStorage("pmy-alertRuleSets-pageSize", 10);
  const [sortModel, setSortModel] = useSortModel("pmy-alertRuleSets-sortModel", [{field: 'name', sort: 'asc'}]);
  const [isLoading, setIsLoading] = useState(true);
  const [ruleSet, setRuleSet]  = useState(null);
  const [isExporting, setIsExporting] = React.useState(false);
  const [selectionModel, setSelectionModel] = useState([]); // rows selected in the list of alerts.
  const [showConfirmDeleteSelection, setShowConfirmDeleteSelection] = useState(false);
  
  useEffect(() => {
    if (selectedVenue)
      dispatch(getUserGroups(selectedVenue));
  }, [selectedVenue]);

  const handleDuplicateAlert = (alertRuleSetId) => {
    setIsLoading(true);

    duplicateAlerts(selectedVenue.id, [alertRuleSetId], false)
      .then(() => {
        toast.success("Alert duplicated successfully!");

        // reload alerts
        setRuleSets(null);
      })
      .catch(error => toast.error("Duplicating alert failed. " + error, { autoClose: false }))
      .finally(() => setIsLoading(false));
  }

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "NAME",
      flex: 1,
    },
    {
      field: "alertRuleSet",
      headerName: "RULES",
      sortable: false,
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{display:"grid", gridTemplateColumns: "auto"}}>
            <AlertRuleViewer alertType={params.row.alertType} alertRules={params.row.rules}  />
          </div>
        );
      }
    },
    {
      field: "action",
      headerName: "ACTION",
      headerAlign: "center",
      width: 170,
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title="Edit">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  setShowPopup(true);
                  setRuleSet(params.row);
                }}
              >
                <EditIcon fontSize="small" className={classes.editIcon}/>
              </IconButton>
            </Tooltip>
            <Tooltip title="Duplicate">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  handleDuplicateAlert(params.row.id);
                }}
              >
                <FileCopyTwoToneIcon fontSize="small" className={classes.duplicateIcon} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={(e) => {
                  setCurrentSelectedRuleId(params.row.id);
                  setDeleteOpen(true);
                }}
              >
                <DeleteIcon fontSize="small" className={classes.deleteIcon} />
              </IconButton>
            </Tooltip>
          </>
        )
      }
    },
  ];
  
  useEffect(() => {
    if (ruleSets === null ) {
      getAlertRuleSetsByVenueId(selectedVenue?.id)
        .then((result) => {
          setIsLoading(false);
          setRuleSets(result.data)
        })
        .catch(error => {
          console.error(error);
          toast.error("Unable to load alerts.", {autoClose: false});
        });
    }
  }, [ruleSets]);

  const handleExport = evt => {
    evt.preventDefault();
    if (selectedVenue?.id) {
        setIsExporting(true);
        venueAlertsExport(selectedVenue.id)
            .then(() => toast.success("Alerts exported"))
            .catch(error => toast.error("Export failed. " + (error.response ? error.response.data : error.message), { autoClose: false }))
            .finally(() => setIsExporting(false));
    }
  }
  
  return (
    <>
      {selectedVenue !== null ? (
        <div>
          <AlertDialog open={deleteOpen} contentText={`Do you want to delete this alert?`}
                       onNoButtonClicked={() => {
                         setDeleteOpen(false);
                       }}
                       onYesButtonClicked={(e) => {
                         removeAlertRuleSet(currentSelectedRuleId).then((result) => {
                           setDeleteOpen(false);
                           setRuleSets(null);//reset grid
                         });
                       }} />
          <AlertDialog 
            open={showConfirmDeleteSelection}
            contentText={`Do you want to delete ${selectionModel.length} ${selectionModel.length === 1 ? "alert" : "alerts"}?`}
            onNoButtonClicked={() => setShowConfirmDeleteSelection(false)}
            onYesButtonClicked={() => {
              removeAlertRuleSetRange(selectionModel).then(() => {
                setShowConfirmDeleteSelection(false);
                setRuleSets(null); // reset grid
              })
            }}
          />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="space-between"
            >
              <Typography variant="h4">Alerts</Typography>
              <Grid
                item
                container
                xs={6}
                md={6}
                justifyContent="flex-end"
                spacing={1}
              >
                {/* <Grid item>
                  <Button onClick={() => {
                    publishOfflineAlerts();
                  }}>
                    Publish offline alerts
                  </Button>
                </Grid> */}
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={handleExport}
                        disabled={isExporting}
                    >
                        {isExporting ? 'Exporting...' : 'Export'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={(e) => {
                        setShowPopup(true);
                        setRuleSet({
                          id: NIL_UUID, 
                          venueId: selectedVenue?.id, 
                          name: "", 
                          alertType: "range", 
                          rules: [{
                            from: null,
                            to: null,
                            color: null,
                            sound: false,
                            userGroupId: null,
                            sms: false,
                            email: false
                          }]});
                        }}
                    >
                        Add
                    </Button>
                </Grid>
              </Grid>
            </Grid>
            <div style={{ display: "flex", width: "100%", height: "100%", paddingTop: 15 }}>
              <div style={{ flexGrow: 1 }}>
                <Card style={{ overflow: 'auto'}}>
                  <CardContent>
                    <DataGrid
                      autoHeight={true}
                      pageSize={pageSize}
                      onPageSizeChange={(value) => setPageSize(value)}
                      rowsPerPageOptions={[5,10,20]}
                      sortModel={sortModel}
                      onSortModelChange={(model) => setSortModel(model)}
                      sortingOrder={['desc','asc']}
                      hideFooterSelectedRowCount={true}
                      columns={columns}
                      rows={
                        (ruleSets === null || isLoading) ? [] : ruleSets
                      }
                      components={{
                        LoadingOverlay: DataGridLoadingOverlay,
                      }}
                      disableSelectionOnClick={true}
                      checkboxSelection
                      onSelectionModelChange={(newSelectionModel) => {
                        setSelectionModel(newSelectionModel);
                      }}
                      selectionModel={selectionModel}  
                    ></DataGrid>
                    <Button
                      variant="contained"
                      component="span"
                      buttonType={ButtonType.Dark}
                      size="small"
                      className={classes.deleteSelectedButton}
                      disabled={selectionModel.length === 0}
                      onClick={() => {
                        setShowConfirmDeleteSelection(true);
                      }}
                    >
                      {`Delete (${selectionModel.length})`}
                    </Button>
                  </CardContent>
                </Card>
              </div>
            </div>
          </Grid>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
      <AlertRuleSetPopup 
        showConfigurationModal={showPopup} 
        setShowConfigurationModal={(val) => { 
          setShowPopup(val);
          if (!val) setRuleSet(null);
          setRuleSets(null); // refresh
        }} 
        ruleSet={ruleSet} 
        ruleSetsList={ruleSets} />
    </>
  );
  
}