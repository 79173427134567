import widgetDataConstants from "../_constants/widget.data.constants";

export function widgetTokenReducer(state = {}, action) {
    switch (action.type) {
        case widgetDataConstants.GET_TOKEN_STARTED: {
            const newState = {...state};
            newState[action.client_id] = { started: true };
            return newState;
        }
        case widgetDataConstants.GET_TOKEN_SUCCESS: {
            const newState = {...state};
            newState[action.client_id] = { token: action.access_token };
            return newState;
        }
        case widgetDataConstants.GET_TOKEN_FAILED: {
            const newState = {...state};
            delete newState[action.client_id];
            return newState;
        }
        default:
            return state;
    }
}

export default widgetTokenReducer;
