import * as occupancyConstants from "../_constants/indicator.constants"
import initialState from "./initialState";

export function indicatorsReducer(
  state = initialState.indicators,
  action
) {
  switch (action.type) {
    case occupancyConstants.LOAD_LATEST_OCCUPANCIES_STARTED:
      return {
        ...state,
        occupancy: {
          loading: { ...state.loading, latestOccupanciesLoading: true }
        }
      };
    case occupancyConstants.LOAD_LATEST_OCCUPANCIES_SUCCESS:
      return {
        ...state,
        occupancy: {
          latestOccupancies: action.latestOccupancies.data,
          loading: { ...state.loading, latestOccupanciesLoading: false }
        }
      };

    case occupancyConstants.LOAD_TRAFFIC_IN_OUT_METRICS_STARTED:
      return {
        ...state,
        trafficInOut: {
          loading: { ...state.loading, trafficInOutMetricsLoading: true }
        }
      };
    case occupancyConstants.LOAD_TRAFFIC_IN_OUT_METRICS_SUCCESS:
      return {
        ...state,
        trafficInOut: {
          trafficInOutMetrics: action.trafficInOutMetrics.data,
          loading: { ...state.loading, trafficInOutMetricsLoading: false }
        }
      };

    case occupancyConstants.LOAD_FOOTFALL_TRAFFIC_METRICS_STARTED:
      return {
        ...state,
        footfallTraffic: {
          loading: { ...state.loading, footfallTrafficMetricsLoading: true }
        }
      };
    case occupancyConstants.LOAD_FOOTFALL_TRAFFIC_METRICS_SUCCESS:
      return {
        ...state,
        footfallTraffic: {
          footfallTrafficMetrics: action.footfallTrafficMetrics.data,
          loading: { ...state.loading, footfallTrafficMetricsLoading: false }
        }
      };

    default:
      return state;
  }
}

export default indicatorsReducer;
