
import * as constants from "../_constants/mode.constants";
import * as service from "../_services/mode.service";


export const loadModeStarted = () => ({
  type: constants.LOAD_MODE_STARTED,
});

export const loadModeSuccess = (venueMode) => ({
  type: constants.LOAD_MODE_SUCCESS,
  mode: { venueMode },
});

export const loadModeFailed = () => ({
  type: constants.LOAD_MODE_FAIL,
});

export const addModeSuccess =  (venueMode) => ({
  type: constants.ADD_MODE,
  mode: { venueMode },
});

export const updateModeSuccess =  (venueMode) => ({
  type: constants.UPDATE_MODE,
  mode: { venueMode },
});

export const deleteModeSuccess =  (venueMode) => ({
  type: constants.DELETE_MODE,
  mode: { venueMode },
});


export const loadModeList = (venueId) => {
  return async (dispatch) => {
    dispatch(loadModeStarted());
    await service
      .getModeLists(venueId)
      .then((modeList) => {
        dispatch(loadModeSuccess(modeList));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadMode = (venueId, modeId) => {
  return async (dispatch) => {
    dispatch(loadModeStarted());
    await service
      .getMode(venueId, modeId)
      .then((modeDto) => {
        dispatch(loadModeSuccess(modeDto));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addMode = (mode) => {
  return async (dispatch) => {
    await service
      .addMode(mode)
      .then(() => {
        dispatch(addModeSuccess(mode));
      })
      .catch((error) => {
        throw error;
      });
  };
};


export const updateMode = (mode) => {
  return async (dispatch) => {
    await service
      .updateMode(mode)
      .then(() => {
        dispatch(updateModeSuccess(mode));
      })
      .catch((error) => {
        throw error;
      });
  };
};


export const deleteMode = (modeId) => {
  return async (dispatch) => {
    await service
      .deleteMode(modeId)
      .then(() => {
        dispatch(deleteModeSuccess(modeId));
      })
      .catch((error) => {
        throw error;
      });
  };
};