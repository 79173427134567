import React, { useState } from "react";
import { Grid, Box, Typography, Card, CardContent } from "@material-ui/core";
import AddUserForm from "./AddUserForm";
import SearchUsers from "./SearchUsers"

const AddUser = () => {
  
  const [email, setEmail] = useState("");
  const [searchClicked, setIsSearched] = useState(false);

  function onSearchClicked() {
    setIsSearched(true);   
  }

  return (
    <>
      
      <Box>
        <Grid>
          <Typography variant="h4" >Add user</Typography>
        </Grid>
        <div style={{ display: "flex", height: "100%", paddingTop: 15 }} >
          <div style={{ flexGrow: 1 }}>
            <Card style={{ overflow: 'auto', height: '80vh' }} >
              <CardContent>
                <Grid item md={12}>
                  {!searchClicked &&
                    <Grid item md={8}>
                      <SearchUsers
                        onEmailChanged={email => { setEmail(email); setIsSearched(false) }}
                        onSearchClicked={onSearchClicked}
                      />
                    </Grid>
                  }{
                    searchClicked &&
                      
                    <Grid item md={8}>
                      <AddUserForm                       
                        email={email}   
                        setIsSearched={setIsSearched}
                      />
                    </Grid>
                    
                  }
                </Grid>
              </CardContent>
            </Card>
          </div>
        </div>
      </Box>
    </>
  );
};

export default AddUser;
