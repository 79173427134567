import { React } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Typography,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@material-ui/core";

import CrossIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';


const useStyles = makeStyles((theme) => ({
    passwordValidationItem: {
        margin: "-22px",
    },

    passwordValidIcon: {
        fontSize: 20,
        color: '#3FBE5D'
    },
    passwordInvalidIcon: {
        fontSize: 20,
        color: '#EE4040'
    },

    passwordValidFont: {
        fontSize: "13px",
        color: "#3FBE5D",
    },
    passwordInvalidFont: {
        fontSize: "13px",
        color: "#EE4040",
    }
}));

const PasswordValidationPoint = (props) => {
    const { newPassword, text, test } = props;

    const classes = useStyles();

    const isNewPasswordValid = test(newPassword);
    const icon = isNewPasswordValid ? <DoneIcon className={classes.passwordValidIcon} /> : <CrossIcon className={classes.passwordInvalidIcon} />;
    const fontClass = isNewPasswordValid ? classes.passwordValidFont : classes.passwordInvalidFont;

    return (
        <ListItem className={classes.passwordValidationItem}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText disableTypography primary={
                <Typography type="p" className={fontClass}>{text}</Typography>
            } />
        </ListItem>

    )
}

export default PasswordValidationPoint;