import {
  Checkbox, FormControl, FormControlLabel, IconButton, InputLabel,
  MenuItem, Modal, Select, TextField, Tooltip, Typography
} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import { Button, ButtonType } from "../../_common/htmlTags";
import { updateDashboard, getDashboard } from "../../../_actions/dashboard.actions";
import React, { useState, useEffect } from "react";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core/styles";
import {useDispatch, useSelector} from "react-redux";
import {getModeList} from "../../../_reducers/mode.selector";
import CloseIcon from "@material-ui/icons/Close";
import {loadModeList} from "../../../_actions/mode.actions";

const useStyles = makeStyles((theme) => ({
  formInput: {
    minWidth: 120,
  },
  configurationPopup: {
    width: "566px",
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(3),
  },
  configPopupTitle: {
    fontSize: "24px",
    fontWeight: "500",
  },
  closeIcon: {
    marginTop:"-20px",
    marginRight: "-20px"
  },
  buttonMargin: {
    marginRight: "10px",
  },
}));

export const EditDashboardConfigurationPopup = ({showConfigurationModal, setShowConfigurationModal, dashboard, dashboards}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const venueModes = useSelector(getModeList);

  const [isProtected, setIsProtected] = useState(dashboard?.isProtected);
  const [venueModeId, setVenueModeId] = useState(dashboard?.venueModeId);
  const [dashboardName, setDashboardName] = useState(dashboard?.name);
  const [dashboardDesc, setDashboardDesc] = useState(dashboard?.description);
  const [saving, setSaving] = useState(false);
  function checkUniqueName() {
    if (dashboards.find((e) => e.name === dashboardName && e.id !== dashboard.id && e.venueId === dashboard.venueId)) {
      toast.error("Dashboard with the selected name already exists", { autoClose: false });
      return false;
    }
    return true;
    }

  function handleSave(evt) {
    evt.preventDefault();
    if (!checkUniqueName()) return;

    setSaving(true);
    
    /// update dashboard object with the last name
    dashboard.name = dashboardName;
    dashboard.description = dashboardDesc;
    dashboard.venueModeId = venueModeId;
    dashboard.isProtected = isProtected;
    
    dispatch(updateDashboard(dashboard))
      .then(() => {
        toast.success("Dashboard updated successfully.");

        //load dashboards again to get latest information
        dispatch(getDashboard(dashboard.venueId, dashboard.id))
          .catch(error => toast.error("Loading dashboards failed " + error, { autoClose: false }));
      })
      .catch(error => {
        rollbackUpdatedValue();
        toast.error("Edit dashboard failed " + error, { autoClose: false })
      })
      .finally(() => {
        setShowConfigurationModal(false);
        setSaving(false);
      });
  }
  
  const rollbackUpdatedValue = () => {

    setDashboardName(dashboard?.name);
    setDashboardDesc(dashboard?.description);
    setVenueModeId(dashboard?.venueModeId);
    setIsProtected(dashboard?.isProtected);
  }
  
  useEffect(() => {
    if (dashboard?.venueId !== undefined) {
      setDashboardName(dashboard?.name);
      setDashboardDesc(dashboard?.description);
      setVenueModeId(dashboard?.venueModeId);
      setIsProtected(dashboard?.isProtected);
      dispatch(loadModeList(dashboard?.venueId))
        .catch(error => toast.error("Loading venue mode failed: " + error, { autoClose: false }));
    }
  }, [dashboard?.venueId, dispatch]);
  
  return (
    <Modal open={showConfigurationModal}>
      <form autoComplete="off" onSubmit={handleSave}>
        <div className={classes.configurationPopup}>
          <Tooltip title="Close" style={{float:"right"}}>
            <IconButton
              aria-label="view"
              className={classes.closeIcon}
              onClick={(e) => {
                setShowConfigurationModal(false);
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
          <Typography className={classes.configPopupTitle}>
            Dashboard details
          </Typography>
          <br />
          <div className={classes.formInput}>
            <TextField
              id="name"
              label="Name"
              name="name"
              value={dashboardName ?? ""}
              onChange={(e) => {setDashboardName(e.target.value)}}
              variant="outlined"
              required
              fullWidth
            />
          </div>
          <br />
          <div className={classes.formInput}>
            <TextField
              id="description"
              label="Description"
              name="description"
              value={dashboardDesc ?? ""}
              onChange={(e) => {setDashboardDesc(e.target.value)}}
              variant="outlined"
              required
              fullWidth
            />
          </div>
          <br />
          <div className={`${classes.formInput}`}>
            <FormControl variant="outlined" fullWidth required>
              <InputLabel id="venueModeId-label">Venue mode</InputLabel>
              <Select
                labelId="venueModeId-label"
                id="venueModeId"
                name="venueModeId"
                value={ venueModes?.length ? venueModeId : "" }
                onChange={(e) => { setVenueModeId(e.target.value) }}
                label="VenueModeId"
              >
                {venueModes.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.formInput}`}>
            <FormControlLabel
            id="isProtected"
            name="isProtected"
            label="Make public"
            control={<Checkbox checked={!isProtected} onChange={(e) => { setIsProtected(!e.target.checked) }} />}
          />
          </div>
          <Row>
            <Col>
              <div className={classes.buttons}>
                <Button
                  className={classes.buttonMargin}
                  variant="contained"
                  type="submit"
                  disabled={saving}
                  buttonType={ButtonType.Primary}
                >
                  {saving ? "Saving..." : "Save"}
                </Button>&nbsp;
                <Button
                  className="btn primary"
                  variant="outlined"
                  type="button"
                  buttonType={ButtonType.Secondary}
                  onClick={() => {
                    rollbackUpdatedValue();
                    setShowConfigurationModal(false);
                  }}
                >Cancel</Button>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </Modal>
  );
};
 
export default EditDashboardConfigurationPopup;