import { createSelector } from 'reselect';

export const getSelectedVenue =
  createSelector(
    state => state.venueReducer?.selectedVenue,  
    selectedEvent => selectedEvent
  );

export const getModeList =
  createSelector(
    state => state.venueMode?.data,
    data => data
);

export const getModeIsLoading =
  createSelector(
    state => state.venueMode?.isLoading,  
    isLoading => isLoading
);
