import WidgetQueueModelContent from "./WidgetQueueModelContent";
import { WidgetWrapper } from "../WidgetWrapper";
import { widgetTypeConstants } from "../../../_constants";

export const WidgetQueueModel = (props) => (
    <WidgetWrapper
        {...{ ...props, widgetType: widgetTypeConstants.widgetQueueModel.id }}
    >
        <WidgetQueueModelContent />
    </WidgetWrapper>
);

export default WidgetQueueModel;