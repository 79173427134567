import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import {
  addDashboard,
  loadDashboardsSimple
} from "../../../_actions/dashboard.actions";
import {  
    Card,
    CardContent,
    Typography,
  } from "@material-ui/core";
import { AddDashboardForm } from "./AddDashboardForm"
import { toast } from "react-toastify";
import { NIL as NIL_UUID } from 'uuid';
import { NoVenueSelectedError } from "../../../_constants/";
import { getModeList } from '../../../_reducers/mode.selector';
import {SCOPES} from "../../../_constants/user.permissions.constants";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import {PermissionDenied} from "../../PermissionDenied";


export const AddDashboard = () => {
    const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
    const dashboards = useSelector((state) => state.dashboards.dashboards);
    const venueModes = useSelector(getModeList);

    const dispatch = useDispatch();
    const history = useHistory();
    let venueId = NIL_UUID;
    if (selectedVenue != null) venueId = selectedVenue.id;

    // state for new dashboard
    const [dashboard, setDashboard] = useState({
        id: NIL_UUID,
        venueId: venueId,
        name: "",
        venueModeId: "",
        description: "",
        dashboardWidget: [],
        isProtected: true
    });
   
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        if (selectedVenue?.id) {
            dispatch(loadDashboardsSimple(selectedVenue.id, false)).catch((error) => {
                toast.error("Loading dashboards failed" + error, { autoClose: false });
            });
        }
    }, [dispatch, selectedVenue]);

    function handleChange(event) {
        const { name, value, checked } = event.target;
        if (name === "id") {
            const selectedDashboard = dashboard.find(e => e.id === value);
            setDashboard((prevDashboard) => ({
                ...prevDashboard,
                [name]: value,
                dashboard: selectedDashboard
            }));
        }
        else if (name === "isProtected") {
            setDashboard((prevDashboard) => ({
                ...prevDashboard,
                [name]: !checked,
            }));
        }
        else {
            setDashboard((prevDashboard) => ({
                ...prevDashboard,
                [name]: value,
            }));
        }
    }
    
    function handleSave(event) {
        event.preventDefault();
        if (dashboards.find((e) => e.name === dashboard.name)) {
            toast.error("Dashboard with the selected name already exists", { autoClose: false });
        } else {
            setSaving(true);
            dispatch(addDashboard(dashboard))
                .then((newDashboard) => {
                    toast.success("Dashboard added successfully.");
                    setSaving(false);
                    history.push("/dashboard/" + newDashboard.id);
                })
                .catch((error) => {
                    toast.error("Adding dashboard failed " + error, { autoClose: false });
                    setSaving(false);
                });
        }
    }

    return selectedVenue === null ?
        (
            <NoVenueSelectedError></NoVenueSelectedError>
        ) : (
            <PermissionsGate 
              RenderError={() => <PermissionDenied />}
              scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_UPDATE_DASHBOARD]}
            >
                <Typography variant="h4">
                    Add new dashboard
                </Typography>
                <br/>
                <Card style={{ overflow: 'auto' }}><CardContent>
                    <AddDashboardForm
                        dashboard={dashboard}
                        venueModes={venueModes}
                        onChange={handleChange}
                        onSave={handleSave}
                        saving={saving}
                    />
                </CardContent></Card>
            </PermissionsGate>
        );
};
