import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import VolumeUp from '@material-ui/icons/VolumeUp';
import { Tooltip } from '@material-ui/core';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

const FLASH_ALERT_ICON_PATH = "../../../icons/flashAlertIcon.svg";

const useStyles = makeStyles((theme) => {
  return {
    "@keyframes flashEffect": {
      "0%": {
        opacity: 1
      },
      "50%" : {
        opacity: 0.5
      },
      "100%" : {
        opacity: 1
      }
    },
    flashColor: {
      animation: `$flashEffect 1s infinite ${theme.transitions.easing.easeInOut}`
    },
    soundIcon: {
      fontSize: "4px",
      paddingLeft: "5px",
      marginTop: "-3px",
      color: theme.palette.color.primary.green,
    },
    flashAlertIcon: {
      height: "15px",
      paddingLeft: "5px",
      marginTop: "-3px",
      color: theme.palette.color.primary.green,      
    },
    smsIcon: {
      height: "16px",
      paddingLeft: "5px",
      marginTop: "-2px",
      color: theme.palette.color.primary.green,      
    },
    emailIcon: {
      height: "16px",
      paddingLeft: "5px",
      marginTop: "-4px",
      color: theme.palette.color.primary.green,      
    },
  }
});
export const AlertRuleViewer = ({alertType, alertRules = []}) => {
  const classes = useStyles();
  
  const renderTextValue = (alertType, alertRule) => {
    let textValue = "";

    if (alertType === "range") {
      // when both of them null means ALL NUMBERS
      if ((alertRule.from === null) && (alertRule.to === null)) textValue = "Any number";
      // when from and to are the same, return just 1 number
      else if ((alertRule.from ?? -Infinity) === (alertRule.to ?? Infinity)) textValue = alertRule.from.toLocaleString();
      else {
        // when from is empty
        if (((alertRule.from ?? -Infinity) === -Infinity) && ((alertRule.to ?? Infinity) !== Infinity))
          textValue = `<= ${alertRule.to.toLocaleString()}` ;
        // when to is empty
        else if (((alertRule.from ?? -Infinity) !== -Infinity) && ((alertRule.to ?? Infinity) === Infinity))
          textValue = `>= ${alertRule.from.toLocaleString()}` ;
        else // when both of are not empty
          textValue = `${alertRule.from.toLocaleString()} - ${alertRule.to.toLocaleString()}`;
      }
    } else {
      // Alert type is "percent"
      if (alertRule.percent === null) {
        textValue = "Any number";
      } else {
        textValue = `>= ${alertRule.percent.toLocaleString()}%`;
      }
    }
    return textValue;
  };
  return (
      <div style={{display: "inline-flex", flexDirection: "row" }}>
        {
          alertRules.sort((a,b) => {
            if (alertType === "range") {
              return  (a.from ?? -Infinity) - (b.from ?? -Infinity);
            } else {
              return (a.percent ?? -Infinity) - (b.percent ?? -Infinity);
            }
          }).map( (a, index) => {
              let noColorBackgroundImage = "";
              if ((a.color?.value ?? "") === "")
                noColorBackgroundImage = 'url("/transparent_background.svg")';

              return (
                <div 
                  key={index} 
                  className={(a.color?.flash ? "" : "")} 
                  style={{
                    backgroundImage: noColorBackgroundImage, 
                    color: "black", 
                    backgroundColor: a.color?.value ?? "", 
                    border: `1px solid ${a.color?.value ?? "lightgrey"}`,
                    height: "20px", 
                    padding: "5px 15px", 
                    marginTop: "-15px", 
                    borderBottomLeftRadius: index === 0 ? 5 : null,
                    borderTopLeftRadius: index === 0 ? 5 : null,
                    borderBottomRightRadius: index === alertRules.length - 1 ? 5 : null,
                    borderTopRightRadius: index === alertRules.length - 1 ? 5 : null
                  }}
                >
                  {renderTextValue(alertType, a)}
                  {a.color?.flash ? <Tooltip title="Flash alert" arrow><img className={classes.flashAlertIcon} src={FLASH_ALERT_ICON_PATH} alt=""/></Tooltip> : ""}
                  {a.sound ? <Tooltip title="Sound alert" arrow><VolumeUp className={classes.soundIcon} /></Tooltip> : ""}
                  {a.sms ? <Tooltip title="SMS alert" arrow>
                    <SmsOutlinedIcon
                      fontSize="small"
                      className={classes.smsIcon}
                    /></Tooltip>: ""
                  }
                  {a.email ? <Tooltip title="Email alert" arrow>
                    <MailOutlineIcon
                      fontSize="small"
                      className={classes.emailIcon}
                    /></Tooltip>: ""
                  }

                </div>
              );
            })
        }
      </div>
  );
}