import React from "react";
import { Grid, Typography, IconButton, Box } from "@material-ui/core";
import 'bootstrap/dist/css/bootstrap.min.css';
import { makeStyles } from "@material-ui/core/styles";
import { AlertDialog } from "../../_helpers/AlertDialog";
import ClearIcon from '@material-ui/icons/Clear';
import SettingsIcon from '@material-ui/icons/Settings';
import devconsole from "../_common/devconsole";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.common,
  headerBar: { ...theme.widget.common.headerBar },
  settingIcon: {
    color: theme.palette.color.secondary.main,
    width: '0.9em',
    height: '0.9em'
  }, 
  deleteIcon: {
    color: theme.palette.color.primary.red,
    width: '1em',
    height: '1em'
  },

  
  }));

export const WidgetBox = props => {
        const { caption, isDynamicCaption = false, captionColor, width, height, isAdminMode = true, canDelete = true, canConfigure = true, bgcolor, border, onDelete, onConfigure, children, setIsDraggable = () => {}} = props;
  
        const classes = useStyles(props);
        const hasToShowHeaderBar = caption || canDelete || canConfigure;
        
        const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
      
        const handleConfigure = e => {
            setIsDraggable(false);
            e.stopPropagation();
            onConfigure?.call();
        } 
      
        const handleDeleteYes = () => {
          setOpenDeleteConfirmation(false);
          devconsole.log("About to call parent's onDelete from WidgetBox");
          onDelete?.call();
        };
      
        const handleDeleteNo = () => setOpenDeleteConfirmation(false);
      
        const handleDelete = e => {
            e.stopPropagation();
            setOpenDeleteConfirmation(true);
        }
      
        return (
          <Grid style={{height: height}}>
            <Box display="flex" flexDirection="column" width={width} height={height} style={{ position: "relative" }} >
              {hasToShowHeaderBar &&
                <Box display="flex" flexDirection="row" className={classes.headerBar} alignItems="center" width={width}
                    style={{ position: "absolute", top: classes.headerBar.height, backgroundColor: isDynamicCaption && !isAdminMode ? "transparent" : classes.headerBar.backgroundColor }}>
                  <Box flex="1" minWidth="0" style={{ minWidth: 0 }}>
                    <Typography className={classes.headerCaption}
                      style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", color: isDynamicCaption && captionColor ? captionColor : "#627E84" }}
                    >
                      {caption}
                    </Typography>
                  </Box>
      
                  {canConfigure &&
                    <IconButton size="small" aria-label="configure" onClickCapture={handleConfigure} onMouseDown={e => {
                      e.stopPropagation()
                    }
                    } >
                      <SettingsIcon className={classes.settingIcon} fontSize="inherit" />
                    </IconButton>
                  }
      
                  {canDelete &&
                    <IconButton size="small" aria-label="delete" onClickCapture={handleDelete} onMouseDown={e => e.stopPropagation()} >
                      <ClearIcon className={classes.deleteIcon} fontSize="inherit" />
                    </IconButton>
                    }
                </Box>
              }
      
              <Box display="flex" flexDirection="row" minWidth="0" width={width} height={height}>
                <Box
                  alignItems="center"
                  flex="1"
                  alignContent="top"
                  justifyContent="center"
                  display="flex"
                  width={width}
                  height={height}
                >
                    <Box width={width} height={height} border={border} bgcolor={bgcolor}>
                        {children}
                    </Box>
                </Box>
              </Box>
      
              <AlertDialog 
                highPriority
                showTitle 
                title="Delete widget" 
                contentText={'Do you want to delete this widget?'}
                open={openDeleteConfirmation} 
                onNoButtonClicked={handleDeleteNo}
                onYesButtonClicked={handleDeleteYes} 
              />

            </Box>
          </Grid>
        );
}
