import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { carParkingSourceImport } from "../../_services/import.service";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  margin: {
    widows: theme.spacing(1),
  },
}));

export const ImportCarParkingSource = () => {
  const classes = useStyles();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const [selectedFile, setSelectedFile] = useState();
  const [isFileSelected, setIsFileSelected] = useState(false);
  const [result, setResult] = useState("");
  const [errors, setErrors] = useState([]);
  const [isImporting, setIsImporting] = useState(false);

  const changeHandler = (venue) => {
    setSelectedFile(venue.target.files[0]);
    setIsFileSelected(
      typeof venue.target.files[0] === "undefined" ? false : true
    );
    setResult("");
    setErrors([]);
  };

  const handleSubmission = (venue) => {
    setIsImporting(true);
    setErrors([]);
    carParkingSourceImport(selectedFile, selectedVenue)
      .then((response) => {
        setResult("Bulk Import completed successfully.");
        toast.success("Bulk Import completed successfully.");
        setIsImporting(false);
      })
      .catch((error) => {
        setIsImporting(false);
        toast.error("Bulk Import failed with errors", { autoClose: false });
        setResult("Bulk Import failed with errors: ");
        // check if error response is not an array then add response to array
        if (error.response && Array.isArray(error.response.data))
          setErrors(error.response.data);
        else if (error.response && error.response.data)
          setErrors(new Array(error.response.data));
        else if (error.message) setErrors(new Array(error.message));
        else setErrors(["Unknown error"]);
      });
  };

  return (
    <>
      {selectedVenue !== null ? (
        <div className={classes.root}>
          <Typography variant="h4">Adamant Car Parking Source File Import</Typography>
          <Typography>
            Please, select Adamant car parking CSV source file to import. Accepted
            file must have the following columns:
          </Typography>
          <ul>
            <li>Location Name</li>
            <li>Gate Name</li>
            <li>Traffic Direction</li>
            <li>Worked Time</li>
          </ul>
          <div>
            <input
              type="file"
              name="file"
              onChange={changeHandler}
              accept=".csv"
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmission}
            disabled={!isFileSelected}
          >
            Import
          </Button>
          {isImporting ? <div>Importing...</div> : ""}
          <div name="results">{result}</div>
          <ul>
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  );
};

export default ImportCarParkingSource;
