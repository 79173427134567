import React, { useRef, useLayoutEffect, useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { EmptyContainer } from "../EmptyContainer";
import CommonWidgetLayout from "../shared/CommonWidgetLayout";
import { getContrastTextColor } from "../../../_helpers/colorUtils";
import WidgetContext from "../WidgetContext";
import { numberValueTypes } from "../../../_constants";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.common,
  ...theme.widget.number,
  ...theme.widget.flashingWidget,
}));

// define initial values
const BASE_FONT_SIZE = 10;
const BASE_WIDGET_SIZE = 122;

const getLocStr = (locsArray) => {
    const str = locsArray?.map(it => it.name)?.filter(it => it)?.join(', ');
    return truncateString(str);
}

const truncateString = (stringValue) => {
    const length = 20;
    if ((stringValue??"").length <= length)
        return stringValue;
    // truncate string if it's more than the specified length
    return `${stringValue.substr(0, length).trim()} ...`;
}

export const WidgetNumberContent = ({ backgroundColor, width, height, data, settings, viewMode, brandingSettings, onAudioPlay }) => {
    const numberData = data?.numberData;
    const colorData = data?.color;
    const targetRef = useRef();
    const widgetContextData = useContext(WidgetContext);
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
    const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
    const classes = useStyles({ backgroundColor, width, fontSize, ...brandingSettings });
    const noData = !settings?.RefListLocation;
    const customName = settings?.WidgetSettingCustomName;
    var props = { fontSize, backgroundColor, width, height };

    const [playSound, setPlaySound] = useState(false);

    useEffect(() => {
        setPlaySound(isViewMode && data?.sound);
    }, [data?.sound, isViewMode, setPlaySound]);

    useEffect(() => {
        if (playSound) {
            onAudioPlay();
        }
    }, [playSound, onAudioPlay]);

    // calculate scale factor depending on the width and height. Always use smallest value to use for scale factor calculation
    // original widget size is approx BASE_WIDGET_SIZE width and height, and base font size is BASE_FONT_SIZE. Using this values we calculate scale factor
    const applyScaleFactor = (width, height) => {
        const newWidgetSize = width > height ? height : width;
        const scaleFactor = newWidgetSize / BASE_WIDGET_SIZE;

        setFontSize(BASE_FONT_SIZE * scaleFactor);
    };

    // use this ref to monitor resize venue and adjust the font sizes accordingly using scale factor
    const ref = useResizeObservedRef(({ width, height }) => {
        applyScaleFactor(width, height);
    });

    // use this hook to set initial font sizes by applying scale factor based on current widget size. this is used when widget renders for the first time on viewing dashboard
    useLayoutEffect(() => {
        if (targetRef.current) {
            applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
        }
    }, []);

    // Quick solution for showing name (description1) to keep existing rendering logic. 
    // TODO: Number widget needs fully developed refactoring and improvements, so this should be improved with such refactoring
    const widgetNameEditMode = customName !== "" ? customName : (getLocStr(settings?.RefListLocation) ?? "Description 1");
    let widgetNameViewMode = "";
    if (numberData) {
        widgetNameViewMode = numberData?.description1 ? (customName !== "" ? customName : truncateString(numberData?.description1)) : "";
    } else {
        // If there is no data, we still need to show the name of the location (if it is specified).
        widgetNameViewMode = customName !== "" ? customName : getLocStr(settings?.RefListLocation);
    }

    // Quick solution to handle text color (of values and caption) depending on background color of widget.
    var textColor;
    if (colorData?.value) {
        textColor = getContrastTextColor(colorData?.value);

        widgetContextData?.setCaptionColor(colorData?.value === '#ffffff' ? "rgba(98, 126, 132, 1)" : textColor);
        
        if (colorData?.flash) {
            widgetContextData?.setCaptionColor("rgba(98, 126, 132, 1)");
        }
    }

    let value = 0; // This 0 will be displayed in edit mode.
    if (isViewMode) {
        if (numberData) {
            value = numberData?.value?.toLocaleString('en-US');
        } else {
            // For whatever reason, there is no data. Most likely, the location (if it is specified)
            // has not been linked to the data source in the Settings > Data sources.
            // Display N/A.
            value = 'N/A';
        }
    }

    const settingsTimePeriodName = settings?.RefNumberWidgetValueType?.id === numberValueTypes.soldTicketsCount.id ? '' : settings?.RefNumberWidgetTimePeriod?.name;
    const timePeriodViewMode = numberData ? numberData?.description2 : settingsTimePeriodName;
    const timePeriodEditMode = settingsTimePeriodName ?? "Description 2";

    // The following are pending changes once we build APIs for this widget and link in WidgetWrapper:
    //   - get data and update rendering with received data. At the moment it is dummy value
    return (
        <>
            <Box ref={ref}
                className={`${classes.container} ${isViewMode && colorData?.flash ? classes.flashEffect : ''}`}
                style={isViewMode && colorData?.value ? {backgroundColor: colorData.value} : null}            
            >
                {
                    noData ? (
                        <EmptyContainer {...props} />
                    ) : (
               
                        <div ref={targetRef}>
                            <CommonWidgetLayout 
                                value={value} 
                                name={isViewMode ? widgetNameViewMode : widgetNameEditMode} 
                                timePeriod={isViewMode ? timePeriodViewMode : timePeriodEditMode } 
                                contrastFontColor={textColor}
                            />

                            {/*
                                <div className={classes.value}>{(isViewMode ? numberData?.value?.toLocaleString('en-US') : 0)}</div>
                                <div className={classes.caption}>{isViewMode ? numberData?.caption : (settings.RefNumberWidgetValueType?.name ?? 'CAPTION')}</div>
                                <div className={classes.desc1}>{isViewMode ? widgetNameViewMode : widgetNameEditMode}</div>
                                <div className={classes.desc2}>{isViewMode ? numberData?.description2 : (settings.RefNumberWidgetTimePeriod?.name ?? "Description 2")}</div>
                            */}

                        </div>
              
                    )
                }
            </Box>
        </>
    );
};

export default WidgetNumberContent;
