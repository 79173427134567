import {
  LOAD_MODE_STARTED,
  LOAD_MODE_SUCCESS,
  LOAD_MODE_FAIL,
  ADD_MODE,
  UPDATE_MODE,
  DELETE_MODE
} from '../_constants/mode.constants';

const initialState =
   { isLoading: false, data: [] };

export function venueModeReducer(state = initialState, action) {

  const { type, mode } = action;

  switch (type) {
    case LOAD_MODE_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    
    case LOAD_MODE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: mode.venueMode.data,
      };
    
    case UPDATE_MODE:
      return {
        ...state,        
        data: state.data.map((e) => e.id === mode.venueMode.id ? mode.venueMode : e)
      };
      
    case DELETE_MODE:
      return {
        ...state,
        data: state.data.filter((t) => t.id !== mode.venueMode)
      };
      
    case ADD_MODE:
      return {
        ...state        
      };
    
    case LOAD_MODE_FAIL:
      return {
          ...state,
          isLoading: false,
      }
    default:
      return state;
  };
}
   
export default venueModeReducer;
