import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";
import { WidgetCameraContent } from "./WidgetCameraContent";

export const WidgetCamera = (props) => (
    <WidgetWrapper
        {...{...props, widgetType: widgetTypeConstants.widgetCamera.id}}
    >
      <WidgetCameraContent />
    </WidgetWrapper>
  );

export default WidgetCamera;
