import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {TextLink} from "../../_common/htmlTags/TextLink";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {
  getVenues,
  selectVenue,
  setHideInactiveVenues
} from "../../../_actions/venue.actions";
import {Box} from "@material-ui/core";
import {Button} from "../../_common/htmlTags/Button";
import AddIcon from "@material-ui/icons/Add";
import { Venue } from "./Venue";
import { IOSSwitch } from "./iosSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PermissionsGate from "../../_common/permissions/PermissionsGate";
import { SCOPES } from "../../../_constants/user.permissions.constants";
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage";

export const VenuesList = (props) => {
  const hideInactiveVenues = useSelector((state) => state.venueReducer.preferences.hideInactiveVenues);
  const venues = useSelector((state) => {
    let venues = state.venueReducer.venues;
    if (hideInactiveVenues) {
      venues = venues.filter((venue) => venue.venueStatus.name === 'Active');
    }
    venues.sort((a, b) => a.name.localeCompare(b.name));
    return venues;
  });
  const isLoading = useSelector(
    (state) => state.venueReducer.loading.venuesLoading
  );
  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );
  const dispatch = useDispatch();
  
  const offset = 0;

  useEffect(() => {
    dispatch(getVenues(offset));
  }, [dispatch]);

  const onSelect = (venue) => {
    if (
      (venue && selectedVenue && selectedVenue.id !== venue.id) ||
      selectedVenue === null
    ) {
      dispatch(selectVenue(venue));
    }
  };

  const onHideInactiveVenuesChange = (e) => {
    dispatch(setHideInactiveVenues(!hideInactiveVenues));
  };

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item xs={12} container justifyContent="space-between">
          <Typography variant="h4">Select venue to manage</Typography>
          <PermissionsGate
            scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_VENUE]}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              component={TextLink}
              to={"/venues/add"}
            >
              Add
            </Button>
          </PermissionsGate>
        </Grid>
        <Box display="flex" flexDirection="row-reverse" alignItems="stretch" width="100%" mt={2} mb={-2}>
          <Box width={250}>
          <FormControlLabel
            control = {<IOSSwitch
              checked={hideInactiveVenues}
              onChange={onHideInactiveVenuesChange}
              onClick={(venue) => venue.cancelBubble = true}
            />}
            label={hideInactiveVenues ? "Hide inactive venues" : "Show inactive venues"}
          />
          </Box>
        </Box>
        <Grid item xs={12}>
          <div>          
          <LoadPleaseWait show={isLoading} >
              <div>
                {venues.length !== 0 ? (
                  venues.map((venue, index) => {
                    return (
                      <Venue
                        id={index}
                        key={index}
                        venue={venue}
                        selected={
                          selectedVenue && venue.id === selectedVenue.id
                            ? true
                            : false
                        }
                        onSelect={onSelect}
                      ></Venue>
                    );
                  })
                ) : (
                  <h4 style={{ textAlign: "center" }}>
                      No active venue to display.
                      <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_ADD_VENUE]}>
                        Start by adding a new venue.
                      </PermissionsGate>                      
                  </h4>
                )}
              </div>
              </LoadPleaseWait>    
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default VenuesList;
