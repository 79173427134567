import React, { useState, useEffect, cloneElement } from "react";
import { AuthService } from '../../../_services/auth.service';
import { PERMISSIONS } from "../../../_constants/user.permissions.constants";

const authService = new AuthService();

// Function checks if user has any permission in the scopes
// depending on user's role in system.
const hasPermission = ({ permissions, scopes }) => {
    const scopesMap = {};
    scopes.forEach((scope) => {
        scopesMap[scope] = true;
    });

    return permissions.some((permission) => scopesMap[permission]);
};

// Functionality that allows to show certain components only to users 
// who have the right to do so, depending on the role.
export default function PermissionsGate({
    children,
    RenderError = () => <></>,
    errorProps = null,
    scopes = [],
    ...otherProps
}) {
    const [permissions, setPermissions] = useState([]);

    const permissionGranted = hasPermission({ permissions, scopes });

    useEffect(() => {
        let isMounted = true;

        authService.getUser().then((user) => {
            if (!isMounted) return;

            setPermissions(PERMISSIONS[user.profile.role]);
        });

        return () => {
            isMounted = false;
        }
    }, []);

    if (!permissionGranted && !errorProps) return <RenderError />;

    if (!permissionGranted && errorProps)
        return cloneElement(children, { ...errorProps });

    return <React.Fragment {...otherProps}>{children}</React.Fragment>;
}