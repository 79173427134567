import {widgetTypeConstants } from "../../../_constants";
import { WidgetContextProvider } from "../WidgetContext";
import { WidgetWrapper } from "../WidgetWrapper";
import { WidgetOccupancyContent } from "./WidgetOccupancyContent";

export const WidgetOccupancy = (props) => (
    <WidgetContextProvider>
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetOccupancy.id}}
        >
            <WidgetOccupancyContent />
        </WidgetWrapper>
    </WidgetContextProvider>
);

export default WidgetOccupancy;
