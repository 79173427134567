import axios from "axios";
import { AuthService, downloadFile } from ".";
import { ApiRootConstants } from "../_constants/index";

var Constants = {
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
};

const authService = new AuthService();

export async function axiosGet(url, options = {}) {
    const user = await authService.getUser();
    if (user?.access_token) {
      const opt = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + user.access_token,
        },
        ...options
      };
  
      return await axios
        .get(`${Constants.venueManagementApiRoot}api/VenueLocation/${url}`, opt)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            } else if (error.response.status === 422) {
              throw new Error(error.response.data.message);
            }
          }
  
          throw error;
        });
    }
  
}

export async function venueLocationsImport(file) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("fileName", file.name);
    bodyFormData.append("VenueLocationsFile", file);

    return await axios
      .post(
        Constants.venueManagementApiRoot +
          "api/VenueLocation/VenueLocationsImport",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export function venueLocationsExport(venueId) {
    return downloadFile(
        `${Constants.venueManagementApiRoot}api/VenueLocation/VenueLocationsExport/${venueId}`,
        `VenueLocations-${venueId}.xlsx`);
}

export async function getLocationById(locationId) {
    return await axiosGet(`GetLocationById/${locationId}`);
}

export async function addLocation(location) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        Constants.venueManagementApiRoot + "api/venueLocation",
        location,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function updateLocation(location) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .put(
        Constants.venueManagementApiRoot + "api/venueLocation",
        location,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function deleteLocation(locationId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("id", locationId);

    return await axios
      .post(
        Constants.venueManagementApiRoot + "api/venueLocation/delete/",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function restoreLocation(locationId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("id", locationId);

    return await axios
      .post(
        Constants.venueManagementApiRoot + "api/venueLocation/restore/",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getVenueLocations(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
      params: {
        venueId: venueId,
      },
    };

    return await axios
      .get(Constants.venueManagementApiRoot + "api/venueLocation", options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getVenueLocationsList(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
      params: {
        venueId: venueId,
      },
    };

    return await axios
      .get(
        `${Constants.venueManagementApiRoot}api/VenueLocation/GetLocationList`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
export async function getLocationTypes() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(
        Constants.venueManagementApiRoot + "api/venueLocation/locationTypes",
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
