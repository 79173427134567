export const venueLocationConstants = {
    GET_EVENT_LOCATIONS_STARTED: 'GET_EVENT_LOCATIONS_STARTED',
    GET_EVENT_LOCATIONS: 'GET_EVENT_LOCATIONS',
    GET_EVENT_LOCATIONS_LIST: 'GET_EVENT_LOCATIONS_LIST',
    ADD_EVENT_LOCATION: 'ADD_EVENT_LOCATION',
    UPDATE_EVENT_LOCATION: 'UPDATE_EVENT_LOCATION',
    DELETE_EVENT_LOCATION: 'DELETE_EVENT_LOCATION',
    RESTORE_EVENT_LOCATION: 'RESTORE_EVENT_LOCATION',
    GET_LOCATION_TYPES: 'GET_LOCATION_TYPES',
    GET_EVENT_LOCATION: 'GET_EVENT_LOCATION',
    GET_EVENT_LOCATIONS_LIST_STARTED: 'GET_EVENT_LOCATIONS_LIST_STARTED',
    // GET_EVENT_LOCATION_STATUSES: 'GET_EVENT_LOCATION_STATUSES',
    // GET_LOCATIONS_STARTED: 'GET_LOCATIONS_STARTED',
    // ERROR_HANDLE: 'ERROR_HANDLE',
    // ERROR_RESET: 'ERROR_RESET',
    // LOCATION_SELECTED: 'LOCATION_SELECTED',
    // LOCATION_RECEIVED: 'LOCATION_RECEIVED',
}
