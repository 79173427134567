import { reportConstants } from "../_constants/report.constants";
import * as reportApi from "../_services/report.service";

const getReportPublishingCronModeStarted = () => {
  return {
    type: reportConstants.GET_REPORT_PUBLISHING_CRON_MODE_STARTED,
  };
};
export const getReportPublishingCronModeSuccess = (reportPublishingCronMode) => {
  return {
    type: reportConstants.GET_REPORT_PUBLISHING_CRON_MODE_SUCCESS,
    reportPublishingCronMode: reportPublishingCronMode,
  };
};

export const publishReportData = async (venueId) => {
  return (await reportApi.publishReportData(venueId)).data;
};

export const setReportPublishingCronMode = (venueId, publishingCronMode) => {
  return async (dispatch) => {
    await reportApi
      .setReportPublishingCronMode(venueId, publishingCronMode)
      .catch((error) => {
        throw error;
      });
  };
};

export const getReportPublishingCronMode = (venueId) => {
  return async (dispatch) => {
    dispatch(getReportPublishingCronModeStarted());
    await reportApi
      .getReportPublishingCronMode(venueId)
      .then((response) => {
        dispatch(getReportPublishingCronModeSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};
