import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import { ROLES} from "../_constants/user.permissions.constants";

var Constants = {
  userManagementApiRoot: ApiRootConstants.userManagementApiRoot,
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
};
const authService = new AuthService();

export async function getUsersWithVenue() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(
        Constants.venueManagementApiRoot +
        "api/VenueUser/GetAllUsers/",
        {
          headers,
        }
      )
      .then((response) =>         
        response.data.map((item) => {
          return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            phoneNumber: item.phoneNumber,
            email: item.userName,
            roleId: item.roles[0]?.id,
            role: item.roles[0],
            venue: item.venues,
            enabled: item.enabled,
            lastSuccessfulLoginUtc: item.lastSuccessfulLoginUtc, // Zulu date-time
          };
        })      
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getUserWithVenue() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(
        Constants.venueManagementApiRoot +
        "api/VenueUser/GetCurrentUserData",
        {
          headers,
        }
      )
      .then((response) => {
        return {
          id: response.data.id,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          phoneNumber: response.data.phoneNumber,
          email: response.data.userName,
          roleId: response.data.roles[0]?.id,
          role: response.data.roles[0],
          venue: response.data.venues,
          enabled: response.data.enabled,
        };
      }
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getUsers() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(
        Constants.userManagementApiRoot +
          "api/Management/GetAllUsers?page=1&pageSize=100",
        {
          headers,
        }
      )
      .then((response) => 
        response.data.users.map((item) => {
          return {
            id: item.id,
            firstName: item.firstName,
            lastName: item.lastName,
            phoneNumber: item.phoneNumber,
            email: item.userName,
            roleId: item.roles[0]?.id,
            role: item.roles[0],
            enabled: item.enabled,
            claims: item.claims
          };
        })
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export async function getUser(id) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.userManagementApiRoot + "api/Users/" + id, {
        headers,
      })
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
  return this.users.filter((item) => item.id === id)[0];
}

export async function getUserByEmail(email) {
  const users = await getUsers();
  return users?.filter((item) => item.email === email)[0];    
}

export async function ensureUser(account) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    var bodyFormData = new FormData();
    bodyFormData.append("userName", account.email);
    bodyFormData.append("email", account.email);
    bodyFormData.append("password", account.password);
    bodyFormData.append("firstName", account.firstName);
    bodyFormData.append("lastName", account.lastName);
    bodyFormData.append("phoneNumber", account.phoneNumber);
    bodyFormData.append("role", account.role?.name);

    return axios
      .post(
        Constants.userManagementApiRoot + "api/Management/EnsureUser",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

export async function updateUser(account) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    var bodyFormData = new FormData();
    
    bodyFormData.append("userName", account.email);
    bodyFormData.append("email", account.email);
    bodyFormData.append("currentPassword", ""); // we do not change password here
    bodyFormData.append("newPassword", ""); // pass empty string
    bodyFormData.append("firstName", account.firstName);
    bodyFormData.append("lastName", account.lastName);
    bodyFormData.append("phoneNumber", account.phoneNumber);
    bodyFormData.append("role", account.role?.name);    

    return axios
      .post(
        Constants.userManagementApiRoot + "api/Management/UpdateUser",
        bodyFormData,
        options
      )     
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

export async function updateCurrentUserFullName(account) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    var bodyFormData = new FormData();
    bodyFormData.append("FirstName", account.firstName);
    bodyFormData.append("LastName", account.lastName);
    bodyFormData.append("PhoneNumber", account.phoneNumber);

    return axios
      .post(
        Constants.venueManagementApiRoot +
        "api/VenueUser/UpdateCurrentUserFullName",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

export async function changePassword(userPassword) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    var bodyFormData = new FormData();
    bodyFormData.append("userName", userPassword.userName);
    // bodyFormData.append("email", account.email);
    bodyFormData.append("currentPassword", userPassword.currentPassword);
    bodyFormData.append("newPassword", userPassword.newPassword);
    // bodyFormData.append("firstName", account.firstName);
    // bodyFormData.append("lastName", account.lastName);
    // bodyFormData.append("role", account.role?.name);

    return axios
      .post(
        Constants.userManagementApiRoot + "api/Management/UpdateUserPassword",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

export async function changePasswordAdmin(userPassword) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
       
      },
    };
    
    return axios
      .post(
        Constants.userManagementApiRoot + "api/Users/ChangePassword",
        userPassword,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
          else { 
            throw new Error(error.response.data);
          }
        }        
      });
  }
}

export async function disableUser(userName) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    var bodyFormData = new FormData();
    bodyFormData.append("userName", userName);

    return axios
      .post(
        Constants.userManagementApiRoot + "api/Management/DisableUser",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

export async function enableUser(userName) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    var bodyFormData = new FormData();
    bodyFormData.append("userName", userName);

    return axios
      .post(
        Constants.userManagementApiRoot + "api/Management/EnableUser",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

export async function deleteUser(userId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return axios
      .delete(
        Constants.venueManagementApiRoot + `api/Venue/DeleteUser/${userId}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

async function filterRole(roles, globalAdmin) {
  const user = await authService.getUser();
  if (user?.profile?.role === globalAdmin)
    return roles;
  else
    return roles.filter(f => f.name !== globalAdmin);
    
}

export async function getLoginUser() {
  return await authService.getUser();    
}

export async function getRoles() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.userManagementApiRoot + "api/Roles", {
        headers,
      })
      .then((response) =>        
        filterRole(response.data.roles, ROLES.ADMINISTRATOR)
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}

