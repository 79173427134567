import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './components/Theme';
import { Home } from './components/Home';
import { DashboardViewer } from './components/dashboardViewer/DashboardViewer';

require('dotenv').config();
function App(prop) {
  return (
    <ThemeProvider theme={theme}>
      {/* Added this route switch here to allow access to view public dashboards. This switch will check the path to public dashboards and the rest of the secure app */}
      <Switch>
        <Route path="/public-dashboard/:venueName/:dashboardName">
          <DashboardViewer/>
        </Route>
        <Route path="*">
          <Home/>
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

export default connect(
    (state) => {
      return {  }; 
    }
)(App);
