import React from "react";
import {useState} from  "react";
import {Box, List, ListItem, Typography} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AlertDialog } from "../../_helpers/AlertDialog";
import { Button, ButtonType } from "../_common/htmlTags";
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 216,
    flexGrow: 1,
    maxWidth: 400,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(1),
  },
  chooseLocationTitle: {
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "1.5em",
    margin: "5px",
    textAlign: "left",
    flexGrow: 1,
  },
  paper: {
    position: 'absolute',
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 3, 1, 3),
  },
  closeIcon: {
    fill: "#616161", 
    cursor:'pointer', 
    margin: "8px 0px 0px 15px"
  }, 
}));

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const flattenLocations = ({location, level = 0}) => {
  const result = [
    {
      id: location.id,
      name: location.name,
      isSensor: location.locationType.isSensor,
      isDeleted: location.isDeleted,
      level: level
    }];
  
  location.children.sort(sortByName).forEach((childLocation) => {
    result.push(...flattenLocations({location: childLocation, level: level + 1}));
  });

  return result;
};

export const SelectLocation = ({
    rootLocation, 
    markedLocationIds, // array of locationIds that are already linked to overlays.
    onOk, 
    onCancel}) => {
  const classes = useStyles();

  // If props.locationId === null, then we are to choose the location
  // the polygon that is being created. This is opposite to changing 
  // the location of an already created polygon.
  const [selectedLocationId, setSelectedLocationId] = useState(null);
  const [cancelAlertOpen, setCancelAlertOpen] = useState(false);

  const onListItemClick = (locationId) => {
    setSelectedLocationId(locationId);
  };

  const renderedListItems = flattenLocations({location: rootLocation})
    .filter((fl) => !fl.isSensor && !fl.isDeleted)
    .map((fl) => {
      return (
        <ListItem
          key={fl.id}
          button
          selected = {fl.id === selectedLocationId}
          disabled = {(fl.id !== selectedLocationId) && markedLocationIds.includes(fl.id)}
          onClick={(e) => onListItemClick(fl.id)}
        >
          <Box
            ml={fl.level*2}>
            {fl.name}
          </Box>
        </ListItem>
      );
    });

  return (
    <div
      className={classes.paper}>
      <AlertDialog open={cancelAlertOpen} contentText={`Do you want to cancel adding location to the map?`}
        onNoButtonClicked={() => {
          setCancelAlertOpen(false);
        }}
        onYesButtonClicked={(e) => {
          onCancel();          
          setCancelAlertOpen(false);
        }} />
      <Box
        flexDirection="column"
        alignItems="middle"
      >
        <Box
          display="flex"
        >
          <Typography
            align="center"
            className={classes.chooseLocationTitle}>
              Choose location
          </Typography>
          <CloseIcon className={classes.closeIcon} onClick={() => setCancelAlertOpen(true)}/>
        </Box>
        <List
          className={classes.root}
          component="nav"
        >
           {renderedListItems}
        </List>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "8px"
          }}>
          <Button
            variant="contained"
            buttonType={ButtonType.Primary}
            className={classes.button}
            disabled={selectedLocationId === null} 
            onClick={() => {
              onOk(selectedLocationId);
            }}
          >
            OK
          </Button>
          <Button
            variant="outlined"
            buttonType={ButtonType.Secondary}
            className={classes.button}
            onClick={() => {
              setCancelAlertOpen(true);
            }}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default SelectLocation;
