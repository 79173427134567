import React from "react" 
import { Typography, Box } from "@material-ui/core";
import { icon } from "../../../_constants";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import * as occUtils from './OccupancyUtils';
import { getWidgetCaptionFontColor, isDarkColor } from "../../../_helpers/colorUtils";
import CommonWidgetLayout from "../shared/CommonWidgetLayout";

const CurrentOccupancyWithMax = (props) => {
  const { data, viewMode, height, classes, textColor, bgColor } = props;
    
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  const highestOccupancyDateTime = new Date(data?.occupancy?.highestOccupancyDateTime); 
  const highTime = highestOccupancyDateTime?.getHours().toString().padStart(2, '0') + ":" + highestOccupancyDateTime.getMinutes().toString().padStart(2, '0');

  const iconPath = icon.path + 
    'triangle highest value ' + 
    (isDarkColor(textColor) ? 'black' : 'white') +
    '.svg';

  const occupancyNumberString = ((isViewMode && data?.occupancy?.occupancyNumber) || 0).toLocaleString("en-US");
  const maximumCapacityString = ((isViewMode && data?.location?.maximumCapacity) || 0).toLocaleString("en-US");
    
  return (
        <Box display="flex" height={height}>
            <Box display="flex" className={`${classes.containerLeftWithBorder}`}>
              <Box className={classes.containerBox}>
                <CommonWidgetLayout 
                    value={occupancyNumberString} 
                    name={occUtils.getLocationName(props)} 
                    contrastFontColor={(isDarkColor(textColor) ? 'black' : 'white')}
                />
              </Box>
            </Box>

            <Box display="flex" className={classes.containerRight}>
              <Box className={classes.containerBox}>
                <Typography className={classes.highestValueCurrentOccupancy}>
                  <img
                    src={iconPath}
                    alt=""
                    style={{ padding:"5px", marginTop: "-5px", width: "30px" }}
                  />
                  {((isViewMode && data?.occupancy?.highestOccupancyNumberToday) || 0).toLocaleString("en-US")}
                </Typography>

                <Typography className={classes.highestValueTitleCurrentOccupancy} style={{color: getWidgetCaptionFontColor(bgColor, textColor)}}>
                  Peaked today<br/>
                  at {(highTime.indexOf("NaN") <0 ? highTime : "00:00")}
                  </Typography>
              </Box>
            </Box>
        </Box>
  );
}

export default CurrentOccupancyWithMax;
