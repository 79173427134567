import React  from 'react';
import {
  Tab,
} from "@material-ui/core"
import PermissionsGate from "./PermissionsGate";

export const WrappedTab = (props) => {
  const { scopes, ...otherProps } = props;
  return (
    <PermissionsGate scopes={scopes}>
      <Tab {...otherProps} />
    </PermissionsGate>
  )
};
WrappedTab.muiName = "Tab";

export default WrappedTab;