import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import allReducers from '../_reducers';
import featureToggle from '../models/featureToggle';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
    allReducers,
    composeEnhancers(
        featureToggle.DEVCONSOLE_ENABLED ?
            applyMiddleware(
                thunk,
                createLogger()
            ) :
            applyMiddleware(
                thunk
            )
    )
);