import { venueConstants } from "../_constants/venue.constants";
import * as venueApi from "../_services/venue.service";
import * as reportViewApi from "../_services/report.view.service";
import * as userGroupApi from "../_services/venue.userGroup.service";


// const venueDeleted = (isDeleted) => {
//   return {
//     type: venueConstants.DELETE_VENUE,
//     isDeleted: isDeleted,
//   };
// };



// const errorReset = () => {
//   return {
//     type: venueConstants.ERROR_RESET,
//     error: null,
//   };
// };

// const resetAllVenues = () => {
//   return {
//     type: venueConstants.RESET_ALL_VENUES,
//     venues: [],
//   };
// };

const getVenuesStarted = () => {
  return {
      type: venueConstants.GET_VENUES_STARTED,
  };
};

const getVenuesSuccess = (venues) => {
  return {
      type: venueConstants.GET_VENUES,
    venues: venues,
  };
};

const getTimeZonesStarted = () => {
  return {
    type: venueConstants.GET_TIMEZONES_STARTED,
  };
};

const getTimeZonesSuccess = (timeZones) => {
  return {
    type: venueConstants.GET_TIMEZONES,
    timeZones: timeZones,
  }
}

const venueAdded = (isAdded, venue) => {
  return {
      type: venueConstants.ADD_VENUE,
    isAdded: isAdded,
    venue: venue,
  };
};

const errorHandle = (error) => {
  return {
    type: venueConstants.ERROR_HANDLE,
    error: error,
  };
};

const venueUpdated = (isUpdated, venue) => {
  return {
    type: venueConstants.UPDATE_VENUE,
    isUpdated: isUpdated,
    venue: venue    
  };
};

const getVenueStarted = () => {
  return {
    type: venueConstants.GET_VENUE_STARTED,
  };
};

const venueSelected = (selectedVenue) => {
  return {
    type: venueConstants.SELECT_VENUE,
    selectedVenue: selectedVenue,
  };
};

const getVenueBrandingSettingsSuccess = (venueBrandingSettings) => {
  return {
    type: venueConstants.GET_VENUE_BRANDING_SETTINGS_SUCCESS,
    venueBrandingSettings: venueBrandingSettings,
  };
};

const getVenueBrandingMainLogoSuccess = (venueBrandingMainLogo) => {
  return {
    type: venueConstants.GET_VENUE_BRANDING_MAIN_LOGO_SUCCESS,
    venueBrandingMainLogo: venueBrandingMainLogo,
  };
};

const getVenueBrandingAlternativeLogoSuccess = (venueBrandingAlternativeLogo) => {
  return {
    type: venueConstants.GET_VENUE_BRANDING_ALTERNATIVE_LOGO_SUCCESS,
    venueBrandingAlternativeLogo: venueBrandingAlternativeLogo,
  };
};

const getVenueBrandingFooterLogoSuccess = (venueBrandingFooterLogo) => {
  return {
    type: venueConstants.GET_VENUE_BRANDING_FOOTER_LOGO_SUCCESS,
    venueBrandingFooterLogo: venueBrandingFooterLogo,
  };
};

export const updateVenueBrandingSettingsSuccess = (venueBrandingSettings) => {
  return {
    type: venueConstants.UPDATE_VENUE_BRANDING_SETTINGS_SUCCESS,
    venueBrandingSettings: venueBrandingSettings,
  };
};

const settingsTabSelected = (pageName, tabName) => {
  return {
    type: venueConstants.SELECT_SETTINGS_TAB,
    pageName: pageName,
    tabName: tabName,
  };
};

const setVenueStatusSuccess = (venue) => {
  return {
    type: venueConstants.UPDATE_VENUE_STATUS,
    venue: venue,
  };
};

const updateHideInactiveVenues = (hide) => {
  return {
      type: venueConstants.UPDATE_HIDE_INACTIVE_VENUES,
    hide: hide
  };
};

const getReportViewsSuccess = (venue, reportViews) => {
  return {
    type: venueConstants.GET_REPORT_VIEWS_SUCCESS,
    venueId: venue.id,
    reportViews: reportViews
  };
};

const getUserGroupsSuccess = (venue, userGroups) => {
  return {
    type: venueConstants.GET_USER_GROUPS_SUCCESS,
    venueId: venue.id,
    userGroups: userGroups
  };
};

// const venueStatusesSelected = (selectedVenueStatuses) => {
//   return {
//     type: venueConstants.GET_VENUE_STATUSES,
//     venueStatuses: selectedVenueStatuses,
//   };
// };

export const getTimeZones = () => {
  return async (dispatch) => {
    dispatch(getTimeZonesStarted());
    await venueApi
      .getTimeZones()
      .then((response) => {
        dispatch(getTimeZonesSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getVenues = (offset) => {
  return async (dispatch) => {
    dispatch(getVenuesStarted());
    await venueApi
      .getVenues(offset)
      .then((response) => {
        dispatch(getVenuesSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addVenue = (venue) => {
  venue = { ...venue, VenueStatus: { Name: "Active" } };
  return async (dispatch) => {
    await venueApi
      .addVenue(venue)
      .then((response) => {
        dispatch(venueAdded(true, { ...response.data, venueStatus: "Active" }));
      })
      .catch((error) => {
        dispatch(errorHandle(error));
        throw error;
      });
  };
};

export const updateVenue = (venue, venueID) => {
  venue = { ...venue, ID: venueID };
  return async (dispatch) => {
    await venueApi
       .updateVenue(venue)
       .then((response) => {
         dispatch(venueUpdated(true, response.data));
       })
       .catch((error) => {
         dispatch(errorHandle(error));
       });
  };
};

export const getVenueById = (venueId) => {
  return async (dispatch) => {
    dispatch(getVenueStarted());
    await venueApi
      .getVenueById(venueId)
       .then((response) => {
         dispatch(venueSelected(response.data));
       })
      .catch((error) => {
        throw error;
      });
  };
};

export const selectVenue = (venue) => {
  return (dispatch) => {
    dispatch(venueSelected(venue));
  };
};

export const selectSettingsTab = (pageName, tabName) => {
  return (dispatch) => {
    dispatch(settingsTabSelected(pageName, tabName));
  };
};

export const setVenueStatus = (venueId, venueStatus) => {
  return async (dispatch) => {
    await venueApi
      .setVenueStatus(venueId, venueStatus)
      .then((response) => {
        dispatch(setVenueStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(errorHandle(error));
      });
  };
};

export const setHideInactiveVenues = (hide) => {
  return (dispatch) => {
    dispatch(updateHideInactiveVenues(hide));
  };
};

export const getVenueBrandingSettings = (venueId) => {
  return async (dispatch) => {
    await venueApi
      .getVenueBrandingSettings(venueId)
      .then((response) => {
        dispatch(getVenueBrandingSettingsSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getVenueBrandingMainLogo = (venueId) => {
  return async (dispatch) => {
    await venueApi
      .getVenueBrandingMainLogo(venueId)
      .then((response) => {
        dispatch(getVenueBrandingMainLogoSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getVenueBrandingAlternativeLogo = (venueId) => {
  return async (dispatch) => {
    await venueApi
      .getVenueBrandingAlternativeLogo(venueId)
      .then((response) => {
        dispatch(getVenueBrandingAlternativeLogoSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getVenueBrandingFooterLogo = (venueId) => {
  return async (dispatch) => {
    await venueApi
      .getVenueBrandingFooterLogo(venueId)
      .then((response) => {
        dispatch(getVenueBrandingFooterLogoSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateVenueBrandingSettings = (venueBrandingSettings) => {
  return async (dispatch) => {
    await venueApi
      .updateVenueBrandingSettings(venueBrandingSettings)
      .then(() => {
        venueBrandingSettings.mainLogo = venueBrandingSettings.mainLogo ? new Blob([venueBrandingSettings.mainLogo]) : null;
        venueBrandingSettings.alternativeLogo = venueBrandingSettings.alternativeLogo ? new Blob([venueBrandingSettings.alternativeLogo]) : null;
        venueBrandingSettings.footerLogo = venueBrandingSettings.footerLogo ? new Blob([venueBrandingSettings.footerLogo]) : null;        

        dispatch(updateVenueBrandingSettingsSuccess(venueBrandingSettings));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getReportViews = (venue) => {
  return async (dispatch) => {
    let reportViews = [];
    await reportViewApi.getReportViews(venue.id)
      .then((response) => {
        reportViews = response.data;
      })
      .catch((error) => {
        throw error;
      });
    dispatch(getReportViewsSuccess(venue, reportViews)); 
  };
};


export const getUserGroups = (venue) => {
  return async (dispatch) => {
    let userGroups = [];
    await userGroupApi.getUserGroups(venue.id)
      .then((response) => {
        userGroups = response.data;
      })
      .catch((error) => {
        throw error;
      });
    dispatch(getUserGroupsSuccess(venue, userGroups)); 
  };
};
//----------------------------------------------------------------------------------
// export const deleteVenue = (venueID) => {
//   return (dispatch) => {
//     axios.delete(`${MAIN_URL}venue/${venueID}`).then((response) => {
//       dispatch(venueDeleted(response));
//     });
//   };
// };

// export const getVenueStatuses = () => {
//   return async (dispatch) => {
//     const authService = new AuthService();
//     const user = await authService.getUser();
//     if (user && user.access_token) {
//       const headers = {
//         Accept: "application/json",
//         Authorization: "Bearer " + user.access_token,
//       };

//       axios
//         .get(`${MAIN_URL}venuestatuses`, { headers })
//         .then((venueStatuses) => {
//           dispatch(venueStatusesSelected(venueStatuses.data));
//         });
//     }
//   };
// };

// export const resetError = () => {
//   return (dispatch) => {
//     dispatch(errorReset());
//   };
// };
