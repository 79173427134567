import {
  venueUserConstants
} from '../_constants/venue.user.constants';

const initialState =
   { isLoading: false, data: [] };

export function venueUserReducer(state = initialState, action) {

  const { type, venueUser } = action;

  switch (type) {
    case venueUserConstants.LOAD_STARTED:
      return {
        ...state,
        isLoading: true,
      }
    
    case venueUserConstants.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: venueUser.venueUser?.data,
      };
      
    case venueUserConstants.DELETE:
      return {
        ...state,
        data: state.data.filter((t) => t.id !== venueUser.venueUser?.data)
      };
      
    case venueUserConstants.ADD:
      return {
        ...state        
      };    
   
    default:
      return state;
  };
}
   
export default venueUserReducer;
