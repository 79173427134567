
import venueUserConstants from "../_constants/venue.user.constants";
import * as service from "../_services/venue.user.service";


export const loadStarted = () => ({
  type: venueUserConstants.LOAD_STARTED,
});

export const loadSuccess = (venueUser) => ({
  type: venueUserConstants.LOAD_SUCCESS,
  venueUser: { venueUser },
});

export const loadFailed = () => ({
  type: venueUserConstants.LOAD_FAIL,
});

export const addSuccess =  (venueUser) => ({
  type: venueUserConstants.ADD,
  venueUser: { venueUser },
});

export const deleteSuccess =  (venueUser) => ({
  type: venueUserConstants.DELETE,
  venueUser: { venueUser },
});


export const loadVenueUsers = (venueId) => {
  return async (dispatch) => {
    dispatch(loadStarted());
    await service
      .getVenueUsers(venueId)
      .then((data) => {
        dispatch(loadSuccess(data));
      })
      .catch((error) => {
        dispatch(loadFailed);
        throw error;
      });
  };
};

export const addVenueUser = (venueUser) => {
  return async (dispatch) => {
    await service
      .addVenueUser(venueUser)
      .then(() => {
        dispatch(addSuccess(venueUser));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateUserVenues = (venueUser) => {
  return async () => {
    await service
      .updateUserVenues(venueUser)      
      .catch((error) => {
        throw error;
      });
  };
};

export const addUserVenues = (venueUser) => {
  return async () => {
    await service
      .addUserVenues(venueUser)      
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteVenueUser = (venueUser) => {
  return async (dispatch) => {
    await service
      .deleteVenueUser(venueUser)
      .then(() => {
        dispatch(deleteSuccess(venueUser));
      })
      .catch((error) => {
        throw error;
      });
  };
};