import * as userConstants from "../_constants/user.management.constants";
import * as userApi from "../_services/userManagement.service";
import * as venueUserApi from "../_services/venue.user.service";
import * as dashboardApi from "../_services/dashboard.service";
import devconsole from "../components/_common/devconsole";

export const loadRolesSuccess = (roles) => {
    return {
      type: userConstants.LOAD_ROLES_SUCCESS,
      roles: roles,
    };
  };

  export const getUsersStarted = () => {
    return {
      type: userConstants.LOAD_USERS_STARTED,
    };
  };
    
export const loadUsersSuccess = (users) => {
  return {
    type: userConstants.LOAD_USERS_SUCCESS,
    users: users,
  };
};

export const ensureUserSuccess = (user) => {
  return {
    type: userConstants.ENSURE_USER_SUCCESS,
    user: user,
  };
};

export const updateUserSuccess = (user) => {
  return {
    type: userConstants.UPDATE_USER_SUCCESS,
    user: user,
  };
};

export const changePasswordSuccess = (userPassword) => {
  return {
    type: userConstants.CHANGE_PASSWORD_SUCCESS,
    userPassword: userPassword,
  };
};

export const disableUserSuccess = (userName) => {
  return {
    type: userConstants.DISABLE_USER_SUCCESS,
    userName: userName
  }
};

export const enableUserSuccess = (userName) => {
  return {
    type: userConstants.ENABLE_USER_SUCCESS,
    userName: userName
  }
};

export const deleteUserSuccess = (userId) => {
  return {
    type: userConstants.DELETE_USER_SUCCESS,
    userId: userId
  }
};

export const loadRoles = () => {
    return async (dispatch) => {
      await userApi
        .getRoles()
        .then((roles) => {
          if (roles)
            dispatch(loadRolesSuccess(roles));
        })
        .catch((error) => {
          throw error;
        });
    };
};
  
export const loadUsers = () => {
  return async (dispatch) => {
    dispatch(getUsersStarted());
    await userApi
      .getUsersWithVenue()
      .then((users) => {
        devconsole.log("loadUsers: ", users);
          if (users)
            dispatch(loadUsersSuccess(users));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const ensureUser = (user) => {
  return async (dispatch) => {
    await userApi
      .ensureUser(user)
      .then(() => {
        dispatch(ensureUserSuccess(user));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateUser = (user) => {
  return async (dispatch) => {
    await userApi
      .updateUser(user)
      
      .then(() => {
        dispatch(updateUserSuccess(user));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const changePassword = (userPassword) => {
  return async (dispatch) => {
    await userApi
      .changePassword(userPassword)
      .then(() => {
        dispatch(changePasswordSuccess(userPassword));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const changePasswordAdmin = (userPassword) => {
  return async (dispatch) => {
    await userApi
      .changePasswordAdmin(userPassword)
      .then(() => {
        dispatch(changePasswordSuccess(userPassword));
      })
      .catch((error) => {
        throw error.message;
      });
  };
};

export const disableUser = (userName) => {
  return async (dispatch) => {
    await userApi
      .disableUser(userName)
      .then(() => {
        dispatch(disableUserSuccess(userName));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const enableUser = (userName) => {
  return async (dispatch) => {
    await userApi
      .enableUser(userName)
      .then(() => {
        dispatch(enableUserSuccess(userName));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const deleteUser = (userId) => {
  return async (dispatch) => {
    try {
      await userApi.deleteUser(userId);
      dispatch(deleteUserSuccess(userId));
    } catch(error) { 
      devconsole.log("Caught exception in user.management.actions.js deleteUser", error)
      throw error;
    }
  };
};
