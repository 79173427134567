import { venueLocationConstants } from "../_constants/venue.location.constants";

const initialState = {
  venueLocations: [],
  error: null,
  loading: {
    locationLoading: false,
    locationsLoading: false,
  },
  selectedLocation: null,
  locationTypes: [],
  isAdded: false,
  venueLocationList: [],
};

const setIsDeletedLocation = (location, idToRemove, isDeleted) => {
    if (location.id === idToRemove) {
        location.isDeleted = isDeleted;
        location.children?.forEach(element => setIsDeletedLocation(element, element.id, isDeleted));
    }
    else {
        location.children?.forEach(element => setIsDeletedLocation(element, idToRemove, isDeleted));
    }
};

const removeLocation = (location, idToRemove) => setIsDeletedLocation(location, idToRemove, true);

const restoreLocation = (location, idToRemove) => setIsDeletedLocation(location, idToRemove, false);

export function venueLocationReducer(state = initialState, action) {
  switch (action.type) {
    case venueLocationConstants.GET_LOCATION_STARTED:
      return {
        ...state,
        loading: { ...state.loading, locationLoading: true },
      };
    case venueLocationConstants.GET_EVENT_LOCATIONS_STARTED:
      return {
        ...state,
        loading: { ...state.loading, locationsLoading: true },
      };
    case venueLocationConstants.GET_EVENT_LOCATIONS:
      return {
        ...state,
        venueLocations: action.venueLocations,
        loading: { ...state.loading, locationsLoading: false },
      };
    case venueLocationConstants.GET_EVENT_LOCATIONS_LIST:
      return {
        ...state,
        venueLocationList: action.venueLocationList,
        loading: { ...state.loading, locationsLoading: false },
      };
    case venueLocationConstants.ADD_EVENT_LOCATION:
      return {
        ...state,
        loading: { ...state.loading, locationLoading: false },
        venueLocations: [...state.venueLocations, action.location],
      };
    case venueLocationConstants.UPDATE_EVENT_LOCATION:
      return {
        ...state,
        loading: { ...state.loading, locationsLoading: false },
        venueLocations: state.venueLocations.map((l) =>
          l.id === action.location.id ? action.location : l
        ),
      };
    case venueLocationConstants.DELETE_EVENT_LOCATION:
      const updatedList = [...state.venueLocations];
      removeLocation(updatedList[0], action.locationId);
      return {
        ...state,
        loading: { ...state.loading, locationLoading: false },
        venueLocations: updatedList,
      };
    case venueLocationConstants.RESTORE_EVENT_LOCATION:
      const restoredList = [...state.venueLocations];
      restoreLocation(restoredList[0], action.locationId);
      return {
        ...state,
        loading: { ...state.loading, locationLoading: false },
        venueLocations: restoredList,
      };
    case venueLocationConstants.GET_LOCATION_TYPES:
      return {
        ...state,
        locationTypes: action.locationTypes,
      };
    case venueLocationConstants.GET_EVENT_LOCATION:
      return {
        ...state,
        selectedLocation: action.location,
        loading: { ...state.loading, locationLoading: false },
      };
    // case venueLocationConstants.LOCATION_SELECTED:
    //   return {
    //     ...state,
    //     location: action.location
    //   };
    // case venueLocationConstants.GET_LOCATIONS_STARTED:
    //   return {
    //     ...state,
    //     loading: {...state.loading, locationsLoading: true}
    //   }
    // case venueLocationConstants.ERROR_HANDLE:
    //   return {
    //     ...state,
    //     error: action.error
    //   };
    // case venueLocationConstants.ERROR_RESET:
    //   return {
    //     ...state,
    //     error: null
    //   };
    default:
      return state;
  }
}

export default venueLocationReducer;
