import React, { useState } from 'react';
import WidgetLabelContent from './WidgetLabelContent';
import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";

export const WidgetLabel = (props) => {
    const [isCaptionTextVisible, setIsCaptionTextVisible] = useState(true);

    return (
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetLabel.id,
                isCaptionTextVisible: isCaptionTextVisible }}
        >
            <WidgetLabelContent setIsCaptionTextVisible={setIsCaptionTextVisible} />
        </WidgetWrapper>
    );
}

export default WidgetLabel;
