import { tableauReportConstants } from "../_constants/tableauReport.constants";
import * as reportApi from "../_services/report.service";

export const publishTableauDataSuccess = (isPublished) => {
  return {
    type: tableauReportConstants.PUBLISH_TABLEAU_DATA,
    isPublished: isPublished,
  };
};

const getTableauPublishingCronModeStarted = () => {
  return {
    type: tableauReportConstants.GET_TABLEAU_PUBLISHING_CRON_MODE_STARTED,
  };
};

export const getTableauPublishingCronModeSuccess = (tableauPublishingCronMode) => {
  return {
    type: tableauReportConstants.GET_TABLEAU_PUBLISHING_CRON_MODE_SUCCESS,
    tableauPublishingCronMode: tableauPublishingCronMode,
  };
};


export const setTableauPublishingCronMode = (venueId, isAuto) => {
  return async (dispatch) => {
    await reportApi
      .setTableauPublishingCronMode(venueId, isAuto)
      .catch((error) => {
        throw error;
      });
  };
};

export const getTableauPublishingCronMode = (venueId) => {
  return async (dispatch) => {
    dispatch(getTableauPublishingCronModeStarted());
    await reportApi
      .getTableauPublishingCronMode(venueId)
      .then((response) => {
        dispatch(getTableauPublishingCronModeSuccess(response.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};