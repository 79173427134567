import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography
} from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { toast } from "react-toastify";
import { Button, ButtonType } from "../../../../_common/htmlTags/Button";
import { updateAlertSettings } from "../../../../../_services/venue.service";

const useStyles = makeStyles((theme) => ({
  thresholdSelect: {
    width: 175,
    marginLeft: theme.spacing(2)
  },
  saveButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: 100
  },
  infoIcon: {
    color: theme.palette.color.primary.main,
    height: "22px",
    width: "22px",
    marginLeft: 15,
    alignSelf: "center"
  },
  popoverLikeTooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: "0px 0px 4px 0px #00000040",
    width: "200px",
  },
  popoverLikeTooltipArrow: {
    "&::before": {
      backgroundColor: theme.palette.common.white,
      boxShadow: "0px 0px 4px 0px #00000040",
    }
  },
}));

export const SmsEmailThreshold = ({venue}) => {
  const classes = useStyles();
  const [threshold, setThreshold] = useState(venue.smsEmailThreshold);
  const [isSaving, setIsSaving] = useState(false);

  const handleChange = (e) => {
    setThreshold(e.target.value);
  };

  const handleSave = () => {
    setIsSaving(true);
    updateAlertSettings(venue.id, threshold).then(() => {
      toast.success("Saved SMS/email threshold.");
      setIsSaving(false);
    }).catch((error) => {
      toast.error("Failed to save SMS/email threshold. " + error, {autoClose: false});
      setIsSaving(false);
    })
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start"
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "baseline"
        }}
      >
        <Typography>Send recurring SMS/email</Typography>
        <FormControl
          variant="outlined"
          required
        >
        <Select
          id="thresholdSelect"
          value={threshold}
          onChange={handleChange}
          className={classes.thresholdSelect}
          size="small"
        >
          <MenuItem value="0">Always</MenuItem>
          <MenuItem value={5}>Once in 5 mins</MenuItem>
          <MenuItem value={10}>Once in 10 mins</MenuItem>
          <MenuItem value={30}>Once in 30 mins</MenuItem>
        </Select>
        </FormControl>
        <Tooltip 
            title="Rapid periodic changes of occupancy, traffic etc. values may trigger the same alert rules multiple times in a short period of time. To reduce the number of repeated SMS/emails, pause the messages for the same alert rule, location and indicator for the specified period of time."
            arrow
            placement="top-start"
            classes={{
              tooltip: classes.popoverLikeTooltip,
              arrow: classes.popoverLikeTooltipArrow
            }}
        >
          <InfoOutlinedIcon
            className={classes.infoIcon}
          />
        </Tooltip>
      </Box>
      <Button
        buttonType={ButtonType.Primary}
        className={classes.saveButton}
        disabled={isSaving}
        onClick = {handleSave}
      >
        {isSaving ? "Saving..." : "Save"}
      </Button>
    </Box>
  );
};

export default SmsEmailThreshold;