import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core';
import { Typography } from "@material-ui/core";
import {PowerBIEmbed} from "powerbi-client-react";
import { models, Report, Embed, service, Page } from 'powerbi-client';
import { getPowerBiData } from "../../_services/report.powerbi.service";

const useStyles = makeStyles((theme) => {
  return {
    reportContent: {
      width: "100%",
      height: "calc(100vh - 110px)"
    },

  };
});


export const PowerBiReport = () => {
  const classes = useStyles();
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);
  const [powerBiData, setPowerBiData] = useState(null);
  const { workspaceId, reportId } = useParams();
  const [report, setReport] = useState();

  useEffect(() => {
    if (report) {
      console.log("setComponentTitle");
      report.setComponentTitle('Embedded Report');
      console.log("Embedded Report");
    }
  }, [report]);

  useEffect(() => {
    if (powerBiData == null) {
      getPowerBiData(workspaceId, reportId)
        .then((data) => {
          setPowerBiData(data.data);
        })
        .catch((err) => {
          console.error(`Publish data for reports failed.`, err);
          
        });    
      }
  }, [powerBiData]);
  //https://playgroundbe-bck-1.azurewebsites.net/Reports/SampleReport

     return powerBiData !== null ? (
       <>
         <PowerBIEmbed
           embedConfig={{
             type: powerBiData.type, // Supported types: report, dashboard, tile, visual, qna, paginated report and create
             id: powerBiData.id, // report id
             embedUrl: powerBiData.embedUrl,
             accessToken: powerBiData.token,
             tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
             permissions: models.Permissions.All,
             reportName: powerBiData.reportName,
             settings: {
              //  panes: {
              //    filters: {
              //      expanded: false,
              //      visible: true,
              //    },
              //  },
               background: models.BackgroundType.Transparent,

               filterPaneEnabled: true,
               navContentPaneEnabled: true,
               visualSettings: {
                 visualHeaders: [
                   {
                     settings: {
                       visible: false,
                     },
                     /* No selector - Hide visual header for all the visuals in the report */
                   },
                 ],
               },
               hideErrors: true,
               localeSettings: {
                 language: "en",
               },
               layoutType: models.LayoutType.Custom,
               customLayout: {
                 displayOption: models.DisplayOption.FitToWidth,
               },
               //layoutType: models.LayoutType.MobileLandscape
             },
           }}
           eventHandlers={
             new Map([
               [
                 "loaded",
                 function () {
                   console.log("Report loaded");
                 },
               ],
               [
                 "rendered",
                 function () {
                   console.log("Report rendered");
                 },
               ],
               [
                 "error",
                 function (event) {
                   console.log(event.detail);
                 },
               ],
               ["visualClicked", () => console.log("visual clicked")],
               ["pageChanged", (event) => console.log(event)],
             ])
           }
           cssClassName={classes.reportContent}
           getEmbeddedComponent={(embeddedReport) => {
             console.log("embeddedReport", embeddedReport);
             setReport(embeddedReport);
           }}
           
         />
       </>
     ) : (
       <>Loading PowerBI Report...</>
     );
} 




export default PowerBiReport;