import React, { useState } from 'react';
import WidgetEmbeddingContent from './WidgetEmbeddingContent';
import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";

export const WidgetEmbedding = (props) => {
    const [isCaptionTextVisible, setIsCaptionTextVisible] = useState(true);

    return (
        <WidgetWrapper
            {...{
                ...props, widgetType: widgetTypeConstants.widgetEmbedding.id,
                isCaptionTextVisible: isCaptionTextVisible
            }}
        >
            <WidgetEmbeddingContent setIsCaptionTextVisible={setIsCaptionTextVisible} />
        </WidgetWrapper>
    );
}

export default WidgetEmbedding;