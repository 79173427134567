import React from "react" 
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";
import { icon } from "../../_constants";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.emptyContainer,  
}));

const iconName = 'SettingsSuggest.svg';
    
export const EmptyContainer = (props) => {
  const classes = useStyles(props);

  return (
    <Box className={classes.emptyContent} >
      <Typography className={classes.emptyContent}>{props.text ?? 'Configure Widget'}</Typography>
      <Box display="flex" flexDirection="column">
        <Typography>
          <img
            src={icon.path + iconName}
            alt={iconName}
            className={classes.icon}
          />
        </Typography>
      </Box>
    </Box>
  );
};

    
export const EmptyContainerWithTwoBoxes = (props) => {
  const classes = useStyles(props);

    return (
      <Box display="flex" height={props.height}>
       <Box display="flex"  className={`${classes.containerLeftWithBorder}`}>
          <Box className={classes.containerBox}>
            <Typography className={classes.emptyContent}>{props.text ?? 'Configure Widget'}</Typography>
          </Box>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          className={classes.containerRight}
        >
          <Typography>
            <img
              src={icon.path + iconName}
              alt={iconName}
              className={classes.icon}
            />
          </Typography>
        </Box>
      </Box>
    );
};



export default EmptyContainer;

