import React, { useState } from "react";
import * as armCustomDataSourceApi from "../../../../_services/vendor-arm-api.armcustomdatasource.service";
import { template } from "../../../../_constants";
import ImportFileModal from "../../../_common/ImportFileModal";

export const ARMCustomDataSourcePopup = ({
  showModal,
  setShowModal,
  refreshParent = () => { },
}) => {
  const [selectedFile, setSelectedFile] = useState();
  
  return (
    <ImportFileModal
      importingEntityName="Custom ARM data source"

      showModal={showModal}
      setShowModal={setShowModal}

      refreshParent={refreshParent}

      onTemplateDownloadClick={() => window.open(template.path + 'ARMDemoData.xlsx')}

      apiImport={() => armCustomDataSourceApi.armCustomDataSourceImport(selectedFile)}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
    />
  );
};

export default ARMCustomDataSourcePopup;