import React from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  InputLabel,
  Select,FormControlLabel,
  MenuItem, Checkbox
} from "@material-ui/core";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { Button, ButtonType } from "../../_common/htmlTags/Button";

 const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),      
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  selectControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),    
  },
  buttons: {
    marginLeft: theme.spacing(1),
  },
}));

export const AddDashboardForm = ({ dashboard, venueModes, onSave, onChange, saving = false, errors = {} }) => {
  const classes = useStyles();
  const history = useHistory();
  
  const handleSave = (e) => {
    e.preventDefault();
    onSave(e);
  }

  return (
    <form autoComplete="off" onSubmit={handleSave}>
      <div>
        <Container fluid>
            <Row>
            <Col md={6} lg={6}>
            <div className={classes.formInput}>
                <TextField
                  id="name"
                  label="Name"
                  name="name"
                  value={dashboard.name}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
                </div>
              </Col>
            </Row>
            <Row>
            <Col md={6} lg={6}>
            <div className={classes.formInput}>
                <TextField
                  id="description"
                  label="Description"
                  name="description"
                  value={dashboard.description}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
                </div>
              </Col>
          </Row>         
          <Row>
          <Col md={6} lg={6}>
            <div className={`${classes.formInput}  `}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel id="venueModeId-label">Venue mode</InputLabel>
                <Select 
                  labelId="venueModeId-label"
                  id="venueModeId"
                  name="venueModeId"
                  value={dashboard.venueModeId ?? ""}
                  onChange={onChange}
                  label="VenueModeId"                    
                >
                  {venueModes.map((e) => (
                    <MenuItem key={e.id} value={e.id}>
                      {e.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControlLabel
                id="isProtected"
                name="isProtected"
                label="Make public"
                control={<Checkbox checked={!dashboard.isProtected} onChange={onChange} />}
              />              
            </Col>
          </Row>
          <Row>
            <Col>
              <div >
                <Button
                  type="submit"
                  variant="contained"
                >
                  {saving ? "Saving..." : "Save"}
                </Button>
                <Button
                  variant="outlined"
                  buttonType={ButtonType.Secondary}
                  className={classes.margin}
                  onClick={() => {
                    history.push("/dashboards");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        
        </Container>
      </div>
    </form>
  );
};