import React, { useState } from "react";
import * as venueEventApi from "../../../../_services/venue.event.service";
import { template } from "../../../../_constants";
import ImportFileModal from "../../../_common/ImportFileModal";

export const ImportEvents = ({
  showModal,
  setShowModal,
  venueId, refreshEvents = () => { },
}) => {
  const [selectedFile, setSelectedFile] = useState(null);

  return (
    <ImportFileModal
      importingEntityName="Venue events"

      showModal={showModal}
      setShowModal={setShowModal}

      refreshParent={refreshEvents}

      onTemplateDownloadClick={() => window.open(template.path + 'VenueEventsBulkUpdate.xlsx')}

      apiImport={() => venueEventApi.venueEventsImport(venueId, selectedFile)}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
    />
  );
}

export default ImportEvents;