import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography
} from "@material-ui/core";
import { toast } from "react-toastify";
import LoadPleaseWait from "../notification/LoadingPleaseWait/LoadingMessage"
import {useApiGet} from "../../_helpers/useApiGet";
import {getFactorTypeVendorMappings, getCurrentFactorsByLocationId, addCurrentFactor} from "../../_services/factors.service";
import {ExplicitGhostTextField} from "../_common/ExplicitGhostTextField";

const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "15px",
    padding: "15px 20px 10px 20px",
  },
  vendorHeader: {
    height: "50px"
  },
  oddVendorBackground: {
    backgroundColor: theme.palette.venueSettings.dataSources.oddVendorBackground
  },
  factorTypeLabel: {
    fontWeight: 500,
    fontSize: 15,
    textTransform: "uppercase",
  },
  factorTypeHeader: {
    height: "50px",
  },
  oddFactorTypeBackground: {
    background: theme.palette.venueSettings.dataSources.locationsBackground
  },
  vendorLabel: {
    fontWeight: 500,
    fontSize: 15
  },
  vendorFactor: {
    height: "50px",
    width: "100%",
  },
  radio: {
    color: theme.palette.venueSettings.dataSources.radioButton,
    '&$checked': {
      color: theme.palette.venueSettings.dataSources.radioButton,
    }
  },
  radioChecked: {    
  },
  vendorCodeOk: {
    color: theme.palette.color.success.main,
    width: "30px",
    height: "100%",
    marginRight: "0px"
  },
  vendorCodeCancel: {
    color: theme.palette.color.danger.main,
    width: "30px",
    height: "100%"
  },
}));

const getFactorTypeVendorMappingsCall = async (venueId) => {
  if (venueId) {
    const result = await getFactorTypeVendorMappings();
    return result;
  } else {
    return [];
  }
};

const getCurrentFactorsByLocationIdCall = async ({venueId, locationId}) => {
  if (venueId && locationId) {
    const result = await getCurrentFactorsByLocationId(venueId, locationId);
    return result.data;
  } else {
    return [];
  }
};

export const Matrix = ({venue, location}) => {
  const classes = useStyles();

  // The "data" here is the array of factor type-vendor mappings as they are loaded from the database.
  const [{data: factorTypeVendorMappings, isLoading : isFactorTypeLoading}] = useApiGet(getFactorTypeVendorMappingsCall, venue.id, []);

  const [factorTypes, setFactorTypes] = useState([]);
  const [vendors, setVendors] = useState([]);

  // The matrix UI does not have a Save button, and the changes are 
  // persisted into the database immediately after the user
  // changes a factor and click somewhere else.
  // While the changes are being persisted into the database,
  // it is possible for the user to do more changes on the UI.
  // We can, theoretically, save these following changes too, but
  // this may lead to problems if the first change 
  // fails and does not get saved to the database.
  const [isSaving, setIsSaving] = useState(false);

  useEffect(()=> {
    if (factorTypeVendorMappings) {
      const newFactorTypes = [];
      factorTypeVendorMappings.forEach((m) => {
        if (!newFactorTypes.find((ft) => ft.id === m.factorType.id)) {
          newFactorTypes.push({
            id: m.factorType.id,
            name: m.factorType.name,
            indicatorId: m.factorType.operationalIndicatorId
          });
        }
      });
      setFactorTypes(newFactorTypes);

      const newVendors = [];
      factorTypeVendorMappings.forEach((m) => {
        if (!newVendors.find((nv) => nv.id === m.vendor.id)) {
          newVendors.push({
            id: m.vendor.id, 
            name: m.vendor.name
          });
        }
      });
      newVendors.sort((a,b) => a.name.localeCompare(b.name));
      setVendors(newVendors);
    }
  },[factorTypeVendorMappings]);

  // The "data" here is the array of factor object loaded from the database, before any editing.
  const [{
    data: initialFactors, 
    isLoading: isFactorLoading
  }, 
    setParamsForGetCurrentFactorsByLocationId
  ] = useApiGet(getCurrentFactorsByLocationIdCall, {venueId: venue.id, locationId: location.locationId}, []);

  const [factors, setFactors] = useState([]);

  useEffect(() => {
    // For each factorTypeVendorMapping, check whether the factor for this mapping was returned
    // from the database. If not, add this factor as 1.
    const placeholderFactors = [];
    factorTypeVendorMappings.forEach(m => {
      if( !initialFactors.find(f => 
        f.vendorId === m.vendor.id 
        && f.factorTypeId === m.factorType.id 
        && f.locationId === location.locationId )) {
          placeholderFactors.push({
            factorTypeId: m.factorType.id,
            factorTypeName: m.factorType.name,
            factorValue: 1,
            startDate: new Date(),
            venueId: venue.id,
            vendorId: m.vendor.id,
            vendorName: m.vendor.name,
            locationId: location.locationId
          });
      }
    });

    setFactors([...initialFactors, ...placeholderFactors]);
  }, [initialFactors, factorTypeVendorMappings]);

  useEffect(() => {
    setParamsForGetCurrentFactorsByLocationId({venueId: venue.id, locationId: location.locationId});
  }, [venue.id, location.locationId, setParamsForGetCurrentFactorsByLocationId]);
  
  const handleFactorChanged = async (vendorId, factorTypeId, factorValue) => {
    if (isSaving) {
      return;
    }

    setIsSaving(true);

    const oldFactor = factors.find(f => f.vendorId === vendorId && f.factorTypeId === factorTypeId && f.locationId === location.locationId);

    setFactors(prev => {
      return prev.map(f => {
        if (f.vendorId === vendorId && f.factorTypeId === factorTypeId && f.locationId === location.locationId ) {
          return {
            ...f,
            factorValue: factorValue
          };
        } else {
          return f;
        }
      });
    });

    addCurrentFactor(factorTypeId, factorValue, venue.id, vendorId, location.locationId)
    .then(() => {
      toast.success("Factor has been saved.");
      setIsSaving(false);
    })
    .catch((error) => {
      toast.error("Failed to save factor. " + error.message, {autoClose: false});
      // Revert to old factor.
      setFactors(prev => {
        return prev.map(f => {
          if (f.vendorId === vendorId && f.factorTypeId === factorTypeId && f.locationId === location.locationId ) {
            return {
              ...f,
              factorValue: oldFactor.factorValue
            };
          } else {
            return f;
          }
        });
      });
      setIsSaving(false);
    });

    setIsSaving(false);
  };

  return (
    <Box
      sx={{
        display: "grid",
        placeItems: "start start",
        gridTemplateAreas: "inner-div",
        width: "auto",
        flexGrow: 1
      }}
    >
      {/* This Box contains the "DATA SOURCES" title and the Box with columns */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "auto",
          gridArea: "inner-div",
          gridRow: 1,
          gridColumn: 1
        }}>
        <Typography
          className={classes.title}
          style={{
            textAlign: "center",
            visibility: isFactorTypeLoading? "hidden" : "visible"
          }}>
          Data source
        </Typography>

      {/* // This Box contains the columns. */}
        <Box 
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "auto",
            // gridArea: "inner-div",
            //visibility: isMappingLoading ? "hidden" : "visible", // KF: I need this comment for possible future beautifying of loading state.
          }}>
            {/* // This Box contains the METRICS/INDICATORS label in the top left corner
            // and the names of the indicators. */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "auto"
              }}
            >
              <Box>
                <Box
                  className={classes.vendorHeader}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    pl: 2,
                    pr: 2,
                    pb: 0
                  }}
                >
                  <Typography
                    style={{visibility:isFactorTypeLoading? "hidden": "visible"}}
                    className={classes.factorTypeLabel}
                  >
                    Indicators/Metrics
                  </Typography>
                </Box>
                {factorTypes.map((factorType, i) => {
                  return (
                    <Box
                      key={factorType.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        pl: 2,
                        pr: 2
                      }}
                      className={`${classes.factorTypeHeader} ${ i % 2 === 0 ? classes.oddFactorTypeBackground: ""}`}
                    >
                      <Typography noWrap>
                        {factorType.name}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            {/* // The following set of Box components shows a column for each vendor. */}
            {vendors.map((vendor, i) => {
              const vendorFactors = factors.filter(f => f.vendorId === vendor.id && f.locationId === location.locationId);
              
              return (
                <Box
                  key={vendor.id}
                  spacing={1}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "min-content",
                    minWidth: "170px"
                  }}>
                  {/* // The following Box is the column header for the vendor. */}
                  <Box
                    className={`${classes.vendorHeader} ${i % 2 === 0 ? classes.oddVendorBackground: ""}`}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      pl: 1, pr: 1,
                      pt: 1, pb: 1,
                    }}
                    autoComplete="off"
                  >
                    <Typography
                      noWrap
                      className={classes.vendorLabel}
                    >
                      {vendor.name}
                    </Typography>
                  </Box>
                  {/* // The folliwng Box components are cells in the column for the vendor. */}
                  {factorTypes.map((factorType, j) => {
                    let backgroundClass = "";
                    if (j % 2 === 0) {
                      backgroundClass = classes.oddFactorTypeBackground;
                    } else if (i % 2 === 0 ) {
                      backgroundClass = classes.oddVendorBackground;
                    }

                    const factor = vendorFactors.find(f => f.factorTypeId === factorType.id);

                    return (
                      <Box
                        key={factorType.id}
                        className={`${classes.vendorFactor} ${backgroundClass}`}
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          pl: 2, pr: 2,
                          pt: 0, pb: 0.5,
                        }}>
                          {factor ? 
                            <ExplicitGhostTextField
                              value={factor.factorValue}
                              onChange={(newFactorValue) => 
                                handleFactorChanged(vendor.id, factorType.id, newFactorValue)
                              }
                            />
                            : null
                          }
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
        </Box>
      </Box>
      <Box
        sx={{
          gridArea: "inner-div",
          margin: "auto",
          gridRow: 1,
          gridColumn: 1
        }}
      >
        <LoadPleaseWait show={isFactorLoading || !location.locationId} />
      </Box>
    </Box>
  );
};