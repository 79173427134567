import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { DASHBOARD_CLIENT_TYPE_COLOR_BLIND } from "../../../_constants/dashboard.constants"

export const getLocationName = props => {
    const { data, settings, viewMode, clientType, isPublicViewMode } = props;
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;

    if ( isViewMode ) {
        if ( isPublicViewMode && clientType === DASHBOARD_CLIENT_TYPE_COLOR_BLIND && data?.alertRule) {
            const color = data.alertRule.color?.value?.toLowerCase();
            switch (color) {
                case '#3fbe5d': return 'Free to use';
                case '#d69706': return 'Near capacity';
                case '#fc5050': return 'Full capacity';
                default: return data?.location?.name;
            }
        } else {
            return data?.location?.name;
        }
    } else {
        return settings.RefLocation?.name;
    }
}
