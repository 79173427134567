import * as locationMappingConstants from "../_constants/location.mapping.constants";
import * as locationMappingApi from "../_services/location.mapping.service";

export const loadLocationsSuccess = (locations) => {
  return {
    type: locationMappingConstants.LOAD_LOCATIONS_SUCCESS,
    locations: locations,
  };
};

export const loadLocationMappingStarted = () => {
  return {
    type: locationMappingConstants.LOAD_LOCATION_MAPPING_STARTED,
  };
};

export const loadLocationMappingSuccess = (locationMappings) => {
  return {
    type: locationMappingConstants.LOAD_LOCATION_MAPPING_SUCCESS,
    locationMappings: locationMappings,
  };
};

export const addLocationMappingSuccess = (locationMapping) => {
  return {
    type: locationMappingConstants.ADD_LOCATION_MAPPING_SUCCESS,
    locationMapping: locationMapping,
  };
};

export const updateLocationMappingSuccess = (locationMapping) => {
  return {
    type: locationMappingConstants.UPDATE_LOCATION_MAPPING_SUCCESS,
    locationMapping: locationMapping,
  };
};

export const removeLocationMappingOptimistic = (locationMappingId) => {
  return {
    type: locationMappingConstants.REMOVE_LOCATION_MAPPING_OPTIMISTIC,
    locationMappingId: locationMappingId,
  };
};

export const loadLocationMapping = (venueId) => {
  return async (dispatch) => {
    dispatch(loadLocationMappingStarted());
    await locationMappingApi
      .getLocationMappings(venueId)
      .then((LocationMapping) => {
        dispatch(loadLocationMappingSuccess(LocationMapping));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addLocationMapping = (locationMapping) => {
  return async (dispatch) => {
    await locationMappingApi
      .addLocationMapping(locationMapping)
      .then(() => {
        dispatch(addLocationMappingSuccess(locationMapping));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateLocationMapping = (locationMapping) => {
  return async (dispatch) => {
    await locationMappingApi
      .updateLocationMapping(locationMapping)
      .then(() => {
        dispatch(updateLocationMappingSuccess(locationMapping));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const removeLocationMapping = (locationMappingId) => {
  return async (dispatch) => {
    dispatch(removeLocationMappingOptimistic(locationMappingId));
    return await locationMappingApi.removeLocationMapping(locationMappingId);
  };
};

export const loadLocations = (venueId) => {
  return async (dispatch) => {
    await locationMappingApi
      .getLocations(venueId)
      .then((locations) => {
        dispatch(loadLocationsSuccess(locations));
      })
      .catch((error) => {
        throw error;
      });
  };
};
