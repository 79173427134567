import axios from "axios";
import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";
import { downloadFile } from ".";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/VenueEvent`,
};

const authService = new AuthService();

export async function getAllVenueEvents(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/ALL?venueId=${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data);
            }
          }
        });
    }
  }
}

export async function getVenueEventById(venueEventId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/${venueEventId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data);
            }
          }
        });
    }
  }
}
export async function addVenueEvent(venueEvent) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .post(
          constants.api,
          venueEvent,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data);
            }
          }
        });
    }
  }
}

export async function updateVenueEvent(venueEvent) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .put(
          constants.api,
          venueEvent,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data);
            }
          }
        });
    }
  }
}

export async function deleteVenueEvent(venueEventId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .delete(
          `${constants.api}?venueEventId=${venueEventId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data);
            }
          }
        });
    }
  }
}

export async function venueEventsImport(venueId, file) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("fileName", file?.name);
    bodyFormData.append("File", file);

    return await axios
      .post(`${constants.api}/Import/${venueId}`,
        bodyFormData,
        options
      )
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export function venueEventsExport(venueId, dateFrom) {
  return downloadFile(`${constants.api}/Export/${venueId}${dateFrom ? `?dateFrom=` + dateFrom : ``}`, `VenueEvents-${venueId}.xlsx`);
}