import WidgetPatronDistributionContent from "./WidgetPatronDistributionContent";
import { WidgetWrapper } from "../WidgetWrapper";
import { widgetTypeConstants } from "../../../_constants";
import { WidgetContextProvider } from "../WidgetContext";

export const WidgetPatronDistribution = (props) => (
    <WidgetContextProvider>
        <WidgetWrapper
            {...{ ...props, widgetType: widgetTypeConstants.widgetPatronDistribution.id }}
        >
            <WidgetPatronDistributionContent />
        </WidgetWrapper>
    </WidgetContextProvider>
);

export default WidgetPatronDistribution;