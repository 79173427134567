export const LOAD_ROLES_SUCCESS = 'LOAD_ROLES_SUCCESS'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'

export const LOAD_USERS_STARTED = 'LOAD_USERS_STARTED'

export const ENSURE_USER_SUCCESS = 'ENSURE_USER_SUCCESS'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'

export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS'
export const ENABLE_USER_SUCCESS = 'ENABLE_USER_SUCCESS'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
