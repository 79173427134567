import React, { useEffect, useState } from "react" 
import { widgetOccupancyValueTypeConstants } from "../../../_constants/widget.type.constants";
import Percentage from "./Percentage";
import RawNumber from "./RawNumber";
import Location from "./Location";
import RawNumberWithMax from "./RawNumberWithMax";
import WidgetOccupancyContainer from "./WidgetOccupancyContainer";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import CurrentOccupancyWithMax from "./CurrentOccupancyWithMax";
import CurrentOccupancy from "./CurrentOccupancy";

export const WidgetOccupancyContent = (props) => {
    const { data, settings, onAudioPlay, viewMode } = props;
    const [playSound, setPlaySound] = useState(false);

    useEffect(() => {
        setPlaySound(viewMode === appViewModeConstants.VIEW_MODE && data?.alertRule?.sound);
    }, [data?.alertRule?.sound, viewMode, setPlaySound]);

    useEffect(() => {
        if (playSound) {
            onAudioPlay();
        }
    }, [playSound, onAudioPlay]);

    switch (settings?.RefOccupancyViewMode?.id) {
        case widgetOccupancyValueTypeConstants.RawNumber:
            return settings?.WidgetSettingShowHighestValue ? 
                (<WidgetOccupancyContainer {...props}><RawNumberWithMax /></WidgetOccupancyContainer>) : 
                (<WidgetOccupancyContainer {...props}><RawNumber /></WidgetOccupancyContainer>);

        case widgetOccupancyValueTypeConstants.Percentage:
            return (<WidgetOccupancyContainer {...props}><Percentage /></WidgetOccupancyContainer>);

        case widgetOccupancyValueTypeConstants.CurrentOccupancy:
            return settings?.WidgetSettingShowHighestValue ? 
                (<WidgetOccupancyContainer {...props} style={{height: "100%"}}><CurrentOccupancyWithMax /></WidgetOccupancyContainer>) : 
                (<WidgetOccupancyContainer {...props}><CurrentOccupancy /></WidgetOccupancyContainer>);

        default:
            return <WidgetOccupancyContainer {...props}><Location /></WidgetOccupancyContainer>;
      }  
}

export default WidgetOccupancyContent;
