import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";

var Constants = {
  dataCollectorApiRoot: ApiRootConstants.dataCollectorApiRoot,
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot,
};

const authService = new AuthService();

export async function getLocationMappings(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = {
      venueId: venueId
    }

    return await axios
      .get(Constants.dataCollectorApiRoot + "api/VendorLocationMappings", {
        headers: headers,
        params: params
      })
      .then((response) => {
        return response.data
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

export async function addLocationMapping(locationMapping) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      Id: locationMapping.id,
      VenueId: locationMapping.venueId,
      VendorId: locationMapping.vendorId,
      LocationId: locationMapping.locationId,
      VendorCode: locationMapping.vendorCode,
      VendorLocationIndicatorMappings: locationMapping.vendorLocationIndicatorMappings,
      TrafficInOutFactor: locationMapping.trafficInOutFactor,
      QueueModelFactor: locationMapping.queueModelFactor
    };

    return await axios
      .post(
        Constants.dataCollectorApiRoot + "api/VendorLocationMappings",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateLocationMapping(locationMapping) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      Id: locationMapping.id,
      VenueId: locationMapping.venueId,
      VendorId: locationMapping.vendorId,
      LocationId: locationMapping.locationId,
      VendorCode: locationMapping.vendorCode,
      VendorLocationIndicatorMappings: locationMapping.vendorLocationIndicatorMappings,
      TrafficInOutFactor: locationMapping.trafficInOutFactor,
      QueueModelFactor: locationMapping.queueModelFactor
    };

    return await axios
      .put(
        Constants.dataCollectorApiRoot + "api/VendorLocationMappings",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateVendorLocationMappings(mappings) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = [];
    mappings.forEach(mapping => {
      bodyFormData.push({
          Id: mapping.id,
          VenueId: mapping.venueId,
          VendorId: mapping.vendorId,
          LocationId: mapping.locationId,
          VendorCode: mapping.vendorCode,
          VendorLocationIndicatorMappings: mapping.vendorLocationIndicatorMappings,
          TrafficInOutFactor: mapping.trafficInOutFactor,
          QueueModelFactor: mapping.queueModelFactor
      });
    });

    return await axios
      .put(
        Constants.dataCollectorApiRoot + "api/VendorLocationMappings/UpdateVendorLocationMappings",
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}


export async function removeLocationMapping(locationMappingId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .delete(
        Constants.dataCollectorApiRoot +
          "api/VendorLocationMappings/" +
          locationMappingId,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function getLocations(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    return axios
      .get(Constants.venueManagementApiRoot + "api/VenueLocation", {
        headers: headers,
        params: {
          venueId: venueId,
        }
      })
      .then((response) => {
        return response.data})
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

export async function getLocationMappingsByLocationId(venueId, locationId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = {
      venueId: venueId, 
      locationId: locationId
    }

    return await axios
      .get(Constants.dataCollectorApiRoot + "api/VendorLocationMappings/GetByLocationId", {
        headers: headers,
        params: params
      })
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        }
        throw error;
      });
  }
}

  export async function getVenueMappingsByLocationId(locationId) {
    const user = await authService.getUser();
    if (user && user.access_token) {
      const headers = {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      };
      const params = {
        locationId: locationId
      }
  
      return await axios
        .get(Constants.venueManagementApiRoot + "api/VenueMap/LocationOverlays/" + locationId, {
          headers: headers,
          params: params
        })
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
          throw error;
        });
    }

  }