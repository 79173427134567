import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// import Card from "@material-ui/core/Card";
// import CardActions from "@material-ui/core/CardActions";
// import CardContent from "@material-ui/core/CardContent";
// import Box from "@material-ui/core/Box";
import CheckIcon from "@material-ui/icons/Check";
import { Paper } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 15,
  },
  paperSelected: {
    padding: theme.spacing(2),
    margin: 15,
    background: theme.palette.common.selectedVenueBackground
  },
}));

export const Venue = ({
  id,
  venue,
  selected = false,
  onSelect
}) => {
  const classes = useStyles();
  const active = venue.venueStatus.name === "Active";

  const onClick = (e) => {
    onSelect(venue);
  };

  return (
    <div id={"venue_" + id}>
      <Paper
        className={selected ? classes.paperSelected : classes.paper}
        elevation={selected ? 8 : 2}
        //onClick={onClick}
      >
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item xs={1} container justifyContent="center" onClick={onClick}>
            {selected ? (
              <CheckIcon
                fontSize="large"
                color={selected ? "primary" : "disabled"}
              ></CheckIcon>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={7} md={9} lg={10} className={classes.outerColumn} onClick={onClick}>
            <Typography gutterBottom variant="h3">
              {venue.name}
            </Typography>
            <Typography variant="subtitle1">{venue.description}</Typography>
          </Grid>
          <Grid item xs={4} md={2} lg={1} container justifyContent="center">
            <Typography variant="h5">
              {active ? "Active" : "Inactive"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Venue;
