import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography
} from "@material-ui/core";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { toast } from "react-toastify";
import LoadPleaseWait from "../../../../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../../../../_helpers/useApiGet";
import {getVenueLocations} from "../../../../../_services/venue.locations.service";


const useTreeItemStyles = makeStyles((theme) => ({
  treeItem: {
    minHeight: "40px"
  },
  parentLabel: {
    flexGrow: 1,
    fontWeight: 500
  },
  leafLabel: {
    flexGrow: 1,
  },
  locationType: {
    color: theme.palette.color.secondary.main
  },
  label: {
    width: "100%",
    marginRight: "5px"
  },
  content: {
    flexDirection: "row-reverse",
    marginLeft: 0,
  },
  expanded: {},
  selected: {
    backgroundColor: theme.palette.venueSettings.dataSources.selectedLocationBackground,
  },
  treeItemIcon: {
    color:  theme.palette.color.secondary.main
  }
}));

const LocationTreeItem =  ({
  locationId, 
  locationName, 
  locationTypeName,
  isLeaf, 
  isSelected, 
  onSelected, 
  ...other
}) => {
  const classes = useTreeItemStyles();

  return (
    <TreeItem 
      nodeId={locationId}
      key={locationId}
      onLabelClick={(e)=> {
        e.preventDefault();
      }}
      label={
        <Box
          className={classes.treeItem}
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
            paddingRight: "10px"
          }}>
          <Typography
            className={isLeaf? classes.leafLabel: classes.parentLabel}>
            {locationName}
          </Typography>
          <Typography
            className={classes.locationType}>
            {locationTypeName}
          </Typography>
        </Box>
      }
      classes={{
        //root: classes.root, // These classes may be needed at the next attempt to style the tree of locations.
        content: classes.content,
        expanded: classes.expanded,
        //selected: classes.selected,
        //group: classes.group,
        label: classes.label,
        iconContainer: classes.treeItemIcon
      }}
      {...other}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  treeView: {
    margin: "10px"
  }
}));

const getVenueLocationsCall = async (venueId) => {
  if (venueId ) {
    const result = await getVenueLocations(venueId);
    return result.data;
  } else {
    return [];
  }
};

const compareLocationsByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const findLocation = (location, locationId) => {
  if (location.id === locationId ) {
    return location;
  }

  if (location.children) {
    for(let i = 0; i < location.children.length; i++) {
      const foundLocation = findLocation(location.children[i], locationId);
      if (foundLocation) {
        return foundLocation;
      }
    }
  }
  return null;
}

export const Locations = ({venue, onLocationSelected}) => {
  const classes = useStyles();

  // The "data" here is the array of locations as they are loaded from the database.
  // This array is expected to contain exactly one element - the root location.
  const [{data: locations, isLoading, isError: isLoadingError, errorMessage: loadingErrorMessage}] = useApiGet(getVenueLocationsCall, venue.id, []);

  const [selectFirstLocationAndExpandAll, setSelectFirstLocationAndExpandAll] = useState(true);

  const [selectedLocationId, setSelectedLocationId] = useState("");

  const [expandedNodes, setExpandedNodes] = useState([]);

  useEffect(() => {
    // When locations are loaded from the database, make sure that all parent locations are expanded.
    const getParentIds = (location) => {
      const result = [];
      if (location.children && location.children.length > 0 ) {
        result.push(location.id);
        location.children.forEach(child => {
          result.push(...getParentIds(child));
        });
      }
      return result;
    }
    if (selectFirstLocationAndExpandAll && locations && locations.length > 0 ) {
      setExpandedNodes(getParentIds(locations[0]));
      setSelectedLocationId(locations[0].id);
      onLocationSelected({
        locationId: locations[0].id,
        isSensor: locations[0].locationType?.isSensor
      });
      setSelectFirstLocationAndExpandAll(false);
    } else {
      setExpandedNodes([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations]);

  useEffect(() => {
    if( isLoadingError) {
      toast.error("Loading locations failed. " + loadingErrorMessage, { autoClose: false });
    }
  }, [isLoadingError, loadingErrorMessage]);

  const renderLocations = (location) => {
    return (
      location.isDeleted ? null :
      <LocationTreeItem
        locationId={location.id}
        locationName={location.name}
        locationTypeName={location.locationType?.name}
        isLeaf={location.children && location.children.filter(it => !it.isDeleted).length === 0}
        key={location.id}
        nodeId={location.id}
      >
        {Array.isArray(location.children)
          ? location.children
            .filter(it => !it.isDeleted)
            .sort(compareLocationsByName)
            .map((childLocation) => renderLocations(childLocation))
          : null}
      </LocationTreeItem>
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        minHeight: 0,
        overflow: "auto"
      }}>
      {isLoading ? 
        <LoadPleaseWait show={isLoading} />
        : 
        <TreeView
          className={classes.treeView}
          defaultCollapseIcon={<ExpandMoreIcon />}
          defaultExpandIcon={<ChevronRightIcon />}
          onNodeToggle={(e, nodeIds) => {
            setExpandedNodes(nodeIds);
          }}
          expanded={expandedNodes}
          onNodeSelect={(e,nodeId) => {
            setSelectedLocationId(nodeId);
            onLocationSelected({
              locationId: nodeId,
              isSensor: findLocation(locations[0], nodeId)?.locationType?.isSensor // nodeId is the id of location
            });
          }}
          selected={selectedLocationId}
        >
          {(locations.length > 0 ) ? renderLocations(locations[0]) : null}
        </TreeView>
      }
    </Box>
  );
};

export default Locations;