import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box, TextField, Grid, Chip, Checkbox, ListItemText,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton
} from "@material-ui/core";
import {
  loadRoles,
  loadUsers,
  updateUser,
  ensureUser,
} from "../../../_actions/user.management.actions";
import { getLoginUser, getUserByEmail } from "../../../_services/userManagement.service";
import { getVenues } from "../../../_actions/venue.actions";
import { updateUserVenues, addUserVenues } from "../../../_actions/venue.user.actions";
import { Button, ButtonType } from "../../_common/htmlTags/Button";
import { Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { checkPassword } from '../../../_helpers/regex';
import { v4 as uuidv4 } from 'uuid';
import { toast } from "react-toastify";
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage"
import { ROLES } from "../../../_constants/user.permissions.constants";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PasswordValidation from "../../user/userInfo/PasswordValidation";

const useStyles = makeStyles((theme) => ({
  numberCircle: { ...theme.htmlTag.numberCircle },
  backgroundColorDisable: { backgroundColor: theme.palette.color.primary.disabled, borderRadius: '5px' },
  root: {
    "& .MuiTextField-root": {
      'margin-bottom': theme.spacing(1),
      'margin-top': theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },

  selectControl: {
    'margin-bottom': theme.spacing(2),
    'margin-top': theme.spacing(2),
  },

  buttons: {
    'margin-bottom': theme.spacing(1),
    'margin-top': theme.spacing(1),
  },

  button: {
    marginRight: theme.spacing(2),
  },

  multipleSelectionOptionChip: {
    borderRadius: "3px",
    backgroundColor: theme.palette.color.primary.background,
    marginRight: "5px",
  },
}));

const SAVE_SUBMIT_BUTTON = "save-button";
const SAVE_AND_ADD_ANOTHER_USER_SUBMIT_BUTTON = "save-and-add-another-user-button";


const AddUserForm = ({
  email,
  setIsSearched
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userManagement.users);
  const roleList = useSelector((state) => state.userManagement.roles);
  const venueList = useSelector((state) => state.venueReducer.venues);

  const [password, setPassword] = useState("");
  const [isUserExist, setUserExist] = useState(false);
  const [loginUser, setLoginUser] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordError, setShowPasswordError] = useState(false);
  const [venues, setVenues] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentVenuePermissions, setCurrentVenuePermissions] = useState([]);
  const [submitButtonName, setSubmitButtonName] = useState("");
  const [venuesPermissionsError, setVenuesPermissionsError] = useState(false);
  const [roleSelectChanged, setRoleSelectChanged] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);

  //user state
  const [user, setUser] = useState({
    id: uuidv4(),
    firstName: "",
    lastName: "",
    email: email,
    phoneNumber: "",
    roleId: "",
    role: null,
    password: "",
    enabled: true,
    venue: []
  });

  useEffect(() => {
    if (userList?.length === 0) {
      dispatch(loadUsers()).catch((error) => {
        toast.error("Loading users failed" + error, { autoClose: false });
      });
    }

    if (venueList?.length === 0) {
      dispatch(getVenues(0)).catch((error) => {
        toast.error("Loading venue failed" + error, { autoClose: false });
      });
    }

    if (roleList.length === 0) {
      dispatch(loadRoles()).catch((error) => {
        toast.error("Loading roles failed" + error, { autoClose: false });
      });

    }

    dispatch(getLoginUser).then((user) => { setLoginUser(user); }).catch((error) => {
      toast.error("Get login user failed" + error, { autoClose: false });
    });

    if (email !== undefined) {
      getUserByEmail(email).then((user) => {
        setIsLoading(false);
        if (user !== undefined) {
          setUser(user);
          setUserExist(true);
        }
      });
    }

  }, [email, userList, venueList, roleList, dispatch]);

  useEffect(() => {

    let user = userList?.find((u) => u.email === email);
    if (user !== undefined) {
      setUser(user);
      setVenues(user?.venue);
    }
    setCurrentVenuePermissions(user?.venue?.map(e => e.id) ?? []);
  }, [email, userList, isUserExist, loginUser])

  const handlePasswordChange = (e) => {
    // keep track of changed password locally as well
    setPassword(e.target.value);
    setShowPasswordError(e.target.value !== confirmPassword);

    setUser((prevUser) => ({
      ...prevUser,
      password: e.target.value,
    }));
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setShowPasswordError(password !== e.target.value);
  }; 
  
  const handleSave = (e) => {
   
    e.preventDefault();
    setSaving(true);

    if (loginUser?.profile?.role !== ROLES.ADMINISTRATOR) {
      if (!(user && user?.venue?.length > 0 && user.venue.length > currentVenuePermissions.length)) {
        setSaving(false);
        setVenuesPermissionsError(true);
        return;
      }
    }

  
    if (loginUser?.profile?.role !== ROLES.ADMINISTRATOR && user?.venue?.length === 0) {
      setVenuesPermissionsError(false);
      setSaving(false);
      toast.error("There is no venue selected for the user.")
      return;
    }

    let venueUserDto = {
      id: user.id,
      username: user.email,
      venues: user.venue,
      roles: [user.role]
    }

    //adding user if not exist
    if (!isUserExist) {

      if (!checkPassword(user.password)) {
        setSaving(false);
        toast.error("Password does not meet strength requirements. It must be at least 6 characters long with at least 1 upper case, 1 lower case, 1 digit, 1 special character and no leading or trailing spaces. ")
        return;
      }

      if (!showPasswordError) {
        //add users when no other error

        dispatch(ensureUser(user))
          .then(() => {
            dispatch(addUserVenues(venueUserDto))
              .finally(() => {
                toast.success("User added successfully.");               
                var location = submitButtonName === SAVE_AND_ADD_ANOTHER_USER_SUBMIT_BUTTON ? "/user" : "/users";
                history.push(location);
                
                if (submitButtonName === SAVE_AND_ADD_ANOTHER_USER_SUBMIT_BUTTON) setIsSearched(false);
              })
              .catch((error) => {
                setSaving(false);
                toast.error("Adding user venue failed " + error, { autoClose: false });
              });
          })
          .catch((error) => {
            setSaving(false);
            toast.error("Adding user failed " + error, { autoClose: false });
          });
      }
      else {
        setSaving(false);
        toast.error("Password and confirm password is not matched.");
      }
      
      return;
    }

    //saving users 
    dispatch(updateUser(user))
      .then(() => {
        dispatch(updateUserVenues(venueUserDto))
          .finally(() => {          
            toast.success("User updated successfully.");
            var location = submitButtonName === SAVE_AND_ADD_ANOTHER_USER_SUBMIT_BUTTON ? "/user" : "/users";
            history.push(location);
            if (submitButtonName === SAVE_AND_ADD_ANOTHER_USER_SUBMIT_BUTTON) setIsSearched(false);
          })
          .catch((error) => {
            setSaving(false);
            toast.error("Updating user failed " + error, { autoClose: false });
          });
      })
      .catch((error) => {
        setSaving(false);
        toast.error("Updating user failed " + error, { autoClose: false });
      });
  }


  function handleChange(e) {
    const { name, value } = e.target;
    if (name === "roleId") {
      const selectedRole = roleList.find(e => e.id === value);
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
        role: selectedRole,
      }));
      setRoleSelectChanged(true);
    }
    else {
      setUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  }

  const handleMultiSelectChange = (event) => {
    const { value } = event.target;
    let venue = [];
    let lastVenue = [];

    let venueSplit = value[value.length - 1].split("`");
    if (venueSplit.length > 1) {
      lastVenue = {
        id: venueSplit[0],
        name: venueSplit[1]
      }
    }

    venue = venues?.map(v => { return { ...v } });
    if (venue.find(v => v.id === lastVenue.id) !== undefined)
      venue = venue.filter(e => e.id !== lastVenue.id);
    else
      venue.push(lastVenue);

    setVenues(venue);
    setUser((prevUser) => ({
      ...prevUser,
      venue: venue,
    }));

    setVenuesPermissionsError(false);
  };

  return (
    <LoadPleaseWait show={isLoading} >
      <form className={classes.root} autoComplete="off" onSubmit={handleSave}>        
        <Box >
          <Grid>
            {email !== undefined &&
              <div style={{ display: 'flex', paddingBottom: '1em', flexDirection: "row", alignItems: "center" }} >
                <IconButton size="small" onClick={() => setIsSearched(false)}><ArrowBackIosIcon /></IconButton>
                <div className={classes.numberCircle}>2</div>
                {isUserExist ?
                  <>
                    {user?.role?.name === ROLES.ADMINISTRATOR && roleSelectChanged === false ?
                      <div>User with email <strong>{email}</strong> already exists in system as Global admin and already has access to all venues.</div>
                      :
                      <div>User with email <strong>{email}</strong> already exists in the system. Please check user details.</div>
                    }
                  </>
                  :
                  <div>User with email <strong>{email}</strong> doesn’t exist in the system. Please create new user.</div>
                }
              </div>
            }
            <Row md={12}>
              <Col md={8}>
                <div className={classes.formInput} >
                  <Row>
                    <Col>
                      <TextField
                        id="email"
                        label="Login / Email"
                        name="email"
                        value={user?.email}
                        disabled
                        className={classes.backgroundColorDisable}
                        variant="outlined"
                        required
                        fullWidth
                      />
                    </Col>
                  </Row>
                  {(!(isUserExist && user?.role?.name === ROLES.ADMINISTRATOR && roleSelectChanged === false)) &&
                    <div>
                      <Row>
                        <Col md>
                          <TextField
                            id="firstName"
                            label="First name"
                            name="firstName"
                            value={user?.firstName}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <TextField
                            id="outlined-lastName"
                            label="Last name"
                            name="lastName"
                            value={user?.lastName}
                            onChange={handleChange}
                            variant="outlined"
                            required
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md>
                          <TextField
                            id="outlined-phoneNumber"
                            label="Mobile number"
                            name="phoneNumber"
                            value={user?.phoneNumber}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <FormControl
                            variant="outlined"
                            className={classes.selectControl}
                            fullWidth
                            required
                          >
                            <InputLabel id="role-label">Role</InputLabel>
                            <Select
                              labelId="role-label"
                              id="role-select"
                              name="roleId"
                              value={user?.roleId}
                              onChange={handleChange}
                              label="Role"
                            >
                              {roleList
                                .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
                                // Not-global admins can not add global admins => remove this role from venues list
                                .filter(e => {
                                  if (loginUser?.profile?.role === ROLES.ADMINISTRATOR)
                                    return e;
                                  return e.name !== ROLES.ADMINISTRATOR;
                                })
                                .map((e) => (
                                  <MenuItem key={e.id} value={e.id}>
                                    {e.name}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>                        
                      <Row style={ user?.role?.name === ROLES.ADMINISTRATOR ? { display: 'none', paddingTop: '0.5em' } : { display: 'block', paddingTop: '0.5em', paddingBottom: '0.5em' }}>
                        <Col>
                          <FormControl
                            variant="outlined"
                            fullWidth                            
                            required={user?.role?.name !== ROLES.ADMINISTRATOR}
                            className={user?.role?.name === ROLES.ADMINISTRATOR ? classes.backgroundColorDisable : ""}
                          >
                            <InputLabel id='assignedVenuesInputLabel' >Venues, assigned to user</InputLabel>
                            <Select
                              name="assignedVenue"
                              MenuProps={{
                                style: {
                                  maxHeight: "30%",
                                  width: 200,
                                },
                                /*
                                anchorOrigin: {
                                  vertical: "bottom",
                                },
                                getContentAnchorEl: () => null,
                                */
                              }}
                              multiple
                              labelId='assignedVenue'
                              label='Venues, assigned to user'
                              value={venues}
                              onChange={(event) => handleMultiSelectChange(event)}
                              renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
                                  .map((venue) =>
                                    <Chip key={venue.id} label={venue.name} className={classes.multipleSelectionOptionChip} />
                                  )}
                                  
                                </Box>
                              )}
                            >
                              {venueList
                                .filter(v => v.venueStatus.name.toLowerCase() === "active")
                                .sort((a, b) => a.name.toUpperCase() > b.name.toUpperCase())
                                .map(venue => {
                                  return (
                                    <MenuItem disabled={currentVenuePermissions?.includes(venue.id)} key={venue.id} value={venue.id + "`" + venue.name}>
                                      <Checkbox

                                        checked={venues?.find(v => v.id === venue.id) !== undefined}
                                      />
                                      <ListItemText primary={venue.name} />
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                            {
                              // Display message below if no new venue permission was added
                              venuesPermissionsError && user?.role?.name !== ROLES.ADMINISTRATOR  ?
                                <FormHelperText style={{ color: "#EE4040", fontWeight: "400", fontSize: "12px", }}>
                                  No changes has been made in venue permissions. Please add at least one new venue
                                </FormHelperText>
                                :
                                <></>
                            }
                          </FormControl>
                        </Col>
                      </Row>
                      {!isUserExist &&
                        <Grid>
                          <Row>
                            <Col>
                              <TextField
                                id="outlined-password"
                                label="Password"
                                name="password"
                                value={password}
                                onChange={handlePasswordChange}
                                variant="outlined"
                                type="password"
                                required
                                fullWidth
                                error={password !== "" && !isNewPasswordValid}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <TextField
                                id="outlined-passwordConfirm"
                                label="Confirm password"
                                value={confirmPassword}
                                error={showPasswordError}
                                helperText={showPasswordError ? "Password does not match" : ""}
                                onChange={handleConfirmPasswordChange}
                                variant="outlined"
                                type="password"
                                required
                                fullWidth
                              />
                            </Col>
                          </Row>
                        </Grid>
                      }
                    </div>
                  }
                  <Row>
                    <Col>
                      {(isUserExist && user?.role?.name === ROLES.ADMINISTRATOR && roleSelectChanged === false) ?
                        <div className={classes.buttons}>
                          <Button
                            variant="contained"  
                            type="submit"                            
                            className={classes.button}
                            disabled={saving}                    
                            onClick={() => {
                              setIsSearched(false);
                            }
                            }
                          >
                            ADD ANOTHER USER
                          </Button>

                          <Button    
                            variant="contained"  
                            className={classes.button}       
                            disabled={saving}          
                            onClick={() => {
                              history.push("/users");
                            }}
                          >
                            BACK TO USERS LIST
                          </Button>
                        </div>
                        :
                        <div className={classes.buttons}>
                          <Button
                            type="submit"
                            className={classes.button}
                            variant="contained"
                            disabled={saving}
                            onClick={() => {
                              setSubmitButtonName(SAVE_SUBMIT_BUTTON);
                              setVenuesPermissionsError(user?.venue?.length === 0);
                            }
                            }
                            >
                            {saving ? "SAVING..." : "SAVE"} 
                          </Button>

                          <Button
                            type="submit"
                            variant="contained"
                            className={classes.button}
                            disabled={saving}                            
                            onClick={() => {
                              setSubmitButtonName(SAVE_AND_ADD_ANOTHER_USER_SUBMIT_BUTTON);
                              setVenuesPermissionsError(user?.venue?.length === 0);                             
                            }
                            }
                          >
                              {saving ? "SAVING AND ADD ANOTHER USER" : "SAVE AND ADD ANOTHER USER"}                               
                          </Button>

                          <Button
                            variant="outlined"
                            disabled={saving}      
                            className={classes.button}
                            buttonType={ButtonType.Secondary}
                            onClick={() => {
                              history.push("/users");
                            }}
                          >
                            CANCEL
                          </Button>
                        </div>
                      }
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={4} style={{ marginTop: '27em' }}>
                {(!isUserExist) &&
                  <PasswordValidation
                    setIsNewPasswordValid={setIsNewPasswordValid}
                    newPassword={password}
                  />
                }
              </Col>
            </Row>

          </Grid>
        </Box>      
      </form>
    </LoadPleaseWait>
  );
};

export default AddUserForm;
