import React, { useEffect, useContext, useState } from "react";
import {
    FormControl,
    Select,
    MenuItem,
    Grid,
    Box,
    FormLabel,
    makeStyles,
    Typography,
    ListItemText
} from '@material-ui/core';
import SettingsDependencyContext from "../SettingsDependencyContext";
import { NIL as NIL_UUID } from "uuid";
import { sortByName } from "../../../../_helpers/functions.sorting";
import SelectionOptionText from "./common/SelectionOptionText";

const useStyles = makeStyles((theme) => ({
    mappingEntitiesFormLabel: {
        position: "relative",
        backgroundColor: "white",
        width: "auto",
        fontSize: "12px",
        bottom: "12px",
        paddingLeft: "6px",
        paddingRight: "4px",
    },
    selectMapFirstNote: {
        marginTop: "-10px",
    }
}));

const MappingEntitiesSetting = ({
    widgetSettingWithValue,
    handleSettingValueChange,
    selectionOptions,
    required,
    additionalSettingsLogic
}) => {
    const settingsDependencyContextData = useContext(SettingsDependencyContext);
    const classes = useStyles();

    const [valuesFromDb, setValuesFromDb] = useState([]);
    const [mapFromDb, setMapFromDb] = useState();

    useEffect(() => {
        // If selectionOptions.mainEntity is changed:

        // Implementation of side effect of assiging values 
        // to widgetSettingWithValue and valuesFromDb variable,
        // when there are some values initially 
        // and we want to define empty values with Null Guids instead of just undefined empty values
        if (selectionOptions.mainEntity.length > 0) {
            let adjustedValuesWithNullGuids = [];

            if (widgetSettingWithValue.value.length > 0) {
                selectionOptions.mainEntity.forEach(mainItem => {
                    adjustedValuesWithNullGuids.push([
                        {
                            value: mainItem.id,
                            widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 0).id,
                            orderNum: 0,
                        },
                        {
                            value:
                                widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id)
                                    &&
                                    widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)?.value
                                    &&
                                    selectionOptions.selectiveEntity.map(e => e.id).includes(widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)?.value)
                                    ?
                                    widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)?.value
                                    :
                                    NIL_UUID,
                            widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 1).id,
                            orderNum: 1,
                        }
                    ]);
                });

                if (valuesFromDb.length === 0)
                    setValuesFromDb(adjustedValuesWithNullGuids);
            }
            // Another condition of side effect,
            // when there are no values initially 
            // and we want assign all values of selection entity as Null Guids
            else {
                selectionOptions.mainEntity.forEach(mainItem => {
                    adjustedValuesWithNullGuids.push([
                        {
                            value: mainItem.id,
                            widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 0).id,
                            orderNum: 0,
                        },
                        {
                            value: NIL_UUID,
                            widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 1).id,
                            orderNum: 1,
                        }
                    ]);
                });
            }

            handleSettingValueChange(widgetSettingWithValue, adjustedValuesWithNullGuids);
        }
    }, [selectionOptions.mainEntity.length])


    useEffect(() => {
        // Implement side effect of assigning initially selected map
        if (settingsDependencyContextData.selectedMap && mapFromDb === undefined) {
            setMapFromDb(settingsDependencyContextData.selectedMap);
        }
        // If initial map is changed (another map is selected),
        // then define all selection options as null guids
        if (mapFromDb && mapFromDb.id !== settingsDependencyContextData.selectedMap.id) {
            let nullGuidSelectionOptions = [];

            selectionOptions.mainEntity.forEach(mainItem => {
                nullGuidSelectionOptions.push([
                    {
                        value: mainItem.id,
                        widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 0).id,
                        orderNum: 0,
                    },
                    {
                        value: NIL_UUID,
                        widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 1).id,
                        orderNum: 1,
                    }
                ]);
            });

            handleSettingValueChange(widgetSettingWithValue, nullGuidSelectionOptions);
        }
        // else, if selected initial map,
        // assing to values initial values from DB with null guids where values are empty
        else {
            if (valuesFromDb.length > 0)
                handleSettingValueChange(widgetSettingWithValue, valuesFromDb);
        }
    }, [settingsDependencyContextData.selectedMap])

    // Reset selected percent alerts (if they were selected for Occupancy indicator) to "No alert"
    useEffect(() => {
        if (settingsDependencyContextData.selectedMapIndicator) {
            additionalSettingsLogic.mapWidgetResetPercentAlerts(settingsDependencyContextData.selectedMapIndicator.name, widgetSettingWithValue);        
        }
    }, [settingsDependencyContextData.selectedMapIndicator])
    

    return (
        <Box sx={{
            border: "1px #DCE3E8 solid",
            padding: "0px 20px 15px 15px"
        }}>
            <FormLabel
                className={classes.mappingEntitiesFormLabel}
            >
                {widgetSettingWithValue.name}
                {additionalSettingsLogic.mapWidgetAlertsIndicatorsDependencyTooltip()}
            </FormLabel>
            {selectionOptions.mainEntity.length === 0 ?
                <Typography className={classes.selectMapFirstNote}>Please select a map first</Typography>
                :
                < Grid
                    container
                    spacing={3}
                    direction="column"
                    style={
                        selectionOptions.mainEntity.length > 3 ?
                            {
                                height: "240px",
                                flexDirection: "inherit",
                                overflowY: "auto",
                                overflowX: "hidden",
                            }
                            :
                            {}
                    }
                >
                    {
                        selectionOptions.mainEntity?.sort(sortByName)?.map(mainItem =>
                        (
                            <Grid container item alignItems="center" spacing={2}>
                                <Grid item md="6" sm="6" xs="6">
                                    {mainItem.name}
                                </Grid>

                                <Grid item md="6" sm="6" xs="6">
                                    <FormControl
                                        variant="outlined"
                                        style={{ width: "100%" }}
                                        fullWidth
                                        required={required}
                                    >
                                        <Select
                                            value={
                                                widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id)
                                                    && widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)?.value
                                                    && selectionOptions.selectiveEntity.map(e => e.id).includes(widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)?.value)
                                                    ?
                                                    widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)?.value
                                                    :
                                                    NIL_UUID
                                            }

                                            onChange={event => {
                                                if (
                                                    widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id)
                                                    && widgetSettingWithValue?.value?.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1)
                                                ) {
                                                    let newMappingFormValues = widgetSettingWithValue?.value.slice();
                                                    newMappingFormValues.find(e => e?.find(it => it.orderNum === 0)?.value === mainItem?.id).find(it => it.orderNum === 1).value = event.target.value ?? NIL_UUID;

                                                    handleSettingValueChange(widgetSettingWithValue, newMappingFormValues);
                                                } else {
                                                    let newDataToAdd = [];
                                                    newDataToAdd.push(
                                                        {
                                                            value: mainItem.id,
                                                            widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 0).id,
                                                            orderNum: 0,
                                                        }
                                                    );

                                                    newDataToAdd.push(
                                                        {
                                                            value: event.target.value,
                                                            widgetSettingsTableColumnId: widgetSettingWithValue.widgetSettingsTableColumns.find(e => e.orderNum === 1).id ?? NIL_UUID,
                                                            orderNum: 1,
                                                        }
                                                    );

                                                    let currentRefTableValues = widgetSettingWithValue?.value?.slice();
                                                    currentRefTableValues.push(newDataToAdd);

                                                    handleSettingValueChange(widgetSettingWithValue, currentRefTableValues);
                                                }
                                            }
                                            }
                                        >
                                            {selectionOptions.selectiveEntity
                                                .filter(o => additionalSettingsLogic.filterAlertsByIndicator(settingsDependencyContextData.selectedMapIndicator?.name, o?.alertType))
                                                .map(selectiveItem => {
                                                    return (
                                                        <MenuItem key={selectiveItem.id} value={selectiveItem.id}>
                                                            <ListItemText primary={
                                                                <SelectionOptionText
                                                                    settingClassName={widgetSettingWithValue.className}
                                                                    item={selectiveItem}
                                                                />} 
                                                            />
                                                        </MenuItem>
                                                    );
                                                })}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )
                        )
                    }
                </Grid>
            }
        </Box >
    );
}

export default MappingEntitiesSetting;
