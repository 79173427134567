import React from "react";
import { Checkbox, FormControlLabel } from '@material-ui/core';

const CheckboxSetting = ({
    widgetSettingWithValue,
    handleSettingValueChange
}) => {
    return (
        <FormControlLabel
            style={{ marginBottom: "-6px" }}
            label={widgetSettingWithValue.name}
            control={
                <Checkbox
                    defaultChecked={widgetSettingWithValue.value === "true"}
                    onChange={
                        venue => handleSettingValueChange(widgetSettingWithValue, venue.target.checked.toString())
                    }
                />
            }
        />
    );
}

export default CheckboxSetting;