import React from "react" 
import { Box, Typography } from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import * as occUtils from './OccupancyUtils';
import EditableNumber from "../EditableNumber";
import { isDarkColor } from "../../../_helpers/colorUtils";

const CurrentOccupancy = (props) => {
  const { data, viewMode, classes, textColor } = props;
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "baseline"
        }}
      >
        {(isViewMode && data?.occupancy?.occupancyNumber) ? 
            <Typography className={classes.commonLayoutValue} style={{color: isDarkColor(textColor) ? 'black' : 'white'}}>
              {data.occupancy.occupancyNumber.toLocaleString("en-US")}
            </Typography>

            //TODO: Improve EditableNumber component and restore it when it is ready and works correctly.
            /*
              <EditableNumber 
                className={classes.commonLayoutValue}
                textColor={textColor}
                value = {data.occupancy.occupancyNumber}
                venueId = {data?.location?.venueId}
                locationId = {data?.location?.id}
                factorTypeId = 'e1ca2628-b3f3-4ae9-86a6-f60f1dbbd1c7' // "Occupancy" factor type
              />
            */
          : <Typography className={classes.commonLayoutValue}> 0 </Typography>
        }
      </Box>
        
      <Typography className={classes.commonLayoutName} style={{color: textColor}}>
          {occUtils.getLocationName(props)}
      </Typography>
    </>
  );
}

export default CurrentOccupancy;
