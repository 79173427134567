import React, { useEffect, useRef, useState, Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {Box, TextField, Typography, Card, CardContent} from "@material-ui/core";
import { Button, ButtonType } from "../_common/htmlTags";
import { toast } from "react-toastify";
import { addMap, updateMap } from "../../_actions/map.actions";
import { MapEditor } from "./MapEditor";
import {useApiGet} from "../../_helpers/useApiGet";
import {getMap} from "../../_services/map.service";
import LoadPleaseWait from "../notification/LoadingPleaseWait/LoadingMessage";
import { NoVenueSelectedError } from "../../_constants";
import { ClearLocationsAlert } from "./ClearLocationsAlert";
import LeaveBlocker from "./LeaveBlocker";

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  card: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: "450px",
    marginBottom: theme.spacing(2)
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    minHeight: 0,
    paddingTop: theme.spacing(3),
    paddingRight: 0
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    minHeight: 0,
    flexGrow: 1,
  },
  horizontalElement: {
    marginLeft: theme.spacing(3)
  },
  saveButton: {
    margin: theme.spacing(1)
  },
  hiddenUploadButton: {
    display: "none"
  },
  visibleUploadButton: {
    margin: theme.spacing(1, 3, 1, 0),
  },
  fileNameLabel: {
    verticalAlign: "middle",
  },
  buttonIcon: {
    marginRight: 10
  }
}));

export const EditMap = (props) => {
  const classes = useStyles();

  // KF: Aweful hack, out of desperation. Some pages, like the current 
  // one must not extend below the screen line. Instead, the content 
  // should get vertical scroll bar(s) if needed. The majority of 
  // pages though, use the page's scroll bar and it's OK for them 
  // to extend down as much as their content needs.
  useEffect(() => {
    document.getElementsByName("main")[0].style.height = "100vh";
    return () => {
      document.getElementsByName("main")[0].style.height = "";
    };
  }, []);

  // Are we creating a new map or editing an existing one?
  const {id} = useParams();
  const isEditing = ((id) ? true : false);

  // The "dirty" is true if overlays have been modified in some way.
  // If only the map name or image have been modified, then "dirty"
  // will remain false. This behaviour can be changed, but at the
  // moment the requirement is to handle the changes only in overlays.
  const [dirty, setDirty] = useState(false);
  
  // While the map is being saved to the database,
  // the updating variable is True.
  const [updating, setUpdating] = useState(false);

  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );

  const emptyMap = {
	venueId: selectedVenue?.id,
    name: "",
    layout: null,
    layoutBlobUrl: null,
    overlays: [] 
  };

  const stableGetMap = useCallback((mapId) => {
    if (isEditing) {
      return getMap(mapId);
    } else {
      return {
        data: {
          venueId: selectedVenue?.id,
          name: "",
          layout: null,
          layoutBlobUrl: null,
          overlays: []
        }
      };
    }
  }, [isEditing, selectedVenue]);

  const [{data: initialMap, isLoading: isMapLoading, isError: isMapLoadingError, errorMessage: mapLoadingErrorMessage}] = useApiGet( stableGetMap, id, emptyMap);

  const dispatch = useDispatch();
  const history = useHistory();

  const [map, setMap] = useState(emptyMap);

  const [clearLocationsAlert, setClearLocationsAlert] = useState({
    open: false,
    onYes: ()=> {},
    onNo: ()=> {}
  });

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    if (isEditing && !isMapLoadingError && isMounted.current) {
      setMap(initialMap);
    }
  }, [initialMap, isEditing, isMapLoadingError]);

  useEffect(() => {
    if (isEditing && isMapLoadingError) {
      toast.error("Loading map failed. " + mapLoadingErrorMessage, { autoClose: false });
    }
  }, [isEditing, isMapLoadingError, mapLoadingErrorMessage]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(map.layoutBlobUrl);
    }
  }, [map.layoutBlobUrl]);

  const handleSave = (event) => {
    event.preventDefault();
    setUpdating(true);

    if (isEditing) {
      dispatch(updateMap(map))
      .then(() => {
        if (props.error !== null && typeof props.error !== "undefined") {
          toast.error("Updating map failed. " + props.error, { autoClose: false });
        }
        else {
          toast.success("Map updated successfully.");
          history.push("/maps");
        }
        if (isMounted.current) {
          setUpdating(false);
        }
      })
      .catch((err) => {
        toast.error("Updating map failed. " + err.response, {
          autoClose: false,
        });
        if (isMounted.current) {
          setUpdating(false);
        }
      });    
    } else {
      dispatch(addMap(map))
      .then(() => {
        if (props.error !== null && typeof props.error !== "undefined")
          toast.error("Adding map failed. " + props.error, { autoClose: false });
        else {
          toast.success("Map added successfully.");
          history.push("/maps");
        }
        if (isMounted.current) {
          setUpdating(false);
        }
      })
      .catch((err) => {
        toast.error("Adding map failed. " + err.response, {
          autoClose: false,
        });
        if (isMounted.current) {
          setUpdating(false);
        }
      });    
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setMap((prev) => ({
      ...prev,
      [name] : value
    }));
  };

  const handleLayoutChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      // Clear the value, because otherwise the onChange event on <input type="file">
      // will not fire if the same file is selected for the second time.
      e.target.value = null;

      // Only images are allowed to be used as map layout.
      var pattern = /image-*/;
      if (!file.type.match(pattern)) {
        toast.error(`Failed to upload ${file.type} file "${file.name}". Only image files can be uploaded.`, {autoClose: false});
        return;
      }

      if ( map.layout && map.overlays.length > 0) {

        const closeAlert = () => {
          setClearLocationsAlert({
            open: false,
            onYes: ()=> {},
            onNo: ()=> {},
            onCancel: () => {}
          });
        };
        const updateLayout = () => {
          setMap((prev) => ({
            ...prev,
            name: prev.name && prev.name !== prev.layout?.name? prev.name : file.name,
            fileName: file.name,
            layout: file,
            layoutBlobUrl : URL.createObjectURL(file),
          }));
        };
        const clearOverlays = () => {
          setMap((prev) => ({
            ...prev,
            overlays: []
          }));
        };

        setClearLocationsAlert({
          open: true,
          onYes: () => {
            updateLayout();
            clearOverlays();
            closeAlert();
          },
          onNo: () => {
            updateLayout();
            closeAlert();
          },
          onCancel: ()=> {
            closeAlert();
          }
        });
      } else {
        setMap((prev) => ({
          ...prev,
          name: prev.name && prev.name !== prev.layout?.name? prev.name : file.name,
          fileName: file.name,
          layout: file,
          layoutBlobUrl : URL.createObjectURL(file)
        }));
      }
    }
  };

  const onOverlayAdded = ({overlayId, locationId, markup}) => {
    setMap((prev) => {
      setDirty(true);
      return {
        ...prev,
        overlays: [
          ...prev.overlays,
          {
            id: overlayId,
            locationId: locationId,
            markup: markup
          }
        ]
      };
    });
  };

  const onOverlayChanged = ({id, locationId, markup}) => {
    setMap((prev) => {
      setDirty(true);
      return {
        ...prev,
        overlays: prev.overlays.map( (overlay, i) => {
          if ( overlay.id === id ) {
            return {
              ...overlay,
              locationId: locationId,
              markup: markup
            }
          } else {
            return overlay;
          }
        })
      };
    });
  };

  const onOverlayDeleted = ({overlayId}) =>{
    if (isMounted.current) {
      setDirty(true);
      setMap((prev) => {
        return {
          ...prev,
          overlays: prev.overlays.filter((overlay,i) => {
            return (overlay.id !== overlayId);
          })
        };
      });
    }
  };

  return (
    selectedVenue === null ? (
      <NoVenueSelectedError />
    ) : (
      <Box sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        flexGrow: 1
      }}>
        <ClearLocationsAlert 
          open={clearLocationsAlert.open}
          onYes={clearLocationsAlert.onYes}
          onNo={clearLocationsAlert.onNo}
          onCancel={clearLocationsAlert.onCancel}
        />
        <LeaveBlocker 
          when={dirty && !updating}
          message="Locations on the map have been changed. Do you want to leave without saving?" />
        <Typography variant="h4" className={classes.title}> 
          {isEditing? "Map details" : "Add new map"}
        </Typography>

        <Card className={classes.card}>
          <CardContent className={classes.cardContent}>
            {isEditing && isMapLoading ? (
              // <h4 style={{ textAlign: "center" }}>Loading...</h4>
              <LoadPleaseWait show={true} />
            ) : (
              isMapLoadingError ? (
                <Fragment>
                  <h4 style={{ textAlign: "center" }}>Error loading map...</h4>
                  <h6 style={{ textAlign: "center" }}>{mapLoadingErrorMessage}</h6>
                </Fragment>
              ) : (
                <form 
                  className={classes.form}
                  onSubmit={handleSave}>
                    {/* This form is laid out in 3 rows:
                    - The elements for uploading the image file
                    - The actual map editor component, where we edit polygons and view the legend
                    - The strip with Save and Cancel buttons. */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom: "24px"
                    }}>
                    <TextField
                      id="mapName"
                      label="Map name"
                      variant="outlined"
                      required
                      style={{
                        width: "600px"
                      }}
                      name="name"
                      value={map.name}
                      onChange={handleChange}
                    />
                    { map.fileName ? 
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          maxWidth: "400px",
                        }}
                        className={classes.horizontalElement}>
                        <Typography
                          variant="body1" 
                          component="div"
                          className={classes.fileNameLabel}>
                          { map.fileName}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          color="textSecondary" 
                          className={classes.fileUploadedLabel}>
                          {map.fileName ? "File uploaded" : ""}
                        </Typography>
                      </Box>
                      : null }
                    <Box
                      className={classes.horizontalElement}>
                      <input
                        id="upload-file-button"
                        type="file"
                        name="layout"
                        onChange={handleLayoutChange}
                        accept=".png,.jpg,.bmp"
                        className={classes.hiddenUploadButton}
                      />
                      <label 
                        htmlFor="upload-file-button"
                        style={{margin: 0}}>
                        <Button 
                          variant="contained" 
                          component="span"
                          buttonType={ButtonType.Dark}>
                          Upload map
                        </Button>
                      </label>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "stretch",
                      flexGrow: 1,
                      minHeight: 0
                    }}>
                    <MapEditor
                      venueId={selectedVenue.id}
                      blobUrl={map.layoutBlobUrl}
                      overlays={map.overlays}
                      onOverlayAdded={onOverlayAdded}
                      onOverlayChanged={onOverlayChanged}
                      onOverlayDeleted={onOverlayDeleted}
                    />
                  </Box>
                  <Box 
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "flex-start",
                      marginTop: "16px"
                    }}>
                    <Button
                      variant="contained"
                      disabled={updating}
                      buttonType={ButtonType.Primary}
                      type="submit"
                    >
                      {/* {isEditing ? "Save" : "Add"} */}
                      Save
                    </Button>
                    <Button
                      className={classes.horizontalElement}
                      variant="outlined"
                      buttonType={ButtonType.Secondary}
                      component={Link}
                      to={"/maps"}
                    >
                      Cancel
                    </Button>
                  </Box>
                </form>
              )
            )}
          </CardContent>
        </Card>
      </Box>
    )
  );
};

export default EditMap;