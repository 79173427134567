import React from "react"
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box, Chip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.common,
}));

export const CommonWidgetLayout = ({ value, name, timePeriod, contrastFontColor }) => {
    const classes = useStyles({ });

    return (
        <Box
            style={{
                justifyContent: "center",
                textAlign: "center",
                overflow: "hidden"
            }}
        >

            {value &&
                <Typography className={classes.commonLayoutValue} style={{ color: contrastFontColor }}>
                    {value}
                </Typography>
            }


            {name && <Typography className={classes.commonLayoutName} style={{ color: contrastFontColor }}>
                {name}
            </Typography>
            }


            {timePeriod &&
                <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
                    <Chip
                        className={classes.timePeriodChip}
                        label={timePeriod}
                        size="small"
                        style={{ color: contrastFontColor }}
                    />
                </div>
            }
        </Box>
    );
}

export default CommonWidgetLayout;
