import WidgetDemographicContent from './WidgetDemographicContent';
import { widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";

export const WidgetDemographic = (props) => (
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetDemographic.id}}
        >
            <WidgetDemographicContent />
        </WidgetWrapper>
    );

export default WidgetDemographic;