const scalePx = (px) => {
  return ({scaleFactor}) => `${Math.round(px * scaleFactor)}px`;
};

export const mapTheme = (theme) => {
  // A couple of edge properties are repeated in a few places.
  // To avoid copy-pasting, they are extracted into this separate object
  // which is then incorporated into the edge styling.
  const polygonCommon = {
    strokeLinecap: "round",
    strokeLinejoin: "round"
  };

  const overlayColor = theme.palette.map.overlay.color;
  const green = theme.palette.map.overlay.green;
  const amber = theme.palette.map.overlay.amber;
  const red = theme.palette.map.overlay.red;

  return {
    polygonCreator: {
      polygon: {
        ...polygonCommon,
        fill: 'none',
        stroke: overlayColor,
        strokeWidth: scalePx(2),
      },
      provisionalEdge: {
        ...polygonCommon,
        stroke: overlayColor,
        strokeWidth: scalePx(2),
        strokeOpacity: "0.4"
      },
      vertex: {
        stroke: overlayColor,
        strokeWidth: scalePx(2),
        fill: theme.palette.common.white,
        rx: scalePx(5),
        ry: scalePx(5),
      },
      firstVertex: {
        fill: theme.palette.map.overlay.firstVertex,
        rx: scalePx(5),
        ry: scalePx(5),
      },
      firstVertexHighlight: {
        fill: theme.palette.map.overlay.firstVertex,
        fillOpacity: "0.3",
        rx: scalePx(12),
        ry: scalePx(12),
      },
      // KF: I may need this style in the near future.
      // selectedVertexAddendum: {
      //   fill: overlayColor,
      //   fillOpacity: "1.0",
      // }
    },
    polygonEditor: {
      polygon: {
        ...polygonCommon,
        stroke: overlayColor,
        strokeOpacity: "1.0",       
        strokeWidth: scalePx(2),
        fill: overlayColor,
        fillOpacity: "0.4",
      },
      vertex: {
        stroke: overlayColor,
        strokeWidth: scalePx(2),
        fill: theme.palette.common.white,
        rx: scalePx(5),
        ry: scalePx(5),
      },
      vertexHighlight: {
        fill: overlayColor,
        fillOpacity: "0.2",
        rx: scalePx(12),
        ry: scalePx(12),
      },
      selectedVertexAddendum: {
        fill: overlayColor,
        fillOpacity: "1.0",
      },
      middleVertex: {
        stroke: overlayColor,
        strokeWidth: scalePx(1),
        //strokeOpacity: "0.4",
        fill: theme.palette.common.white,
        //fillOpacity: "0.4",
        rx: scalePx(2.5),
        ry: scalePx(2.5),
        pointerEvents: "none", // We are listening to the mouse venues on the larger "mouse pad".
      },
      middleVertexMousePad: {
        fill: "transparent",
        rx: scalePx(6),
        ry: scalePx(6),
      },
      middleVertexHighlight: {
        fill: overlayColor,
        fillOpacity: "0.2",
        rx: scalePx(8),
        ry: scalePx(8),
      },
    },
    polygonViewer: {
      polygon: {
        ...polygonCommon,
        fill: overlayColor,
        fillOpacity: "0.2",
        stroke: overlayColor,
        strokeOpacity: "0.5",
        strokeWidth: scalePx(2),
      },
      mouseOverPolygon: {
        ...polygonCommon,
        fill: overlayColor,
        fillOpacity: "0.4",
        stroke: overlayColor,
        strokeOpacity: "1.0",
        strokeWidth: scalePx(2),
      }
    },
    preview: {
      polygon: {
        ...polygonCommon,
        fill: overlayColor,
        fillOpacity: "0.2",
      },
      showStrokeAddendum: {
        stroke: overlayColor,
        strokeOpacity: "0.5",
        strokeWidth: scalePx(2),
      },
    },
    colorMap: {
      polygon: {
        ...polygonCommon,
        fill: overlayColor,
        fillOpacity: "0.6",
        stroke: overlayColor,
        strokeOpacity: "0.6",
        strokeWidth: scalePx(1),
      },
      greenPolygonAddendum: {
        fill: green,
        stroke: green,
      },
      amberPolygonAddendum: {
        fill: amber,
        stroke: amber,
      },
      redPolygonAddendum: {
        fill: red,
        stroke: red,
      },
      nonSelectedLocationAddendum: {
        fillOpacity: "0.2",
        strokeWidth: scalePx(0),
        stroke: "none",
      },
      "@keyframes flashEffect": {
        "0%": {
          fillOpacity: 0.6,
          strokeOpacity: 0.6,
          // The filters are commented out for future experiments.
          //filter: "brightness(1)",
          //filter: "contrast(1)"
        },
        "50%" : {
          fillOpacity: 0.3,
          strokeOpacity: 0.3,
          //filter: "brightness(0.9)",
          //filter: "contrast(0.5)"
        },
        "100%" : {
          fillOpacity: 0.6,
          strokeOpacity: 0.6,
          //filter: "brightness(1)",
          //filter: "contrast(1)"
        }
      },
      flashPolygonAddendum: {
        animation: `$flashEffect 1s infinite` // ${theme.transitions.easing.easeInOut}`
      },
    },
  };
};

export default mapTheme;