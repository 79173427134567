import { useState, useEffect } from "react";

export const useSessionStorage = (key, defaultValue) => {
  
  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    if (key !== undefined && key.indexOf("undefined") < 0)
      sessionStorage.setItem(key, JSON.stringify(data));
  }, [key, data]);

  return [JSON.parse(sessionStorage.getItem(key) !== "undefined" ? sessionStorage.getItem(key) :null ), setData];
};