import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography, CircularProgress } from "@material-ui/core";
import { EmptyContainer } from "../EmptyContainer";
import { ColorMap } from "../../maps/ColorMap";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { ApiRootConstants } from "../../../_constants";

const useStyles = makeStyles((theme) => ({  
  ...theme.widget.trafficLight,
  ...theme.widget.map,
}));


export const WidgetCapacityMapContent = (props) => {
  const { height, viewMode, data, settings, brandingSettings, onAudioPlay, onGetCaption  } = props;
  const classes = useStyles({ props, ...brandingSettings});
  const bgcolorClass = classes.defaultBackground;
  const mapId = settings?.RefMap?.id;
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  const imgRef = `${ApiRootConstants.venueManagementApiRoot}api/VenueMap/Layout/${mapId}/${Math.round(Date.now() / 10000)}`;
  
  props = { ...props, icon: 'SettingsSuggest.svg', color: "#000000" };

  useEffect(() => {
    if (!isViewMode && (settings?.RefMap?.name || settings?.RefMapIndicator?.name)) {
        onGetCaption(`${settings?.RefMap?.name} - ${settings?.RefMapIndicator?.name}`);
    }
  }, [isViewMode, settings?.RefMap?.name, settings?.RefMapIndicator?.name, onGetCaption]);

  return (
    <>
      {mapId && data && isViewMode ?
        <Box className={classes.container}>
            <Box className={classes.title}>
                <Typography className={classes.caption}>{data.name ? `${data.name} - ${data.indicator?.name}` : 'Map'}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                alignItems: "stretch",
                minHeight: 0
              }}>
              <ColorMap map={data} onLocationSelected={locationId => {}} onAudioPlay={onAudioPlay} />
            </Box>
        </Box>

    : !mapId ?
        <Box display="flex" className={`${classes.container} ${bgcolorClass}`}>
            <EmptyContainer {...props} />
        </Box>

    : !isViewMode ?
        <Box className={classes.container}>
            <ColorMap map={{layoutBlobUrl: imgRef}} onLocationSelected={locationId => {}} onAudioPlay={() => {}} />
        </Box>
    :
        <Box display="flex" className={`${classes.container} ${bgcolorClass}`} height={height} >
            <CircularProgress />
        </Box>
      }
    </>
  );
}

export default WidgetCapacityMapContent;
