import axios from "axios";
import { AuthService} from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/UserGroup`,
};

const authService = new AuthService();

export async function getUserGroups(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/GetUserGroups/${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function getUserGroup(userGroupId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}/GetUserGroup/${userGroupId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function addUserGroup(userGroup) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {
   
      return await axios
        .post(`${constants.api}/AddUserGroup`, userGroup, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function updateUserGroup(userGroup) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {
      
      return await axios
        .put(`${constants.api}/UpdateUserGroup`, userGroup, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function deleteUserGroup(userGroupId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {

      return await axios
        .delete(`${constants.api}/${userGroupId}`, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
};

export async function deleteUserFromAllUserGroups(userId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {

      return await axios
        .delete(`${constants.api}/DeleteUserFromAllUserGroups/${userId}`, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw error;
            }
          }
        });
    }
  }
}


