import axios from "axios";
import { AuthService, downloadFile } from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.ARMVendorApiRoot}api/ARMCustomDataSource`,
};

const authService = new AuthService();

export async function armCustomDataSourceImport(file) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        "Content-Type": "multipart/form-data",
      },
    };

    const bodyFormData = new FormData();
    bodyFormData.append("fileName", file.name);
    bodyFormData.append("File", file);

    return await axios
      .post(`${constants.api}/Import`,
        bodyFormData,
        options
      )
      .catch((error) => {
        //console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }
        throw error;
      });
  }
}

export function armCustomDataSourceExport(venueId) {
    return downloadFile(`${constants.api}/Export/${venueId}`, `ARMCustomDataSource-${venueId}.xlsx`);
}
