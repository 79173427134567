import React, { Fragment } from "react";
import {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Box, FormControl, IconButton, InputLabel, List,
  ListItem, ListItemSecondaryAction, ListItemText, MenuItem, 
  Select, Tooltip, Typography} from "@material-ui/core";
import {ArrowBackIos} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { AlertDialog } from "../../_helpers/AlertDialog";
import { icon } from "../../_constants";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "400px",
      borderStyle: "solid none solid solid",
      borderColor: theme.palette.common.panelBorderColor,
      borderWidth: "1px",
      borderRadius: "3px 0 0 3px"
    },
    locationsTitle: {
      flexGrow: 1,
      textTransform: "uppercase",
      margin: "8px 5px 5px 10px",
    },
    chooseLocationTitle: {
      textTransform: "uppercase",
      margin: "5px"
    },
    selectControl: {
      minWidth: 120,
    },
    backwardsIcon: {
      marginLeft: "5px", // The arrow icon happens to be not centered, this marging compensates for that.
    },
    locationName: {
      width: "275px"
    },
    locationIcon: {
      margin: "8px 5px 5px 5px"
    },
    editIcon: {
      color:  theme.palette.color.primary.dark
    },
    deleteIcon: {
      color:  theme.palette.color.primary.red
    },
    listItemSelected: {
    },
    listItemRoot: {
      //backgroundColor: "blue", // This comment is for debugging
      '&.Mui-selected': {
        backgroundColor: theme.palette.color.primary.background,
        '&:hover': {
          backgroundColor: theme.palette.color.primary.background,
        }
      },
    },
    selectLocation: {
      // This style is for the the drop-down list where the use
      // changes the location for a polygon ("Location" field 
      // in the "Choose location" panel).
      // A location can exist at some deep level in the hierarchy 
      // of locations. In the drop-down list, this hierarchy is
      // displayed using indentation for each location name. When
      // a location is selected and the drop-down part of the list
      // is collapsed, we should not display the indentation because
      // the location is no longer shown in the context of the
      // hierarchy.
      '& div': {
        marginLeft: 0
      }
    }
  };
});

const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

const flattenLocations = ({location, level = 0}) => {
  const result = [
    {
      id: location.id,
      name: location.name,
      isSensor: location.locationType.isSensor,
      isDeleted: location.isDeleted,
      level: level
    }];
  
  location.children.sort(sortByName).forEach((childLocation) => {
    result.push(...flattenLocations({location: childLocation, level: level + 1}));
  });

  return result;
};

export const Legend = ({overlays, rootLocation, currentOverlayId, 
  onOverlaySelected, onLocationChanged, onOverlayDeleted}) => {
  const classes = useStyles();

  // The currentOverlayId prop is for the overlay that is currently selected
  // on the map.
  // The overlayId local state is for the overlay that is being edited. If no
  // overlay is being edited at the moment, then the overlayId state will be null.
  const [overlayId, setOverlayId] = useState(null);

  const overlay = overlays.find(o => o.id === overlayId);

  // This is a flat array of all locations.
  const [locations, setLocations] = useState([]);

  // This flag shows/hides the confirmation message for 
  // deleting the polygon.
  const [deleteAlert, setDeleteAlert] = useState({
    open: false
  });
  
  useEffect(() => {
    if (rootLocation) {
      setLocations(flattenLocations({location: rootLocation}));
    }
  }, [rootLocation]);
  
  let renderedOverlayLocations = null;
  if (locations.length > 0) {
    renderedOverlayLocations = overlays.map((o) => {    
      const locationName = o.locationId ?
        locations.find(l => l.id === o.locationId).name
        : "<not set>";

        return (
          <ListItem
            key={o.id}
            selected={o.id === currentOverlayId}
            onClick={() => onOverlaySelected(o.id)}
            classes={{
              root: classes.listItemRoot,
              selected: classes.listItemSelected
            }}
          >
            <ListItemText primary={<Typography className={classes.locationName}>{locationName}</Typography>} />
            {o.id === currentOverlayId ?
              <ListItemSecondaryAction>
                <Fragment>
                  <IconButton 
                    edge="end" 
                    aria-label="Change"
                    className={classes.editIcon}
                    onClick={() => {
                      setOverlayId(o.id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton 
                    edge="end" 
                    aria-label="Remove"
                    className={classes.deleteIcon}
                    onClick={() => {
                      setDeleteAlert({
                        open: true,
                        overlayId: o.id,
                        locationName: locationName
                      });
                      onOverlayDeleted(o.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Fragment>
              </ListItemSecondaryAction>
              : null 
            }
          </ListItem>
        );
    });
  }

  let renderedLocationsToChooseFrom = null;
  if (locations.length > 0 && overlay) {
    const markedLocationIds = overlays.filter(o=> o.locationId).map(o => o.locationId);
    renderedLocationsToChooseFrom = 
      locations
        .filter((l) => !l.isSensor && !l.isDeleted)
        .map((l) => {
          return (
            <MenuItem
              key={l.id}
              value={l.id}
              disabled = {(l.id !== overlay.locationId) && markedLocationIds.includes(l.id)}
            >
              <Box
                ml={l.level*2}>
                {l.name}
              </Box>
            </MenuItem>
          );
        });
  }

  return (
    <Box
      ml={5}
      component="div"
      height="100%"
      //bgcolor="red"
      display="flex"
      flexDirection="column"
      className={classes.root}>
      <AlertDialog open={deleteAlert.open} contentText={`Do you want to remove location ${deleteAlert.locationName} from the map?`}
        onNoButtonClicked={() => {
          setDeleteAlert({
            open: false,
            overlayId: null,
            locationName: null
          });
        }}
        onYesButtonClicked={(e) => {
          onOverlayDeleted(deleteAlert.overlayId);
          setDeleteAlert({
            open: false,
            overlayId: null,
            locationName: null
          });
        }} />
      <Box
        display="flex"
        alignItems="center"
        mx={1} my={1}>
        {overlay ?
          <Tooltip 
            title="To Locations">
            <IconButton
              disableRipple={true}
              onClick={() => {
                setOverlayId(null);
              }}
            >
              <ArrowBackIos className={classes.backwardsIcon}/>
            </IconButton>
          </Tooltip>
        : null }
        <Box ml={1}>
          {overlay?
            <Typography
              variant="h5"
              className={classes.chooseLocationTitle}>
              Choose Location
            </Typography>
            : 
            <Box
              display="flex"
            >
              <Typography className={classes.locationIcon}>
                <img
                  src={icon.path + "location-icon.svg"}
                  alt=""
                />
              </Typography>
              <Typography
                variant="h5"
                className={classes.locationsTitle}>
                Locations
              </Typography>
            </Box>
          }
        </Box>
      </Box>
      <Box
        //bgcolor="pink"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
          overflow: "auto"
        }}>
        {overlay ?
          <Box
            display="flex"
            flexDirection="column"
            py={2}
            px={2}
            >
            <FormControl
              variant="outlined"
              className={classes.selectControl}
              fullWidth
            >
              <InputLabel id="choose-location-label">Location</InputLabel>
              <Select
                // labelId="locationType-label"
                // id="locationType-select"
                // name="locationTypeId"
                value={overlay.locationId}
                onChange={(e) => {
                  const newLocationId = e.target.value;
                  onLocationChanged(overlay, newLocationId);
                }}
                label="Location"
                classes={{
                  select: classes.selectLocation
                }}
              >
                {renderedLocationsToChooseFrom}
              </Select>
            </FormControl>
          </Box>
          :
          <List>
            {renderedOverlayLocations}
          </List>
        }
      </Box>
    </Box>
  );
};

export default Legend;