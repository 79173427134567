import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useQuery } from 'react-query';
import WidgetSettingsModalWindow from "./SettingsModalWindow";
import { widgetSettingsValuesSelector, widgetSettingsSelector } from "../../../_reducers/widget.data.selectors";
import { widgetSettingsDataTypesConstants } from "../../../_constants/widget.settings.constants";
import {
    loadAllWidgetTypesSettings,
    loadWidgetSettingsValues,
    updateWidgetSettingValue,
    updateRefListWidgetSettingValue,
    updateRefTableWidgetSettingValue,
} from "../../../_actions/widget.data.actions";
import {
    loadOccupancyViewModes,
    loadTrafficTypes,
    loadNumberWidgetValueTypes,
    loadDemographicWidgetValueTypes,
    loadDemographicWidgetTimePeriods,
    loadDemographicWidgetGender,
    loadDemographicWidgetAgeGroup,
    loadNumberWidgetTimePeriods,
    loadCameras,
    loadMapWidgetIndicators,
    loadQueueModelAlertMetrics,
    loadTrafficInOutAlertMetrics,
    loadCohorts,
    loadTrafficInOutDisplayModes,
    loadPatronDistributionMetrics,
    loadPatronDistributionValueTypes,
} from "../../../_actions/dashboard.actions";
import { loadIndicatorVendorMappings } from "../../../_actions/schedule.task.actions";
import { loadVendors } from "../../../_actions/schedule.task.actions";
import { loadMaps } from "../../../_actions/map.actions";
import { getVenueLocationsList } from "../../../_actions/venue.location.actions";
import { loadTrafficInOutMetrics, loadFootfallTrafficMetrics } from "../../../_actions/indicators.actions";
import { loadDashboardAlertRuleSets } from "../../../_actions/dashboard.alertRuleSet.actions";
import { SettingsDependencyProvider } from "./SettingsDependencyContext";
import { NIL as NIL_UUID } from "uuid";

/**
 * Component is responsible for connection and working with Server.
 * 
 * Returns necessary UI representation for widget settings.
 */

const LONG_STALE_TIME = 60 * 60 * 1000;
const SHORT_STALE_TIME = 60 * 1000;

const WidgetSettingsWrapper = ({
    isSettingsOpen,
    handleCloseSettings,
    widgetId,
    widgetTypeId,
    widgetTypeName,
}) => {
    const dispatch = useDispatch();

    const [widgetSettingsWithValuesToUpdate, setWidgetSettingsWithValuesToUpdate] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const selectedVenue = useSelector(state => state.venueReducer.selectedVenue);
    const widgetTypeSettings = useSelector(state => widgetSettingsSelector(state, widgetTypeName));
    const widgetSettingsValues = useSelector(state => widgetSettingsValuesSelector(state, widgetTypeName, widgetId));

    var settingsData = (widgetTypeSettings ?? []).map(e => {
        let value = null;

        switch (e.dataType) {
            case widgetSettingsDataTypesConstants.refIdList:
                value = widgetSettingsValues?.filter(v => v.widgetSettingId === e.id).map(e => e.value) ?? e.defaultValue
                break;

            case widgetSettingsDataTypesConstants.refTable:
                value = [];

                let rawData = widgetSettingsValues?.filter(v => v.widgetSettingId === e.id).map(el => {
                    return (

                        {
                            value: el.widgetSettingsValueColumn?.widgetSettingsTableColumn?.orderNum === 1 ? el.value ?? NIL_UUID : el.value,
                            rowNumber: el.widgetSettingsValueColumn?.rowNumber,
                            orderNum: el.widgetSettingsValueColumn?.widgetSettingsTableColumn?.orderNum,
                            widgetSettingsTableColumnName: el.widgetSettingsValueColumn?.widgetSettingsTableColumn?.name,
                            widgetSettingsTableColumnId: el.widgetSettingsValueColumn?.widgetSettingsTableColumn?.id,
                        }
                    )
                }
                ) ?? [];

                rawData.forEach(e => {
                    if (value[e.rowNumber] && value[e.rowNumber].length > 0)
                        value[e.rowNumber].push(e);
                    else
                        value[e.rowNumber] = [e];

                })

                break;

            default:
                value = widgetSettingsValues?.find(v => v.widgetSettingId === e.id)?.value ?? e.defaultValue
                break;
        }

        return (
            {
                id: widgetSettingsValues?.find(v => v.widgetSettingId === e.id)?.id ?? widgetId + "_" + e.id,
                className: e.className,
                dataType: e.dataType,
                name: e.name,
                dashboardWidgetId: widgetId,
                widgetSettingId: e.id,
                value: value,
                widgetSettingsTableColumns: e.widgetSettingsTableColumns
            }
        )
    });

    useQuery(['loadAllWidgetTypesSettings'], () => dispatch(loadAllWidgetTypesSettings()), { staleTime: LONG_STALE_TIME });
    useQuery(['loadWidgetSettingsValues', widgetTypeId, widgetId], () => dispatch(loadWidgetSettingsValues(widgetTypeId, widgetId)), { staleTime: SHORT_STALE_TIME });

    useQuery('loadVendors', () => dispatch(loadVendors()), { staleTime: LONG_STALE_TIME });
    useQuery(['loadMaps', selectedVenue?.id], () => dispatch(loadMaps(selectedVenue?.id)), { staleTime: SHORT_STALE_TIME });
    useQuery(['getVenueLocationsList', selectedVenue?.id], () => dispatch(getVenueLocationsList(selectedVenue?.id)), { staleTime: SHORT_STALE_TIME });
    useQuery('loadTrafficInOutMetrics', () => dispatch(loadTrafficInOutMetrics()), { staleTime: LONG_STALE_TIME });
    useQuery('loadFootfallTrafficMetrics', () => dispatch(loadFootfallTrafficMetrics()), { staleTime: LONG_STALE_TIME });
    useQuery('loadOccupancyViewModes', () => dispatch(loadOccupancyViewModes()), { staleTime: LONG_STALE_TIME });
    useQuery('loadTrafficTypes', () => dispatch(loadTrafficTypes()), { staleTime: LONG_STALE_TIME });
    useQuery('loadCohorts', () => dispatch(loadCohorts()), { staleTime: LONG_STALE_TIME });
    useQuery('loadIndicatorVendorMappings', () => dispatch(loadIndicatorVendorMappings()), { staleTime: LONG_STALE_TIME });
    useQuery('loadNumberWidgetValueTypes', () => dispatch(loadNumberWidgetValueTypes()), { staleTime: LONG_STALE_TIME });
    useQuery('loadDemographicWidgetValueTypes', () => dispatch(loadDemographicWidgetValueTypes()), { staleTime: LONG_STALE_TIME });
    useQuery('loadDemographicWidgetTimePeriods', () => dispatch(loadDemographicWidgetTimePeriods()), { staleTime: LONG_STALE_TIME });
    useQuery('loadDemographicWidgetGender', () => dispatch(loadDemographicWidgetGender()), { staleTime: LONG_STALE_TIME });
    useQuery('loadDemographicWidgetAgeGroup', () => dispatch(loadDemographicWidgetAgeGroup()), { staleTime: LONG_STALE_TIME });
    useQuery('loadNumberWidgetTimePeriods', () => dispatch(loadNumberWidgetTimePeriods()), { staleTime: LONG_STALE_TIME });
    useQuery('loadMapWidgetIndicators', () => dispatch(loadMapWidgetIndicators()), { staleTime: LONG_STALE_TIME });
    useQuery('loadQueueModelAlertMetrics', () => dispatch(loadQueueModelAlertMetrics()), { staleTime: LONG_STALE_TIME });
    useQuery('loadTrafficInOutAlertMetrics', () => dispatch(loadTrafficInOutAlertMetrics()), { staleTime: LONG_STALE_TIME });
    useQuery(['loadCameras', selectedVenue?.id], () => dispatch(loadCameras(selectedVenue?.id)), { staleTime: SHORT_STALE_TIME });
    useQuery(['loadDashboardAlertRuleSets', selectedVenue?.id], () => dispatch(loadDashboardAlertRuleSets(selectedVenue?.id)), { staleTime: SHORT_STALE_TIME });
    useQuery('loadTrafficInOutDisplayModes', () => dispatch(loadTrafficInOutDisplayModes()), { staleTime: LONG_STALE_TIME });
    useQuery('loadPatronDistributionMetrics', () => dispatch(loadPatronDistributionMetrics()), { staleTime: LONG_STALE_TIME });
    useQuery('loadPatronDistributionValueTypes', () => dispatch(loadPatronDistributionValueTypes()), { staleTime: LONG_STALE_TIME });

    /**
     * Method handles change of specific setting value.
     */
    const handleSettingValueChange = (widgetSettingWithValue, newValue) => {
        var newWidgetSettingsWithValuesToUpdate = widgetSettingsWithValuesToUpdate.length > 0 ?
            widgetSettingsWithValuesToUpdate?.map(a => { return { ...a } }) :
            settingsData.map(a => { return { ...a } });

        if (newWidgetSettingsWithValuesToUpdate) {
            newWidgetSettingsWithValuesToUpdate.find(
                s => s.widgetId === widgetSettingWithValue.widgetId && s.id === widgetSettingWithValue.id
            ).value = newValue;
        }

        setWidgetSettingsWithValuesToUpdate(newWidgetSettingsWithValuesToUpdate);
    }

    /**
    * Method handles form with all settings submit.
    */
    function handleSubmitSettingsValues(event) {
        event.preventDefault();

        if (widgetSettingsWithValuesToUpdate?.length > 0) {
            setIsSaving(true);

            const refTableDataToUpdate = widgetSettingsWithValuesToUpdate.filter(a => a.dataType === widgetSettingsDataTypesConstants.refTable);
            if (refTableDataToUpdate?.length > 0) {
                refTableDataToUpdate.forEach(refTableSettingWithValues => {
                    dispatch(updateRefTableWidgetSettingValue(widgetTypeId, widgetId, refTableSettingWithValues))
                        .catch((error) => {
                            toast.error("Updating widget settings failed. " + error, { autoClose: false });
                            setIsSaving(false);
                        });
                })
            }

            const refIdListDataToUpdate = widgetSettingsWithValuesToUpdate.filter(a => a.dataType === widgetSettingsDataTypesConstants.refIdList);
            if (refIdListDataToUpdate?.length > 0) {
                refIdListDataToUpdate.forEach(refIdListSettingWithValues => {
                    dispatch(updateRefListWidgetSettingValue(refIdListSettingWithValues))
                        .catch((error) => {
                            toast.error("Updating widget settings failed. " + error, { autoClose: false });
                            setIsSaving(false);
                        });
                })
            }

            const datatoUpdate = widgetSettingsWithValuesToUpdate.filter(a => a.dataType !== widgetSettingsDataTypesConstants.refIdList && a.dataType !== widgetSettingsDataTypesConstants.refTable);

            // Handling groups collection setting data saving (for example, Patron Distribution widget)
            const settingsGroupCollectionData = widgetSettingsWithValuesToUpdate.find(e => e.dataType === widgetSettingsDataTypesConstants.settingsGroupCollection)?.value ?? [];

            var resData = datatoUpdate.concat(settingsGroupCollectionData);
            resData = resData.filter(e => e.dataType !== widgetSettingsDataTypesConstants.settingsGroupCollection)

            if (resData?.length > 0) {
                dispatch(updateWidgetSettingValue(widgetTypeId, widgetId, resData))
                    .then(() => {
                        toast.success("Widget settings updated successfully.");
                        setIsSaving(false);
                    })
                    .catch((error) => {
                        toast.error("Updating widget settings failed. " + error, { autoClose: false });
                        setIsSaving(false);
                    });
            }
        }

        setTimeout(handleCloseSettings, 400);
    }

    return (
        <SettingsDependencyProvider>
            <WidgetSettingsModalWindow
                isSettingsOpen={isSettingsOpen}
                handleCloseSettings={() => {
                    handleCloseSettings();
                    setWidgetSettingsWithValuesToUpdate([]);
                }}
                settingsData={widgetSettingsWithValuesToUpdate?.length > 0 ? widgetSettingsWithValuesToUpdate : settingsData}
                widgetTypeId={widgetTypeId}
                handleSettingValueChange={handleSettingValueChange}
                handleSubmitSettingsValues={handleSubmitSettingsValues}
                isSaving={isSaving}
            />
        </SettingsDependencyProvider>
    )
};

export default React.memo(WidgetSettingsWrapper);