import React from "react";
import { 
  Box,
  TextField
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import AlertRulesEditor from "./AlertRulesEditor";

const useStyles = makeStyles((theme) => {
  return {
    name: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(1)
    },
    alertRules: {
      alignSelf: "flex-start"
    }
  };
});

// The expected alertRuleSet object looks like this:
//     {
//       name: "Color rule set ABC",
//       rules: [
//         {
//           from: 0,
//           to: 25,
//           color: {
//             value: "#83C58A",
//           }, sound: false
//         }, {
//           from: 26,
//           to: null,
//           color: {
//             value: "#74B5D0"
//           }, sound: false
//         }, {
//           from: 51,
//           to: 100,
//           color: {
//             value: "#E98999"
//           }, sound: true
//         }, {
//           from: 101,
//           to: 120,
//           color: {
//             value: "#A6B8FB",
//             flash: true
//           }, sound: true
//         }
//       ]
//     }
// and a similar object will be passed as the only parameter of the onChange callback.
export const AlertRuleSetEditor =({alertRuleSet, onChange}) => {
  const classes = useStyles();

  const handleAlertRulesChange =(alertType, alertRules) => {
    onChange({
      ...alertRuleSet,
      alertType: alertType,
      rules: alertRules
    });
  };

  const handleNameChange = (e) => {
    const { value } = e.target;
    onChange({
      ...alertRuleSet,
      name: value
    });
  };

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: 0,
        display: "flex",
        flexDirection: "column"
      }}
    >
      <TextField
        id="name"
        label="Name"
        variant="outlined"
        required
        name="name"
        value={alertRuleSet?.name}
        onChange={handleNameChange}
        className={classes.name}
      />
      <AlertRulesEditor
        className={classes.alertRules}
        label="Rules"
        alertType={alertRuleSet?.alertType}
        alertRules={alertRuleSet?.rules}
        onChange={handleAlertRulesChange}
      />
    </Box>
  );
};

export default AlertRuleSetEditor;