import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
} from "@material-ui/core";
import {Locations} from "./CurrentFactors.Locations";
import {Matrix} from "./CurrentFactors.Matrix";

const useStyles = makeStyles((theme) => ({
  root: {
  },
  locations: {
    backgroundColor: theme.palette.venueSettings.dataSources.locationsBackground,
  },
  title: {
    textTransform: "uppercase",
    fontWeight: "500",
    fontSize: "15px",
    padding: "15px 20px 5px 20px",
  }
}));

export const CurrentFactors = ({venue}) => {
  const classes = useStyles();

  // KF: Awful hack, out of desperation. Some pages, like the current 
  // one must not extend below the screen line. Instead, the content 
  // should get vertical scroll bar(s) if needed. The majority of 
  // pages though, use the page's scroll bar and it's OK for them 
  // to extend down as much as their content needs.
  useEffect(() => {
    document.getElementsByName("main")[0].style.height = "100vh";
    return () => {
      document.getElementsByName("main")[0].style.height = "";
    };
  }, []);

  const [location, setLocation] = useState({locationId: "", isSensor: false});

  return (
    // This Box contains the loading component and another Box with the settings, on top of each other.
    <Box
      className={classes.root}
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "stretch",
        flexGrow: 1,
        minHeight: 0
      }}
    >
      <Box
        className={classes.locations}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "350px",
          overflow: "auto",
          minHeight: 0,
        }}>
          <Typography
            className={classes.title}>
            Locations
          </Typography>
          <Locations 
            venue={venue}
            onLocationSelected={({locationId, isSensor}) => setLocation({locationId: locationId, isSensor: isSensor})}
          />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          minHeight: 0,
          ml: 3,
          alignSelf: "flex-start"
        }}>
          <Matrix 
            venue={venue} 
            location={location}
          />
      </Box>
    </Box>
  );
};

export default CurrentFactors;