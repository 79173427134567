import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Grid,
  TextField,
} from "@material-ui/core";
import { toast } from "react-toastify";
import { Button } from "../../_common/htmlTags/Button";
import {
  getUserWithVenue,
  updateCurrentUserFullName
} from "../../../_services/userManagement.service";
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage";
import { ROLES } from "../../../_constants/user.permissions.constants";

const useStyles = makeStyles((theme) => ({
  disabledTextField: {
    backgroundColor: theme.palette.color.primary.disabled, borderRadius: '5px',
    "& .MuiInputBase-root.Mui-disabled": {
      color: "#627E84",
    }
  },
  adminNote: {
    opacity: 0.7,
    marginBottom: "20px"
  }
}));

const ADMIN_LOGIN = "admin";

export const UserInfo = () => {
  const classes = useStyles();

  const [user, setUser] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  useEffect(() => {
    getUserWithVenue()
      .then((response) => {
        setUser(response);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Loading user failed " + error, { autoClose: false });
      });
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;

    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  }

  const handleSave = (e) => {
    e.preventDefault();

    setUpdating(true);

    updateCurrentUserFullName(user)
      .then(() => {
        setUpdating(false);
        toast.success("User updated successfully.");
      })
      .catch((error) => {
        setUpdating(false);
        toast.error("Updating user failed " + error, { autoClose: false });
      });
  }

  return (
    <LoadPleaseWait show={isLoading} >
      {user?.email === ADMIN_LOGIN &&
        <div className={classes.adminNote}>
          <i>This profile cannot be changed because it belongs to the system role</i>
        </div>
      }

      <form className={classes.root} autoComplete="off" onSubmit={handleSave}>
        <Grid container direction="column" spacing={3}>

          <Grid item>
            <TextField
              id="email"
              label="Login / Email"
              name="email"
              value={user?.email}
              disabled
              className={classes.disabledTextField}
              variant="outlined"
              required
              fullWidth

              InputLabelProps={{
                shrink: true,
                style: { color: "#627E84" }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="firstName"
              label="First name"
              name="firstName"
              value={user?.firstName}
              onChange={handleChange}
              variant="outlined"
              required
              fullWidth

              disabled={user?.email === ADMIN_LOGIN}
              className={user?.email === ADMIN_LOGIN ? classes.disabledTextField : ""}

              InputLabelProps={{
                shrink: true,
                style: { color: "#627E84" }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="lastName"
              label="Last name"
              name="lastName"
              value={user?.lastName}
              onChange={handleChange}
              variant="outlined"
              required
              fullWidth

              disabled={user?.email === ADMIN_LOGIN}
              className={user?.email === ADMIN_LOGIN ? classes.disabledTextField : ""}

              InputLabelProps={{
                shrink: true,
                style: { color: "#627E84" }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="phoneNumber"
              label="Mobile number"
              name="phoneNumber"
              value={user?.phoneNumber}
              onChange={handleChange}
              variant="outlined"
              fullWidth

              disabled={user?.email === ADMIN_LOGIN}
              className={user?.email === ADMIN_LOGIN ? classes.disabledTextField : ""}

              InputLabelProps={{
                shrink: true,
                style: { color: "#627E84" }
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              id="role"
              label="Role"
              name="role"
              value={user?.role.name}
              disabled
              className={classes.disabledTextField}
              variant="outlined"
              required
              fullWidth

              InputLabelProps={{
                shrink: true,
                style: { color: "#627E84" }
              }}
            />
          </Grid>

          {user?.role?.name !== ROLES.ADMINISTRATOR &&
            <Grid item>
              <TextField
                id="venuePermissions"
                label="Venue permissions"
                name="venuePermissions"
                value={user?.venue.map(e => e.name).join(", ")}
                disabled
                className={classes.disabledTextField}
                variant="outlined"
                required
                fullWidth

                InputLabelProps={{
                  shrink: true,
                  style: { color: "#627E84" }
                }}
              />
            </Grid>
          }

          <Grid item>
            <Button
              variant="contained"
              disabled={user?.email === ADMIN_LOGIN || updating}
              type="submit"
              style={{ marginTop: "20px" }}
            >
              {updating ? "Saving..." : "Save"}
            </Button>
          </Grid>

        </Grid>
      </form>
    </LoadPleaseWait>
  );
}

export default UserInfo;