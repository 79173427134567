import React from "react";
import {
  TextField
} from  "@material-ui/core";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { alpha, IconButton, InputAdornment } from '@material-ui/core';
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear";

const useStyles = makeStyles((theme) => ({
  okButton: {
    color: theme.palette.color.success.main,
    width: "20px",
    height: "100%",
    marginRight: "0px"
  },
  cancelButton: {
    color: theme.palette.color.danger.main,
    width: "20px",
    height: "100%"
  },
  editing: {
    //borderWidth: 1,
    //borderColor: props => props.textColor ? alpha(props.textColor, 0.6) : theme.palette.color.primary.main      
  },
  root: {
    "& .MuiOutlinedInput-root input": {
      height: props => props.height ?? "25px",
      paddingTop: "3px",
      paddingBottom: "3px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
    },
    '&:hover .MuiOutlinedInput-root:not(.Mui-focused) .MuiOutlinedInput-notchedOutline': {
      borderColor: props => props.textColor ? alpha(props.textColor, 0.4) : theme.palette.color.secondary.main,
      borderStyle: 'dotted'
    },
    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
      borderColor: props => props.textColor ? alpha(props.textColor, 0.6) : theme.palette.color.primary.main,
      borderWidth: 1
    },
  },
  rootEditing: {
    "& .MuiOutlinedInput-root input": {
      height: props => props.height ?? "25px",
      paddingTop: "3px",
      paddingBottom: "3px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: props => props.textColor ? alpha(props.textColor, 0.4) : theme.palette.color.secondary.main,
    },
    "& .MuiOutlinedInput-root.Mui-focused  .MuiOutlinedInput-notchedOutline": {
      borderColor: props => props.textColor ? alpha(props.textColor, 0.6) : theme.palette.color.primary.main,
      borderWidth: 1
    },

  }
}));

function isInteger(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
     (str >>> 0 === parseFloat(str));  // checks for non-negative integer.
}

// This method has l10n issue. It only works with full stop as the decimal point.
function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const ExplicitGhostTextField = (props) => {
  const {
    value, // value is a number
    intOnly = false, 
    maximumFractionDigits = 4,
    onChange, 
    textAlign = 'center', 
    width = "auto", 
    height,
    marginLeft, 
    marginRight, 
    marginTop,
    marginBottom,
    paddingRight, 
    fontSize, 
    textColor,
     ...other} = props;

  const classes = useStyles({textColor: textColor, height: height});

  const numToViewString = (num) => {
    return num.toLocaleString("en-US",{maximumFractionDigits : maximumFractionDigits, useGrouping: "auto"});
  };
  
  const numToEditString = (num) => {
    return num.toLocaleString("en-US",{maximumFractionDigits : maximumFractionDigits, useGrouping: false});
  };

  //const [isEditing, setIsEditing] = useState(false);
  //const [internalValue, setInternalValue] = useState(numToViewString(value));
  const [isEditing, setIsEditing] = useState(false);
  const [cachedValue, setCachedValue] = useState(null); // null if the user has not started editing yet.

  useEffect(() => {
    //setInternalValue(numToViewString(value));
    setCachedValue(null);
  }, [value]);

  const handleSave = () => {
    // The cachedValue is not null if we get here.

     // If the entered value is not a number, forget it and show the original value.
    if ((intOnly && isInteger(cachedValue)) || (!intOnly && isNumeric(cachedValue))) {
      if (value !== Number(cachedValue)) {
        if (onChange) {
          onChange(Number(cachedValue));
        }
      } else {
        setCachedValue(null);
      }
    } else {
      setCachedValue(null);
    }
    setIsEditing(false);
  };

  let valueToShow;
  if (cachedValue === null) {
    if (isEditing) {
      valueToShow = numToEditString(value);
    } else {
      valueToShow = numToViewString(value);
    }
  } else {
    valueToShow = cachedValue;
  }

  return (
    <TextField
      {...other} 
      autoComplete="off"
      margin="dense"
      size="small"
      type="text"
      variant="outlined"
      value={valueToShow}
      style={{
        width: width,
        flexGrow: 0,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom
      }}
      inputProps={{style: {
        textAlign: textAlign,
        paddingRight: paddingRight,
        fontSize: fontSize,
        color: textColor
      }}}
      onChange={(e) => {
        if (intOnly) {
          const regex = /^[0-9\b]+$/;
          if (e.target.value === "" || regex.test(e.target.value)) {
            setCachedValue(e.target.value);
          }
        } else {
          setCachedValue(e.target.value);
        }
      }}
      onFocus={() => {
        setIsEditing(true);
      }}
      onBlur={() => {
        if (cachedValue === null) {
          setIsEditing(false);
        }
      }}
      classes={cachedValue === null ? 
        {
          root: classes.root
        } 
        :
        {
          root: classes.rootEditing
        }
      }
      // className={isEditing? classes.editing : ""}
      InputProps={cachedValue !== null ? {
        endAdornment: (
          <InputAdornment
            position="end">
            <IconButton 
              edge="end" 
              className={classes.okButton}
              onClickCapture={(e) => {
                handleSave();
              }}
              onMouseDown={e => e.stopPropagation()}
            >
              <DoneIcon />
            </IconButton>
            <IconButton 
              edge="end" 
              className={classes.cancelButton}
              onClickCapture={(e) => {
                setCachedValue(null);
                setIsEditing(false);
              }}
              onMouseDown={e => e.stopPropagation()}
            >
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )}
          :null
        } 
    />
  );
};

export default ExplicitGhostTextField;