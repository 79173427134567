import React, { useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import LoadPleaseWait from '../../notification/LoadingPleaseWait/LoadingMessage';
import useWebSocket, { ReadyState } from 'react-use-websocket';

export const SocketImage = (props) => {
    const { width, height, url } = props;

    const [socketUrl, setSocketUrl] = useState(url);
    const [image, setImage] = useState(null);
    const [error, setError] = useState(null);

    const { lastJsonMessage, readyState } = useWebSocket(socketUrl, {
        retryOnError: true,
        shouldReconnect: closeEvent => true,
        reconnectAttempts: 100,
        reconnectInterval: 2000,
        onError: error => {
            console.error('SocketImage', error);
            setError(error);
        }
    });

    const connectionStatus = {
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    }[readyState];

    console.debug('SocketImage', { connectionStatus });

    useEffect(() => {
        if (lastJsonMessage !== null) {
            setImage(lastJsonMessage.message);
        }
    }, [lastJsonMessage, setImage]);

    useEffect(() => {

        // Refresh Socket each 3 minutes
        const timer = setInterval(() => {
            console.debug('SocketImage', 'Reconnecting...');
            setSocketUrl(null);
            setSocketUrl(url);
        }, 60000 * 3);

        return () => clearInterval(timer);

    }, [url]);

    return (
        <LoadPleaseWait show={!image} text={error && 'Error occurred, reconnecting...'} >
            <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width, height}} alt='' src={`data:image/png;base64,${image}`} />
            </Box>
        </LoadPleaseWait>
    );
}
