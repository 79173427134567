import React, {useEffect, useState} from "react";
import * as alertRuleSetApi from "../../_services/dashboard.alertRuleSet.service";
import {
  IconButton, 
  Modal,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Button, ButtonType} from "../_common/htmlTags";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { NIL as NIL_UUID} from "uuid";
import {toast} from "react-toastify";
import AlertRuleSetEditor from "../_common/alertRules/AlertRuleSetEditor";

const useStyles = makeStyles((theme) => {
  return {
    deleteIcon: { 
      color: theme.palette.color.primary.red
    },

    configurationPopup: {
      width: "1020px",
      backgroundColor: "#FFFFFF",
      position: "absolute",
      top: "50%",
      left: "50%",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(3),
      ...theme.scrollbar
    },
    configPopupTitle: {
      fontSize: "24px",
      fontWeight: "500",
    },
    closeIcon: {
      marginTop:"-20px",
      marginRight: "-20px"
    }
  };
});

export const AlertRuleSetPopup = ({showConfigurationModal, setShowConfigurationModal, ruleSet, ruleSetsList}) =>
{
  const classes = useStyles();
  const ruleSetId = ruleSet?.id ?? NIL_UUID;
  const venueId = ruleSet?.venueId ?? NIL_UUID;
  const [currentRuleSet, setCurrentRuleSet] = useState(null);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    setCurrentRuleSet(ruleSet);
  }, [ruleSet]);
  
  const handleSave = (e) => {
    e.preventDefault();
    setSaving(false);
    
    /////////////////////////////////////////////////////////////
    // VALIDATION 
    /////////////////////////////////////////////////////////////
    
    /// validate venueId
    if (venueId === NIL_UUID) {
      toast.error("Venue's id must not be null", {autoClose: false});
      return;
    }
    
    /// validate name must not be empty
    if ((currentRuleSet?.name?.trim() ?? "") === "") {
      toast.error("Name must not be blank.", {autoClose: false});
      return;
    }
    
    /// validate name must not exist in other alert rule set 
    let found = ruleSetsList?.filter(a =>
        a.id !== currentRuleSet.id
        && a.venueId === currentRuleSet.venueId
        && a.name.trim().toLowerCase() === currentRuleSet.name.trim().toLowerCase() );
    if (found?.length !== 0) {
      toast.error("Alert name already exist.", {autoClose: false});
      return;
    }
    
    // validate if there is no the alert Rules  
    if (currentRuleSet.rules.length === 0) {
      toast.error("Alert must have at least 1 rule.", {autoClose: false});
      return;
    }
    
    let i = 0;
    for(i = 0; i < currentRuleSet.rules.length; i++) {
      let a = currentRuleSet.rules[i];
      let fromValue = a.from ?? -Infinity;
      let toValue = a.to ?? Infinity;
      let percent = a.percent ?? -Infinity;
      // validate to value must be greater than or equals (>=) from value
      if (toValue < fromValue) {
        toast.error(`From value '${a.from ?? ""}' is greater than to value: '${a.to ?? ""}'.`, {autoClose: false});
        return;
      }
      
      /// validate the color rules number to make sure no duplication
      if (currentRuleSet.alertType === "range") {
        let totalFound = currentRuleSet.rules.filter(b => ((b.from ?? -Infinity) <= fromValue && fromValue <= (b.to ?? Infinity)) || (b.from ?? -Infinity) <= toValue && toValue <= (b.to ?? Infinity));
        if (totalFound.length > 1) {
          toast.error(`Range from '${a.from ?? "Min"}' to '${a.to ?? "Max"}' is overlapping with another range.`, {autoClose: false});
          return;
        }
      } else {
        let totalFound = currentRuleSet.rules.filter(b => ((b.percent ?? -Infinity) === percent));
        if (totalFound.length > 1) {
          toast.error(`Percent rule '${a.percent ?? "Min"}' is duplicated.`, {autoClose: false});
          return;
        }
      }
    };
    
    /////////////////////////////////////////////////////////////
    // END OF VALIDATION
    /////////////////////////////////////////////////////////////

    // Trim the name to remove extra space before and after the string
    currentRuleSet.name = currentRuleSet.name.trim();
    if (ruleSetId === NIL_UUID) {
      // add new
      alertRuleSetApi.addAlertRuleSet(currentRuleSet)
        .then(result => {
          setSaving(false);
          setShowConfigurationModal(false); //close modal dialog
          toast.success("Alert added successfully.");
        })
        .catch((error) => {
          setSaving(false);
          toast.error("Failed to save alert. " + error, {autoClose: false});
        });
    }
    else {
      // update existing
      alertRuleSetApi.updateAlertRuleSet(currentRuleSet)
        .then(result => {
          setSaving(false);
          toast.success("Alert updated successfully.");
          setShowConfigurationModal(false); //close modal dialog
        })
        .catch((error) => {
          setSaving(false);
          toast.error("Failed to save alert. " + error, {autoClose: false});
        });
    }
  };
  return (
    <Modal open={showConfigurationModal}>
      <div className={classes.configurationPopup}>
        <Tooltip title="Close" style={{float:"right"}}>
          <IconButton
            aria-label="view"
            className={classes.closeIcon}
            onClick={(e) => {
              setShowConfigurationModal(false);
            }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Typography className={classes.configPopupTitle}>
          Alert details
        </Typography>
        <br />
        <div>
        <AlertRuleSetEditor 
          alertRuleSet={currentRuleSet}
          onChange={value => {setCurrentRuleSet(value)} }
        >
        </AlertRuleSetEditor>
        </div><br />
        <div className={classes.buttons}>
          <Button
            variant="contained"
            type="submit"
            disabled={saving}
            buttonType={ButtonType.Primary}
            onClick={handleSave}
            style={{
              padding: "10px",
              marginRight: "10px",
              marginLeft: "8px"
            }}
          >
            {saving ? "Saving..." : "Save"}
          </Button>&nbsp;
          <Button
            className="btn primary"
            variant="outlined"
            type="button"
            buttonType={ButtonType.Secondary}
            onClick={() => {
              setShowConfigurationModal(false);
            }}
            style={{
              padding: "9px"
            }}
          >Cancel</Button>
        </div>
      </div>
    </Modal>
  );
  
}