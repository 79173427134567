export const LOAD_DASHBOARD_SUCCESS = 'LOAD_DASHBOARD_SUCCESS'
export const LOAD_DASHBOARD_DATA_STARTED = 'LOAD_DASHBOARD_DATA_STARTED'
export const LOAD_DASHBOARD_DATA_SUCCESS = 'LOAD_DASHBOARD_DATA_SUCCESS'
export const LOAD_DASHBOARD_DATA_FAILED = 'LOAD_DASHBOARD_DATA_FAILED'
export const LOAD_INDICATOR_SUCCESS = 'LOAD_INDICATOR_SUCCESS'
export const ADD_DASHBOARD_SUCCESS = 'ADD_DASHBOARD_SUCCESS'
export const UPDATE_DASHBOARD_SUCCESS = 'UPDATE_DASHBOARD_SUCCESS'
export const UPDATE_DASHBOARD_LAST_UPDATED_SUCCESS = 'UPDATE_DASHBOARD_LAST_UPDATED_SUCCESS'
export const UPDATE_DASHBOARD_LAST_REQUESTED_SUCCESS = 'UPDATE_DASHBOARD_LAST_REQUESTED_SUCCESS'
export const REMOVE_DASHBOARD_OPTIMISTIC = 'REMOVE_DASHBOARD_OPTIMISTIC'
export const REMOVE_DASHBOARD_RANGE_OPTIMISTIC = 'REMOVE_DASHBOARD_RANGE_OPTIMISTIC'
export const UPDATE_WIDGET_TRAFFIC_TYPE_SUCCESS = 'UPDATE_WIDGET_TRAFFIC_TYPE_SUCCESS'
export const ENSURE_DASHBOARD_SUCCESS = 'ENSURE_DASHBOARD_SUCCESS'
export const LOAD_DASHBOARD_DETAIL_SUCCESS = 'LOAD_DASHBOARD_DETAIL_SUCCESS'
export const PUBLISH_DASHBOARD_SUCCESS = 'PUBLISH_DASHBOARD_SUCCESS'
export const LOAD_OCCUPANCY_VIEW_MODES_SUCCESS = 'LOAD_OCCUPANCY_VIEW_MODES_SUCCESS'
export const LOAD_TRAFFIC_TYPES_SUCCESS = 'LOAD_TRAFFIC_TYPES_SUCCESS'
export const LOAD_COHORTS_SUCCESS = 'LOAD_COHORTS_SUCCESS'
export const LOAD_NUMBER_WIDGET_VALUE_TYPES_SUCCESS = 'LOAD_NUMBER_WIDGET_VALUE_TYPES_SUCCESS'
export const LOAD_NUMBER_WIDGET_TIME_PERIODS_SUCCESS = 'LOAD_NUMBER_WIDGET_TIME_PERIODS_SUCCESS'
export const LOAD_DEMOGRAPHIC_WIDGET_VALUE_TYPES_SUCCESS = 'LOAD_DEMOGRAPHIC_WIDGET_VALUE_TYPES_SUCCESS'
export const LOAD_DEMOGRAPHIC_WIDGET_TIME_PERIODS_SUCCESS = 'LOAD_DEMOGRAPHIC_WIDGET_TIME_PERIODS_SUCCESS'
export const LOAD_DEMOGRAPHIC_WIDGET_AGE_GROUP_SUCCESS = 'LOAD_DEMOGRAPHIC_WIDGET_AGE_GROUP_SUCCESS'
export const LOAD_DEMOGRAPHIC_WIDGET_GENDER_SUCCESS = 'LOAD_DEMOGRAPHIC_WIDGET_GENDER_SUCCESS'
export const LOAD_MAP_WIDGET_INDICATORS_SUCCESS = 'LOAD_MAP_WIDGET_INDICATORS_SUCCESS'
export const LOAD_QUEUE_MODEL_ALERT_METRICS_SUCCESS = 'LOAD_QUEUE_MODEL_ALERT_METRICS_SUCCESS'
export const LOAD_TRAFFIC_IN_OUT_ALERT_METRICS_SUCCESS = 'LOAD_TRAFFIC_IN_OUT_ALERT_METRICS_SUCCESS'
export const LOAD_CAMERAS_SUCCESS = 'LOAD_CAMERAS_SUCCESS'
export const UPDATE_HIDE_INACTIVE_DASHBOARDS = 'UPDATE_HIDE_INACTIVE_DASHBOARDS'
export const LOAD_TRAFFIC_IN_OUT_DISPLAY_MODES_SUCCESS = 'LOAD_TRAFFIC_IN_OUT_DISPLAY_MODES_SUCCESS';
export const LOAD_PATRON_DISTRIBUTION_METRICS_SUCCESS = 'LOAD_PATRON_DISTRIBUTION_METRICS_SUCCESS';
export const LOAD_PATRON_DISTRIBUTION_VALUE_TYPES_SUCCESS = 'LOAD_PATRON_DISTRIBUTION_VALUE_TYPES_SUCCESS';
export const UPDATE_AFTER_HOURS_WARNING_HEIGHT = 'UPDATE_AFTER_HOURS_WARNING_HEIGHT';


/// Following are hardcoded from the WidgetSetting static value in Dashboard API
export const WIDGET_SETTING_SHOW_DATA_SOURCE_ID = '3a8556a3-7ba5-4be4-a1d2-ec50dd0952a4'
export const WIDGET_SETTING_TIME_PERIOD_ID = '57161422-36f0-4d25-8639-60317b21e49a'
export const TIME_PERIOD_LIST = [
    { id: 1, value: 15, name: 'Last 15 minutes' },
    { id: 2, value: 30, name: 'Last 30 minutes' },
    { id: 3, value: 60, name: 'Last 1 hour' },
    { id: 4, value: 1440, name: 'Last 1 day' },
    { id: 5, value: 10080, name: 'Last 1 week' },
]
export const UPDATE_WIDGET_SUCCESS = 'UPDATE_WIDGET_SUCCESS'
export const DASHBOARD_CLIENT_TYPE_COLOR_BLIND = 'colorblind'
