import constants from "../_constants/map.constants";
import initialState from "../_reducers/initialState";

export function mapReducer(
  state = initialState.maps,
  action
) {
  switch (action.type) {
    case constants.LOAD_MAPS_STARTED:
      return {
        ...state,
        loading: { ...state.loading, mapsLoading: true }
      };
    case constants.LOAD_MAPS_SUCCESS:
      return {
        ...state,
        maps: [...action.maps],
        loading: { ...state.loading, mapsLoading: false }
      };
    case constants.LOAD_LAYOUT_STARTED:
      return {
        ...state,
        loading: {
          ...state.loading,
          layoutLoading: [
            ...state.loading.layoutLoading.filter( l => l !== action.mapId),
            action.mapId
          ]
        },
      };
    case constants.LOAD_LAYOUT_SUCCESS:
      return {
        ...state,
        layouts: [
          ...state.layouts.filter( m => m.id !== action.map.id ),
          action.map
        ],
        loading: {
          ...state.loading,
          layoutLoading: state.loading.layoutLoading.filter( l=> l !== action.map.id )
        }
      };
      case constants.LOAD_MAP_STARTED:
        return {
          ...state,
          selectedMap: null,
          loading: { ...state.loading, mapLoading: true }
        };
      case constants.LOAD_MAP_SUCCESS:
        return {
          ...state,
          selectedMap: action.map,
          loading: { ...state.loading, mapLoading: false }
        };
      case constants.ADD_MAP:
        return {
          ...state,
          loading: { 
            ...state.loading, 
            mapLoading: false 
          },
        };
      case constants.UPDATE_MAP:
        return {
          ...state,
          selectedMap: null,
          loading: { 
            ...state.loading, 
            mapLoading: false 
          },
        };
    case constants.DELETE_MAP:
      return {
        ...state,
        maps: [
          ...state.maps.filter( m => m.id !== action.mapId )
        ],
        layouts: [
          ...state.layouts.filter( l => l.mapId !== action.mapId )
        ]
      };
    default:
      return state;
  }
}

export default mapReducer;
