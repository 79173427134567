import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import { Box, Modal, Typography, IconButton } from '@material-ui/core';
import {Button, ButtonType} from "../../../_common/htmlTags";
import PermissionsGate from "../../../_common/permissions/PermissionsGate";
import DeleteIcon from "@material-ui/icons/Delete";
import {SCOPES} from "../../../../_constants/user.permissions.constants";

const useStyles = makeStyles((theme) => ({
  mainBox: {
    backgroundColor: "#FFFFFF",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: theme.spacing(3),
    borderRadius: "5px",
  },
  fieldName: {
    fontSize: "16px",
    color: theme.palette.color.secondary.main,
    fontWeight: 500
  },
  fieldValue: {
    fontSize: "16px",
    color: theme.palette.color.primary.dark,
  },
  deleteIcon: {
    color:  theme.palette.color.primary.red,
    marginLeft: theme.spacing(1)
  },
  title: {
    fontSize: "24px",
    fontWeight: "500",
    marginBottom: theme.spacing(2)
  },
}));

// The main event view, opens in a modal and contains all information
// about the event in question
export const ViewEvent = ({ event, onCancel, onEventEdit, onEventDelete }) => {
  const classes = useStyles();

  // Format date and time so that the seconds are not displayed.
  const formatDate = (date) => { return `${date.toLocaleDateString([], {})} ${date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}`; }
  const formatTime = (time) => { return new Date(`1970-01-01 ${time}`).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }); }

  const startDateStr = formatDate(new Date(event.startDate));
  const endDateStr = formatDate(new Date(event.endDate));
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "8px 16px 16px 16px",
        width: "800px"
      }}
      className={classes.mainBox}
    >
      <Typography className={classes.title}>{event.eventName}</Typography>
      <Box
      >
        <Typography
          display="inline"
          className={classes.fieldName}
        >
          Dates:&nbsp;
        </Typography>
        <Typography
          display="inline"
          className={classes.fieldValue}
        >
          {startDateStr} &ndash; {endDateStr}
        </Typography>
      </Box>
      <Box
        sx={{
          mt: "16px"
        }}
      >
        <Typography
          display="inline"
          className={classes.fieldName}
        >
          Business hours:&nbsp;
        </Typography>
        <Typography
          display="inline"
          className={classes.fieldValue}
        >
          {formatTime(event.businessHoursFrom)} &ndash; {formatTime(event.businessHoursTo)}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "baseline",
          mt: "16px"
        }}>
        <Typography
        className={classes.fieldName}
        >
          Description:&nbsp;
        </Typography>
        <Typography
          className={classes.fieldValue}
          style={{
            flexGrow: 1
          }}
        >
          {event.eventDescription}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mt: "32px"
        }}
      >
        <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_EDIT_EVENTS]}>
          <Button
            variant="contained"
            type="submit"
            buttonType={ButtonType.Primary}
            style={{
              padding: "10px 24px",
              margin: "0 8px 0px 0px"
            }}
            onClick={()=>{
              onEventEdit(event);
            }}
          >
            Change
          </Button>
        </PermissionsGate>
        <Button
          variant="outlined"
          type="button"
          buttonType={ButtonType.Secondary}
          onClick={() => {
            onCancel();
          }}
          style={{
            padding: "10px 24px",
            margin: "0 0 0 16px"
          }}
        >
          Cancel
        </Button>
        <PermissionsGate scopes={[SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_DELETE_EVENTS]}>
          <IconButton 
            edge="end" 
            aria-label="Remove"
            className={classes.deleteIcon}
            onClick={() => {
              onEventDelete(event);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </PermissionsGate>
      </Box>
    </Box>
  )
}

export default ViewEvent;