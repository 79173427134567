import * as taskConstants from "../_constants/schedule.tasks.constants";
import * as taskApi from "../_services/scheduledTasks.service";

export const loadTasksStarted = () => {
  return {
    type: taskConstants.LOAD_TASKS_STARTED,
  };
};
export const loadTasksSuccess = (tasks) => {
  return {
    type: taskConstants.LOAD_TASKS_SUCCESS,
    tasks: tasks,
  };
};

export const loadTaskByIdStarted = () => {
  return {
    type: taskConstants.LOAD_TASK_BY_ID_STARTED,
  };
};
export const loadTaskByIdSuccess = (task) => {
  return {
    type: taskConstants.LOAD_TASK_BY_ID_SUCCESS,
    task: task,
  };
};

export const loadIndicatorsSuccess = (indicators) => {
  return {
    type: taskConstants.LOAD_INDICATORS_SUCCESS,
    indicators: indicators,
  };
};

export const loadVendorsSuccess = (vendors) => {
  return {
    type: taskConstants.LOAD_VENDORS_SUCCESS,
    vendors: vendors,
  };
};

export const loadIndicatorsWithVendorListStarted = () => {
  return {
    type: taskConstants.LOAD_INDICATORS_WITH_VENDOR_STARTED,
  };
};
export const loadIndicatorsWithVendorListSuccess = (indicatorsWithVendorList) => {
  return {
    type: taskConstants.LOAD_INDICATORS_WITH_VENDOR_SUCCESS,
    indicatorsWithVendorList: indicatorsWithVendorList,
  };
};

export const loadIndicatorVendorMappingsStarted = () => {
  return {
    type: taskConstants.LOAD_INDICATOR_VENDOR_MAPPINGS_STARTED,
  };
};
export const loadIndicatorVendorMappingsSuccess = (indicatorVendorMappings) => {
  return {
    type: taskConstants.LOAD_INDICATOR_VENDOR_MAPPINGS_SUCCESS,
    indicatorVendorMappings: indicatorVendorMappings,
  };
};

export const loadIndicatorScheduleMappingsStarted = () => {
  return {
    type: taskConstants.LOAD_INDICATOR_SCHEDULE_MAPPINGS_STARTED,
  };
};
export const loadIndicatorScheduleMappingsSuccess = (indicatorScheduleMappings) => {
  return {
    type: taskConstants.LOAD_INDICATOR_SCHEDULE_MAPPINGS_SUCCESS,
    indicatorScheduleMappings: indicatorScheduleMappings,
  };
};

export const loadSchedulesSuccess = (schedules) => {
  return {
    type: taskConstants.LOAD_SCHEDULES_SUCCESS,
    schedules: schedules,
  };
};

export const addTaskSuccess = (task) => {
  return {
    type: taskConstants.ADD_TASK_SUCCESS,
    task: task,
  };
};

export const updateTaskSuccess = (task) => {
  return {
    type: taskConstants.UPDATE_TASK_SUCCESS,
    task: task,
  };
};

export const disableTaskOptimistic = (taskId) => {
  return {
    type: taskConstants.DISABLE_TASK_OPTIMISTIC,
    taskId: taskId,
  };
};

export const enableTaskOptimistic = (taskId) => {
  return {
    type: taskConstants.ENABLE_TASK_OPTIMISTIC,
    taskId: taskId,
  };
};

export const loadTasks = (venueId) => {
  return async (dispatch) => {
    dispatch(loadTasksStarted());
    await taskApi
      .getTasks(venueId)
      .then((tasks) => {
        dispatch(loadTasksSuccess(tasks));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadTaskById = (venueId, id) => {
  return async (dispatch) => {
    dispatch(loadTaskByIdStarted());
    await taskApi
      .getTaskById(venueId, id)
      .then((task) => {
        dispatch(loadTaskByIdSuccess(task));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const addTask = (task) => {
  return async (dispatch) => {
    await taskApi
      .addTask(task)
      .then(() => {
        dispatch(addTaskSuccess(task));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateTask = (task) => {
  return async (dispatch) => {
    await taskApi
      .updateTask(task)
      .then(() => {
        dispatch(updateTaskSuccess(task));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const disableTask = (taskId) => {
  return async (dispatch) => {
    dispatch(disableTaskOptimistic(taskId));
    return await taskApi
      .disableTask(taskId)
      .catch((error) => {
        throw error;
      });
  };
};

export const enableTask = (taskId) => {
  return async (dispatch) => {
    dispatch(enableTaskOptimistic(taskId));
    return await taskApi
      .enableTask(taskId)
      .catch((error) => {
        throw error;
      });
  };
};

export const loadIndicators = () => {
  return async (dispatch) => {
    await taskApi
      .getIndicators()
      .then((indicators) => {
        dispatch(loadIndicatorsSuccess(indicators));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadVendors = () => {
  return async (dispatch) => {
    await taskApi
      .getVendors()
      .then((vendors) => {
        dispatch(loadVendorsSuccess(vendors));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadIndicatorsWithVendorList = () => {
  return async (dispatch) => {
    dispatch(loadIndicatorsWithVendorListStarted());
    await taskApi
      .getIndicatorsWithVendorList()
      .then((indicators) => {
        dispatch(loadIndicatorsWithVendorListSuccess(indicators));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadIndicatorVendorMappings = () => {
  return async (dispatch) => {
    dispatch(loadIndicatorVendorMappingsStarted());
    await taskApi
      .getIndicatorVendorMappings()
      .then((mappings) => {
        dispatch(loadIndicatorVendorMappingsSuccess(mappings));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadIndicatorScheduleMappings = () => {
  return async (dispatch) => {
    dispatch(loadIndicatorScheduleMappingsStarted());
    await taskApi
      .getIndicatorScheduleMappings()
      .then((mappings) => {
        dispatch(loadIndicatorScheduleMappingsSuccess(mappings));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadSchedules = () => {
  return async (dispatch) => {
    await taskApi
      .getSchedules()
      .then((schedules) => {
        dispatch(loadSchedulesSuccess(schedules));
      })
      .catch((error) => {
        throw error;
      });
  };
};