import React, {useState, useEffect, useRef} from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import {Button, ButtonType} from "../_common/htmlTags";
import { toast } from "react-toastify";
import LoadPleaseWait from "../notification/LoadingPleaseWait/LoadingMessage";
import {useApiGet} from "../../_helpers/useApiGet";
import { getCalcMetricsSettings, recalculateMetrics, updateCalcMetricsSettings } from '../../_services';
import { DateTime } from 'luxon';

const useStyles = makeStyles((theme) => ({
  recalcButton: {
    width: "200px",
    marginLeft: "20px"
  },
  startingFromField: {
    width: "200px",
  },
}));

const getCalcMetricsSettingsCall = async (venueId) => {
  if (venueId) {
    const result = await getCalcMetricsSettings(venueId);
    return result.data;
  } else {
    return null;
  }
};

function getVenueTodayStartTimeISO(venue) {
  if (venue?.businessHoursStart) {
    const [hours, minutes] = venue.businessHoursStart.split(":");
    var now = DateTime.now();
    const dt = now.set({hour: hours, minute: minutes, second: 0, millisecond: 0});
    return (dt > now ? dt.minus({days: 1}) : dt).toISO({ includeOffset: false });
  } else {
    return DateTime.now()
      .set({hour: 0, minute: 0, second: 0, millisecond: 0})
      .toISO({ includeOffset: false });
  }
}

export const CalcMetricsSettings = ({venue}) => {
  const classes = useStyles();

  const [isSaving, setIsSaving] = useState(false);
  const [isRecalculating, setIsRecalculating] = useState(false);
  const [{data: initialSettings, isLoading}] = useApiGet(getCalcMetricsSettingsCall, venue.id, null);
  const [autoFactorCalcMetrics, setAutoFactorCalcMetrics] = useState(null);
  const [startingFrom, setStartingFrom] = useState(getVenueTodayStartTimeISO(venue));

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  useEffect(() => {
    setAutoFactorCalcMetrics(initialSettings?.autoFactorCalcMetrics ?? null);
  }, [initialSettings]);

  const changeAutoFactorCalcMetrics = async () => {
    if (isSaving) {
      return;
    }

    let settingsToSave = {
      venueId: venue.id,
      autoFactorCalcMetrics: !autoFactorCalcMetrics,
    }

    setIsSaving(true);
    await updateCalcMetricsSettings(settingsToSave)
      .then(() => {
        toast.success("Calculated metrics settings have been saved.");
        setAutoFactorCalcMetrics(!autoFactorCalcMetrics);
      })
      .catch(error => toast.error("Failed to save calculated metrics settings. " + error.message, {autoClose: false}))
      .finally(() => setIsSaving(false));
  };

  const startRecalculation = async () => {
    setIsRecalculating(true);
    await recalculateMetrics(venue.id, DateTime.fromISO(startingFrom, { zone: venue.ianaTimeZoneId }).toJSDate())
      .catch(error => toast.error("Failed to start metrics recalculations. " + error.message, {autoClose: false}))
      .finally(() => setIsRecalculating(false));
  }

  return (
    <div>
      {venue !== null ? (
        <LoadPleaseWait show={isLoading} >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              marginBottom: "16px"
            }}>
            <Typography variant="h5">Calculated metrics settings</Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "16px"
              }}>
              <FormControlLabel
                control={<Checkbox
                  checked={autoFactorCalcMetrics}
                  disabled={isSaving || isLoading}
                  onChange={changeAutoFactorCalcMetrics}
                  name="autoFactorCalcMetrics" />}
                label={
                  <div>
                    <Typography display="inline">
                        Automatically recalculate metrics on every historical factor change
                    </Typography>
                </div>
                }
              />
              <Box sx={{ display: "flex", marginTop: "25px" }}>
                <TextField
                  id="startingFrom"
                  label="Recalculate from"
                  type="datetime-local"
                  name="startingFrom"
                  value={startingFrom}
                  disabled={isLoading || isRecalculating}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  className={classes.startingFromField}
                  onChange={e => setStartingFrom(e.target.value)}
                />
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isLoading || isRecalculating}
                  buttonType={ButtonType.Primary}
                  onClick={startRecalculation}
                  className={classes.recalcButton}
                >
                  Recalculate
                </Button>
              </Box>
            </Box>
          </Box>
        </LoadPleaseWait>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </div>
  );
};

export default CalcMetricsSettings;