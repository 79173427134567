import React from 'react';
import { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from "react-router";
import {getLatestARMEvent, getARMReportToken} from '../../_services/report.service';
import LoadPleaseWait from "../../components/notification/LoadingPleaseWait/LoadingMessage";
import devconsole from "../../components/_common/devconsole";

export const ArmReport =() => {
  const { armViewId } = useParams();
  const [ARMReportToken, setARMReportToken] = useState(null);
  const [latestARMEventId, setLatestARMEventId] = useState(null);
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const armView = useSelector((state) => 
    state.venueReducer.reportViews.find(tv => tv.id === armViewId)
  );
  useEffect(() => {
    getARMReportToken(selectedVenue?.id).then((d) => {
      devconsole.log("data getARMReportToken", d.data);
      setARMReportToken(d.data.token);
    }).catch((error) => {
      console.error("Error getARMReportToken", error.message);
    });
    getLatestARMEvent(selectedVenue?.id).then((d) => {
      devconsole.log("data getLatestARMEvent", d.data);
      setLatestARMEventId(d.data.uuid);
    }).catch((error) => {
      console.error("Error getLatestARMEvent", error.message);
    });
  }, [selectedVenue]);
  
  if (armView && ARMReportToken && latestARMEventId) {
    let reportUrl = `${ armView.url }/event-analytics/#/${ latestARMEventId }?accesstoken=${ ARMReportToken}`;
    return (
      <iframe title={"ARM Report"}
        src={reportUrl}
        style={{
          "width": "100%",
          "backgroundColor": "white",
          "border": "0px",
          "height": "calc(100vh - 110px)"
        }}>

      </iframe>
    )
  }
  else {
    return (
      <>
        {selectedVenue ?
          <LoadPleaseWait show={true} />
          : null
        }
      </>
    )
  }
};

export default ArmReport;
