import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ModeForm from "./ModeForm";
import { toast } from "react-toastify";
import {  addMode } from '../../../_actions/mode.actions';
import {   getModeList, getSelectedVenue } from '../../../_reducers/mode.selector';
import { useHistory } from "react-router";


const AddMode = () => {
    
    const selectedVenue = useSelector(getSelectedVenue);
    const [mode, setMode] = useState({
        name: "",
        venueId: selectedVenue?.id,
        description: "",
        isDefault: false,
        isDeleted: false,
    });
    
    const history = useHistory();
    const dispatch = useDispatch();
    const data = useSelector(getModeList);

    function handleChange(event) {
        const { name, value } = event.target;
        setMode((prevMode) => ({
            ...prevMode,
            [name]: value,
        }));
    }
    
    
    function handleSave(event) {
      
        if (data?.find((e) => e.name === mode.name)) {
            toast.error("Venue mode already exists", { autoClose: false });
        } else {
            dispatch(addMode(mode))
                .then(() => {
                    toast.success("Venue mode added successfully.");
                    history.push("/venue");
                })
                .catch((error) => {
                    toast.error("Adding venue mode failed" + error, { autoClose: false });
                });
        }
    }

    return (
        <>          
            <ModeForm
                mode={mode}
                onSave={handleSave}
                onChange={handleChange}
                />          
        </>
    );
};

export default AddMode;
