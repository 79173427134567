import React from 'react';
import { useState, useEffect } from 'react';
import {
  SvgIcon,
  Box,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import { DateTime, Duration } from 'luxon';
import { updateAfterHoursWarningHeight } from '../../../_actions/dashboard.actions';
import { useDispatch, useSelector } from "react-redux";
import {useResizeObservedRef} from "../../../_helpers/useResizeObservedRef";

const useStyles = makeStyles((theme) => {
  return {
    box: {
      backgroundColor: theme.palette.color.secondary.main,
      minHeight: "auto",
      paddingTop: "18px",
      paddingBottom: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    icon: {
      //color: theme.palette.color.secondary.main,
      color: "white",
      height: "30px",
      width: "30px",
      marginRight: theme.spacing(2)
    },
    label: {
      color: "white",
      fontWeight: 500,
      fontSize: "18px",
      // [theme.breakpoints.down("sm")]: {
      //   display: "none"
      // },
    },
    popoverLikeTooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: "0px 0px 4px 0px #00000040",
      width: "250px",
    },
    popoverLikeTooltipArrow: {
      "&::before": {
        backgroundColor: theme.palette.common.white,
        boxShadow: "0px 0px 4px 0px #00000040",
      }
    },
  };
});

export const DashboardBusinessHoursWarning =({venue}) => {
  const classes = useStyles();
  const [status, setStatus] = useState({});

  const dispatch = useDispatch();
  
  useEffect(() => {
    if (!venue) return;

    const calculateStatus = async () => {
      const startDuration = Duration.fromISOTime( venue.businessHoursStart);
      let start = startDuration.hours * 60 + startDuration.minutes;
      const endDuration = Duration.fromISOTime( venue.businessHoursEnd);
      let end = endDuration.hours * 60 + endDuration.minutes;

      const nowInVenueTz = DateTime.fromObject({}, {zone: venue.ianaTimeZoneId });
      let now = nowInVenueTz.hour * 60 + nowInVenueTz.minute;

      const newStatus = {};
      if (start == end) {
        // The venue is always open.
        newStatus.businessPeriod = 'working';
      } else {
        if (end < start) {
          end = end + 1440;
        }

        if (now < start) {
          now = now + 1440;
        };

        const nextStart = start + 1440;

        if (start <= now && now < end) {
          // The venue is open.
          newStatus.businessPeriod = 'working';
        } else {
          // The venue is closed. Is dashboard reset pending?
          if (venue.dashboardResetSchedule === null) {
            newStatus.businessPeriod = 'resetPending';
          } else if (venue.dashboardResetSchedule === 0) {
            newStatus.businessPeriod = 'sleeping';
          } else {
            const reset = end + venue.dashboardResetSchedule;
            if (reset < nextStart) {
              if (now < reset) {
                newStatus.businessPeriod = 'resetPending';
                const resetTimeMinutes = reset % 1440;
                newStatus.resetTime = Duration.fromObject({hours: Math.floor(resetTimeMinutes / 60), minutes: resetTimeMinutes % 60}).toISOTime();
              } else {
                newStatus.businessPeriod = 'sleeping';
              }
            } else {
              newStatus.businessPeriod = 'resetPending';
            }
          }
        }
      }
      setStatus(newStatus);
    };

    const intervalId = setInterval(() => { calculateStatus(); }, 120000);
    calculateStatus();
    return () => clearInterval(intervalId);
  }, [venue]);

  useEffect(() => {
    if (!venue || !status || status.businessPeriod == 'working') {
      dispatch(updateAfterHoursWarningHeight(0)); 
    }
  }, [status, dispatch]);

  let businessHoursStartString, businessHoursEndString, resetTimeString, message;
  if (venue) {
    businessHoursStartString = new Date('2015-01-01T' + venue.businessHoursStart).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    businessHoursEndString = new Date('2015-01-01T' + venue.businessHoursEnd).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'});
    resetTimeString = status.resetTime ? new Date('2015-01-01T' + status.resetTime).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit'}) : " the start of the next business day";
    message = `Viewing dashboard outside of business hours ${businessHoursStartString} to ${businessHoursEndString}.${status.businessPeriod == 'resetPending' ? ` The widgets will be reset to 0s at ${resetTimeString}.` : ''}`;
  }

  const boxRef = useResizeObservedRef(({width, height}) => {
    // KF: 34px is coming from paddings: 18px + 2 * 8px
    dispatch(updateAfterHoursWarningHeight(height + 34));
  });
 
  return (<>
  {
     venue && status && (status.businessPeriod == 'resetPending' || status.businessPeriod == 'sleeping') ?
      <Box 
        className={classes.box}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
        }}
        ref={boxRef}
      >
        <Box 
          sx={{
            flexGrow: 1
          }}
        />
        <SvgIcon 
          className={classes.icon}
          style={{transform: "scale(0.8)"}}
        >
          <g>
            <path d="M0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76515 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 11.7348 23.8946 11.4804 23.7071 11.2929C23.5196 11.1054 23.2652 11 23 11C22.7348 11 22.4804 11.1054 22.2929 11.2929C22.1054 11.4804 22 11.7348 22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10929 21.422 6.32746 20.4696 4.92893 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.80629 12.0111 2.00433 10.0004 2.7612 8.17317C3.51808 6.3459 4.7998 4.78412 6.4443 3.6853C8.08879 2.58649 10.0222 2 12 2C12.2652 2 12.5196 1.89464 12.7071 1.70711C12.8946 1.51957 13 1.26522 13 1C13 0.734784 12.8946 0.48043 12.7071 0.292893C12.5196 0.105357 12.2652 0 12 0C8.81846 0.00344108 5.76821 1.26883 3.51852 3.51852C1.26883 5.76821 0.00344086 8.81846 0 12Z"/>
            <path d="M8 11H10.277C10.4513 10.7004 10.7004 10.4513 11 10.277V7C11 6.73478 11.1054 6.48043 11.2929 6.29289C11.4804 6.10536 11.7348 6 12 6C12.2652 6 12.5196 6.10536 12.7071 6.29289C12.8946 6.48043 13 6.73478 13 7V10.277C13.2652 10.4297 13.4917 10.6414 13.662 10.8958C13.8323 11.1501 13.9417 11.4402 13.9818 11.7436C14.0219 12.047 13.9917 12.3556 13.8934 12.6454C13.7951 12.9353 13.6314 13.1986 13.415 13.415C13.1986 13.6314 12.9353 13.7951 12.6454 13.8934C12.3556 13.9917 12.047 14.0219 11.7436 13.9818C11.4402 13.9417 11.1501 13.8323 10.8958 13.662C10.6414 13.4917 10.4297 13.2652 10.277 13H8C7.73478 13 7.48043 12.8946 7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929C7.48043 11.1054 7.73478 11 8 11Z" />
            <path d="M21.024 6.08C21.184 6.08 21.36 6.08533 21.552 6.096C21.7547 6.096 21.9467 6.112 22.128 6.144C22.32 6.16533 22.4907 6.208 22.64 6.272C22.8 6.336 22.912 6.42133 22.976 6.528C23.136 6.80533 23.184 7.056 23.12 7.28C23.0667 7.49333 22.9493 7.65867 22.768 7.776C22.576 7.91467 22.352 8.00533 22.096 8.048C21.84 8.10133 21.6053 8.12267 21.392 8.112C20.9547 8.09067 20.4907 8.08533 20 8.096C19.5093 8.11733 19.0453 8.13333 18.608 8.144C18.4587 8.144 18.2987 8.144 18.128 8.144C17.968 8.15467 17.8133 8.144 17.664 8.112C17.5147 8.09067 17.3707 8.04267 17.232 7.968C17.104 7.89333 16.992 7.776 16.896 7.616C16.768 7.41333 16.736 7.21067 16.8 7.008C16.8747 6.79467 16.96 6.59733 17.056 6.416C17.2053 6.14933 17.3813 5.888 17.584 5.632C17.7973 5.376 17.9787 5.15733 18.128 4.976L20.128 2.352L18.512 2.4C18.384 2.4 18.2453 2.4 18.096 2.4C17.9573 2.4 17.8133 2.38933 17.664 2.368C17.5147 2.336 17.376 2.29867 17.248 2.256C17.12 2.21333 17.008 2.14933 16.912 2.064C16.816 1.968 16.7467 1.86133 16.704 1.744C16.6613 1.616 16.64 1.488 16.64 1.36C16.6507 1.232 16.6773 1.10933 16.72 0.992C16.7733 0.864 16.8427 0.752 16.928 0.656C16.992 0.592 17.072 0.538666 17.168 0.496C17.2747 0.453333 17.392 0.421333 17.52 0.4C17.648 0.378666 17.776 0.368 17.904 0.368C18.0427 0.357333 18.1707 0.352 18.288 0.352C18.5227 0.352 18.784 0.352 19.072 0.352C19.36 0.352 19.6373 0.352 19.904 0.352C20.1813 0.341333 20.4373 0.335999 20.672 0.335999C20.9173 0.325333 21.1147 0.32 21.264 0.32C21.456 0.32 21.648 0.314666 21.84 0.303999C22.032 0.293333 22.2133 0.309333 22.384 0.352C22.5547 0.394666 22.6987 0.474666 22.816 0.592C22.944 0.698666 23.024 0.874667 23.056 1.12C23.0773 1.28 23.0667 1.44 23.024 1.6C22.992 1.74933 22.9333 1.89867 22.848 2.048C22.7733 2.18667 22.6827 2.33067 22.576 2.48C22.4693 2.61867 22.3627 2.752 22.256 2.88L19.776 6.08H21.024Z" />
          </g>
        </SvgIcon>
        <Typography
          className={classes.label}
        >
          {message}
        </Typography>
        <Box 
          sx={{
            flexGrow: 1
          }}
        />
      </Box>
     : null
  } </>);
};

export default DashboardBusinessHoursWarning;