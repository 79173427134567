import React from "react";
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";

import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import DOMPurify from "dompurify";

const notificationMessageTypesList = {
  NOTIFICATION_MESSAGE_TYPE_INFORMATION: "information",
  NOTIFICATION_MESSAGE_TYPE_ERROR: "error",
  NOTIFICATION_MESSAGE_TYPE_SUCCESS: "success",
  NOTIFICATION_MESSAGE_TYPE_WARNING: "warning",
  NOTIFICATION_MESSAGE_TYPE_PROCESSING: "processing"
}

const useStyles = makeStyles(({ spacing, palette }) => {
  return {
    card: {
      display: 'flex',
      maxHeight: "100px",
      padding: "10px 5px 10px 10px"
    },
    titleAndContentContainer: {
      display: "flex",
      margin: "-5px",
      paddingTop: "5px"
    },
    title: {
      color: '#4d4d4d',
      fontSize: 16,
      marginBottom: 0,
      marginLeft: "8px",
      fontWeight: 600,
      marginTop: "4px"
    },
    content: {
      fontSize: 14,
      color: palette.grey[700],
      letterSpacing: '1px',
      marginBottom: 4,
      paddingTop: "12px"
    },
  };
});

export const ToastNotification = (notificationMessage) => {
  const styles = useStyles();

  var message = notificationMessage.notificationMessage;
  var icon = <InfoIcon style={{ fill: "#6666ff" }} />;
  switch (message.type) {
    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_ERROR:
      icon = <ErrorIcon style={{ fill: "#c91d12" }} />;
      break;

    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_SUCCESS:
      icon = <CheckCircleIcon style={{ fill: "green" }} />;
      break;

    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_WARNING:
      icon = <WarningIcon style={{ fill: "#ffcc00" }} />;
      break;

    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_PROCESSING:
      icon = <img src={"processing.gif"} style={{ width: "28px", height: "28px" }} alt="Processing..." />
      break;

    default:
      break;
  }

  return (
    <Card className={cx(styles.card)} elevation={0}>
      <Box>
        <div className={styles.titleAndContentContainer}>
          {icon}
          <Typography className={styles.title} variant="h3">
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.title) }} />
          </Typography>
        </div>

        <p className={styles.content}>
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />
        </p>
      </Box>
    </Card>
  );
}

export default ToastNotification;