import React, { useRef, useState, useLayoutEffect, useContext, useEffect } from "react" 
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { makeStyles } from "@material-ui/core/styles";
import { Box, } from "@material-ui/core";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import { EmptyContainer } from "../EmptyContainer";
import CommonWidgetLayout from "../shared/CommonWidgetLayout";
import WidgetContext from "../WidgetContext";
import { getContrastTextColor } from "../../../_helpers/colorUtils";

const TRAFFIC_IN_OUT_DISPLAY_MODE_ID_IN_ONLY       = "TrafficIn_only";
const TRAFFIC_IN_OUT_DISPLAY_MODE_ID_OUT_ONLY      = "TrafficOut_only";
const TRAFFIC_IN_OUT_DISPLAY_MODE_ID_IN_OUT_BOTH   = "TrafficInOut_both";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.common,
  ...theme.widget.traffic,
  ...theme.widget.trafficLight,
  ...theme.widget.flashingWidget,
  container: { ...theme.widget.traffic.container },
  
}));

// define initial values
const BASE_FONT_SIZE = 10;
const BASE_WIDGET_SIZE = 100;

const CAPTION_COLOR = "rgba(98, 126, 132, 1)";
  
export const TrafficInOut = ({ width, height, data, settings, viewMode, brandingSettings, onAudioPlay }) => {

  const targetRef = useRef();
  const widgetContextData = useContext(WidgetContext);
  const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
  const [playSound, setPlaySound] = useState(false);  
  const classes = useStyles({ width, fontSize, ...brandingSettings });
  const backgroundColor = classes.defaultBackground;
  
  const noData = !settings?.RefLocation;
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  const isFlash = isViewMode && data?.alertRule?.color?.flash && !noData ? true : false;
    
  useEffect(() => {
    setPlaySound(isViewMode && data?.alertRule?.sound);
  }, [data?.alertRule?.sound, isViewMode, setPlaySound]);

  useEffect(() => {
    if (playSound) {
        onAudioPlay();
    }
  }, [playSound, onAudioPlay]);

  const bgAlertColor = noData || !isViewMode ? null : (data?.alertRule?.color?.value ?? null);
  const textColor = bgAlertColor == null ? null : getContrastTextColor(bgAlertColor);

  // Handling caption color depending on widget's background color.
  widgetContextData.setCaptionColor(isFlash || bgAlertColor === null ? CAPTION_COLOR : (textColor ?? CAPTION_COLOR));

  /**
   * Traffic In Out Display Mode (Metric) logic handling:
   * to show only selected traffic meetric: In, Out or Both
   * 
   * (In UI the term "Metric" is used, 
   * whereas in code terminology "DisplayMode" is used to prevent intersection 
   * with already existing entity "TrafficInOutMetric")
   * 
   * In the view mode, if there is no data (maybe because the configured location is not mapped to the data source or 
   * for other reason), the 'N/A' is displayed instead of the empty widget.
   **/
  
  const trafficInOutDisplayMode = settings?.RefTrafficInOutDisplayMode?.id;

  let viewNA = isViewMode && (!data?.traffic ||
    !data?.vendor && !settings?.WidgetSettingIncludeDescendants);
  if (viewNA && (data?.traffic?.trafficInValue || data?.traffic?.trafficOutValue)) {
    viewNA = false;
  }

  const trafficInOutDisplayModeMapping = {
    [TRAFFIC_IN_OUT_DISPLAY_MODE_ID_IN_ONLY]: {
      title: "in",
      value: viewNA ? 'N/A' : (isViewMode && data?.traffic?.trafficInValue || 0).toLocaleString("en-US"),
    },

    [TRAFFIC_IN_OUT_DISPLAY_MODE_ID_OUT_ONLY]: {
      title: "out",
      value: viewNA ? 'N/A' : (isViewMode && data?.traffic?.trafficOutValue || 0).toLocaleString("en-US"),
    },

    [TRAFFIC_IN_OUT_DISPLAY_MODE_ID_IN_OUT_BOTH]: {
      title: "in/out",
      value: viewNA ? 'N/A' : 
        ((isViewMode && data?.traffic?.trafficInValue || 0).toLocaleString("en-US")
        + ' / ' + 
        (isViewMode && data?.traffic?.trafficOutValue || 0).toLocaleString("en-US")),
    }
  }
 
  const applyScaleFactor = (width, height) => {
    const newWidgetSize = width > height ? height : width;
    const scaleFactor = newWidgetSize / BASE_WIDGET_SIZE;

    setFontSize(BASE_FONT_SIZE * scaleFactor);
    
  };
  
  const ref = useResizeObservedRef(({ width, height }) => {
    applyScaleFactor(width, height);
  });

  // use this hook to set initial font sizes by applying scale factor based on current widget size. this is used when widget renders for the first time on viewing dashboard
  useLayoutEffect(() => {
    if (targetRef.current) {
      applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
    }
  }, []);

  const name = isViewMode ? (data?.location?.name ?? settings?.RefLocation?.name) : settings?.RefLocation?.name;
  const timePeriod = isViewMode ? (data?.traffic?.timePeriodName ?? settings?.WidgetSettingTimePeriod?.name) : settings?.WidgetSettingTimePeriod?.name;
  
  return (
    <>
      <Box
        className={classes.container +
          ` ${bgAlertColor === null ? backgroundColor : ''} ` +
          ` ${isFlash ? classes.flashEffect : ''} `
        }
        style={bgAlertColor !== null ? {backgroundColor: bgAlertColor} : null}
        height={height}
        ref={ref} >
        
        {!noData ? (
          <Box display="flex" height={height} ref={targetRef}>
            <Box style={{ height: '100%' }}
              display="flex"
              flexDirection="column"
              className={`${classes.trafficInOutContainerLeftWithBorder} `}
            >
              <CommonWidgetLayout 
                value={trafficInOutDisplayModeMapping[trafficInOutDisplayMode]?.value} 
                name={name} 
                timePeriod={timePeriod} 
                contrastFontColor={textColor}
              />
            </Box>                        
          </Box>
        ) : (
          <Box className={classes.container} display="flex">
            <EmptyContainer />
          </Box>
        )}
      </Box>
    </>
  );

}

export default TrafficInOut;
