export const venueConstants = {
    ADD_VENUE: 'ADD_VENUE',
    UPDATE_VENUE: 'UPDATE_VENUE',
    GET_VENUE: 'GET_VENUE',
    SELECT_VENUE: 'SELECT_VENUE',
    SELECT_SETTINGS_TAB: 'SELECT_SETTINGS_TAB',
    GET_VENUES: 'GET_VENUES',
    DELETE_VENUE: 'DELETE_VENUE',
    GET_VENUE_STATUSES: 'GET_VENUE_STATUSES',
    GET_TIMEZONES: 'GET_TIMEZONES',
    ERROR_HANDLE: 'ERROR_HANDLE',
    ERROR_RESET: 'ERROR_RESET',
    RESET_ALL_VENUES: 'RESET_ALL_VENUES',
    GET_TIMEZONES_STARTED: 'GET_TIMEZONES_STARTED',
    GET_VENUE_STARTED: 'GET_VENUE_STARTED',
    GET_VENUES_STARTED: 'GET_VENUES_STARTED',
    UPDATE_VENUE_STATUS: 'UPDATE_VENUE_STATUS',
    UPDATE_HIDE_INACTIVE_VENUES: 'UPDATE_HIDE_INACTIVE_VENUES',
    GET_VENUE_BRANDING_SETTINGS_STARTED: 'GET_VENUE_BRANDING_SETTINGS_STARTED',
    GET_VENUE_BRANDING_SETTINGS_SUCCESS: 'GET_VENUE_BRANDING_SETTINGS_SUCCESS',
    GET_VENUE_BRANDING_MAIN_LOGO_SUCCESS: 'GET_VENUE_BRANDING_MAIN_LOGO_SUCCESS',
    GET_VENUE_BRANDING_ALTERNATIVE_LOGO_SUCCESS: 'GET_VENUE_BRANDING_ALTERNATIVE_LOGO_SUCCESS',
    GET_VENUE_BRANDING_FOOTER_LOGO_SUCCESS: 'GET_VENUE_BRANDING_FOOTER_LOGO_SUCCESS',
    UPDATE_VENUE_BRANDING_SETTINGS_SUCCESS: 'UPDATE_VENUE_BRANDING_SETTINGS_SUCCESS',
    GET_REPORT_VIEWS_SUCCESS: 'GET_REPORT_VIEWS_SUCCESS',
    GET_USER_GROUPS_SUCCESS: 'GET_USER_GROUPS_SUCCESS',
}
