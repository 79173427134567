import { factorConstants } from "../_constants/factor.constants";


const factorTabSelected = (selectedTab) => {
  return {
    type: factorConstants.SELECT_FACTORS_TAB,
    selectedTab: selectedTab,
  };
};

export const selectFactorTab = (selectedTab) => {
  return (dispatch) => {
    dispatch(factorTabSelected(selectedTab));
  };
};

export const filterHistoryByLocation = (locationId) => {
  return (dispatch) => {
    dispatch({
      type: factorConstants.SELECT_FACTOR_HISTORY_FILTER,
      filter: {
        locationId: locationId
      }
    });
  };
};

export const filterHistoryByFactorType = (factorTypeId) => {
  return (dispatch) => {
    dispatch({
      type: factorConstants.SELECT_FACTOR_HISTORY_FILTER,
      filter: {
        factorTypeId: factorTypeId
      }
    });
  };
};

export const clearHistoryFilter = () => {
  return (dispatch) => {
    dispatch({
      type: factorConstants.CLEAR_FACTOR_HISTORY_FILTER,
    });
  };
};
