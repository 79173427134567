import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {Col, Container, Row} from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import {Button, ButtonType} from "../../_common/htmlTags";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  marginLeft: {
    marginLeft: theme.spacing(1),
  },
  selectControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  buttons: {
    marginLeft: theme.spacing(1),
  },
}));

export const VenueEventForm = ({venueEvent, onChange, onSave, saving}) => {
  const classes = useStyles();
  const history = useHistory();
  const handleSave = (e) => {
    e.preventDefault();
    onSave(e);
  }

  return (
    <form autoComplete="off" onSubmit={handleSave}>
      <div>
        <Container fluid>
          <Row>
            <Col md={12} lg={6}>
              <div className={classes.formInput}>
                <TextField
                  id="eventName"
                  label="Name"
                  name="eventName"
                  value={venueEvent?.eventName}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={6}>
              <div className={classes.formInput}>
                <TextField
                  id="eventDescription"
                  label="Description"
                  name="eventDescription"
                  value={venueEvent?.eventDescription}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className={classes.formInput}>
                <TextField
                  id="startDate"
                  label="Start Date"
                  name="startDate"
                  type="datetime-local"
                  value={venueEvent?.startDate}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className={classes.formInput}>
                <TextField
                  id="endDate"
                  label="End Date"
                  name="endDate"
                  type="datetime-local"
                  value={venueEvent?.endDate}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <div className={classes.formInput}>
                <TextField
                  id="businessHoursFrom"
                  label="Business hours start"
                  name="businessHoursFrom"
                  type="time"
                  value={venueEvent?.businessHoursFrom}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
            <Col md={6} lg={3}>
              <div className={classes.formInput}>
                <TextField
                  id="businessHoursTo"
                  label="Business hours end"
                  name="businessHoursTo"
                  type="time"
                  value={venueEvent?.businessHoursTo}
                  onChange={onChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div >
                <Button
                  type="submit"
                  variant="contained"
                >
                  {saving ? "Saving..." : "Save"}
                </Button>
                <Button
                  variant="outlined"
                  buttonType={ButtonType.Secondary}
                  className={classes.margin}
                  onClick={() => {
                    history.push("/events");
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </form>
  )
}
export default VenueEventForm;