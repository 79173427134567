import {createSelector} from 'reselect';

// Returns the list of location types sorted by name.
export const selectLocationTypes = createSelector(
  (state) => state.venueLocationReducer.locationTypes,
  (locationTypes) => locationTypes.sort((a,b) => a.name.localeCompare(b.name))
);

// Returns the list of vendors (UI:data sources) sorted by name.
export const selectVendors = createSelector(
  (state) => state.scheduleTasks.vendors,
  (vendors) => vendors.sort((a,b) => a.name.localeCompare(b.name))
);