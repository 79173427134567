export const mapConstants = {
  LOAD_MAPS_STARTED: 'LOAD_MAPS_STARTED',
  LOAD_MAPS_SUCCESS: 'LOAD_MAPS_SUCCESS',
  LOAD_LAYOUT_STARTED: 'LOAD_LAYOUT_STARTED',
  LOAD_LAYOUT_SUCCESS: 'LOAD_LAYOUT_SUCCESS',
  LOAD_MAP_STARTED: 'LOAD_MAP_STARTED',
  LOAD_MAP_SUCCESS: 'LOAD_MAP_SUCCESS',
  ADD_MAP: 'ADD_MAP',
  GET_MAP: 'GET_MAP',
  UPDATE_MAP: "UPDATE_MAP",
  DELETE_MAP: 'DELETE_MAP'
};

export default mapConstants;