import {widgetTypeConstants } from "../../../_constants";
import { WidgetContextProvider } from "../WidgetContext";
import { WidgetWrapper } from "../WidgetWrapper";
import { TrafficInOut } from "./TrafficInOut";

export const WidgetTrafficInOut = (props) => (
        <WidgetContextProvider>
            <WidgetWrapper
                {...{...props, widgetType: widgetTypeConstants.widgetTrafficInOut.id}}
            >
                <TrafficInOut />
            </WidgetWrapper>
        </WidgetContextProvider>
    );

export default WidgetTrafficInOut;
