import {React, useState, useEffect, useRef} from "react";
import { getLogStat, getLogs } from "../../_services/indicators.ArmMonitor";
import {makeStyles} from "@material-ui/core/styles";
import { Box, IconButton, Typography } from "@material-ui/core";
import {DataGrid} from "@material-ui/data-grid";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import { useSelector } from "react-redux";
import useLocalStorage from "../../_helpers/useLocalStorage";
import useSortModel from "../../_helpers/useSortModel";
import { JsonViewer } from "./JsonViewer";

const useStyles = makeStyles((theme) => {
  return {
    statText: {
      fontWeight: "700",
      fontStyle: "italic",
      marginTop: theme.spacing(1)
    },
    dataGrid: {
      marginTop: theme.spacing(3),
      width: "1210px",
      minWidth: 0,
    },
    error: {
      color: theme.palette.color.primary.red
    },
    json: {
      textOverflow: "ellipsis",
      flexGrow: 1,
      overflowX: "hidden"
    },
    viewJsonIconButton: {
      padding: "8px"
    }
  };
});

const ArmMonitor = () => {
  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const classes = useStyles();
  const [logStat, setLogStat] = useState(null);
  const [logRecords, setLogRecords] = useState([]);
  const [pageSize, setPageSize] = useLocalStorage("pmy-armMonitor-pageSize", 20);
  const [sortModel, setSortModel] = useSortModel("pmy-armMonitor-sortModel", [{field: 'timeStamp', sort: 'desc'}]);
  const [showJson, setShowJson] = useState(false);
  const [showJsonTitle, setShowJsonTitle] = useState(null);
  const [showJsonData, setShowJsonData] = useState(null);
  
  const isMounted = useRef(false);

  const columns = [
    {
      field: "id",
      hide: true,
      valueFormatter: (params) => params.row?.id,
    },
    {
      field: "isError",
      width: 150,
      headerName: "ERROR",
      renderCell: (params) => {
        return (
          <>
            {params.row.isError ? <ErrorOutlineIcon  className={classes.error} fontSize={"small"} /> : null }
          </>
        );
      }
    },   
    {
      field: "logType",
      width: 240,
      headerName: "LOG TYPE",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minWidth: 0
            }}
          >
            <Typography>
              {params.row.logType}
            </Typography>
          </Box>
        );
      }
    },

    {
      field: "parameters",
      width: 300,
      headerName: "PARAMETERS",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minWidth: 0
            }}
          >
            <Typography
              className={classes.json}
            >
              {params.row.parameters}
            </Typography>
            <IconButton
              aria-label="view"
              className={classes.viewJsonIconButton}
              onClick={(e) => {
                setShowJson(true);
                setShowJsonTitle("Parameters");
                setShowJsonData(params.row.parameters);
              }}
            >
              <PageviewOutlinedIcon />
            </IconButton>
          </Box>
        );
      }
    },    
    {
      field: "message",
      width: 300,
      headerName: "MESSAGE",
      renderCell: (params) => {
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              minWidth: 0
            }}
          >
            <Typography
              className={classes.json}
            >
              {params.row.message}
            </Typography>
            <IconButton
              aria-label="view"
              className={classes.viewJsonIconButton}
              onClick={(e) => {
                setShowJson(true);
                setShowJsonTitle("Message");
                setShowJsonData(params.row.message);
              }}
            >
              <PageviewOutlinedIcon />
            </IconButton>
          </Box>
        );
      }
    },    
    {
      field: "timeStamp",
      width: 220,
      headerName: "DATE",
      renderCell: (params) => {
        return (
          (new Date(params.row.timeStamp + 'Z')).toLocaleString()
        );
      }
    }
  ];

  const sortModelValid = columns.find(it => it.field == sortModel.field );

  useEffect(() => {
    isMounted.current = true;

    if ( !columns.find(it => it.field == sortModel.field )) {
      setSortModel([{field: 'timestamp', sort: 'desc'}]);
    }

    const loadStat = () => {
      if (!selectedVenue?.id) return;

      const currentDate = new Date();
      const currentDateInMilliseconds = currentDate.getTime();
      const oneHourAgo = new Date(currentDateInMilliseconds - 60 * 60 * 1000);  
      const oneDayAgo = new Date(currentDateInMilliseconds - 24 * 60 * 60 * 1000);

      getLogStat(selectedVenue.id, oneHourAgo.toISOString())
        .then((response) => {
          if (!isMounted.current) return;
          setLogStat(response.data);
        }).catch(error => {
        });
      getLogs(selectedVenue.id, oneDayAgo.toISOString(), currentDate.toISOString())
        .then((response) => {
          if (!isMounted.current) return;
          setLogRecords(response.data);
        });
    };

    loadStat();

    const intervalId = setInterval(() => {
      loadStat();
    }, 60* 1000);
    return () => {
      isMounted.current = false;
      clearInterval(intervalId);
    };
  }, [ selectedVenue?.id ]);

  let percentageOfSuccess = "";
  if (logStat && logStat.successRequestsCount + logStat.errorRequestsCount > 0) {
    percentageOfSuccess = `(${Math.round(logStat.successRequestsCount / (logStat.successRequestsCount + logStat.errorRequestsCount) * 100)}% successful)`;
  }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          minWidth: 0
      }}>
        <Typography className={classes.statText}>
          Last successful call: {logStat?.lastRequestDateUtc ? new Date(logStat?.lastRequestDateUtc).toLocaleString() : "N/A"}
        </Typography>
        <Typography className={classes.statText}>
          Last hour success/failure:  {logStat ? `${logStat.successRequestsCount}/${logStat.errorRequestsCount} ${percentageOfSuccess}` : "N/A"}
        </Typography>
        <DataGrid
          disableSelectionOnClick={true}
          hideFooterRowCount={true}
          hideFooterSelectedRowCount={true}
          autoHeight
          rowHeight={40}
          pageSize={pageSize}
          onPageSizeChange={(val) => setPageSize(val)}
          rowsPerPageOptions={[10,20,50,75,100]}
          sortModel={sortModelValid? sortModel : [{field: 'timeStamp', sort: 'desc'}]}
          onSortModelChange={(model) => setSortModel(model)}
          sortingOrder={['desc','asc']}
          columns={columns}
          rows={logRecords}
          className={classes.dataGrid}
        />
      </Box>
      {showJson ?
        <JsonViewer 
          title={showJsonTitle}
          data={showJsonData}
          onClose={() => {
            setShowJson(false);
            setShowJsonTitle(null);
            setShowJsonData(null)
          }}
        /> : null
      }
    </>
  );
};

export default ArmMonitor;