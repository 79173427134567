import React, { createContext, useState } from "react";
export const SettingsDependencyContext = createContext();

export default SettingsDependencyContext;

export const SettingsDependencyProvider = ({ children }) => {

    const [selectedMap, setSelectedMap] = useState();
    const [selectedDemographicValueType, setSelectedDemographicValueType] = useState();
    const [selectedMapIndicator, setSelectedMapIndicator] = useState();
    const [selectedNumberValueType, setSelectedNumberValueType] = useState();
    const [selectedPatronDistributionMetric, setSelectedPatronDistributionMetric] = useState();
    const [submitValidation, setSubmitValidation] = useState();

    const contextData = {
        // Dependency from RefMap:
        selectedMap,
        setSelectedMap,

        // Dependency from RefMapIndicator
        selectedMapIndicator,
        setSelectedMapIndicator,

        // Dependency from Demographic Value Types3
        selectedDemographicValueType,
        setSelectedDemographicValueType,

        // Dependency from RefNumberWidgetValueType
        selectedNumberValueType,
        setSelectedNumberValueType,

        selectedPatronDistributionMetric,
        setSelectedPatronDistributionMetric,

        submitValidation,
        setSubmitValidation,
    };

    return (
        <SettingsDependencyContext.Provider value={contextData}>
            {children}
        </SettingsDependencyContext.Provider>
    );
};