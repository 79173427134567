import React from "react";
import Box from "@material-ui/core/Box";
import Card from '@material-ui/core/Card';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";
import moment from 'moment'

import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import DOMPurify from "dompurify";

const notificationMessageTypesList = {
  NOTIFICATION_MESSAGE_TYPE_INFORMATION: "information",
  NOTIFICATION_MESSAGE_TYPE_ERROR: "error",
  NOTIFICATION_MESSAGE_TYPE_SUCCESS: "success",
  NOTIFICATION_MESSAGE_TYPE_WARNING: "warning",
  NOTIFICATION_MESSAGE_TYPE_PROCESSING: "processing"
}

const useStyles = makeStyles(({ spacing, palette }) => {
  return {
    card: {
      display: 'flex',
      minWidth: 288,
      borderRadius: 0,
      '& > *:nth-child(2)': {
        flex: 'auto',
      },
      borderBottom: "1px",
      borderBottomStyle: "solid",
      borderTop: "1px",
      borderTopStyle: "solid",
      borderColor: "rgba(204,204,204,.8)",
      borderBottomColor: "rgba(204,204,204,.8)",
      padding: "10px 5px 10px 10px"
    },
    titleAndContentContainer: {
      display: "flex",
      margin: "-5px",
      paddingTop: "5px"
    },
    title: {
      color: '#4d4d4d',
      fontSize: 16,
      marginBottom: 0,
      marginLeft: "8px",
      fontWeight: 600,
      marginTop: "4px"
    },
    content: {
      fontSize: 14,
      color: palette.grey[700],
      letterSpacing: '1px',
      marginBottom: 4,
      paddingTop: "12px"
    },
    timestampContainer: {
      padding: "23px 5px 3px 10px"
    },
    timestamp: {
      marginLeft: 8,
      fontSize: 14,
      color: palette.grey[500],
      right: 20,
      textAlign: "right",
      position: "absolute",
    },
  };
});

export const NotificationMessage = (notificationMessage) => {
  const styles = useStyles();

  var message = notificationMessage.notificationMessage;
  var icon = <InfoIcon style={{ fill: "#6666ff" }} />;
  switch (message.type) {
    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_ERROR:
      icon = <ErrorIcon style={{ fill: "#c91d12" }} />;
      break;

    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_SUCCESS:
      icon = <CheckCircleIcon style={{ fill: "green" }} />;
      break;

    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_WARNING:
      icon = <WarningIcon style={{ fill: "#ffcc00" }} />;
      break;

    case notificationMessageTypesList.NOTIFICATION_MESSAGE_TYPE_PROCESSING:
      icon = <img src={"processing.gif"} style={{ width: "28px", height: "28px" }} alt="Processing..." />
      break;

    default:
      break;
  }

  return (
    <Card className={cx(styles.card)} elevation={0}>
      <Box>
        <div className={styles.titleAndContentContainer}>
          {icon}
          <Typography className={styles.title} variant="h3">
            <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.title) }} />
          </Typography>
        </div>

        <p className={styles.content}>
          <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.content) }} />
        </p>

        <Box display={'flex'} alignItems={'center'} className={styles.timestampContainer}>
          <p className={styles.timestamp}>
            {moment.utc(message.timestamp).local().startOf('seconds').fromNow()}
          </p>
        </Box>
      </Box>
    </Card>
  );
}

export default NotificationMessage;