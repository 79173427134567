import {widgetTypeConstants } from "../../../_constants";
import { WidgetWrapper } from "../WidgetWrapper";
import { FootfallTraffic } from "./FootfallTraffic";

export const WidgetFootfallTraffic = (props) => (
        <WidgetWrapper
            {...{...props, widgetType: widgetTypeConstants.widgetFootfall.id}}
        >
            <FootfallTraffic {...props}/>
        </WidgetWrapper>
    );

export default WidgetFootfallTraffic;
