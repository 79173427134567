import { alpha } from '@material-ui/core';

const scrollbarTheme = (theme) =>
({
  '& ::-webkit-scrollbar': {
    width: "15px",
    height: "15px",
    backgroundColor: alpha(theme.palette.common.panelBorderColor, 0.5), //"transparent",
    backgroundClip: 'padding-box',
    border: '6px solid transparent'
  },
  '& ::-webkit-scrollbar-thumb': {
    //width: "0.5px"
    backgroundColor: theme.palette.common.panelBorderColor,
    backgroundClip: 'padding-box',
    border: '4px solid transparent',
    borderRadius: "7px",
    '&:hover': {
        backgroundColor: "#9AACAF"
    }
  }
});
export default scrollbarTheme;
