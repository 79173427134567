import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";

import axios from "axios";
const authService = new AuthService();

export async function carParkingSourceImport(file, selectedVenue) {
    const user = await authService.getUser();
    if (user && user.access_token) {
      const options = {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + user.access_token,
          "Content-Type": "multipart/form-data",
        },
        timeout: 1000 * 60 * 10 // wait for 10 mins
      };
  
      const bodyFormData = new FormData();
      bodyFormData.append("venueId", selectedVenue.id);
      bodyFormData.append("timeZoneId", selectedVenue.timeZoneId);
      bodyFormData.append("fileName", file.name);
      bodyFormData.append("sourceFile", file);
  
      return await axios
        .post(
          ApiRootConstants.dataCollectorApiRoot +
            "api/ImportSourceData/AdamantImport",
          bodyFormData,
          options
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            } else if (error.response.status === 422) {
              throw new Error(error.response.data.message);
            }
          }
  
          throw error;
        });
    }
  }