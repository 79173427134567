import React from "react";
import MapPreview from "./MapPreview"

const MapThumbnail = ({mapId}) => {
  return (
    <MapPreview
      mapId={mapId} 
      meetOrSlice="slice"
      showOverlays={true}
      showOverlayStroke={false}
    />
  );
};

export default MapThumbnail;