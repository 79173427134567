import React from "react";
import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { parsePolygonSvg } from "./PolygonEditor";

const useStyles = makeStyles((theme) => theme.map.polygonViewer);

export const PolygonViewer = ({layoutSize, scaleFactor, overlayId, markup, onPolygonSelected = () => {}}) => {
  const classes = useStyles({scaleFactor: scaleFactor});
  const [currentPhase, setCurrentPhase] = useState("view");

  const points = parsePolygonSvg(markup);

  return (
    <g>
      <polygon 
        className={`${classes.polygon} ${currentPhase === "mouseOver" ? classes.mouseOverPolygon : ""}`}
        points={points}
        onMouseEnter={() => {
          setCurrentPhase("mouseOver");
        }}
        onMouseLeave={() => {
          setCurrentPhase("view");
        }}
        onMouseDown={() => onPolygonSelected(overlayId)}
      />
    </g>
  );
};

export default PolygonViewer;