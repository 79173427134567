import React, { useState } from "react";
import * as venueCameraApi from "../../../../_services/venue.camera.service";
import { template } from "../../../../_constants";
import ImportFileModal from "../../../_common/ImportFileModal";

export const VenueCamerasImportPopup = ({
  showModal,
  setShowModal,
  refreshParent = () => { },
}) => {
  const [selectedFile, setSelectedFile] = useState();
  
  return (
    <ImportFileModal
      importingEntityName="Camera streams"

      showModal={showModal}
      setShowModal={setShowModal}

      refreshParent={refreshParent}

      onTemplateDownloadClick={() => window.open(template.path + 'CameraStreamsBulkUpdate.xlsx')}

      apiImport={() => venueCameraApi.venueCamerasImport(selectedFile)}
      selectedFile={selectedFile}
      setSelectedFile={setSelectedFile}
    />
  );
};

export default VenueCamerasImportPopup;