import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import { toast } from "react-toastify";

var Constants = {
  reportApiRoot: ApiRootConstants.reportApiRoot,
};

const authService = new AuthService();
export async function getPowerBiData(workspaceId, reportId) {
  const user = await authService.getUser();
    console.log("powerbi:", user);
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };
    console.log("powerbi:", `${Constants.reportApiRoot}powerbi/${workspaceId}/${reportId}`);
    return await axios
      .get(
        `${Constants.reportApiRoot}powerbi/${workspaceId}/${reportId}`,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
