import React, { useRef, useLayoutEffect, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { icon } from "../../../_constants";

const BASE_FONT_SIZE = 10;
const BASE_WIDGET_SIZE = 122;
const MINIMAL_WIDGET_HEIGHT = 32;

const useStyles = makeStyles((theme) => ({
    ...theme.widget.emptyContainer,
    ...theme.widget.widgetLabel,
}));

// Function returns allowable number of lines for label text depending on widget height.
const getTextLinesNumber = (widgetHeight) => {
    var textLinesNumber = 1;
    switch (true) {
        case widgetHeight > 32 && widgetHeight < 100:
            textLinesNumber = 2;
            break;

        case widgetHeight > 100:
            textLinesNumber = 3;
            break;
        default:
            break;
    }

    return textLinesNumber;
}

export const WidgetLabelContent = ({ backgroundColor, width, height, settings, viewMode, setIsCaptionTextVisible, brandingSettings }) => {
    const [fontSize, setFontSize] = useState(BASE_FONT_SIZE);
    const [widgetHeight, setWidgetHeight] = useState();

    const targetRef = useRef();
    const classes = useStyles({ backgroundColor, width, fontSize, textLinesNumber: getTextLinesNumber(widgetHeight), ...brandingSettings });

    const labelValue = settings?.WidgetSettingLabelValue;

    const EmptyContainer = () => {
        return (
            <Box className={classes.emptyContent}>
                <Typography>Configure widget</Typography>
                {widgetHeight > MINIMAL_WIDGET_HEIGHT ? (
                    <Box display="flex" flexDirection="column">
                        <Typography>
                            <img
                                alt={icon.path + 'SettingsSuggest.svg'}
                                src={icon.path + 'SettingsSuggest.svg'}
                                className={classes.icon}
                            />
                        </Typography>
                    </Box>) : (<></>)
                }
            </Box>
        );
    };

    // calculate scale factor depending on the width and height. Always use smallest value to use for scale factor calculation
    // original widget size is approx BASE_WIDGET_SIZE width and height, and base font size is BASE_FONT_SIZE. Using this values we calculate scale factor
    const applyScaleFactor = (width, height) => {
        const newWidgetSize = width > height ? height : width;
        const scaleFactor = newWidgetSize / BASE_WIDGET_SIZE;

        setFontSize(BASE_FONT_SIZE * scaleFactor);
        setWidgetHeight(height);
    };

    // use this ref to monitor resize venue and adjust the font sizes accordingly using scale factor
    const ref = useResizeObservedRef(({ width, height }) => {
        applyScaleFactor(width, height);
    });

    // use this hook to set initial font sizes by applying scale factor based on current widget size. this is used when widget renders for the first time on viewing dashboard
    useLayoutEffect(() => {
        if (targetRef.current) {
            applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
        }
    }, []);

    useEffect(() => {
        setIsCaptionTextVisible(!labelValue);
    }, [setIsCaptionTextVisible,viewMode, labelValue])

    return (
        <Box className={classes.container} ref={ref}>
            {labelValue ? (
                <div className={classes.value}>{labelValue}</div>
            ) : (
                <EmptyContainer />
            )}
        </Box>
    );
};

export default WidgetLabelContent;
