import React from "react";
import {
  Typography,
  FormControl,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Row, Col } from "react-bootstrap";
import { useHistory } from "react-router";
import { DataGrid } from "@material-ui/data-grid";
import { DataGridLoadingOverlay } from '../_common/DataGridLoadingOverlay';
import LoadPleaseWait from "../notification/LoadingPleaseWait/LoadingMessage";
import { Button, ButtonType } from "../_common/htmlTags/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      //width: "25ch",
    },
  },
  margin: {
    margin: theme.spacing(1),
  },
  formInput: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    minWidth: 120,
  },
}));

const EditTaskForm = ({
  indicators,
  filteredDataSources,
  filteredSchedules,
  filteredVendorLocationMapping,
  selectedSchedule,
  selectedVendorLocationMappings,
  selectedIndicatorId,
  selectedVendorId,
  description,
  name,
  taskEnabled,
  createdDateUTC, 
  startProcessDateTimeUTC,
  onSave,
  onNameChange,
  onDescriptionChange,
  onEnabledChange,
  onVendorChange,
  onIndicatorChange,
  onLocationChange,
  onScheduleChange,
  saving = false,
  errors = {},
  locationMappingsLoading,
  isLoading,
  forBusinessHoursOnly,
  onBusinessHoursOnlyChange
}) => {
  const classes = useStyles();
  const history = useHistory();
  
  return (
    <form onSubmit={onSave}>
      <Typography variant="h4" className={classes.margin}>
        Scheduled task details
      </Typography>

      <LoadPleaseWait show={!isLoading} >    
        {errors.onSave && (
          <div className="alert alert-danger" role="alert">
            {errors.onSave}
          </div>
        )}
 <Card style={{ overflow: 'auto' }}><CardContent>
        <Container fluid>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <TextField
                  id="name"
                  name="name"
                  label="Name"
                  value={name || ""}
                  onChange={onNameChange}
                  variant="outlined"
                  required
                  fullWidth
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <TextField
                  id="description"
                  name="description"
                  label="Description"
                  value={description || ""}
                  variant="outlined"
                  onChange={onDescriptionChange}
                  required
                  fullWidth
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <FormControl variant="outlined" fullWidth>
                  <InputLabel id="indicator-label">
                    Indicator *
                  </InputLabel>
                  <Select
                    labelId="indicator-label"
                    id="operationalIndicatorId"
                    name="operationalIndicatorId"
                    value={selectedIndicatorId || ""}
                    onChange={onIndicatorChange}
                    label="Indicator"
                  >
                    {indicators.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <FormControl required variant="outlined" fullWidth>
                  <InputLabel id="vendor-label">Data source</InputLabel>
                  <Select
                    labelId="vendor-label"
                    id="vendorId"
                    name="vendorId"
                    value={selectedVendorId || ""}
                    onChange={onVendorChange}
                    label="Data source"
                  >
                    {filteredDataSources.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
          </Row>
          <br />
          <>
            <div className={classes.instructions}>
              <Typography variant="h6">Choose locations</Typography>
            </div>
            <div>
              <DataGrid
                autoHeight
                checkboxSelection
                pageSize={20}
                columns={[
                  {
                    field: "id",
                    hide: true,
                  },
                  {
                    field: "locationName",
                    headerName: "LOCATION",
                    width: 300,
                  },
                  {
                    field: "locationParentName",
                    headerName: "PARENT LOCATION",
                    width: 300,
                  },
                ]}
                rows={filteredVendorLocationMapping}
                loading={
                  locationMappingsLoading &&
                  locationMappingsLoading.locationMappingsLoading
                }
                components={{
                  LoadingOverlay: DataGridLoadingOverlay,
                }}
                selectionModel={selectedVendorLocationMappings.map(a => a.id)}
                onSelectionModelChange={onLocationChange}
                sortModel={[
                  {
                    field: "locationParentName",
                    sort: "asc",
                  },
                ]}
              />
            </div>
          </>
          <br />

          <Row>
            <Col>
              <div className={classes.formInput}>
                <FormControl required variant="outlined" fullWidth>
                  <InputLabel id="schedule-label">Schedule</InputLabel>
                  <Select
                    labelId="schedule-label"
                    id="frequencyId"
                    name="frequencyId"
                    value={selectedSchedule || ""}
                    onChange={onScheduleChange}
                    label="Schedule"
                  >
                    {filteredSchedules.map((e) => (
                      <MenuItem key={e.id} value={e.id}>
                        {e.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <TextField
                  id="start-process-date-time"
                  name="description"
                  label="Start"
                  variant="outlined"
                  value={
                    createdDateUTC === startProcessDateTimeUTC
                      ? "immediately"
                      : "on " +
                        new Date(
                          new Date(startProcessDateTimeUTC).getTime() -
                            new Date(
                              startProcessDateTimeUTC
                            ).getTimezoneOffset() *
                              60000
                        ).toLocaleString()
                  }
                  fullWidth
                  disabled
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={forBusinessHoursOnly}
                      onChange={onBusinessHoursOnlyChange}
                      name="businessHoursOnly"
                      color="primary"
                    />
                  }
                  label={<Typography>Execute within business hours only</Typography>}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className={classes.formInput}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={taskEnabled}
                      onChange={onEnabledChange}
                      name="enabled"
                      color="primary"
                    />
                  }
                  label={<Typography>Enabled</Typography>}
                />
              </div>
            </Col>
          </Row>
          <Button
            variant="contained"
            type="submit"
            disabled={saving}
            className="btn btn-primary"
          >
            {saving ? "Saving..." : "Save"}
          </Button>
          <Button
            variant="outlined"
            buttonType={ButtonType.Secondary}
            className={classes.margin}
            onClick={() => {
              history.push("/scheduled-tasks");
            }}
          >
            Cancel
          </Button>
          </Container>
          </CardContent></Card>
      </LoadPleaseWait>
    </form>
  );
};

export default EditTaskForm;
