import {useCallback, useEffect, useRef} from 'react';

// ------ How to use this hook -------
//
// Call the hook with a callback that takes {width, height} object as a parameter.
// This callback will be called every time the resize is detected.
//
// The hook returns a function that can be used as a callback for the ref prop
// of the element which size we want to observe. Using callback instead of useRef
// in the element's ref prop helps to avoid having useRef reference in
// the useEffect deps.
//
// Example:
//   const resizeObservedRef = useResizeObservedRef(({width, height}) => {
//       // Do something with the new size of the observed component.
//   });
//   .....
//   return (
//      <div 
//         ref={resizeObservedRef}
//         style={{width: "100%", height: "100%"}}>
//      ...
//      </div>
//   );

export const useResizeObservedRef = (callback) => {

  const isMounted = useRef(false);
  useEffect(() => {
    isMounted.current = true;
    return () => { isMounted.current = false }
  }, []);

  const resizeObserver = useRef(
    new ResizeObserver(entries => {
      // We care only about the first entry. We expect
      // only one element to be watched at a time.

      // contentRect has width and height properties that are what we are interested in,
      // but it's OK to use the whole object.
      if (isMounted.current) {
        callback(entries[0].contentRect); 
      }
    })
  );

  return useCallback( node => {
    if (node) {
      resizeObserver.current.observe(node);
    }

    return () => {
      if (node) {
        resizeObserver.current.unobserve(node);
      }
    };
  },[]);
};

export default useResizeObservedRef;