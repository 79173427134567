import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";

var Constants = {
  OccupancyIndicatorApiRoot: ApiRootConstants.OccupancyIndicatorApiRoot
};

const authService = new AuthService();

export async function addOccupancyManualAdjustment(occupancyManualAdjustment) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    const bodyFormData = {
      LocationId: occupancyManualAdjustment.locationId,
      VenueId: occupancyManualAdjustment.venueId,
      VendorId: occupancyManualAdjustment.vendorId,
      AdjustmentDate: occupancyManualAdjustment.adjustmentDate,
      AdjustmentFinalDate: occupancyManualAdjustment.adjustmentFinalDate,
      AdjustmentValue: occupancyManualAdjustment.adjustmentValue,
    };

    return await axios
      .post(
        Constants.OccupancyIndicatorApiRoot + "api/OccupancyAdjustment?locationVendorCode=" + occupancyManualAdjustment.locationVendorCode,
        bodyFormData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function loadLatestOccupancies(venueId, vendorId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(
        Constants.OccupancyIndicatorApiRoot + "api/GetLatestOccupancies?venueId=" + venueId + "&vendorId=" + vendorId,
        {},
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function loadTrafficInOutMetrics() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(
        Constants.OccupancyIndicatorApiRoot + "api/Traffic/GetTrafficInOutMetrics",
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function loadFootfallTrafficMetrics() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(
        Constants.OccupancyIndicatorApiRoot + "api/Traffic/GetFootfallTrafficMetrics",
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
