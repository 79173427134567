import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Grid,
    TextField,
} from "@material-ui/core";
import { AuthService } from "../../../_services";
import { useLocation } from "react-router-dom";
import { checkPassword } from "../../../_helpers/regex";
import { toast } from "react-toastify";
import { changePassword } from "../../../_actions/user.management.actions";
import { useDispatch } from "react-redux";
import { Button } from "../../_common/htmlTags/Button";
import PasswordValidation from "./PasswordValidation";

const useStyles = makeStyles((theme) => ({
    validBorder: {
        borderColor: "#3FBE5D !important"
    },
    errorBorder: {
        "&.Mui-focused fieldset": {
            borderColor: "#EE4040",
        },
    },
}));

const authService = new AuthService();

export const ChangePassword = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const location = useLocation();

    const [user, setUser] = useState(null);
    const [userPassword, setUserPassword] = useState({
        userName: user?.profile.name,
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
    });
    const [showPasswordError, setShowPasswordError] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [isNewPasswordValid, setIsNewPasswordValid] = useState(true);

    useEffect(() => {
        authService.getUser().then(u => {
            setUser(u);
        });
    }, []);

    useEffect(() => {
        setUserPassword({ currentPassword: userPassword.currentPassword, newPassword: userPassword.newPassword, confirmPassword: userPassword.confirmPassword, userName: user?.profile.name });
    }, [location, userPassword.currentPassword, userPassword.newPassword, userPassword.confirmPassword]);

    function handleSave(event) {
        event.preventDefault();
        setUpdating(true);

        if (!checkPassword(userPassword.newPassword)) {
            setUpdating(false);
            toast.error(
                "Password does not meet strength requirements! It must be at least 6 characters long with at least 1 upper case, 1 lower case, 1 digit, 1 special characters and no leading or trailing spaces.  "
            );
            return;
        }

        dispatch(changePassword(userPassword))
            .then(() => {
                setUpdating(false);
                toast.success("User password updated successfully.");

            })
            .catch((error) => {
                setUpdating(false);
                toast.error("Updating user password failed. " + error, { autoClose: false });
            });
    }

    const handleCurrentPasswordChange = (e) => {
        setUserPassword({ ...userPassword, currentPassword: e.target.value });
    };

    const handleNewPasswordChange = (e) => {
        setUserPassword({ ...userPassword, newPassword: e.target.value });
        setShowPasswordError(e.target.value !== userPassword.confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setUserPassword({ ...userPassword, confirmPassword: e.target.value });
        setShowPasswordError(userPassword.newPassword !== e.target.value);
    };

    return (
        <form autoComplete="off" onSubmit={handleSave}>
            <Grid container spacing={2} >
                <Grid item container spacing={3} xs={8} md={8} direction="column" >

                    <Grid item>
                        <TextField
                            fullWidth
                            id="current-password"
                            label="Current password"
                            name="currentPassword"
                            value={userPassword.currentPassword}
                            onChange={handleCurrentPasswordChange}
                            variant="outlined"
                            type="password"
                            required
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            fullWidth
                            id="new-password"
                            label="New password"
                            name="newPassword"
                            value={userPassword.newPassword}
                            onChange={handleNewPasswordChange}
                            variant="outlined"
                            type="password"
                            error={userPassword.newPassword && !isNewPasswordValid}
                            InputProps={{
                                classes: {
                                    notchedOutline: isNewPasswordValid && userPassword.newPassword ? classes.validBorder : ""
                                }
                            }}
                            required
                        />
                    </Grid>

                    <Grid item>
                        <TextField
                            fullWidth
                            id="confirm-password"
                            label="Confirm password"
                            value={userPassword.confirmPassword}
                            error={showPasswordError}
                            helperText={
                                showPasswordError ? "Password does not match" : ""
                            }
                            onChange={handleConfirmPasswordChange}
                            variant="outlined"
                            type="password"
                            required
                        />
                    </Grid>

                    <Grid item>
                        <Button
                            variant="contained"
                            disabled={updating}
                            type="submit"
                            style={{ marginTop: "20px" }}
                        >
                            {updating ? "Saving..." : "Save"}
                        </Button>
                    </Grid>

                </Grid>

                <Grid item xs="6" sm="6" md="3">
                    {
                        userPassword.newPassword ?
                            <PasswordValidation
                                setIsNewPasswordValid={setIsNewPasswordValid}
                                newPassword={userPassword.newPassword}
                            />
                            :
                            <></>
                    }
                </Grid>
            </Grid>
        </form>
    );
}

export default ChangePassword;