import React, { useRef, useState, useLayoutEffect, useEffect, useContext } from "react" 
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { EmptyContainer } from "../EmptyContainer";
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { widgetOccupancyValueTypeConstants } from "../../../_constants";
import { getContrastTextColor } from "../../../_helpers/colorUtils";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import WidgetContext from "../WidgetContext";

const useStyles = makeStyles((theme) => ({
  ...theme.widget.common,
  ...theme.widget.occupancy,
  icon: { ...theme.widget.widgetIcon.container },
  container: { ...theme.widget.occupancy.container },
  white: theme.palette.common.white,
  emptyContainerBorderColor: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderColor: theme.palette.widget.borderColor,       
  },
  ...theme.widget.flashingWidget,
}));

// define initial values
const BASE_WIDGET_SIZE = 100;

const WHITE_COLOR = '#ffffff';

const WidgetOccupancyContainer = (props) => {
  const { width, height, data, settings, viewMode, brandingSettings, style, children, } = props;

  const noData = !settings?.RefLocation;

  const twoBoxes = settings?.RefOccupancyViewMode?.id === widgetOccupancyValueTypeConstants.RawNumber &&
    settings?.WidgetSettingShowHighestValue ? true : false;
  const baseFontSize = 8;
  const isFlash = data?.alertRule?.color?.flash ? true : false;
  const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;
  
  const [scaleFactor, setScaleFactor] = useState(1);
  const [fontSize, setFontSize] = useState(baseFontSize);

  const targetRef = useRef();
  const widgetContextData = useContext(WidgetContext);
  
  const applyScaleFactor = (width, height) => setScaleFactor((width > height ? width : height) / BASE_WIDGET_SIZE);
  useEffect(() => setFontSize(baseFontSize * scaleFactor), [scaleFactor, baseFontSize, setFontSize]);

  const ref = useResizeObservedRef(item => applyScaleFactor(item.width, item.height));

  // use this hook to set initial font sizes by applying scale factor based on current widget size. 
  // this is used when widget renders for the first time on viewing dashboard
  useLayoutEffect(() => {
    if (targetRef.current) {
        applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
    }
  }, []);

  const bgColor = noData || !isViewMode ? WHITE_COLOR : (data?.alertRule?.color?.value ?? WHITE_COLOR);
  const textColor = getContrastTextColor(bgColor);

  const classes = useStyles({ width, fontSize, ...brandingSettings });

  // Handling caption color depending on widget's background color.
  widgetContextData.setCaptionColor(bgColor === '#ffffff' ? "rgba(98, 126, 132, 1)" : (textColor ?? "rgba(98, 126, 132, 1)"));
  if (isFlash) {
    widgetContextData.setCaptionColor("rgba(98, 126, 132, 1)");
  }

  return (
    <>
      <Box
        display={twoBoxes ? undefined : 'flex'}
        className={classes.container +
            ` ${bgColor === WHITE_COLOR ? classes.emptyContainerBorderColor : ''} ` +
            ` ${isViewMode && !noData && isFlash ? classes.flashEffect : ''} `}
        height={height}
        bgcolor={bgColor}
        color={textColor}
        ref={ref}
      >
        {!noData ? (
            <Box 
                className={twoBoxes ? undefined : classes.containerBox}
                height={twoBoxes ? height : undefined}
                ref={targetRef}
                style={style}
            >
                { React.cloneElement(React.Children.only(children), {...props, classes, textColor, bgColor}) }
            </Box>
        ) : (
          <EmptyContainer {...props} />
        )}
      </Box>
    </>
  );
}

export default WidgetOccupancyContainer;
