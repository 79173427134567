import { makeStyles } from "@material-ui/core/styles";
import widgetTypeConstants from "../../../../_constants/widget.type.constants";

const useStyles = makeStyles((theme) => ({
  draggablePanel: { ...theme.dashboard.gridLayoutDashboard.draggableWidgetType.draggablePanel },
  iconPanel: { ...theme.dashboard.gridLayoutDashboard.draggableWidgetType.iconPanel },
  icon: { ...theme.dashboard.gridLayoutDashboard.draggableWidgetType.icon },
  iconMinimize: { ...theme.dashboard.gridLayoutDashboard.draggableWidgetType.iconMinimize }
}));
export const DraggableWidgetCollapsed = ({ isDraggable = false, 
                                      onSetDroppedWidgetType = (widgetType) => {},
                                      iconSrc = "", 
                                      widgetCaption = "",
                                      widgetDescription = "", 
                                      widgetType = widgetTypeConstants.widgetMap.id,
                                      isSelected = false,
                                      onSelected=(widgetType) => {}}) => {
    const classes = useStyles();
    return (
      <div
        onClick={() => { onSelected(widgetType); }}
        draggable={isDraggable}
        unselectable="on"
        // this is a hack for firefox
        // Firefox requires some kind of initialization
        // which we can do by adding this attribute
        // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
        onDragStart={(e) => {
          onSelected(widgetType);
          onSetDroppedWidgetType(widgetType);
          e.dataTransfer.setData("text/plain", "");
        }}
      >
        <div
          className={classes.draggablePanel}>
          <div className={classes.iconPanel} >
            <img className={classes.iconMinimize} src={iconSrc} alt={widgetCaption} title={widgetCaption} />
          </div>
        </div>
      </div>
    );
};