import React from "react";
import {useState} from "react";
import { 
  Box 
} from '@material-ui/core';
import AlertRulesEditor from './AlertRulesEditor';
import AlertRuleSetEditor from "./AlertRuleSetEditor";

export const AlertRulesTest = () => {

  const [alertRules, setAlertRules] = useState([
    {
      from: 0,
      to: 25,
      color: {
        value: "#83C58A",
      }
    }, {
      from: 26,
      to: null,
      color: {
        value: "#74B5D0"
      }
    }, {
      from: 51,
      to: 100,
      color: {
        value: "#E98999"
      }
    }, {
      from: 101,
      to: 120,
      color: {
        value: "#A6B8FB",
        flash: true
      }
    }
  ]);

  const [alertRuleSet, setAlertRuleSet] = useState({
    name: "Color rule set ABC",
    alertRules: [
      {
        from: 0,
        to: 25,
        color: {
          value: "#83C58A",
        }
      }, {
        from: 26,
        to: null,
        color: {
          value: "#74B5D0"
        }
      }, {
        from: 51,
        to: 100,
        color: {
          value: "#E98999"
        }
      }, {
        from: 101,
        to: 120,
        color: {
          value: "#A6B8FB",
          flash: true
        }
      }
    ]
  });

  const handleAlertRulesChange = (alertRules) => {
    console.log("handleAlertRulesChanged", alertRules);
    setAlertRules(alertRules);
  };

  const handleAlertRuleSetChange = (alertRuleSet) => {
    console.log("handleAlertRuleSet", alertRuleSet);
    setAlertRuleSet(alertRuleSet);
  };

  console.log("alertRules", alertRules);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: 0,
        flexGrow: 1,
        backgroundColor: "white"
      }}>
        <AlertRulesEditor
          label="Color rules"
          alertRules={alertRules}
          onAlertRulesChange={handleAlertRulesChange}
        />
        <Box
          mt={5}
          sx={{
            display: "flex",
            flexDirection: "column",
            //height: '500px',
          }}>
          <AlertRuleSetEditor
            //className={classes.alertRuleSet}
            alertRuleSet={alertRuleSet}
            onChange={handleAlertRuleSetChange}
          />
        </Box>
    </Box>
  );
};