import * as dashboardConstants from "../_constants/dashboard.constants";
import * as dashboardApi from "../_services/dashboard.service";
import * as scheduleTaskApi from "../_services/scheduledTasks.service";
import * as venueCameraApi from "../_services/venue.camera.service";

export const loadDashboardsSuccess = (dashboards) => {
  return {
    type: dashboardConstants.LOAD_DASHBOARD_SUCCESS,
    dashboards: dashboards,
  };
};

export const loadDashboardDataStarted = (dashboardId) => {
    return {
      type: dashboardConstants.LOAD_DASHBOARD_DATA_STARTED,
      dashboardId,
    };
  };

export const loadDashboardDataSuccess = (dashboardId) => {
    return {
      type: dashboardConstants.LOAD_DASHBOARD_DATA_SUCCESS,
      dashboardId,
    };
  };
  
  export const loadDashboardDataFailed = (dashboardId) => {
    return {
      type: dashboardConstants.LOAD_DASHBOARD_DATA_FAILED,
      dashboardId,
    };
  };

export const loadDashboardDetailsSuccess = (dashboard) => {
  return {
    type: dashboardConstants.LOAD_DASHBOARD_DETAIL_SUCCESS,
    dashboard: dashboard,
  };
};

export const loadIndicatorsSuccess = (indicators) => {
  return {
    type: dashboardConstants.LOAD_INDICATOR_SUCCESS,
    indicators: indicators,
  };
};

export const addDashboardSuccess = (dashboard) => {
  return {
    type: dashboardConstants.ADD_DASHBOARD_SUCCESS,
    dashboard: dashboard,
  };
};

export const updateDashboardSuccess = (dashboard) => {
  return {
    type: dashboardConstants.UPDATE_DASHBOARD_SUCCESS,
    dashboard: dashboard,
  };
};

export const updateDashboardLastUpdatedSuccess = (dashboardId, lastUpdated) => {
    return {
      type: dashboardConstants.UPDATE_DASHBOARD_LAST_UPDATED_SUCCESS,
      dashboardId,
      lastUpdated,
    };
  };

export const updateDashboardLastRequestedSuccess = (dashboardId, lastRequested) => {
    return {
      type: dashboardConstants.UPDATE_DASHBOARD_LAST_REQUESTED_SUCCESS,
      dashboardId,
      lastRequested,
    };
  };
  
export const publishDashboardSuccess = (dashboard) => {
    return {
      type: dashboardConstants.PUBLISH_DASHBOARD_SUCCESS,
      dashboard: dashboard,
    };
  };
  
export const removeDashboardOptimistic = (dashboardId) => {
  return {
    type: dashboardConstants.REMOVE_DASHBOARD_OPTIMISTIC,
    dashboardId: dashboardId,
  };
};

export const removeDashboardRangeOptimistic = (dashboardIds) => {
  return {
    type: dashboardConstants.REMOVE_DASHBOARD_RANGE_OPTIMISTIC,
    dashboardIds: dashboardIds,
  };
};

export const loadOccupancyViewModesSuccess = (occupancyViewModes) => {
  return {
    type: dashboardConstants.LOAD_OCCUPANCY_VIEW_MODES_SUCCESS,
    occupancyViewModes: occupancyViewModes,
  };
};

export const loadTrafficTypesSuccess = (trafficTypes) => {
  return {
    type: dashboardConstants.LOAD_TRAFFIC_TYPES_SUCCESS,
    trafficTypes: trafficTypes,
  };
};

export const loadCohortsSuccess = (cohorts) => {
  return {
    type: dashboardConstants.LOAD_COHORTS_SUCCESS,
    cohorts: cohorts,
  };
};

export const loadNumberWidgetValueTypesSuccess = (numberWidgetValueTypes) => {
  return {
    type: dashboardConstants.LOAD_NUMBER_WIDGET_VALUE_TYPES_SUCCESS,
    numberWidgetValueTypes: numberWidgetValueTypes,
  };
};

export const loadNumberWidgetTimePeriodsSuccess = (numberWidgetTimePeriods) => {
  return {
    type: dashboardConstants.LOAD_NUMBER_WIDGET_TIME_PERIODS_SUCCESS,
    numberWidgetTimePeriods: numberWidgetTimePeriods,
  };
};

export const loadDemographicWidgetValueTypesSuccess = (demographicWidgetValueTypes) => {
  return {
    type: dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_VALUE_TYPES_SUCCESS,
    demographicWidgetValueTypes: demographicWidgetValueTypes,
  };
};

export const loadDemographicWidgetTimePeriodsSuccess = (demographicWidgetTimePeriods) => {
  return {
    type: dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_TIME_PERIODS_SUCCESS,
    demographicWidgetTimePeriods: demographicWidgetTimePeriods,
  };
};

export const loadDemographicWidgetAgeGroupSuccess = (demographicWidgetAgeGroup) => {
  return {
    type: dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_AGE_GROUP_SUCCESS,
    demographicWidgetAgeGroup: demographicWidgetAgeGroup,
  };
};

export const loadDemographicWidgetGenderSuccess = (demographicWidgetGender) => {
  return {
    type: dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_GENDER_SUCCESS,
    demographicWidgetGender: demographicWidgetGender,
  };
}

export const loadMapWidgetIndicatorsSuccess = (mapWidgetIndicators) => {
  return {
    type: dashboardConstants.LOAD_MAP_WIDGET_INDICATORS_SUCCESS,
    mapWidgetIndicators: mapWidgetIndicators,
  };
};

export const loadCamerasSuccess = (cameras) => {
    return {
      type: dashboardConstants.LOAD_CAMERAS_SUCCESS,
      cameras,
    };
  };

export const updateAfterHoursWarningHeight = (height) => {
  return {
    type: dashboardConstants.UPDATE_AFTER_HOURS_WARNING_HEIGHT,
    afterHoursWarningHeight: height,
  }
};
  
export const loadDashboards = (venueId, isPublished) => {
  return async (dispatch) => {
    await dashboardApi
      .getDashboards(venueId, isPublished)
      .then((dashboards) => {
        dispatch(loadDashboardsSuccess(dashboards));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadDashboardsSimple = (venueId, isPublished) => {
  return async (dispatch) => {
    await dashboardApi
      .getDashboardsSimple(venueId, isPublished)
      .then((dashboards) => {
        dispatch(loadDashboardsSuccess(dashboards));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadWidgetTrafficSuccess = (widgetTrafficTypes) => {
  return {
    type: dashboardConstants.UPDATE_WIDGET_TRAFFIC_TYPE_SUCCESS,
    widgetTrafficTypes: widgetTrafficTypes,
  };
};
export const loadWidgetTrafficType = (venueId) => {
  return async (dispatch) => {
    await dashboardApi
      .getWidgetTrafficTypes()
      .then((dashboards) => {
        dispatch(loadWidgetTrafficSuccess(dashboards));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const getDashboard = (venueId, dashboardId) => {
  return async (dispatch) => {
    await dashboardApi
      .getDashboard(venueId, dashboardId)
      .then((dashboard) => {
        dispatch(updateDashboardSuccess(dashboard));
      })
      .catch((error) => {
        throw error;
      });
  };
};
export const loadIndicators = () => {
  return async (dispatch) => {
    await scheduleTaskApi
      .getIndicators()
      .then((indicators) => {
        dispatch(loadIndicatorsSuccess(indicators));
      })
      .catch((error) => {
        throw error;
      });
  };
};
export const addDashboard = (dashboard) => {
  return async (dispatch) => {
    return await dashboardApi
      .addDashboard(dashboard)
      .then((data) => {
        dispatch(addDashboardSuccess(data));
        return data;
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const updateDashboard = (dashboard) => {
  return async (dispatch) => {
    await dashboardApi
      .updateDashboard(dashboard)
      .then(() => {
        dispatch(updateDashboardSuccess(dashboard));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const publishDashboard = (dashboard) => {
    return async (dispatch) => {
      await dashboardApi
        .publishDashboardJSONConfig(dashboard.venueId, [dashboard.id])
        .then(() => {
          dispatch(publishDashboardSuccess(dashboard));
        })
        .catch((error) => {
          throw error;
        });
    };
  };
  
export const removeDashboard = (dashboardId) => {
  return async (dispatch) => {
    await dashboardApi
      .removeDashboard(dashboardId)
      .then(() => {
        dispatch(removeDashboardOptimistic(dashboardId));
      });
  };
};

export const removeDashboardRange = (dashboardIds) => {
  return async (dispatch) => {
    await dashboardApi
      .removeDashboardRange(dashboardIds)
      .then(() => {
        dispatch(removeDashboardRangeOptimistic(dashboardIds));
      });
  };
}


export const ensureDashboardSuccess = (dashboard) => {
  return {
    type: dashboardConstants.ENSURE_DASHBOARD_SUCCESS,
    dashboard: dashboard,
  };
};
//export const ensureDashboard = (dashboard) => {
//  return async (dispatch) => {
//    await dashboardApi
//      .ensureDashboard(dashboard)
//      .then(() => {
//        dispatch(ensureDashboardSuccess(dashboard));
//      })
//      .catch((error) => {
//        throw error;
//      });
//  };
//};

export const updateWidgetSuccess = (widget) => {
  return {
    type: dashboardConstants.UPDATE_WIDGET_SUCCESS,
    widget: widget,
  };
};

export const updateWidgetSettingValue = (widgetSettingValues) => {
  return async (dispatch) => {
    await dashboardApi
      .updateWidgetSettingValue(widgetSettingValues)
      .then((widget) => {
        dispatch(updateWidgetSuccess(widget.data));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadOccupancyViewModes = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadOccupancyViewModes()
      .then((occupancyViewModes) => {
        dispatch(loadOccupancyViewModesSuccess(occupancyViewModes));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadTrafficTypes = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadTrafficTypes()
      .then((trafficTypes) => {
        dispatch(loadTrafficTypesSuccess(trafficTypes));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadNumberWidgetValueTypes = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadNumberWidgetValueTypes()
      .then((numberWidgetValueTypes) => {
        dispatch(loadNumberWidgetValueTypesSuccess(numberWidgetValueTypes));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadDemographicWidgetValueTypes = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadDemographicWidgetValueTypes()
      .then((demographicWidgetValueTypes) => {
        dispatch(loadDemographicWidgetValueTypesSuccess(demographicWidgetValueTypes));
      })
      .catch((error) => {
        throw error;
      });
  };
};


export const loadDemographicWidgetTimePeriods = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadDemographicWidgetTimePeriods()
      .then((demographicWidgetTimePeriods) => {
        dispatch(loadDemographicWidgetTimePeriodsSuccess(demographicWidgetTimePeriods));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadDemographicWidgetGender = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadDemographicWidgetGender()
      .then((demographicWidgetGender) => {
        dispatch(loadDemographicWidgetGenderSuccess(demographicWidgetGender));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadDemographicWidgetAgeGroup = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadDemographicWidgetAgeGroup()
      .then((demographicWidgetAgeGroup) => {
        dispatch(loadDemographicWidgetAgeGroupSuccess(demographicWidgetAgeGroup));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadNumberWidgetTimePeriods = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadNumberWidgetTimePeriods()
      .then((numberWidgetTimePeriods) => {
        dispatch(loadNumberWidgetTimePeriodsSuccess(numberWidgetTimePeriods));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadMapWidgetIndicators = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadMapWidgetIndicators()
      .then((numberWidgetTimePeriods) => {
        dispatch(loadMapWidgetIndicatorsSuccess(numberWidgetTimePeriods));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadCameras = (venueId) => {
    return async (dispatch) => {
      await venueCameraApi
        .getCameras(venueId)
        .then(camerasResponse => dispatch(loadCamerasSuccess(camerasResponse.data)))
        .catch(error => { throw error; });
    };
  };

export const loadCohorts = () => {
    return async (dispatch) => {
      await dashboardApi
        .loadCohorts()
        .then((cohorts) => {
          dispatch(loadCohortsSuccess(cohorts));
        })
        .catch((error) => {
          throw error;
        });
    };
};  

export const loadQueueModelAlertMetricsSuccess = (queueModelAlertMetrics) => {
  return {
    type: dashboardConstants.LOAD_QUEUE_MODEL_ALERT_METRICS_SUCCESS,
    queueModelAlertMetrics: queueModelAlertMetrics,
  };
};
export const loadQueueModelAlertMetrics = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadQueueModelAlertMetrics()
      .then((queueModelAlertMetrics) => {
        dispatch(loadQueueModelAlertMetricsSuccess(queueModelAlertMetrics));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadTrafficInOutAlertMetricsSuccess = (trafficInOutAlertMetrics) => {
  return {
    type: dashboardConstants.LOAD_TRAFFIC_IN_OUT_ALERT_METRICS_SUCCESS,
    trafficInOutAlertMetrics: trafficInOutAlertMetrics,
  };
};
export const loadTrafficInOutAlertMetrics = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadTrafficInOutAlertMetrics()
      .then((trafficInOutAlertMetrics) => {
        dispatch(loadTrafficInOutAlertMetricsSuccess(trafficInOutAlertMetrics));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const setHideInactiveDashboards = (hide) => {
  return (dispatch) => {
    dispatch(updateHideInactiveDashboards(hide));
  };
};
const updateHideInactiveDashboards = (hide) => {
  return {
    type: dashboardConstants.UPDATE_HIDE_INACTIVE_DASHBOARDS,
    hide: hide
  };
};

export const loadTrafficInOutDisplayModesSuccess = (trafficInOutDisplayModes) => {
  return {
    type: dashboardConstants.LOAD_TRAFFIC_IN_OUT_DISPLAY_MODES_SUCCESS,
    trafficInOutDisplayModes: trafficInOutDisplayModes,
  };
};
export const loadTrafficInOutDisplayModes = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadTrafficInOutDisplayModes()
      .then((trafficInOutDisplayModes) => {
        dispatch(loadTrafficInOutDisplayModesSuccess(trafficInOutDisplayModes));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadPatronDistributionMetricsSuccess = (patronDistributionMetrics) => {
  return {
    type: dashboardConstants.LOAD_PATRON_DISTRIBUTION_METRICS_SUCCESS,
    patronDistributionMetrics: patronDistributionMetrics,
  };
};
export const loadPatronDistributionMetrics = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadPatronDistributionMetrics()
      .then((patronDistributionMetrics) => {
        dispatch(loadPatronDistributionMetricsSuccess(patronDistributionMetrics));
      })
      .catch((error) => {
        throw error;
      });
  };
};

export const loadPatronDistributionValueTypesSuccess = (patronDistributionValueTypes) => {
  return {
    type: dashboardConstants.LOAD_PATRON_DISTRIBUTION_VALUE_TYPES_SUCCESS,
    patronDistributionValueTypes: patronDistributionValueTypes,
  };
};
export const loadPatronDistributionValueTypes = () => {
  return async (dispatch) => {
    await dashboardApi
      .loadPatronDistributionValueTypes()
      .then((patronDistributionValueTypes) => {
        dispatch(loadPatronDistributionValueTypesSuccess(patronDistributionValueTypes));
      })
      .catch((error) => {
        throw error;
      });
  };
};