import axios from "axios";
import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.dataCollectorApiRoot}api/DataSourcePointLocationLink/`,
};

const authService = new AuthService();

export async function getMetrics() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}Metrics`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function getIndicatorMetrics() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}IndicatorMetrics`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function getDataSourcePoints(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}${venueId}/DataSourcePoints`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function getDataSourcePointLocationMetrics(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${constants.api}${venueId}/DataSourcePointLocationMetrics`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function replaceDataSourcePointLocationMetrics(venueId, locationId, indicatorId, dataSourcePointLocationMetrics) {
  
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = dataSourcePointLocationMetrics;
   

    if (options !== null) {
      
      return await axios
        .post(`${constants.api}${venueId}/ReplaceDataSourcePointLocationMetrics/${locationId}/${indicatorId}`, formData, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function addUserVenues(venueUser) {
  
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {
      
      return await axios
        .post(`${constants.api + 'AddUserVenues/'}`, venueUser, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function deleteVenueUser(venueUser) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {

      return await axios
        .delete(`${constants.api}${venueUser.venueId}/${venueUser.id}`, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
            else {
              throw new Error(error.response.data?.message);
            }
          }
        });
    }
  }
}

export async function deleteUserFromAllVenues(userId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return axios
      .delete(
        `${constants.api}DeleteUserFromAllVenues/${userId}`, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 400) {
            // Handle 400
            //const message = error.response.data.split(",").map(e => e.split("-")[1]);
            throw new Error(error.response.data);
          } else if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
        }
        throw error;
      });
  }
}