import React from "react";
import { TextField } from '@material-ui/core';

const InputSetting = ({
    widgetSettingWithValue,
    handleSettingValueChange,
    required,
}) => {
    return (
        <TextField
            required={required}
            variant="outlined"
            style={{ width: "100%" }}
            label={widgetSettingWithValue.name}
            defaultValue={widgetSettingWithValue.value}
            onChange={
                venue => handleSettingValueChange(widgetSettingWithValue, venue.target.value.toString())
            }
        />
    );
}

export default InputSetting;