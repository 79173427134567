import React from "react";
import { Fragment } from "react";
import {useSelector} from "react-redux";
import {
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField, Tooltip,
  Typography
} from '@material-ui/core';
//import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
//import ColorSettingCircle from "../../admin/venueManagement/settings/brandingSettings/ColorSettingCircle";
import ColorPicker from "../ColorPicker";
import VolumeMuteIcon from '@material-ui/icons/VolumeMute';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import InputAdornment from "@material-ui/core/InputAdornment";
import {NIL as NIL_UUID} from "uuid";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      height: "65px",
      marginTop: "-10px",
    },
    fromLabel: {
      width: "50px"
    },
    toLabel: {
      width: "30px",
      marginLeft: "20px",
      marginRight: "5px"
    },
    endpointField: {
      width: "120px",
      backgroundColor: "white",
    },

    ruleInput: {
      height: "38px",
    },
    deleteIcon: {
      color:  theme.palette.color.danger.main,
      padding: "12px",
      margin: theme.spacing(1)
    },
    colorPicker: {
      marginLeft: theme.spacing(2)
    },
    muted: { 
      color: theme.palette.color.secondary.main, 
      marginLeft: "20px", 
      cursor: "pointer"
    },
    notMuted: {
      color: theme.palette.color.primary.green, 
      marginLeft: "20px", 
      cursor: "pointer"
    },
    notification: {
      cursor: "pointer",
      margin: "8px"
    },
    notificationOn: {
      color: theme.palette.color.primary.green, 
    },
    notificationOff: {
      color: theme.palette.color.secondary.main
    },
    notificationDisabled: {
      color: theme.palette.color.secondary.main,
      opacity: "30%"
    },
    email: {
    },
    sms: {
      marginTop: "13px",
      marginLeft: "25px"
    },
    selectUserGroupControl: {
      width: "230px",
      marginLeft: "70px"
    },
  };
});

export const AlertRuleEditor = ({alertRule, alertType, onAlertRuleChange, onAlertRuleDelete, fromRef}) => {
  const classes = useStyles();
  
  const userGroups = useSelector((state) => {
    return state.venueReducer.userGroups;
  });

  const handleFromChange = (e) => {
    const { value } = e.target;
    if (value) {
      const newFrom = +value;
      if (isNaN(newFrom)) {
        onAlertRuleChange(alertRule);
      } else {
        onAlertRuleChange({
          ...alertRule,
          from: newFrom
        });
      }
    } else {
      onAlertRuleChange({
        ...alertRule,
        from: null
      });
    }
  };

  const handleToChange = (e) => {
    const { value } = e.target;
    if (value) {
      const newTo = +value;
      if (isNaN(newTo)) {
        onAlertRuleChange(alertRule);
      } else {
        onAlertRuleChange({
          ...alertRule,
          to: newTo
        });
      }
    } else {
      onAlertRuleChange({
        ...alertRule,
        to: null
      });
    }
  };

  const handlePercentChange = (e) => {
    const { value } = e.target;
    if (value) {
      const newPercent = +value;
      if (isNaN(newPercent)) {
        onAlertRuleChange(alertRule);
      } else {
        onAlertRuleChange({
          ...alertRule,
          percent: newPercent
        });
      }
    } else {
      onAlertRuleChange({
        ...alertRule,
        percent: null
      });
    }
  };

  const handleColorChange = (colorValue, colorFlash) => {
    const newAlertRule ={
      ...alertRule,
      color: {
        value: colorValue,
        flash: colorFlash
      }
    };
    onAlertRuleChange(newAlertRule);
  };

  const handleSoundChange = (value= false) => {
    const newAlertRule ={
      ...alertRule,
      sound: value
    };
    onAlertRuleChange(newAlertRule);
  };

  const toggleSms = () => {
    const newAlertRule = {
      ...alertRule,
      sms: !alertRule.sms
    };
    onAlertRuleChange(newAlertRule);
  }

  const toggleEmail = () => {
    const newAlertRule = {
      ...alertRule,
      email: !alertRule.email
    };
    onAlertRuleChange(newAlertRule);
  }

  const handleUserGroupChange = (userGroupId) => {
    const newAlertRule = {
      ...alertRule,
      userGroupId: userGroupId === NIL_UUID ? null : userGroupId
    };
    onAlertRuleChange(newAlertRule);
  }

  // <input> element does not like null values.
  let from = alertRule.from;
  if (from === 0) {
    from = "0";
  } else if (!from) {
    from = "";
  }
  let to = alertRule.to;
  if (to === 0) {
    to = "0";
  } else if (!to) {
    to = "";
  }
  let percent = alertRule.percent;
  if (percent === 0) {
    percent = "0";
  } else if (!percent) {
    percent = "";
  }

  const renderedUserGroupsToChooseFrom = 
    [
      <MenuItem
        key={NIL_UUID}
        value={NIL_UUID}
      >
        <Box>
          {"<none>"}
        </Box>
      </MenuItem>,
      ...userGroups.map(ug => {
        return (
          <MenuItem
            key={ug.id}
            value={ug.id}
          >
            <Box>
              {ug.name}
            </Box>
          </MenuItem>
        )})
    ];

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
        //backgroundColor: alertRule.color
      }}
      className={classes.root}
    >
      <Typography
        className={classes.fromLabel}>
        From
      </Typography>
      { alertType === "range" ?
        <Fragment>
          <TextField
            variant="outlined"
            type={"number"}
            name="from"
            value={from}
            className={classes.endpointField}
            onChange={handleFromChange}
            autoComplete="off"
            placeholder="Min"
            inputRef={fromRef}
          />
          <Typography
            className={classes.toLabel}>
              to
          </Typography>
          <TextField
            variant="outlined"
            type={"number"}
            name="to"
            value={to}
            className={classes.endpointField}
            onChange={handleToChange}
            autoComplete="off"
            placeholder="Max"
          />
        </Fragment>
        : 
        <Fragment>
            <TextField
              variant="outlined"
              type={"number"}
              name="percent"
              value={percent}
              className={classes.endpointField}
              onChange={handlePercentChange}
              autoComplete="off"
              placeholder="Min"
              inputRef={fromRef}
              InputProps={{
                //startAdornment: <InputAdornment position='start'>%</InputAdornment>,
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
                //classes: defaultInputClasses
              }}
            />
            <Box
              sx={{
                width: "0px"
              }}
            />
        </Fragment>
      }
      <Box
        className={classes.colorPicker}
      >
        <ColorPicker 
          color={alertRule.color?.value}
          flash={alertRule.color?.flash}
          enableFlash
          onChange={handleColorChange}
        />
      </Box>
      {alertRule.sound ?
        <Tooltip title={"Play Sound"} ><VolumeUpIcon fontSize={"large"} className={classes.notMuted} onClick={() => { handleSoundChange(false); }} /></Tooltip> :
        <Tooltip title={"Mute Sound"} ><VolumeMuteIcon fontSize={"large"} className={classes.muted} onClick={() => { handleSoundChange(true); }} /></Tooltip>
      }

      {alertType === "percent" ?
        <Box
          sx={{
            width: "175px"
          }}
        />
      :
        null
      }

      <FormControl
        variant="outlined"
        className={classes.selectUserGroupControl}
      >
        <Select
          value={alertRule.userGroupId?? NIL_UUID}
          onChange={(e) => {
            const newUserGroupId = e.target.value;
            handleUserGroupChange(newUserGroupId);
          }}
          classes={{
            //select: classes.selectUserGroup
          }}
          variant="outlined"
        >
          {renderedUserGroupsToChooseFrom}
        </Select>
      </FormControl>
      <Tooltip title="SMS">
        <SmsOutlinedIcon
          fontSize="medium"
          className={`${classes.notification} ${classes.sms} ${alertRule.userGroupId ? (alertRule.sms? classes.notificationOn : classes.notificationOff ) : classes.notificationDisabled}`}
          onClick={toggleSms}
        />
      </Tooltip>
      <Tooltip title="Email">
        <MailOutlineIcon
          fontSize="medium"
          className={`${classes.notification} ${alertRule.userGroupId ? (alertRule.email? classes.notificationOn : classes.notificationOff ) : classes.notificationDisabled}`}
          onClick={toggleEmail}
        />
      </Tooltip>
      
      <Tooltip title={"Remove rule"} >
        <IconButton 
          edge="end" 
          aria-label="Remove"
          className={classes.deleteIcon}
          onClick={onAlertRuleDelete}
        >
          <ClearIcon />
        </IconButton>
      </Tooltip>
    </Box>
    
  );
};

export default AlertRuleEditor;