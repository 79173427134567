import { Box } from "@material-ui/core";
import appViewModeConstants from "../../_constants/app.view.mode.constants";
import widgetTypeConstants from "../../_constants/widget.type.constants";
import { WidgetFactory } from "./WidgetFactory";

export const WidgetFactoryTest = () => (
    <>
        <WidgetFactory
            widgetType={widgetTypeConstants.widgetSample.id} 
            widgetId="SMP-VIEW-ID"
            viewMode={appViewModeConstants.VIEW_MODE}
            width='300px'
            height='200px' />

        <Box height={20}/>

        <WidgetFactory
            widgetType={widgetTypeConstants.widgetOccupancy.id} 
            widgetId="OCC-ADMIN-ID"
            viewMode={appViewModeConstants.ADMIN_MODE}
            width='300px'
            height='150px'/>

        <Box height={50}/>

        <WidgetFactory
            widgetType={widgetTypeConstants.widgetTrafficInOut.id} 
            widgetId="OCC-VIEW-ID"
            viewMode={appViewModeConstants.ADMIN_MODE}
            width='400px'
            height='150px'/>
    </>
);