import {useLocation} from "react-router-dom";

export const QueryParams = () => {
    const params = new URLSearchParams(useLocation().search);
    // Create the new one and make sure that the key (name) is in lower case
    const newParams = new URLSearchParams();
    for (const [name, value] of params) {
      newParams.append(name.toLowerCase(), value);
    }
    return newParams;
} 

export const GetQueryParam = (queryParam) => {
    const params = QueryParams();
    return params.get(queryParam.toLowerCase());
}