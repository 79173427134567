import React from 'react'
import { Switch } from 'react-router-dom'
import { Dashboards, AddDashboard, EditDashboard } from "./admin/dashboards/";
import { DashboardViewer } from "./dashboardViewer/DashboardViewer";
import { VenuesList } from "./admin/venueManagement/VenuesList";
import { HomeRedirection } from "./HomeRedirection";
import { VenueLocations } from "./admin/venueManagement/VenueLocations";
import { AccountSettings } from "./user/AccountSettings";
import { VenueLocationsImport } from "./admin/venueManagement/bulkUpdate/VenueLocationsImport";
import AddMode from "./admin/venueModes/AddMode";
import EditMode from "./admin/venueModes/EditMode";
import { UsersList, EditUser, AddUser } from "./admin/users";
import { ScheduledTasks, AddNewTask, EditTaskPage } from '../components/dataCollector';
import { NotFound } from "./NotFound";
import { PermissionDenied } from "./PermissionDenied";
import AddVenue from "./admin/venueManagement/AddVenue";
import AddLocation from "./admin/venueManagement/AddLocation";
import EditLocation from "./admin/venueManagement/EditLocation";
import Venue from "./admin/venueManagement/settings/venue/Venue";
import DataSources from "./admin/venueManagement/settings/dataSources/DataSources";
import Reports from "./admin/venueManagement/settings/reports/Reports";
import Alerts from "./admin/venueManagement/settings/alerts/Alerts";
import Integration from "./admin/venueManagement/settings/integration/Integration";
import TableauReport from "./reports/TableauReport";
import ArmReport from "./reports/ArmReport";
import LookerStudioReport from "./reports/LookerStudioReport";
import ImportCarParkingSource from './import/importCarParkingSource';
import OccupancyManualAdjustment from './occupancy/OccupancyManualAdjustment';
import Maps from "./maps/Maps";
import EditMap from "./maps/EditMap";
import * as userPermissionsConstants from "../_constants/user.permissions.constants";
import { AuthService } from '../_services/auth.service';
import { GuardedRoute, GuardProvider } from 'react-router-guards';
import { WidgetFactoryTest } from './widgets/WidgetFactoryTest';
import {AlertRulesTest} from './_common/alertRules/AlertRulesTest'
import {AlertRuleSets} from "./AlertRuleSets/AlertRuleSets";
import Monitor from "./monitor/Monitor";
import UserGroups from "./admin/userGroups/UserGroups";
import Factors from "./factors/Factors";
import Events from "./admin/venueManagement/events/Events";
import AddVenueEvent from "./admin/venueManagement/AddVenueEvent";
import EditVenueEvent from "./admin/venueManagement/EditVenueEvent";
import PowerBiReport from "./reports/PowerBiReport";

const authService = new AuthService();
const requireLogin = async (to, from, next) => {
    let user = await authService.getUser();
    var userAvailableRoutes = [];
    if (user) {
        userPermissionsConstants.AVAILABLE_PAGES_BY_ROLE[user.profile.role].forEach(availablePage => {
            availablePage[userPermissionsConstants.AVAILABLE_PAGE_LINKS_LIST_KEY].forEach(availableLink => {
                userAvailableRoutes.push(availableLink);
            })
        });
    }

    if (to.meta.auth) {
        if (user) {
            if (user.expired) {
                authService.login();
            }
            else {
                var pathRoot = "/" + to.location.pathname.split("/")[1];
                if (userAvailableRoutes.includes("*") || userAvailableRoutes.includes(pathRoot)) {
                    next();
                } else {
                    next.redirect('/permission-denied');
                }
            }
        } else {
            authService.login();
        }
    } else {
        if (user) {
            next.redirect('/home');
        } else {
            next();
        }
    }
};

const MyRoutes = () => {
    return (
        <GuardProvider guards={[requireLogin]} error={NotFound}>
            <Switch>
                <GuardedRoute path='/' exact component={HomeRedirection} meta={{ auth: true }} />
                <GuardedRoute exact path="/home" component={VenuesList} meta={{ auth: true }} />
                <GuardedRoute exact path="/VenuesList" component={VenuesList} meta={{ auth: true }} />
                <GuardedRoute exact path="/venues/add" component={AddVenue} meta={{ auth: true }} />
                <GuardedRoute exact path="/dashboards" component={Dashboards} meta={{ auth: true }} />
                <GuardedRoute exact path="/dashboard" component={AddDashboard} meta={{ auth: true }} />
                <GuardedRoute exact path="/dashboard/:dashboardId" component={EditDashboard} meta={{ auth: true }} />
                <GuardedRoute exact path="/private-dashboard" component={DashboardViewer} meta={{ auth: true }} />
                <GuardedRoute exact path="/venueLocations" component={VenueLocations} meta={{ auth: true }} />
                <GuardedRoute exact path="/venueLocationsImport" component={VenueLocationsImport} meta={{ auth: true }} />
                <GuardedRoute exact path="/addLocation" component={AddLocation} meta={{ auth: true }} />
                <GuardedRoute exact path="/editLocation/:id" component={EditLocation} meta={{ auth: true }} />
                <GuardedRoute exact path="/venue" component={Venue} meta={{ auth: true }} />
                <GuardedRoute exact path="/settings-datasources" component={DataSources} meta={{ auth: true }} />
                <GuardedRoute exact path="/settings-reports" component={Reports} meta={{ auth: true }} />
                <GuardedRoute exact path="/settings-alerts" component={Alerts} meta={{ auth: true }} />
                <GuardedRoute exact path="/settings-integration" component={Integration} meta={{ auth: true }} />
                <GuardedRoute exact path="/users" component={UsersList} meta={{ auth: true }} />
                <GuardedRoute exact path="/user/:id" component={EditUser} meta={{ auth: true }} />
                <GuardedRoute exact path="/user" component={AddUser} meta={{ auth: true }} />
                {/*TODO: RETURN USER PROFILE WHEN PAGE WILL BE READY!*/}
                <GuardedRoute exact path="/user-profile" component={AccountSettings} meta={{ auth: true }} />
                {/* <GuardedRoute exact path="/change-password" component={ChangePassword} meta={{ auth: true }} /> */}
                <GuardedRoute exact path="/scheduled-tasks" component={ScheduledTasks} meta={{ auth: true }} />
                <GuardedRoute exact path="/add-new-task" component={AddNewTask} meta={{ auth: true }} />
                <GuardedRoute exact path="/edit-task/:id" component={EditTaskPage} meta={{ auth: true }} />
                {/*<GuardedRoute exact path="/location-mapping" component={LocationMapping} meta={{ auth: true }} />*/}
                <GuardedRoute exact path="/reports/tableau/:tableauViewId" component={TableauReport} meta={{auth:true}} />
                <GuardedRoute exact path="/reports/powerbi/:workspaceId/:reportId" component={PowerBiReport} meta={{auth:true}} />
                <GuardedRoute exact path="/reports/arm/:armViewId" component={ArmReport} meta={{auth:true}} />
                <GuardedRoute exact path="/reports/lookerstudio/:lookerStudioViewId" component={LookerStudioReport} meta ={{auth:true}} />
                <GuardedRoute exact path="/import" component={ImportCarParkingSource} meta={{ auth: true }} />
                <GuardedRoute exact path="/adjustments" component={OccupancyManualAdjustment} meta={{ auth: true }} />
                <GuardedRoute exact path="/maps" component={Maps} meta={{auth: true}} />
                <GuardedRoute exact path="/alert-rule-sets" component={AlertRuleSets} meta={{auth: true}} />
                <GuardedRoute exact path="/addMap" component={EditMap} meta={{ auth: true }} />
                <GuardedRoute exact path="/editMap/:id" component={EditMap} meta={{auth: true}} />
                <GuardedRoute exact path="/settings/mode/:id" component={EditMode} meta={{ auth: true }} />
                <GuardedRoute exact path="/settings/mode" component={AddMode} meta={{ auth: true }} />
                <GuardedRoute exact path='/permission-denied' component={PermissionDenied} meta={{ auth: true }} />

                <GuardedRoute exact path='/widget' component={WidgetFactoryTest} meta={{ auth: true }} />
                <GuardedRoute exact path='/widgets/settings/alert-rules-test' component={AlertRulesTest} meta={{auth: true}}/>
                <GuardedRoute exact path='/monitor' component={Monitor} meta={{ auth: true }} />
                <GuardedRoute exact path='/user-groups' component={UserGroups} meta={{auth: true}} />
                <GuardedRoute exact path="/factors" component={Factors} meta={{ auth: true }} />
                <GuardedRoute exact path="/events" component={Events} meta={{ auth: true }} />
                <GuardedRoute exact path='/event/add' component={AddVenueEvent} meta={{auth: true}} />
                <GuardedRoute exact path='/event/edit/:venueEventId' component={EditVenueEvent} meta={{auth: true}} />

                <GuardedRoute path='*' component={NotFound} />

            </Switch>
        </GuardProvider>
    )
}

export default MyRoutes
