import { venueReducer } from './venue.reducer';
import { venueLocationReducer } from './venue.location.reducer';
import { scheduleTaskReducer as scheduleTasks } from './schedule.tasks.reducer';
import { userManagementReducer as userManagement } from './user.management.reducer';
import { locationMappingReducer as locationMapping } from './location.mapping.reducer';
import { dashboardReducer as dashboards } from './dashboard.reducer';
import { indicatorsReducer as indicators } from './indicators.reducer';
import { mapReducer as maps } from './map.reducer';
import { tableauReportingReducer as tableauReporting } from './tableauReporting.reducer';
import { mapWidgetReducer as mapWidget } from './map.widget.reducer';
import { reportingReducer as reporting } from './reporting.reducer';
import { widgetTokenReducer as widgetToken } from './widget.token.reducer';
import { venueModeReducer as venueMode } from './mode.reducer';
import { venueUserReducer as venueUser } from './venue.user.reducer';
import widgetDataReducer from './widget.data.reducer';
import widgetTypeConstants from '../_constants/widget.type.constants';
import factorReducer from './factor.reducer';
import { notificationReducer as notifications }  from './notification.reducer';

import { combineReducers } from 'redux';

const nameOf = (obj, field) => Object.entries(obj).find(entry => entry[1] === field)[0];

const reducers = {
    venueReducer,
    venueLocationReducer,
    scheduleTasks,
    userManagement,
    locationMapping,
    dashboards,
    indicators,
    maps,
    mapWidget,
    reporting,
    widgetToken,
    venueMode,
    venueUser,
    tableauReporting,
    factorReducer,
    notifications,
};

reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetOccupancy)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetOccupancy, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetTrafficInOut)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetTrafficInOut, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetFootfall)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetFootfall, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetNumber)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetNumber, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetMap)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetMap, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetLabel)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetLabel, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetCamera)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetCamera, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetEmbedding)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetEmbedding, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetDemographic)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetDemographic, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetQueueModel)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetQueueModel, state, action);
reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetPatronDistribution)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetPatronDistribution, state, action);

reducers[nameOf(widgetTypeConstants, widgetTypeConstants.widgetSample)] =
    (state, action) => widgetDataReducer(widgetTypeConstants.widgetSample, state, action);

const allReducers = combineReducers(reducers);

export default allReducers;
