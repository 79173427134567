import axios from "axios";
import { AuthService} from ".";
import { ApiRootConstants } from "../_constants/index";

const constants = {
  api: `${ApiRootConstants.venueManagementApiRoot}api/VenueCalcMetrics`,
};

const authService = new AuthService();

async function axiosConfig() {
  const user = await authService.getUser();
  if (user?.access_token) {
    const config = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data",
      },
    };

    return config;
  }
}

function axiosErrHandler(error) {
  console.error(error);
  if (error.response) {
    if (error.response.status === 401) {
      throw new Error("User is not logged in");
    } else if (error.response.status === 422) {
      throw new Error(error.response.data.message);
    }
  }

  throw error;
}

async function axiosGet(url) {
  const config = await axiosConfig();
  if (config) {
    return await axios
      .get(`${constants.api}/${url}`, config)
      .catch(error => axiosErrHandler(error));
  }
}

async function axiosPut(url, data) {
  const config = await axiosConfig();
  if (config) {
    return await axios
      .put(`${constants.api}/${url}`, data, config)
      .catch(error => axiosErrHandler(error));
  }
}

async function axiosPost(url, data) {
  const config = await axiosConfig();
  if (config) {
    return await axios
      .post(`${constants.api}/${url}`, data, config)
      .catch(error => axiosErrHandler(error));
  }
}

export async function getCalcMetricsSettings(venueId) {
  return axiosGet(`GetSettings/${venueId}`);
};

export async function updateCalcMetricsSettings(settings) {
  return axiosPut(`UpdateSettings`, settings);
};

export async function recalculateMetrics(venueId, startingFrom) {
  return axiosPost(`RecalculateMetrics/${venueId}/${startingFrom.toISOString()}`);
};
