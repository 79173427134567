import React, { useRef, useState, useLayoutEffect, useEffect, useContext } from "react"
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { EmptyContainer } from "../EmptyContainer";
import useResizeObservedRef from "../../../_helpers/useResizeObservedRef";
import { getContrastTextColor } from "../../../_helpers/colorUtils";
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import WidgetContext from "../WidgetContext";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.common,
    ...theme.widget.patronDistribution,
    container: { ...theme.widget.patronDistribution.container },
    white: theme.palette.common.white,
    emptyContainerBorderColor: {
        borderWidth: "1px",
        borderStyle: "solid",
        borderColor: theme.palette.widget.borderColor,
    },
    ...theme.widget.flashingWidget,
}));

// define initial values
const BASE_WIDGET_SIZE = 100;

const WidgetPatronDistributionContainer = (props) => {
    const { width, height, data, settings, viewMode, brandingSettings, style, children, } = props;

    const location = settings?.RefLocation;
    const baselineLocation = settings?.RefBaseLineLocation;


    const noData = !(location && baselineLocation);

    const alertRule = data?.alertRule;

    const baseFontSize = 7.5;
    const isFlash = alertRule?.color?.flash ? true : false;
    const isViewMode = viewMode === appViewModeConstants.VIEW_MODE;

    const [scaleFactor, setScaleFactor] = useState(1);
    const [fontSize, setFontSize] = useState(baseFontSize);

    const targetRef = useRef();
    const widgetContextData = useContext(WidgetContext);

    const applyScaleFactor = (width, height) => setScaleFactor((width > height ? width : height) / BASE_WIDGET_SIZE);
    useEffect(() => setFontSize(baseFontSize * scaleFactor), [scaleFactor, baseFontSize, setFontSize]);

    const ref = useResizeObservedRef(item => applyScaleFactor(item.width, item.height));

    // use this hook to set initial font sizes by applying scale factor based on current widget size. 
    // this is used when widget renders for the first time on viewing dashboard
    useLayoutEffect(() => {
        if (targetRef.current) {
            applyScaleFactor(targetRef.current.offsetWidth, targetRef.current.offsetHeight);
        }
    }, []);

    const bgColor = noData || !isViewMode ? null : (alertRule?.color?.value ?? null);
    const textColor = bgColor == null ? null : getContrastTextColor(bgColor);

    const classes = useStyles({ width, fontSize, ...brandingSettings });

    // Handling caption color depending on widget's background color.
    widgetContextData.setCaptionColor(bgColor === '#ffffff' ? "rgba(98, 126, 132, 1)" : (textColor ?? "rgba(98, 126, 132, 1)"));
    if (isFlash) {
        widgetContextData.setCaptionColor("rgba(98, 126, 132, 1)");
    }

    return (
        <>
            <Box
                display={'flex'}
                className={classes.container +
                    ` ${classes.emptyContainerBorderColor} ` +
                    ` ${isViewMode && !noData && isFlash ? classes.flashEffect : ''} `}
                height={height}
                bgcolor={bgColor}
                color={textColor}
                ref={ref}
            >
                {!noData ? (
                    <Box
                        className={classes.containerBox}
                        height={height}
                        ref={targetRef}
                        style={style}
                    >
                        {React.cloneElement(React.Children.only(children), { ...props, classes, textColor, bgColor })}
                    </Box>
                ) : (
                    <EmptyContainer {...props} />
                )}
            </Box>
        </>
    );
}

export default WidgetPatronDistributionContainer;
