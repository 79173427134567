import { AuthService } from "./auth.service";
import axios from "axios";
import { ApiRootConstants } from "../_constants/index";
import { v4 as uuidv4 } from 'uuid';

var Constants = {
  venueManagementApiRoot: ApiRootConstants.venueManagementApiRoot
};

const authService = new AuthService();

export async function getTimeZones() {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(Constants.venueManagementApiRoot + `api/Venue/GetTimeZones`, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getVenues(offset) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .get(Constants.venueManagementApiRoot + `api/Venue/?limit=${50}&offset=${offset}`, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getVenueById(venueId, token = null) {
  if (token === null) {
    const user = await authService.getUser();
  if (user && user.access_token) {
    token = user.access_token;
  }
  const options = {
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    },
  };

    return await axios
      .get(Constants.venueManagementApiRoot + "api/Venue/" + venueId, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function addVenue(venue) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(Constants.venueManagementApiRoot + "api/Venue", venue, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function updateVenue(venue) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    var businessHoursStart = venue.businessHoursStart.split(":");
    venue.businessHoursStart = businessHoursStart[0] + ":" + businessHoursStart[1] + ":00";

    var businessHoursEnd = venue.businessHoursEnd.split(":");
    venue.businessHoursEnd = businessHoursEnd[0] + ":" + businessHoursEnd[1] + ":00";

    return await axios
      .put(Constants.venueManagementApiRoot + "api/Venue", venue, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function duplicateVenue(duplicateRequest) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    return await axios
      .post(`${Constants.venueManagementApiRoot}api/Venue/DuplicateVenue`, duplicateRequest, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function setVenueStatus(venueId, activeFlag) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = new FormData();
    formData.set("venueID", venueId);
    formData.set("venueStatus", activeFlag ? "Active" : "Inactive");

    return await axios
      .put(Constants.venueManagementApiRoot + "api/SetVenueStatus", formData, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function getVenueDataSourceConfigList(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    const params = null;

    return await axios
      .get(`${ApiRootConstants.venueManagementApiRoot}api/Venue/${venueId}/VenueDataSourceConfigList`, {
        headers: headers,
        params: params
      })

  }
}

export async function getVenueBrandingSettings(venueId, token = null) {
  if (token === null) {
    // when token is null, get it from user
    const user = await authService.getUser();
    if (user && user.access_token) 
      token = user.access_token;
  }
  
  if (token !== null) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + token,
    };
    const params = null;

    return await axios
      .get(`${ApiRootConstants.venueManagementApiRoot}api/Venue/${venueId}/GetVenueBrandingSettings`, {
        headers: headers,
        params: params
      })
  }
}

export async function getVenueBrandingMainLogo(venueId, token = null) {
  if (token === null) {
    // when token is null, get it from user
    const user = await authService.getUser();
    if (user && user.access_token) 
      token = user.access_token;
  }

  if (token !== null) {
    const getMainLogoOptions = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
      params: {
        venueId: venueId
      },
    };

    return await axios
      .get(`${ApiRootConstants.venueManagementApiRoot}api/Venue/${venueId}/GetMainLogo`,
        getMainLogoOptions
      )
  }
}

export async function getVenueBrandingAlternativeLogo(venueId, token = null) {
  if (token === null) {
    // when token is null, get it from user
    const user = await authService.getUser();
    if (user && user.access_token)
      token = user.access_token;
  }

  if (token !== null) {
    const getMainLogoOptions = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
      params: {
        venueId: venueId
      },
    };

    return await axios
      .get(`${ApiRootConstants.venueManagementApiRoot}api/Venue/${venueId}/GetAlternativeLogo`,
        getMainLogoOptions
      )
  }
}

export async function getVenueBrandingFooterLogo(venueId, token = null) {
  if (token === null) {
    // when token is null, get it from user
    const user = await authService.getUser();
    if (user && user.access_token)
      token = user.access_token;
  }

  if (token !== null) {
    const getMainLogoOptions = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
      },
      responseType: "blob",
      params: {
        venueId: venueId
      },
    };

    return await axios
      .get(`${ApiRootConstants.venueManagementApiRoot}api/Venue/${venueId}/GetFooterLogo`,
        getMainLogoOptions
      )
  }
}

export async function updateVenueBrandingSettings(venueBrandingSettings, token = null) {
  if (token === null) {
    // when token is null, get it from user
    const user = await authService.getUser();
    if (user && user.access_token)
      token = user.access_token;
  }

  if (token !== null) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + token,
        "Content-Type": "multipart/form-data"
      },
    };

    const formData = new FormData();

    formData.append("Id", uuidv4());
    formData.append("VenueId", venueBrandingSettings.venueId);
    formData.append("MainLogo", venueBrandingSettings.mainLogo);
    formData.append("AlternativeLogo", venueBrandingSettings.alternativeLogo);
    formData.append("FooterLogo", venueBrandingSettings.footerLogo);
    formData.append("Settings", venueBrandingSettings.settings);

    return await axios
      .put(
        `${ApiRootConstants.venueManagementApiRoot}api/UpdateVenueBrandingSettings`,
        formData,
        options
      )
      .catch((error) => {
        console.error(error);
        if (error.response.status === 401) {
          throw new Error("User is not logged in");
        } else if (error.response.status === 422) {
          throw new Error(error.response.data.message);
        }

        throw error;
      });
  }
}

export async function updateARMApiSettings(venueId, ARMResourceLineageId, IsUseTestingData, IsUseARMApi) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = new FormData();
    formData.set("VenueID", venueId);
    formData.set("ARMResourceLineageId", ARMResourceLineageId);
    formData.set("IsUseTestingData", IsUseTestingData);
    formData.set("IsUseARMApi", IsUseARMApi);
    return await axios
      .put(Constants.venueManagementApiRoot + "api/Venue/UpdateARMApiSettings", formData, options)
      .catch((error) => {
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}

export async function updateAlertSettings(venueId, smsEmailThreshold) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
        ContentType: "multipart/form-data"
      },
    };

    const formData = new FormData();
    formData.set("venueId", venueId);
    formData.set("smsEmailThreshold", smsEmailThreshold);

    return await axios
      .put(Constants.venueManagementApiRoot + "api/Venue/UpdateAlertSettings", formData, options)
      .catch((error) =>{
        console.error(error);
        if (error.response) {
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }
        }

        throw error;
      });
  }
}
