import React from 'react';
import { Settings } from '../Settings';
import {ReportViewSettings} from "../ReportViewSettings";
import DataWarehouse from "./DataWarehouse";
import TableauApi from './TableauApi';
import { SCOPES } from "../../../../../_constants/user.permissions.constants";

export const Reports = () => {

  return (
    <Settings
      pageName="reports"
      title="Reports"
      tabs={[{
        name: "configureReports",
        title: "CONFIGURE REPORTS",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_REPORT_VIEW_SETTINGS],
        component: ReportViewSettings
      }, {
        name: "dataWarehouse",
        title: "DATA WAREHOUSE",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_PUBLISH_DATA_FOR_REPORTS],
        component: DataWarehouse
      }, {
        name: "tableauApi",
        title: "TABLEAU API",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_TABLEAU_API_SETTINGS],
        component: TableauApi
      }]}
    />
  );
};

export default Reports;