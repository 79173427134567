import React, { useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  Box,
  Chip,
} from '@material-ui/core';
import SelectionOptionText from "./common/SelectionOptionText";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  multipleSelectionOptionChip: {
    borderRadius: "3px",
    backgroundColor: "#E9ECF2",
  },
  selected: {
    '&.Mui-selected': {
      backgroundColor: "#EFF3F6",
      '&:hover': {
        backgroundColor: "#EFF3F6",
      }
    },
  },
}));

const getChip = (item, classes) => {
  return item &&
    <Chip
      key={item.id}
      className={classes.multipleSelectionOptionChip}
      label={item.name}
    />
};

const MultipleSelectSetting = ({
  widgetSettingWithValue,
  handleSettingValueChange,
  selectionOptions,
  required,
  additionalSettingsLogic,
}) => {
  const classes = useStyles();

  /*
  Below is defined any additional logic for this setting from prop "additionalSettingsLogic".
  */
  useEffect(() => {
    if (widgetSettingWithValue?.value?.length === 0 && additionalSettingsLogic.checkedItems.length > 0) {
      handleSettingValueChange(widgetSettingWithValue, additionalSettingsLogic.checkedItems);
    }
  }, [])

  return (
    <FormControl
      variant="outlined"
      style={{ width: "100%" }}
      fullWidth
      required={required}
    >
      <InputLabel id={widgetSettingWithValue.id}>{widgetSettingWithValue.name}</InputLabel>
      <Select
        MenuProps={{
          style: {
            maxHeight: "75%",
          },
          /*
          anchorOrigin: {
            vertical: "bottom",
          },
          */
          getContentAnchorEl: () => null,
        }}
        multiple
        labelId={widgetSettingWithValue.id}
        label={widgetSettingWithValue.name}
        value={widgetSettingWithValue?.value?.filter(valId => selectionOptions?.find(opt => opt.id === valId)) ?? []}
        onChange={(event) => handleSettingValueChange(widgetSettingWithValue, event.target.value)}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 6 }}>
            {selected.map((id) => getChip(selectionOptions?.find(e => e.id === id), classes))}
          </Box>
        )}
      >
        {selectionOptions.map(item => {
          return (
            <MenuItem key={item.id} value={item.id} className={classes.selected}>
              <Checkbox
                checked={
                  widgetSettingWithValue?.value?.includes(item.id)
                }
              />
              <ListItemText primary={
                <SelectionOptionText
                  settingClassName={widgetSettingWithValue.className}
                  item={item}
                />
              } />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default MultipleSelectSetting;
