import React from 'react';
import { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import {getTableauToken} from "../../_services/report.service";
import { useParams } from "react-router";
import { makeStyles } from '@material-ui/core';
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    tableauViz: {
      width: "100%",
      height: "calc(100vh - 110px)"
    },
    errorHeader: {
    },
    errorMessage: {
      marginTop: theme.spacing(1)
    }
  };
});

export const TableauReport =() => {
  const classes = useStyles();
  const { tableauViewId } = useParams();

  const selectedVenue = useSelector((state) => state.venueReducer.selectedVenue);

  const tableauView = useSelector((state) => 
    state.venueReducer.reportViews.find(tv => tv.id === tableauViewId)
  );

  const [viz, setViz] = useState(null)
  const [token, setToken] = useState("");
  const [error, setError] = useState(null);

  const loadViz = (token) => {
    setViz(
      <tableau-viz 
        className={classes.tableauViz}
        id="tableauViz"       
        src={tableauView.url}
        device="desktop"
        token={token}
        toolbar='top'
        // hide-tabs={true}
        // touch-optimize={false}
        // disable-url-actions={true}
      ></tableau-viz>
    );
  };

  useEffect(() => {

    async function callGetTableauToken() {
      try 
      {
        const token = await getTableauToken(selectedVenue?.id);
        setToken(token.data);
      } catch (error) {
        setError(error);
      }
    };
    callGetTableauToken();
  }, []);

  useEffect(() => {
    if( token ) {
      loadViz(token);
    }   
  }, [token]);

  return (
    <>
      {error ? 
        <div>
          <Typography variant='h5' className={classes.errorHeader}>Failed to load Tableau report</Typography>
          <Typography className={classes.errorMessage}>{error.message}</Typography>
        </div>
        :
        <div className={classes.tableauViz}>
          {viz}
        </div>
      }
    </>
  )
};

export default TableauReport;
