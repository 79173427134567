import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { EmptyContainer } from "../EmptyContainer";

const useStyles = makeStyles((theme) => ({
    ...theme.widget.embeddedWidget,
}));

export const WidgetEmbeddingContent = (props) => {
    const { width, height, viewMode, settings, brandingSettings, setIsCaptionTextVisible } = props;

    const classes = useStyles({ props, ...brandingSettings });
    const bgcolorClass = classes.defaultBackground;

    let embeddingUrl = settings?.WidgetSettingEmbeddingUrl;

    useEffect(() => {
        setIsCaptionTextVisible(!embeddingUrl);
    }, [setIsCaptionTextVisible, viewMode, embeddingUrl])

    return (
        <Box className={classes.container}>
            {
                embeddingUrl ?
                    <iframe
                        src={embeddingUrl}
                        width={width}
                        height={height}
                        frameBorder="0"
                    >
                    </iframe>
                    :
                    <Box display="flex" className={`${classes.container} ${bgcolorClass}`}>
                        <EmptyContainer {...props} />
                    </Box>
            }
        </Box>
    );
};

export default WidgetEmbeddingContent;