import axios from "axios";
import React, { useCallback, useEffect, useState } from 'react';
import { Box } from "@material-ui/core";
import ReactTimeout from 'react-timeout';
import LoadPleaseWait from '../../notification/LoadingPleaseWait/LoadingMessage';
import { AuthService } from "../../../_services/auth.service";
import devconsole from "../../_common/devconsole";

const log = function () { devconsole.log('TimeRefreshingImage', ...arguments); }

const authService = new AuthService();

const requestHttpWithToken = async (config, access_token) => {
    config.headers = {
        Accept: "application/json",
    }

    if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
    }

    return await axios.request(config);
}

const requestHttp = async (config, token) => requestHttpWithToken(config, token ?? (await authService.getUser())?.access_token);
const getHttp = async (url, token) => await requestHttp({ method: 'get', url }, token);

const requestImageJson = (url, token) => getHttp(url, token);

//////////// For debug/test purposes only ////////////////////////
//
const requestImageJsonMock = (url, token) => {

    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 200; // Adjust the dimensions as needed
    canvas.height = 200;

    const context = canvas.getContext('2d');

    // Generate random pixel data
    const imageData = context.createImageData(canvas.width, canvas.height);
    for (let i = 0; i < imageData.data.length; i += 4) {
        imageData.data[i] = Math.floor(Math.random() * 256); // Red channel
        imageData.data[i + 1] = Math.floor(Math.random() * 256); // Green channel
        imageData.data[i + 2] = Math.floor(Math.random() * 256); // Blue channel
        imageData.data[i + 3] = 255; // Alpha channel (fully opaque)
    }

    // Put the pixel data onto the canvas
    context.putImageData(imageData, 0, 0);

    // Convert the canvas content to base64 image
    const base64Image = canvas.toDataURL('image/png');

    return new Promise(resolve => setTimeout(resolve, Math.random() * 1000 + 500, {
            data: {
                image: base64Image.substring('data:image/png;base64,'.length)
            }
        }));
}
//
////////////////////////////////////////////////////////

const TimeRefreshingImage = (props) => {
    const { width, height, url, timeout } = props;

    const [imageBase64, setImageBase64] = useState(null);
    const [error, setError] = useState(null);
    const [refreshNum, setRefreshNum] = useState(0);
    const [accessToken, setAccessToken] = useState(null);

    const refreshImage = useCallback(() => {
        props.setTimeout(() => setRefreshNum(n => n + 1), timeout);
    }, [setRefreshNum, timeout, props]);

    const onError = useCallback(err => {
        console.error('TimeRefreshingImage', err);
        setImageBase64(null);
        setError(err);
        props.setTimeout(refreshImage, 1000);
    }, [setError, refreshImage, setImageBase64, props]);

    const onLoad = useCallback(() => {
        setError(null);
        refreshImage();
    })

    useEffect(() => {
        props.setInterval(() => 
            authService.getUser().then(tkn => setAccessToken(tkn)), 
            60000);
    }, []);

    useEffect(() => {
        requestImageJson(url, accessToken)
            .then(response => {
                if (response.data.image) {
                    if (response.data.image == imageBase64) {
                        refreshImage();
                    }
                    else {
                        setImageBase64(response.data.image);
                    }
                } else {
                    onError({error: 'No image'});
                }
            }).catch(err => onError(err));
    }, [refreshNum]);

    return (
        <LoadPleaseWait show={!imageBase64 || error} text={error && 'Error occurred, reconnecting...'} >
            <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <img style={{width, height}} alt=''
                    src={`data:image/png;base64,${imageBase64}`}
                    onLoad={onLoad}
                    onError={onError} />
            </Box>
        </LoadPleaseWait>
    );
}

export default ReactTimeout(TimeRefreshingImage);
