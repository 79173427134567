import axios from "axios";
import { AuthService } from "../_services/auth.service";
import { widgetDataConstants, widgetTypeConstants } from "../_constants";
import * as dashboardApi from "../_services/dashboard.service";
import { updateDashboardLastUpdatedSuccess, loadDashboardDataStarted, loadDashboardDataSuccess, loadDashboardDataFailed, updateDashboardSuccess, updateDashboardLastRequestedSuccess } from "./dashboard.actions";
import devconsole from "../components/_common/devconsole";

const authService = new AuthService();

const requestHttpWithToken = async (config, access_token) => {
    config.headers = {
        Accept: "application/json",
    }

    if (access_token) {
        config.headers.Authorization = `Bearer ${access_token}`;
    }

    return await axios.request(config);
}

const requestHttp = async (config) => requestHttpWithToken(config, (await authService.getUser())?.access_token);

export const loadWidgetDataStarted = (widgetTypeId, widgetId) => ({
    type: `${widgetDataConstants.LOAD_DATA_STARTED}${widgetTypeId}`,
    widgetId
});

export const loadWidgetDataSuccess = (widgetTypeId, widgetId, data) => ({
    type: `${widgetDataConstants.LOAD_DATA_SUCCESS}${widgetTypeId}`,
    widgetId,
    data
});

export const loadWidgetSettingsStarted = (widgetTypeId) => ({
    type: `${widgetDataConstants.LOAD_WIDGET_SETTINGS_STARTED}${widgetTypeId}`,
});
export const loadWidgetSettingsSuccess = (widgetTypeId, settings) => ({
    type: `${widgetDataConstants.LOAD_WIDGET_SETTINGS_SUCCESS}${widgetTypeId}`,
    settings
});

export const loadWidgetSettingsValuesStarted = (widgetTypeId, widgetId) => ({
    type: `${widgetDataConstants.LOAD_WIDGET_SETTINGS_VALUES_STARTED}${widgetTypeId}_${widgetId}`,
    widgetId
});
export const loadWidgetSettingsValuesSuccess = (widgetTypeId, widgetId, settingsValues) => ({
    type: `${widgetDataConstants.LOAD_WIDGET_SETTINGS_VALUES_SUCCESS}${widgetTypeId}_${widgetId}`,
    widgetId,
    settingsValues
});

export const getWidgetTokenStarted = (widgetId, client_id) => ({
    type: widgetDataConstants.GET_TOKEN_STARTED,
    widgetId,
    client_id
});

export const widgetTokenFailed = (widgetId, client_id) => ({
    type: widgetDataConstants.GET_TOKEN_FAILED,
    widgetId,
    client_id
});

export const getWidgetTokenSuccess = (widgetId, client_id, access_token) => ({
    type: widgetDataConstants.GET_TOKEN_SUCCESS,
    widgetId,
    client_id,
    access_token
});

export const updateWidgetSettingsValuesSuccess = (widgetTypeId, widgetId, settingsValues) => {
    return {
        type: `${widgetDataConstants.UPDATE_WIDGET_SETTINGS_VALUES_SUCCESS}${widgetTypeId}_${widgetId}`,
        widgetTypeId: widgetTypeId,
        widgetId: widgetId,
        settingsValues: settingsValues
    };
};

export const getWidgetToken = (widgetId, widgetSecurity) => {
    return async (dispatch) => {
        dispatch(getWidgetTokenStarted(widgetId, widgetSecurity.client_id));
        devconsole.log("------------------------> Widget Security", widgetSecurity);
        const params = new URLSearchParams({
            grant_type: 'client_credentials',
            client_id: widgetSecurity.client_id,
            client_secret: widgetSecurity.client_secret,
            scope: widgetSecurity.scope
        });

        return axios({
            method: 'post',
            url: widgetSecurity.url,
            data: params
        })
            .then(response => {
                devconsole.log("FROM API TOKEN", response.data.access_token);
                dispatch(getWidgetTokenSuccess(widgetId, widgetSecurity.client_id, response.data.access_token));
            })
            .catch(err => {
                console.error(err);
                dispatch(widgetTokenFailed(widgetId, widgetSecurity.client_id));
            })
    }
}

export const loadWidgetTypeSettings = (widgetTypeId) => {
    return async (dispatch) => {
        dispatch(loadWidgetSettingsStarted(widgetTypeId));
        await dashboardApi
            .getWidgetSettings(widgetTypeId)
            .then((response) => {
                dispatch(loadWidgetSettingsSuccess(widgetTypeId, response));
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const loadAllWidgetTypesSettings = () => {
    return async (dispatch) => {
        await dashboardApi
            .getAllWidgetTypesSettings()
            .then((response) => {
                for(var widgetTypeId in response) {
                    dispatch(loadWidgetSettingsSuccess(widgetTypeId, response[widgetTypeId]));
                }
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const loadWidgetSettingsValues = (widgetTypeId, widgetId) => {
    return async (dispatch) => {
        dispatch(loadWidgetSettingsValuesStarted(widgetId, widgetTypeId));
        await dashboardApi
            .getWidgetSettingsValues(widgetId)
            .then((response) => {
                dispatch(loadWidgetSettingsValuesSuccess(widgetTypeId, widgetId, response));
            })
            .catch((error) => {
                throw error;
            });
    };
}

export const updateWidgetSettingValue = (widgetTypeId, widgetId, widgetSettingValues) => {
    return async (dispatch) => {
        await dashboardApi
            .updateWidgetSettingValue(widgetSettingValues)
            .then((updatedValues) => {
                dispatch(updateWidgetSettingsValuesSuccess(widgetTypeId, widgetId, updatedValues.data));
                dispatch(loadWidgetDataSuccess(widgetTypeId, widgetId, null));
            })
            .catch((error) => {
                throw error;
            });
    };
};

export const updateRefListWidgetSettingValue = (widgetSettingValues) => {
    return async (dispatch) => {
        await dashboardApi
            .updateRefListWidgetSettingValue(widgetSettingValues)
            .catch((error) => {
                throw error;
            });
    };
};

export const updateRefTableWidgetSettingValue = (widgetTypeId, widgetId, widgetSettingValues) => {
    return async (dispatch) => {
        await dashboardApi
            .updateRefTableWidgetSettingValue(widgetSettingValues)
            .then((updatedValues) => {
                dispatch(updateWidgetSettingsValuesSuccess(widgetTypeId, widgetId, updatedValues.data));
                dispatch(loadWidgetDataSuccess(widgetTypeId, widgetId, null));
            })
            .catch((error) => {
                throw error;
            });
    };
};

//
// widgetsData (C# WidgetDataDto[]) format:
// [
//  {
//    widgetType,
//    widgetId,
//    widgetData
//  }, ...
// ]
//

export const refreshDashboardWidgetsData = (dashboardId, access_token, clearCache = false) => 
    _refreshDashboardWidgetsData(dashboardId,
        dashboardApi.loadDashboardWidgetsAccumulatedData(dashboardId, access_token, clearCache));

export const checkDashboardCacheAndRefresh = (dashboardId, sessionId, access_token, clearCache = false) => 
    _refreshDashboardWidgetsData(dashboardId,
        dashboardApi.checkDashboardCache(dashboardId, sessionId, access_token, clearCache));
    
const _refreshDashboardWidgetsData = (dashboardId, widgetsDataGetterPromise) => {
    return async (dispatch) => {
        dispatch(loadDashboardDataStarted(dashboardId));
        await widgetsDataGetterPromise
            .then(dashboardWidgetsData => {
                dashboardWidgetsData.widgetsData.forEach(
                    d => {
                        const widgetTypeId = isNaN(d.widgetType) ? widgetTypeConstants[d.widgetType].id : d.widgetType;
                        dispatch(loadWidgetSettingsValuesSuccess(widgetTypeId, d.widgetId, d.settings));
                        dispatch(loadWidgetDataSuccess(widgetTypeId, d.widgetId, {...d.widgetData, isRefreshing: d.isRefreshing}));
                    });
                
                dispatch(updateDashboardLastUpdatedSuccess(dashboardWidgetsData.dashboardId, dashboardWidgetsData.lastUpdated));
                dispatch(updateDashboardLastRequestedSuccess(dashboardWidgetsData.dashboardId, {
                    nextRequestUtc: dashboardWidgetsData.nextRequestUtc,
                    nextRequestSeconds: dashboardWidgetsData.nextRequestSeconds,
                }));
                dispatch(loadDashboardDataSuccess(dashboardId));
                
            })
            .catch(error => {
                dispatch(loadDashboardDataFailed(dashboardId));
                console.error(error);
            });
    }
}
