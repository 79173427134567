import React from 'react';
import { Settings } from '../Settings';
import { ARMApiSettings } from "../ARMApiSettings";
import { SeeTicketsApiSettings } from "./SeeTicketsApiSettings";
import { TicketMasterApiSettings } from './TicketMasterApiSettings';
import { LivDataApiSettings } from './LivDataApiSettings';
import { SCOPES } from "../../../../../_constants/user.permissions.constants";

export const Integration = () => {

  return (
    <Settings
      pageName="integration"
      title="Integration"
      tabs={[{
        name: "armApi",
        title: "ARM API",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_ARM_API_SETTINGS],
        component: ARMApiSettings
      }, {
        name: "seeTicketsApi",
        title: "SEE TICKETS API",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_SEE_TICKETS_API_SETTINGS],
        component: SeeTicketsApiSettings
      }, {
        name: "ticketMasterApi",
        title: "TICKETMASTER API",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_TICKET_MASTER_API_SETTINGS],
        component: TicketMasterApiSettings
      }, {
        name: "LivDataApi",
        title: "LIV DATA API",
        scopes: [SCOPES.CAN_FULL_MANAGEMENT, SCOPES.CAN_SETUP_LIV_DATA_API_SETTINGS],
        component: LivDataApiSettings
      }]}
    />
  );
};

export default Integration;