import { venueConstants } from "../_constants/venue.constants";

const initialState = {
  venues: [],
  timeZones: [],
  venueStatuses: [],
  error: null,
  isAdded: false,
  isUpdated: false,
  selectedVenueID: null,
  selectedVenue: null,
  loading: {
    venueLoading: false,
    venuesLoading: false,
  },
  hasMore: true,
  offset: 0,
  preferences: {
    hideInactiveVenues: true
  },
  venueBrandingSettings: {
    mainLogoBlobUrl: "",
    alternativeLogoBlobUrl: "",
    footerLogoBlobUrl: "",
    settings: "",
  },
  reportViews: [],
  userGroups: [],

  // This object will store the tabs selected in the Settings pages.
  selectedSettingsTabs: {
    // venues: "overview",
    // dataSources: "ai-streams",
    // reports: "configure-reports",
    // alerts: "twilio-api",
    // integrations: "arm-api"
  }
};

export function venueReducer(state = initialState, action) {
  switch (action.type) {
    case venueConstants.GET_VENUES_STARTED:
      return {
        ...state,
        loading: { ...state.loading, venuesLoading: true },
      };
    case venueConstants.GET_TIMEZONES_STARTED:
      return {
        ...state,
        loading: { ...state.loading, timeZonesLoading: true },
      };
    case venueConstants.GET_TIMEZONES:
      return {
        ...state,
        timeZones: [...action.timeZones],
        loading: { ...state.loading, timeZonesLoading: false },
      };
    case venueConstants.GET_VENUES:
      return {
        ...state,
        venues: [...action.venues],
        loading: { ...state.loading, venuesLoading: false },
      };
    case venueConstants.ADD_VENUE:
      return {
        ...state,
        venues: [...state.venues, action.venue],
      };
    case venueConstants.UPDATE_VENUE:
      return {
        ...state,
        venues: state.venues.map((e) =>
          e.id === action.venue.id ? action.venue : e
        ),
        selectedVenue: action.venue.id === state.selectedVenue?.id? action.venue : state.selectedVenue
        //selectedVenueID: action.venue.id,
        // venues
      };
    case venueConstants.UPDATE_VENUE_STATUS:
      return {
        ...state,
        venues: state.venues.map((e) =>
          e.id === action.venue.id ? action.venue : e
        ),
      };
    case venueConstants.GET_VENUE_STARTED:
      return {
        ...state,
        loading: { ...state.loading, venueLoading: true },
      };
    case venueConstants.SELECT_VENUE:
      return {
        ...state,
        selectedVenueID: action.selectedVenue?.id,
        selectedVenue: action.selectedVenue,
        loading: { ...state.loading, venueLoading: false },
      };
    case venueConstants.SELECT_SETTINGS_TAB:
      return {
        ...state,
        selectedSettingsTabs: {
          ...state.selectedSettingsTabs,
          [action.pageName] : action.tabName
        }
      };
    case venueConstants.UPDATE_HIDE_INACTIVE_VENUES:
      return {
        ...state,
        preferences: {
          ...state.preferences,
          hideInactiveVenues: action.hide
        }
      };
    // case venueConstants.GET_VENUE:
    //   return {
    //     ...state,
    //     selectedVenue: action.selectedVenue,
    //     loading: {...state.loading, venueLoading: false}
    //   };
    // case venueConstants.DELETE_VENUE:
    //   return {
    //     ...state,
    //     isDeleted: false
    //   };
    // case venueConstants.GET_VENUE_STATUSES:
    //   return {
    //     ...state,
    //     venueStatuses: action.venueStatuses
    //   };
    case venueConstants.ERROR_HANDLE:
      return {
        ...state,
        error: action.error,
      };
    // case venueConstants.ERROR_RESET:
    //   return {
    //     ...state,
    //     error: null
    //   };
    // case venueConstants.RESET_ALL_VENUES:
    //   return {
    //     ...state,
    //     venues: []
    //   };
    case venueConstants.GET_VENUE_BRANDING_SETTINGS_SUCCESS:
      return {
        ...state,
        venueBrandingSettings: {
          ...state.venueBrandingSettings,
          settings: action.venueBrandingSettings ? JSON.parse(action.venueBrandingSettings.settings) : {},
        },
      };

    case venueConstants.GET_VENUE_BRANDING_MAIN_LOGO_SUCCESS:
      return {
        ...state,
        venueBrandingSettings: {
          ...state.venueBrandingSettings,
          mainLogoBlobUrl: action.venueBrandingMainLogo.size !== 0 ? URL.createObjectURL(action.venueBrandingMainLogo) : ""
        },
      };

    case venueConstants.GET_VENUE_BRANDING_ALTERNATIVE_LOGO_SUCCESS:
      return {
        ...state,
        venueBrandingSettings: {
          ...state.venueBrandingSettings,
          alternativeLogoBlobUrl: action.venueBrandingAlternativeLogo.size !== 0 ? URL.createObjectURL(action.venueBrandingAlternativeLogo) : ""
        },
      };

    case venueConstants.GET_VENUE_BRANDING_FOOTER_LOGO_SUCCESS:
      return {
        ...state,
        venueBrandingSettings: {
          ...state.venueBrandingSettings,
          footerLogoBlobUrl: action.venueBrandingFooterLogo.size !== 0 ? URL.createObjectURL(action.venueBrandingFooterLogo) : ""
        },
      };

    case venueConstants.UPDATE_VENUE_BRANDING_SETTINGS_SUCCESS:
      return {
        ...state,
        venueBrandingSettings: {
          ...state.venueBrandingSettings,
          mainLogoBlobUrl: action.venueBrandingSettings.mainLogo && action.venueBrandingSettings.mainLogo.size !== 0 ? URL.createObjectURL(action.venueBrandingSettings.mainLogo) : state.venueBrandingSettings.mainLogoBlobUrl,
          alternativeLogoBlobUrl: action.venueBrandingSettings.alternativeLogo && action.venueBrandingSettings.alternativeLogo.size !== 0 ? URL.createObjectURL(action.venueBrandingSettings.alternativeLogo) : state.venueBrandingSettings.alternativeLogoBlobUrl,
          footerLogoBlobUrl: action.venueBrandingSettings.footerLogo && action.venueBrandingSettings.footerLogo.size !== 0 ? URL.createObjectURL(action.venueBrandingSettings.footerLogo) : state.venueBrandingSettings.footerLogoBlobUrl,
          settings: action.venueBrandingSettings.settings ? JSON.parse(action.venueBrandingSettings.settings) : state.venueBrandingSettings.settings,
        },
      };
    case venueConstants.GET_REPORT_VIEWS_SUCCESS:
      if (state.selectedVenue && state.selectedVenue.id === action.venueId) {
        return {
          ...state,
          reportViews: action.reportViews
        };
      }
    case venueConstants.GET_USER_GROUPS_SUCCESS:
      if (state.selectedVenue && state.selectedVenue.id === action.venueId) {
        return {
          ...state,
          userGroups: action.userGroups
        };
      }
    default:
      return state;
  }
}

export default venueReducer;
