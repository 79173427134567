import React, { useEffect, useState } from "react"
import appViewModeConstants from "../../../_constants/app.view.mode.constants";
import WidgetPatronDistributionContainer from "./WidgetPatronDistributionContainer";
import OccupancyValueComparison from "./OccupancyValueComparison";
import OccupancyPercentage from "./OccupancyPercentage";
import TrafficInOutValueComparison from "./TrafficInOutValueComparison";
import TrafficInOutPercentage from "./TrafficInOutPercentage";
import { metricConstants } from "../../../_constants/metric.constants";

const valueTypePercentage = "PatronDistributionPercentage";
const valueTypeValueComparison = "PatronDistributionCount";

export const WidgetPatronDistributionContent = (props) => {
    const { data, settings, onAudioPlay, viewMode } = props;

    const alertRule = data?.alertRule;

    const [playSound, setPlaySound] = useState(false);

    useEffect(() => {
        setPlaySound(viewMode === appViewModeConstants.VIEW_MODE && alertRule?.sound);
    }, [alertRule?.sound, viewMode, setPlaySound]);

    useEffect(() => {
        if (playSound) {
            onAudioPlay();
        }
    }, [playSound, onAudioPlay]);


    const isTrafficInOut = settings?.RefPatronDistributionMetric?.name === metricConstants.trafficIn.name || settings?.RefPatronDistributionMetric?.name === metricConstants.trafficOut.name;
    const isOccupancy = settings?.RefPatronDistributionMetric?.name === metricConstants.occupancy.name;

    const isValueTypePercentage = settings?.RefPatronDistributionWidgetValueType?.id == valueTypePercentage;
    const isValueTypeValueComparison = settings?.RefPatronDistributionWidgetValueType?.id == valueTypeValueComparison;

    if (isOccupancy && isValueTypeValueComparison)
        return <WidgetPatronDistributionContainer {...props}><OccupancyValueComparison /></WidgetPatronDistributionContainer>
    else if (isOccupancy && isValueTypePercentage)
        return <WidgetPatronDistributionContainer {...props}><OccupancyPercentage /></WidgetPatronDistributionContainer>

    else if (isTrafficInOut && isValueTypeValueComparison)
        return <WidgetPatronDistributionContainer {...props}><TrafficInOutValueComparison /></WidgetPatronDistributionContainer>
    else if (isTrafficInOut && isValueTypePercentage)
        return <WidgetPatronDistributionContainer {...props}><TrafficInOutPercentage /></WidgetPatronDistributionContainer>

    return <WidgetPatronDistributionContainer {...props}><></></WidgetPatronDistributionContainer>
}

export default WidgetPatronDistributionContent;
