import React from 'react';
import {Button} from '@material-ui/core';
import {Dialog, DialogActions, DialogContent, DialogContentText} from '@material-ui/core';

export const ClearLocationsAlert = ({open, onYes, onNo, onCancel}) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              Re-uploading the image may invalidate the locations marked on the map.<br />Do you want to remove all marked locations?<br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onYes} color="primary">
            Yes,<br />remove all locations
          </Button>
          <Button onClick={onNo} color="primary" autoFocus>
            No,<br/>keep marked locations
          </Button>
          <Button onClick={onCancel} color="primary">
            Cancel<br/>image uploading
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ClearLocationsAlert;