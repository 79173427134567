import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Card, CardContent } from "@material-ui/core";
import {
  getVenueLocations,
  deleteLocation,
  restoreLocation,
  exportLocations,
} from "../../../_actions/venue.location.actions";
import { useDispatch, useSelector } from "react-redux";
import TreeView from "@material-ui/lab/TreeView";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Button } from "../../_common/htmlTags";
import {TextLink} from "../../_common/htmlTags/TextLink";
import { VenueLocationTreeItem } from "./VenueLocationTreeItem";
import { toast } from "react-toastify";
import { AlertDialog } from "../../../_helpers/AlertDialog";
import LoadPleaseWait from "../../notification/LoadingPleaseWait/LoadingMessage"

const useStyles = makeStyles((theme) => ({
  root: {
    height: 264,
    flexGrow: 1,
    //maxWidth: 400,
    borderTopColor: theme.palette.grey[300],
    borderTopStyle: "solid",
    borderTopWidth: 1
  },
  deleteErrorSubtitle: {
    marginTop: theme.spacing(1)
  }
}));

export const VenueLocations = () => {
  const selectedVenue = useSelector(
    (state) => state.venueReducer.selectedVenue
  );
  const venueLocations = useSelector(
    (state) => state.venueLocationReducer.venueLocations
  );
  const loading = useSelector((state) => state.venueLocationReducer.loading);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [expandedNodes, setExpandedNodes] = React.useState([]);
  const [deleteOpen, setDeleteOpen] = React.useState(false);
  const [restoreOpen, setRestoreOpen] = React.useState(false);
  const [treeItemId, setTreeItemId] = React.useState(null);
  const [isDataRequested, setIsDataRequested] = React.useState(false);
  const [isExporting, setIsExporting] = React.useState(false);

  useEffect(() => {
    if (selectedVenue !== null) {
        dispatch(getVenueLocations(selectedVenue.id))
            .then(() => setIsDataRequested(true));
    }
  }, [dispatch, selectedVenue, setIsDataRequested]);

  useEffect(() => {

    const getParentNodes = (parentNodes, venueLocations) => {
      if (venueLocations.children === null || venueLocations.children.length === 0) {
        return;
      } else {
        parentNodes.push(venueLocations.id);
        venueLocations.children.forEach((element) => {
          getParentNodes(parentNodes, element);
        });
      }
    };

    const expanded = [];
    if (venueLocations.length > 0 && isDataRequested) getParentNodes(expanded, venueLocations[0]);
    setExpandedNodes(expanded);

  }, [venueLocations, isDataRequested]);

  const sortVenueByNameDelegate = (a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  };

  const handleDelete = (itemId) => {
    if (itemId) {
      try {
        dispatch(deleteLocation(itemId)).then(() => {
          toast.success("Location deleted");
        }).catch((error) => {
          let errorMessage = error.response?.data ?? error.message;

          toast.error(
            <div>
                <div>Cannot delete location.</div>
                <div>
                    {
                      errorMessage.split(/\r?\n/).map((errorMessagePart) => {
                        let usedSomewhereMessage = errorMessagePart.split("||").slice(0, 1);
                        let locations = errorMessagePart.split("||").slice(1);
                        return (
                          <>
                            <div className={classes.deleteErrorSubtitle}>{usedSomewhereMessage}</div>
                            <ul>
                            {
                              locations.map((location, i) => (
                                <li key={i}>{location}</li>
                              ))
                            }
                            </ul>
                          </>
                        );
                      })
                    }
                </div>
            </div>
            , { autoClose: false });
        });
      } catch (error) {
        toast.error("Delete failed. " + error.message, { autoClose: false });
      }
    }
  };

  const handleRestore = (itemId) => {
    if (itemId) {
      try {
        dispatch(restoreLocation(itemId)).then(() => {
          toast.success("Location restored");
        }).catch((error) => {
          toast.error("Restore failed. " + (error.response ? error.response.data : error.message), { autoClose: false });
        });
      } catch (error) {
        toast.error("Restore failed. " + error.message, { autoClose: false });
      }
    }
  };

  const handleSetDeleteTreeItemId = (itemTreeId) => {
    setTreeItemId(itemTreeId);
    setDeleteOpen(true);
  }

  const handleSetRestoreTreeItemId = (itemTreeId) => {
    setTreeItemId(itemTreeId);
    setRestoreOpen(true);
  }

  const renderTree = (treeItems) => {
    return (
      <VenueLocationTreeItem
        key={treeItems.id}
        nodeId={treeItems.id}
        label={treeItems.name}
        type={treeItems.locationType?.name}
        isSensor={treeItems.locationType?.isSensor}
        capacities={{
          maximum: treeItems.maximumCapacity,
        }}
        deleted={treeItems.isDeleted}
        treeItemId={treeItems.id}
        isRootLocation={treeItems.id === venueLocations[0].id}
        onSetDeleteTreeItemId={handleSetDeleteTreeItemId}
        onSetRestoreTreeItemId={handleSetRestoreTreeItemId}
      >
        {Array.isArray(treeItems.children)
          ? treeItems.children
            .sort(sortVenueByNameDelegate)
            .map((treeItem) => renderTree(treeItem))
          : null}
      </VenueLocationTreeItem>
    );
  };

  const handleNodeToggle = (venue, nodeIds) => {
    setExpandedNodes(nodeIds);
  }

  const handleExport = evt => {
    evt.preventDefault();
    if (selectedVenue?.id) {
        setIsExporting(true);
        exportLocations(selectedVenue.id)
            .then(() => toast.success("Locations exported"))
            .catch(error => toast.error("Export failed. " + (error.response ? error.response.data : error.message), { autoClose: false }))
            .finally(() => setIsExporting(false));
    }
  }

  return (
    <>
      {selectedVenue !== null ? (
        <div>
          <AlertDialog open={deleteOpen} contentText={`Do you want to delete this location?`}
            onNoButtonClicked={() => {
              setDeleteOpen(false);
            }}
            onYesButtonClicked={() => {
              handleDelete(treeItemId);
              setDeleteOpen(false);
            }} />
          <AlertDialog open={restoreOpen} contentText={`Do you want to restore this location?`}
            onNoButtonClicked={() => {
              setRestoreOpen(false);
            }}
            onYesButtonClicked={() => {
              handleRestore(treeItemId);
              setRestoreOpen(false);
            }} />
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              md={12}
              container
              justifyContent="space-between"
            >
              <Typography variant="h4">Locations</Typography>
              <Grid
                item
                container
                xs={6}
                md={6}
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item>
                    <Button
                        variant="contained"
                        onClick={handleExport}
                        disabled={isExporting}
                    >
                        {isExporting ? 'Exporting...' : 'Export'}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        component={TextLink}
                        to={"/venueLocationsImport"}
                    >
                        Import
                    </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={8}>
                {loading.locationsLoading || !isDataRequested ?
                    <LoadPleaseWait show={true} />
                : 
                  <Card style={{ height: "80vh", overflow: 'auto'}}>
                    <CardContent>
                        <TreeView
                            className={classes.root}
                            expanded={expandedNodes ?? []}
                            onNodeToggle={handleNodeToggle}
                            defaultCollapseIcon={<ExpandMoreIcon />}
                            defaultExpandIcon={<ChevronRightIcon />}
                        >
                            {venueLocations.length > 0
                            ? renderTree(venueLocations[0])
                            : ""}
                        </TreeView>
                    </CardContent>
                  </Card>
                }
            </Grid>
            
          </Grid>
        </div>
      ) : (
        <div>
          <Typography variant="h4">Please select venue first</Typography>
        </div>
      )}
    </>
  );
};

export default VenueLocations;
