import axios from "axios";
import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";

const Constants = {
  modeApi: `${ApiRootConstants.venueManagementApiRoot}api/`,
};

const authService = new AuthService();

export async function getModeLists(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };

    if (headers !== null) {
      return axios
        .get(
          `${Constants.modeApi}VenueMode/${venueId}`,
          { headers }
        )
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 401) {
              throw new Error("User is not logged in");
            } else if (error.response.status === 422) {
              throw new Error(error.response.data.message);
            }
          }
          throw error;
        });
    }
  }
}

export async function getMode(venueId, modeId) {
  const modeList = await getModeLists(venueId);
  return modeList.filter((item) => item.id === modeId)[0];
}

export async function addMode(venueMode) {
  
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {
   
      return await axios
        .post(`${Constants.modeApi}VenueMode`, venueMode, options)
        .catch((error) => {
          console.error(error);
          if (error.response) {
            if (error.response.status === 400) {
              throw new Error(error.response.data);
            } else if (error.response.status === 401) {
              throw new Error("User is not logged in");
            }
          }
          throw error;
        });
    }
  }
}

export async function updateMode(VenueModeDto) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };


    if (options !== null) {

      return await axios
        .put(
          `${Constants.modeApi}VenueMode`,
          VenueModeDto,
          options
        )
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }

          throw error;
        });
    }
  }
}

export async function deleteMode(modeId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const options = {
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + user.access_token,
      },
    };

    if (options !== null) {

      return await axios
        .delete(`${Constants.modeApi}VenueMode/${modeId}`,options)
        .catch((error) => {
          console.error(error);
          if (error.response.status === 401) {
            throw new Error("User is not logged in");
          }
          else if (error.response.status === 400) {
              throw new Error(error.response.data);            
          } else if (error.response.status === 422) {
            throw new Error(error.response.data.message);
          }

          throw error;
        });
    }
  }
}