import * as dashboardConstants from "../_constants/dashboard.constants";
import * as dashboardAlertRulesConstants from "../_constants/dashboard.alertRuleSet.constants";
import initialState from "../_reducers/initialState";
//import { arrayMove } from 'react-sortable-hoc';

export function dashboardReducer(state = initialState.dashboards, action) {
  switch (action.type) {
    case dashboardConstants.ADD_DASHBOARD_SUCCESS:
      return { ...state, dashboards: [...state.dashboards, action.dashboard] };
    case dashboardConstants.UPDATE_DASHBOARD_SUCCESS:
      const newDashboards =
        state.dashboards.find(it => it.id === action.dashboard.id) ?
          state.dashboards.map(
            t => t.id === action.dashboard.id ?
              {
                ...t,
                ...action.dashboard,
                dashboardWidget: [...(action.dashboard.dashboardWidget ?? t.dashboardWidget ?? [])]
              } : t) :
          [...state.dashboards, action.dashboard];
      return { ...state, dashboards: newDashboards };
    case dashboardConstants.UPDATE_DASHBOARD_LAST_UPDATED_SUCCESS:
      return {
        ...state,
        dashboardsLastUpdated: { ...(state.dashboardsLastUpdated ?? {}), [action.dashboardId]: action.lastUpdated }
      };
    case dashboardConstants.UPDATE_DASHBOARD_LAST_REQUESTED_SUCCESS:
        return {
          ...state,
          dashboardsLastRequested: { ...(state.dashboardsLastRequested ?? {}), [action.dashboardId]: action.lastRequested }
        };
    case dashboardConstants.LOAD_DASHBOARD_SUCCESS:
      return {
        ...state, dashboards: [...action.dashboards],
        loading: { ...(state.loading ?? {}), dashboardLoading: false },
      };
    case dashboardConstants.LOAD_DASHBOARD_DATA_STARTED:
      return {
        ...state,
        loading: { ...(state.loading ?? {}), dashboardDataLoading: true },
      };
    case dashboardConstants.LOAD_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        loading: { ...(state.loading ?? {}), dashboardDataLoading: false },
      };
    case dashboardConstants.LOAD_DASHBOARD_DATA_FAILED:
      return {
        ...state,
        loading: { dashboardDataLoading: false },
      };
    case dashboardConstants.REMOVE_DASHBOARD_OPTIMISTIC:
      return { ...state, dashboards: state.dashboards.filter((t) => t.id !== action.dashboardId) };
    case dashboardConstants.REMOVE_DASHBOARD_RANGE_OPTIMISTIC:
        return { ...state, dashboards: state.dashboards.filter((t) => !action.dashboardIds.find( id => id === t.id)) }; 
    case dashboardConstants.UPDATE_WIDGET_TRAFFIC_TYPE_SUCCESS:
      return { ...state, widgetTrafficTypes: action.widgetTrafficTypes };
    case dashboardConstants.LOAD_OCCUPANCY_VIEW_MODES_SUCCESS:
      return {
        ...state,
        occupancyViewModes: action.occupancyViewModes
      };
    case dashboardConstants.LOAD_TRAFFIC_TYPES_SUCCESS:
      return {
        ...state,
        trafficTypes: action.trafficTypes
      };
    case dashboardConstants.LOAD_COHORTS_SUCCESS:
      return {
        ...state,
        cohorts: action.cohorts
      };
    case dashboardConstants.LOAD_NUMBER_WIDGET_VALUE_TYPES_SUCCESS:
      return {
        ...state,
        numberWidgetValueTypes: action.numberWidgetValueTypes
      };
    case dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_VALUE_TYPES_SUCCESS:
        return {
          ...state,
          demographicWidgetValueTypes: action.demographicWidgetValueTypes
        };
    case dashboardConstants.LOAD_NUMBER_WIDGET_TIME_PERIODS_SUCCESS:
      return {
        ...state,
        numberWidgetTimePeriods: action.numberWidgetTimePeriods
      };
    case dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_TIME_PERIODS_SUCCESS:
        return {
          ...state,
          demographicWidgetTimePeriods: action.demographicWidgetTimePeriods
      };
    
    case dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_AGE_GROUP_SUCCESS:
        return {
          ...state,
          demographicWidgetAgeGroup: action.demographicWidgetAgeGroup
      };
    
    case dashboardConstants.LOAD_DEMOGRAPHIC_WIDGET_GENDER_SUCCESS:
        return {
          ...state,
          demographicWidgetGender: action.demographicWidgetGender
        };
    case dashboardConstants.LOAD_MAP_WIDGET_INDICATORS_SUCCESS:
      return {
        ...state,
        mapWidgetIndicators: action.mapWidgetIndicators
      };
    case dashboardConstants.LOAD_CAMERAS_SUCCESS:
      return {
        ...state,
        cameras: action.cameras
      };

    case dashboardAlertRulesConstants.LOAD_DASHBOARD_ALERT_RULE_SETS_SUCCESS:
      return {
        ...state,
        dashboardAlertRuleSets: action.dashboardAlertRuleSets
      };

    case dashboardConstants.LOAD_QUEUE_MODEL_ALERT_METRICS_SUCCESS:
      return {
        ...state,
        queueModelAlertMetrics: action.queueModelAlertMetrics
      };

    case dashboardConstants.LOAD_TRAFFIC_IN_OUT_ALERT_METRICS_SUCCESS:
      return {
        ...state,
        trafficInOutAlertMetrics: action.trafficInOutAlertMetrics
      };

    case dashboardConstants.LOAD_TRAFFIC_IN_OUT_DISPLAY_MODES_SUCCESS:
      return {
        ...state,
        trafficInOutDisplayModes: action.trafficInOutDisplayModes
      };

    case dashboardConstants.LOAD_PATRON_DISTRIBUTION_VALUE_TYPES_SUCCESS:
      return {
        ...state,
        patronDistributionValueTypes: action.patronDistributionValueTypes
      };

    case dashboardConstants.LOAD_PATRON_DISTRIBUTION_METRICS_SUCCESS:
      return {
        ...state,
        patronDistributionMetrics: action.patronDistributionMetrics
      };

    case dashboardConstants.UPDATE_AFTER_HOURS_WARNING_HEIGHT:
      return {
        ...state,
        afterHoursWarningHeight: action.afterHoursWarningHeight
      };

    //TODO: Replace it with necessary redux update, when there'll be redux for widgets and dashboards
    /*
    case dashboardConstants.UPDATE_WIDGET_SUCCESS:
      var updatedWidget = action.widget;
      var updatedWidgetDashboard = state.dashboards.find(t => t.id === updatedWidget.dashboardId);

      var foundIndex = updatedWidgetDashboard.dashboardWidget.findIndex(x => x.dashboardWidgetId === updatedWidget.dashboardWidgetId);
      updatedWidgetDashboard.dashboardWidget[foundIndex] = updatedWidget;

      return { ...state, dashboards: state.dashboards.map(t => t.id === updatedWidgetDashboard.id ? updatedWidgetDashboard : t) };
    */

      case dashboardConstants.UPDATE_HIDE_INACTIVE_DASHBOARDS:
        return {
          ...state,
          preferences: {
            ...state.preferences,
            hideInactiveDashboards: action.hide
          }
        };

    default:
      return state;
  }
}

export default dashboardReducer;
