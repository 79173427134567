import { AuthService } from "./auth.service";
import { ApiRootConstants } from "../_constants/index";
import axios from "axios";

const authService = new AuthService();

export async function getUserDefaultDashboard(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/UserDefaultDashboard?venueId=${venueId}&userId=${user.profile.sub}`, { headers, })
  }
}

export async function setUserDefaultDashboard(venueId, dashboardId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .post(`${ApiRootConstants.dashboardApiRoot}api/UserDefaultDashboard?venueId=${venueId}&userId=${user.profile.sub}&dashboardId=${dashboardId}`, null,{ headers, })
  }
}

export async function deleteUserDefaultDashboard(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .delete(`${ApiRootConstants.dashboardApiRoot}api/UserDefaultDashboard?venueId=${venueId}&userId=${user.profile.sub}`, {
        headers,
      })
  }
}

// -------------------------------------------- FAVORITE DASHBOARDS FUNCTIONALITY --------------------------------------------
export async function getUserFavoriteDashboards(venueId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .get(`${ApiRootConstants.dashboardApiRoot}api/Dashboard/GetUserFavoriteDashboards?venueId=${venueId}&userId=${user.profile.sub}`, { headers, })
  }
}

export async function setUserFavoriteDashboard(venueId, dashboardId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .post(`${ApiRootConstants.dashboardApiRoot}api/Dashboard/SetUserFavoriteDashboard?venueId=${venueId}&userId=${user.profile.sub}&dashboardId=${dashboardId}`, null,{ headers, })
  }
}

export async function deleteUserFavoriteDashboard(venueId, dashboardId) {
  const user = await authService.getUser();
  if (user && user.access_token) {
    const headers = {
      Accept: "application/json",
      Authorization: "Bearer " + user.access_token,
    };
    return await axios
      .delete(`${ApiRootConstants.dashboardApiRoot}api/Dashboard/DeleteUserFavoriteDashboard?venueId=${venueId}&userId=${user.profile.sub}&dashboardId=${dashboardId}`, {
        headers,
      })
  }
}